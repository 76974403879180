
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row, Switch } from 'antd';

import DvrInstanceTable from './DvrInstancesTable';

export default function AdvancedControlModal({ dvr, onOk }) {
    const [enabledControls, updateEnabledControls] = useState(false);

    return (
        <Modal
            title='Advanced DVR control'
            open={dvr != null}
            className='hook-dvrs-advanceddvrcontrol-modal'
            width={'auto'}
            cancelText='Close'
            style={{ display: 'flex', justifyContent: 'center', maxWidth: '80%' }}
            onCancel={() => {
                onOk();
                updateEnabledControls(false);
            }}
            cancelButtonProps={{ className: 'hook-dvrs-advanceddvrcontrol-close' }}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <Row>
                <Col offset={2} className='advanced-control-text-col-id'>
                    DVR name: {dvr?.name}
                </Col>
            </Row>
            <Row>
                <Col offset={2}>
                    Enable advanced actions:
                </Col>
                <Col offset={3}>
                    <Switch
                        checked={enabledControls}
                        className='hook-dvrs-advanceddvrcontrol-toggleEnable'
                        onChange={ () => { updateEnabledControls(!enabledControls); }}
                    />
                </Col>
            </Row>
            <Row>
                <Col offset={2} className='advanced-control-text-col'>
                    <span className='advanced-control-warning-text'>Warning: Using advanced actions will affect DVR operation.</span>
                </Col>
            </Row>
            <DvrInstanceTable dvr={dvr} enabledControls={enabledControls} showActions={true}/>
        </Modal>
    );
}

AdvancedControlModal.propTypes = {
    dvr: PropTypes.object,
    onOk: PropTypes.func,
};
