import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission } from '../../services';
import { Modal, Table, Button, Row, Col, Tooltip, message, PageHeader, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrashAlt, faExclamationCircle, faPencilAlt, faCopy
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { Ticker, CopyPaster } from '../util';
import EditStatmuxPoolModal from './EditStatmuxPoolModal';
import NewStatmuxPoolModal from './NewStatmuxPoolModal';

const CAN_CREATE_STATMUX_POOL = 'statmux_pools.add';
const CAN_EDIT_STATMUX_POOL = 'statmux_pools.edit';
const CAN_DELETE_STATMUX_POOL = 'statmux_pools.delete';
const STATMUX_POOLS_REFRESH_PERIOD_MS = 3 * 1000;

function OutputEntry({ type, url }) {

    const [copyPaste, updateCopyPaste] = useState(null);

    return <tr key={type}>
        <td><CopyPaster text={copyPaste} onComplete={() => {
            updateCopyPaste(null);
        }}/>{`${type}`}:
        </td>
        <td>{url}</td>
        <td><Button className='mk-link-button hook-statmuxpools-statmuxtable-outputtablecopy' type='link'
            style={{ marginLeft: '8px' }} icon={<FontAwesomeIcon icon={faCopy}/>} onClick={() => updateCopyPaste(url)}/>
        </td>
    </tr>;
}

OutputEntry.propTypes = {
    type: PropTypes.string,
    url: PropTypes.string
};

export default function StatmuxPools() {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);
    const accountId = userDetails.accountID;
    const history = useHistory();

    const [statmuxPoolsList, updateStatmuxPoolsList] = useState([]);
    const [showNewStatmuxPool, updateShowNewStatmuxPool] = useState(false);
    const [statmuxPoolErrors, updateStatmuxPoolErrors] = useState(null);
    const [statmuxPoolDataEditing, updateStatmuxPoolDataEditing] = useState(null);
    const [statmuxPoolEditEnabled, updateStatmuxPoolEditEnabled] = useState(true);

    const canEditStatmuxPool = testPermission(CAN_EDIT_STATMUX_POOL, permissionsMap);
    const canDeleteStatmuxPool = testPermission(CAN_DELETE_STATMUX_POOL, permissionsMap);

    const refreshStatmuxPools = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;
        const shallContinue = () => {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const statmuxPoolsResponse = await solutionApi.listStatmuxPools(accountId);
            if (shallContinue()) {
                updateStatmuxPoolsList(statmuxPoolsResponse.data);
            }
        } catch (exception) {
            console.log(exception);
            message.error('Failed to retrieve statmux pools');
        }
    }, [solutionApi, userDetails.accountID]);

    const deleteRecord = useCallback((statmuxPoolId, statmuxPoolName = '') => {
        const accountId = userDetails.accountID;
        Modal.confirm({
            title: `Are you sure you want to delete the Statmux pool '${statmuxPoolName}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x'/>,
            okButtonProps: { className: 'hook-statmuxpools-dashboard-statmuxpooldeleteconfirm' },
            cancelButtonProps: { className: 'hook-statmuxpools-dashboard-statmuxpooldeletecancel' },
            onOk() {
                return solutionApi.deleteStatmuxPool(accountId, statmuxPoolId).then(refreshStatmuxPools);
            },
        });
    }, [userDetails.accountID, refreshStatmuxPools, solutionApi]);

    const editRecord = useCallback(async (statmuxPoolId, editEnabled = true) => {
        try {
            const statmuxPoolDataResponse = await solutionApi.getStatmuxPool(accountId, statmuxPoolId);
            if (statmuxPoolDataResponse.ok) {
                updateStatmuxPoolDataEditing(statmuxPoolDataResponse.data);
                updateStatmuxPoolEditEnabled(editEnabled);
            } else {
                updateStatmuxPoolDataEditing(null);
            }
        } catch (exception) {
            updateStatmuxPoolDataEditing(null);
        }
    }, [solutionApi, accountId]);

    const columns = [
        {
            title: 'Statmux Pool Name',
            dataIndex: 'name',
            key: 'name',
            className: 'hook-statmuxpools-statmuxpooltable-name',
        },
        {
            title: 'Bitrate',
            dataIndex: 'bitrate',
            key: 'bitrate',
            className: 'hook-statmuxpools-statmuxpooltable-bitrate',
            render: (...[, record,]) => record.bitrate.toString() + ' bps'
        },
        {
            title: 'Video Only',
            key: 'videoOnly',
            className: 'hook-statmuxpools-statmuxtable-videoonly',
            render: (...[, record,]) => record.videoOnly ? 'On' : 'Off'
        },
        {
            title: 'Actions',
            key: 'actions',
            className: 'hook-statmuxpools-statmuxpooltable-actions',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                const statmuxPoolId = record.id;
                const statmuxPoolName = record.name;

                let editButtonText = 'Edit';
                const editProps = {
                    shape: 'circle',
                    icon: <FontAwesomeIcon icon={faPencilAlt}/>,
                    onClick: () => {
                        console.log('edit');
                        editRecord(statmuxPoolId);
                    }
                };

                let deleteButtonText = 'Delete';
                const deleteProps = {
                    shape: 'circle',
                    icon: <FontAwesomeIcon icon={faTrashAlt}/>,
                    onClick: () => {
                        deleteRecord(statmuxPoolId, statmuxPoolName);
                    }
                };

                if (!canEditStatmuxPool) {
                    editButtonText = 'You lack the required permissions to make changes to any statmux pools.';
                    editProps.disabled = true;
                }

                if (!canDeleteStatmuxPool) {
                    deleteButtonText = 'You lack the required permissions to delete any statmux pools';
                    deleteProps.disabled = true;
                }

                return (
                    <Space>
                        <Tooltip title={editButtonText}>
                            <Button className='hook-statmuxpools-statmuxpooltable-editbutton' {...editProps} />
                        </Tooltip>
                        <Tooltip title={deleteButtonText}>
                            <Button className='hook-statmuxpools-statmuxpooltable-deletebutton' {...deleteProps}/>
                        </Tooltip>
                    </Space>
                );
            }
        },
    ];

    const onAddNewStatmuxPool = useCallback(async (newStatmuxPool) => {
        let ok = false;
        try {

            console.log('posting new statmux pool', newStatmuxPool);
            const accountId = userDetails.accountID;
            let response = await solutionApi.createStatmuxPool(accountId, newStatmuxPool);
            if (response.ok) {
                updateStatmuxPoolErrors(null);
                updateShowNewStatmuxPool(false);
                ok = true;
            } else {
                console.log(response);
                updateStatmuxPoolErrors(response.data);
            }

        } catch (exception) {
            console.log(exception);
        }
        return ok;
    }, [solutionApi, userDetails.accountID]);

    const onNewStatmuxPoolCancel = useCallback(() => {
        updateStatmuxPoolErrors(null);
        updateShowNewStatmuxPool(false);
    }, []);

    const onEditStatmuxPoolCancel = useCallback(() => {
        updateStatmuxPoolErrors(null);
        updateStatmuxPoolDataEditing(null);
    }, []);

    const onSaveStatmuxPool = useCallback(async (updateStatmuxPool) => {

        try {
            const accountId = userDetails.accountID;
            let response = await solutionApi.updateStatmuxPool(accountId, updateStatmuxPool.id, updateStatmuxPool);
            if (response.ok) {
                updateStatmuxPoolDataEditing(null);
                updateStatmuxPoolErrors(null);
            } else {
                updateStatmuxPoolErrors(response.data);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [userDetails.accountID, solutionApi]);

    const canCreateStatmuxPool = testPermission(CAN_CREATE_STATMUX_POOL, permissionsMap);
    const createButtonProps = {
        onClick: () => {
            updateShowNewStatmuxPool(true);
        }
    };
    let createButtonText = 'Press to create a new statmux pool';
    if (!canCreateStatmuxPool) {
        createButtonProps['disabled'] = true;
        createButtonText = 'You do not have the required permissions to create a statmux pool';
    }

    const headerForm = () => (<Row justify='space-between'>
        <Col>
            <Tooltip title={createButtonText}><Button
                className='hook-statmuxpools-dashboard-newstatmuxpool' {...createButtonProps}>New statmux pool</Button></Tooltip>
        </Col>
    </Row>);

    const showEditStatmuxPool = statmuxPoolDataEditing != null;

    useEffect(() => {
        // once only on load
        refreshStatmuxPools(null);
    }, [refreshStatmuxPools]);

    return (
        <>
            <Ticker asyncCallback={refreshStatmuxPools} timeout={STATMUX_POOLS_REFRESH_PERIOD_MS}/>
            <PageHeader onBack={() => history.push('.')} title="Statmux Pools" />
            <Table className='statmuxpool-table hook-statmuxpools-dashboard-statmuxpoolstable' pagination={false}
                size='middle' dataSource={statmuxPoolsList} columns={columns} rowKey='id' title={headerForm}
            />
            <EditStatmuxPoolModal errors={statmuxPoolErrors} visible={showEditStatmuxPool}
                statmuxPoolData={statmuxPoolDataEditing}
                onConfirm={onSaveStatmuxPool} onCancel={onEditStatmuxPoolCancel} editEnabled={statmuxPoolEditEnabled}
            />
            <NewStatmuxPoolModal errors={statmuxPoolErrors} visible={showNewStatmuxPool} onConfirm={onAddNewStatmuxPool}
                onCancel={onNewStatmuxPoolCancel}
            />
        </>
    );
}
