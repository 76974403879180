import React, { useState, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Ticker } from '../pages/util';
import { notification, Tooltip } from 'antd';
import displayErrors from '../services/DisplayErrors';
import { SolutionApiContext, SelectedAccountContext, CiModeContext } from '../services';

import { Link } from 'react-router-dom';

const NOTIFICATION_REFRESH_PERIOD_MS = 5 * 1000;

export default function Bell() {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const ciMode = useContext(CiModeContext);
    const [pollfrom, updatePollFrom] = useState(new Date());

    const openNotification = (message, user, dateTime) => {
        let description = `By ${user} at ${dateTime}`;
        if (user === 'System notification') {
            description = `At ${dateTime}`;
        }
        notification.open({
            message: message,
            description: description
        });
    };

    const refreshNotifications = useCallback(async () => {
        try {
            if (userDetails != null) {
                const notificationsResponse = await solutionApi.listNotifications(
                    userDetails.accountID, [`fromWhen=${encodeURIComponent(pollfrom.toISOString().slice(0, -5))}Z`]
                );

                if (notificationsResponse.ok) {
                    if (!ciMode.runningCI) {
                        if (notificationsResponse.data.length > 0) {
                            let latestDate = pollfrom;
                            let maxToasters = 3;
                            let count = 0;

                            notificationsResponse.data.forEach(element => {
                                let dt = new Date(element.eventTime);

                                if (dt > latestDate) {
                                    dt.setSeconds(dt.getSeconds() + 1);
                                    latestDate = dt;
                                }

                                if (count < maxToasters) {
                                    openNotification(element.message, element.actioningUser, element.eventTime);
                                    count++;
                                }
                            });

                            if (notificationsResponse.data.length > maxToasters) {
                                openNotification(
                                    `+ ${notificationsResponse.data.length - maxToasters} more notifications`,
                                    'various user(s)',
                                    `around ${latestDate}`
                                );
                            }
                            updatePollFrom(latestDate);
                        }
                    }
                } else {
                    console.log(notificationsResponse);
                    let jsonData = notificationsResponse.data;
                    displayErrors(jsonData.summary, jsonData.errors, 15);
                }
            }
        } catch (exception) {
            console.log('Error polling for main notification indicator ' + exception);
        }

    }, [ciMode.runningCI, pollfrom, solutionApi, userDetails]);

    return (
        <>
            <Ticker asyncCallback={refreshNotifications} timeout={NOTIFICATION_REFRESH_PERIOD_MS} />
            <Link to="/notifications" style={{ color: 'unset' }}>
                <Tooltip title='Notifications'>
                    <FontAwesomeIcon icon={faBell} size='2x' />
                </Tooltip>
            </Link>
        </>
    );
}
