import React, { useCallback, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { Alert, Button, Form, Input, Select, Row, Col, Tooltip, Checkbox } from 'antd';
import { processResponseErrors, SolutionApiContext, SelectedAccountContext } from '../../services';
import { validateTrimmedWhitespace } from '../../services/FormUtils';

import { CopyPaster } from '../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

// Hard coded templates are used for CDVR
// Will be properly managed after integration of
// generic templates
const defaultTemplates = [
    { 'id': 'default', 'name': 'DVR: 1 day, Recording retention: 30 days' },
    { 'id': 'medium', 'name': 'DVR: 7 days, Recording retention: 1 year' }
];

export default function DvrForm({
    dvr,
    form,
    errors,
    editEnabled = true,
    updateValid = () => { }
}) {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);

    const [templateOptions, updateTemplateOptions] = useState([]);
    const [accountRegions, updateAccountRegions] = useState([]);
    const [copyPaste, updateCopyPaste] = useState(null);
    const [encryptedSource, setEncryptedSource] = useState(dvr?.encryptedSource);

    const updateRegions = useCallback(async () => {
        const regionsResponse = await solutionApi.getAccountRegions(userDetails.accountID);
        if (regionsResponse.ok) {
            const regions = regionsResponse.data;

            updateAccountRegions(regions);
        }
    }, [solutionApi, userDetails.accountID]);


    const onLoad = useCallback(async () => {
        form.setFieldsValue(dvr);
        setEncryptedSource(dvr?.encryptedSource);
        updateTemplateOptions(defaultTemplates);
    }, [dvr, form, updateTemplateOptions]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        updateRegions();
    }, [updateRegions]);

    const errorsClassName = errors ? '' : 'form-hidden';

    let errorObj = {
        summary: '',
        errorsLookup: {}
    };

    if (errors && errors.errors) {
        errorObj = processResponseErrors(errors);
    }

    const getErrorsProps = useCallback((fieldName) => {
        let props = {};

        const lookup = errorObj.errorsLookup[fieldName];

        if (lookup) {
            props.validateStatus = 'error';
            props.help = lookup.text;
        }

        return props;
    }, [errorObj.errorsLookup]);

    const onValuesChanged = useCallback((...[, allValues]) => {
        // ideally we would have the list of required fields to check if their default value has changed
        let requiredFieldsValues = [allValues.name, allValues.template, allValues.location, allValues.sourceUrl, allValues.storageUrl];
        if (allValues.encryptedSource) {
            requiredFieldsValues = [...requiredFieldsValues, allValues.protectionKeyServerUrl, allValues.protectionContentId];
        }

        updateValid(requiredFieldsValues.every(fieldValue =>
            fieldValue !== undefined
            && fieldValue !== ''
            && fieldValue !== null
        ));

    }, [updateValid]);

    const onEncryptedSourceChanged = useCallback((e) => {
        setEncryptedSource(e.target.checked);
    }, []);

    const currentFormValues = form.getFieldsValue();
    const fieldLoadComplete = currentFormValues.id === dvr.id;
    const disableFields = !fieldLoadComplete || !editEnabled;

    return (
        <Form
            initialValues={{ }}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 16 }}
            layout='horizontal'
            form={form}
            onValuesChange={onValuesChanged}
        >
            <Row>
                <Col span={18}>
                    <Alert
                        style={{ marginBottom: 8, whiteSpace: 'pre-line' }}
                        className={errorsClassName}
                        message={errors ? errorObj.summary : ''}
                        description={errors && errors.errors ? errors.errors.map(item => item.text).join('\n') : ''}
                        type="error"
                    />
                    <Form.Item key='id' name='id' label='id' className='form-hidden'>
                        <Input type='hidden' value={dvr.id} />
                    </Form.Item>
                    <Form.Item key='name' name='name' label='Name' {...getErrorsProps('name')}
                        rules={[{ required: true, validator: validateTrimmedWhitespace, }]}>
                        <Input
                            disabled={disableFields}
                            className='hook-dvrs-dvrconfig-name'
                            placeholder="DVR Name"
                        />
                    </Form.Item>
                    <Form.Item
                        key='template'
                        name='template'
                        label="Template"
                        rules={[{ required: true }]}
                    >
                        <Select
                            disabled={disableFields}
                            className="hook-dvrs-dvrconfig-template"
                            optionFilterProp="label"
                            options={templateOptions.map((tpl, index) => ({
                                className: `hook-dvrs-dvrconfig-template-${index.toString()}`,
                                value: tpl.id ? tpl.id : '',
                                label: `${tpl.name ? tpl.name : ''}`
                            }))}
                        />
                    </Form.Item>
                    <Form.Item name='location' label='Location' {...getErrorsProps('region')}
                        rules={[{ required: true }]}>
                        <Select placeholder='Select a location for this DVR'
                            className='hook-dvrs-dvrentry-region'
                            disabled={disableFields}
                            showSearch={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.includes(input)
                            }>
                            {
                                _.sortBy(accountRegions, 'name').map((region, index) => {
                                    return <Select.Option key={region.id} className={`hook-dvrs-dvrentry-region-${index.toString()}`} value={region.name}>{region.name}</Select.Option>;
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item key='sourceUrl' name='sourceUrl' label='Source URL' {...getErrorsProps('instances.0.sourceURL')}
                        rules={[{ required: true, validator: validateTrimmedWhitespace, }]}>
                        <Input
                            disabled={disableFields}
                            className='hook-dvrs-dvrconfig-sourceUrl'
                            placeholder="Source URL"
                        />
                    </Form.Item>
                    <Form.Item key='encryptedSource' name='encryptedSource' label='Content Protection' valuePropName='checked' {...getErrorsProps('encryptedSource', errors)}>
                        <Checkbox disabled={disableFields} className='hook-dvrs-dvrconfig-encrypted'
                            checked={encryptedSource} onChange={onEncryptedSourceChanged} />
                    </Form.Item>
                    <Form.Item key='protectionKeyServerUrl' name='protectionKeyServerUrl' label='Key Server URL' {...getErrorsProps('instances.0.protection.keyServerUrl')}
                        rules={[{ required:{ encryptedSource }, validator: validateTrimmedWhitespace, }]} hidden={!encryptedSource}>
                        <Input
                            disabled={disableFields}
                            className='hook-dvrs-dvrconfig-protection-keyServerUrl'
                            placeholder='Key Server URL'
                        />
                    </Form.Item>
                    <Form.Item key='protectionContentId' name='protectionContentId' label='Content ID' {...getErrorsProps('instances.0.protection.contentId')}
                        rules={[{ required:{ encryptedSource }, validator: validateTrimmedWhitespace, }]} hidden={!encryptedSource}>
                        <Input
                            disabled={disableFields}
                            className='hook-dvrs-dvrconfig-protection-contentId'
                            placeholder='Content ID'
                        />
                    </Form.Item>
                    <Form.Item key='storageUrl' name='storageUrl' label='Storage URL' {...getErrorsProps('instances.0.storageId')}
                        rules={[{ required: true, validator: validateTrimmedWhitespace, }]}>
                        <Input
                            disabled={disableFields}
                            className='hook-dvrs-dvrconfig-storageUrl'
                            placeholder='Storage URL'
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Row>
                        <CopyPaster text={copyPaste} onComplete={() => {updateCopyPaste(null);}} />
                        <Tooltip title='Copy DVR ID'>
                            <Button className='mk-link-button hook-dvrs-dvridcopy' type='link' style={{ marginLeft: '8px' }}
                                icon={<FontAwesomeIcon icon={faCopy} />} onClick={() => updateCopyPaste(dvr?.id)}
                                disabled={!dvr?.id}
                            />
                        </Tooltip>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

DvrForm.propTypes = {
    form: PropTypes.object,
    dvr: PropTypes.object,
    errors: PropTypes.object,
    updateValid: PropTypes.func,
    editEnabled: PropTypes.bool
};
