import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import postRobot from 'post-robot';

export default function UiConnector({ connectorUrl, exitUrl, id }) {

    useEffect(() => {
        let domain = window.location.protocol + '//' + window.location.hostname;
        if (window.location.port) {
            domain += ':' + window.location.port;
        }

        // We can only receive one exit message, so simplify listener lifecycle management
        if(id){
            let childWindow = document.getElementById(id).contentWindow;
            postRobot.once(
                'exit',
                { window: childWindow, domain: domain },
                () => {
                    window.location.href = exitUrl;
                }
            );
        }
    }, [id, exitUrl]);

    return <>
        {
            <iframe className="connector-iframe" id={id} title="UI connector"
                sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-downloads"
                src={connectorUrl}
            />
        }
    </>;
}

UiConnector.propTypes = {
    connectorUrl: PropTypes.string,
    exitUrl: PropTypes.string,
    id: PropTypes.string,
    onOk: PropTypes.func,
    onOpenOther: PropTypes.func
};
