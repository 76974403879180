import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Select, Table, Row, Col, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import LocationChooser from '../../components/LocationChooser';

const emptyInstance = {
    id: 0,
    location: '',
    label: '',
    role: 'primary',
    state: 'stopped',
    templateRevision: 'latest'
};

export function extractInstancesToArray(instancesObj) {
    const instancesArray = [];
    for (const label in instancesObj){
        let instance = instancesObj[label];
        instancesArray.push(instance);
    }

    return instancesArray;
}

export function InstancesTable({ feed, instances, updateInstances, hideLabelColumn, hideTemplateRevisionColumn,
    updateValid, maximumInstanceCount }) {

    const [v2Instances, updatev2Instances] = useState([]);


    const extractInstancesFromArray = useCallback(() => {
        const instancesObj = { instances: {} };
        let defaultLabel = 0;
        v2Instances.forEach(inst => {
            const label = inst.label != null && inst.label != '' ? inst.label : defaultLabel++;
            instancesObj.instances[label] = inst;
        });

        return instancesObj;
    }, [v2Instances]);

    const areInstancesValid = useCallback((instancesArray) => {
        let valid = instancesArray.length > 0 && instancesArray.every((instance) => instance.location.length > 0);
        if (!hideLabelColumn){
            // If we are showing labels, make sure the are set and that are no duplicates
            let labels = new Set(instancesArray.map(instance => instance.label != null ? instance.label : ''));
            valid = labels.size == instancesArray.length && !labels.has('');
        }
        return valid;
    },[hideLabelColumn]);

    useEffect(() => {
        let instancesArray = extractInstancesToArray(instances);

        if(feed){
            const locations = feed.config.routes.map(route => route.location);
            const setLocations = [... new Set(locations)];
            setLocations.sort((a, b) => a.localeCompare(b));
            if (instancesArray.length == 0 && locations.length > 0){
                // Initialize via the feeds. Limit ourselves to '0' and '1' so it works with v1.5 channels
                if (locations.length > 1){
                    console.log('Initializing two instances');
                    instancesArray = [
                        {
                            location: feed.config.routes[0].location,
                            role: feed.config.routes[0].role,
                            state: 'stopped',
                            templateRevision: 'latest',
                            label: hideLabelColumn ? '0' : feed.config.routes[1].role === 'secondary' ? 'primary' : 'main'
                        },
                        {
                            location: feed.config.routes[1].location,
                            role: feed.config.routes[1].role,
                            state: 'stopped',
                            templateRevision: 'latest',
                            label: hideLabelColumn ? '1' : feed.config.routes[1].role === 'secondary' ? 'secondary' : 'backup'
                        }
                    ];
                }
                else {
                    console.log('Initializing an instance');
                    instancesArray = [
                        {
                            location: feed.config.routes[0].location,
                            role: feed.config.routes[0].role,
                            state: 'stopped',
                            templateRevision: 'latest',
                            label: hideLabelColumn ? '0' : 'main'
                        }
                    ];
                }
            }
        }

        updatev2Instances(instancesArray);
        updateValid(areInstancesValid(instancesArray));

    }, [instances, feed, updateValid, areInstancesValid, hideLabelColumn]);

    useEffect(() => {
        updateInstances(extractInstancesFromArray());
    }, [v2Instances, extractInstancesFromArray, updateInstances]);

    const columns = [
        {
            title: 'Available in location',
            key: 'location',
            className: 'hook-instances-instance-edit-label',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                return (<>
                    <LocationChooser
                        className='hook-instances-instance-edit-row-location'
                        location={ record.location }
                        setLocation={ value => {
                            updateInstance({ ...record, location: value });
                        }}
                    />
                </>);
            }
        }, {
            title: 'Label',
            key: 'label',
            hidden: hideLabelColumn,
            dataIndex: 'label',
            className: 'hook-instances-instance-edit-label',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                return <Tooltip title={'Enter an instance label'}>
                    <Input className='hook-instances-instanceedit-instancetable-row-label' style={{ width: '140px' }} value={record.label}
                        onChange={e => {
                            updateInstance({ ...record, label: e.target.value });
                        }} />
                </Tooltip>;
            }
        }, {
            title: 'HA role',
            key: 'role',
            dataIndex: 'role',
            className: 'hook-instances-instance-edit-sourcetable-role',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                return (<>
                    <Tooltip title={'Choose the role to associate to the instance'}>
                        <Select className='hook-instances-instanceedit-instancetable-row-location' style={{ width: '112px' }} placeholder='Select role' value={record.role}
                            onChange={e => {
                                updateInstance({ ...record, role: e });
                            }}>
                            <Select.Option value="primary" className='hook-instances-instanceedit-instancetable-type-primary'>Primary</Select.Option>
                            <Select.Option value="secondary" className='hook-instances-instanceedit-instancetable-type-secondary'>Secondary</Select.Option>
                        </Select>
                    </Tooltip>
                </>);
            }
        }, {
            title: 'Template revision',
            key: 'templateRevision',
            className: 'hook-instances-instance-edit-template-revision',
            hidden: hideTemplateRevisionColumn,
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                return (<Tooltip title={'Enter a location'}>
                    <Input className='hook-instances-instanceedit-instancetable-row-template-revision' style={{ width: '100px' }} value={record.templateRevision}
                        onChange={e => {
                            updateInstance({ ...record, templateRevision: e.target.value });
                        }} />
                </Tooltip>);
            }
        }, {
            title: 'Actions',
            key: 'actions',
            className: 'hook-instances-instanceedit-instancetable-actions',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                const deleteButtonProps = {
                    shape: 'circle',
                    icon: <FontAwesomeIcon icon={faTrashAlt} />,
                    className: 'hook-instances-instanceedit-instancetable-deletebutton',
                    onClick: () => { removeInstance(record); }
                };

                let deleteButtonText = 'Click to delete this instance';
                return (<>
                    <Tooltip title={deleteButtonText}>
                        <Button {...deleteButtonProps} />
                    </Tooltip>
                </>);
            }
        }].filter(item => !item.hidden);

    const headerForm = () => (<Row justify='space-between'>
        <Col>
            Add instances to make this media service available in multiple locations.
        </Col>
    </Row>);

    const footerForm = () => (<Row justify='space-between'>
        <Col>
            <Tooltip title='Click to add a new instance'><Button className='hook-instances-addinstance'
                onClick={() => {
                    addInstance({ ...emptyInstance });
                }}
                disabled={maximumInstanceCount > 0 && v2Instances.length == maximumInstanceCount}>Add instance</Button></Tooltip>
        </Col>
    </Row>);

    let id = 0;
    if(v2Instances){
        for (const instance of v2Instances) {
            instance.id = id;
            id++;
        }
    }
    const [nextRowId, updateNextRowId] = useState(id);


    const updateInstance = useCallback(updatedInstance => {
        const index = v2Instances.findIndex(instance => instance.id == updatedInstance.id);
        let newInstances = v2Instances.slice();
        newInstances[index] = updatedInstance;
        updatev2Instances(newInstances);
        updateValid(areInstancesValid(newInstances));
    },[v2Instances, updateValid, areInstancesValid]);

    const addInstance = useCallback(newInstance => {
        newInstance.id = nextRowId;
        updateNextRowId(nextRowId + 1);
        let newInstances = v2Instances.slice();
        newInstances.push(newInstance);
        updatev2Instances(newInstances);
        updateValid(areInstancesValid(newInstances));
    },[nextRowId, v2Instances, updateValid, areInstancesValid]);

    const removeInstance = useCallback(instanceToRemove => {
        const index = v2Instances.findIndex(instance => instance.id == instanceToRemove.id);
        let newInstances = v2Instances.slice();
        newInstances.splice(index, 1);
        updatev2Instances(newInstances);
        updateValid(areInstancesValid(newInstances));
    }, [v2Instances, updateValid, areInstancesValid]);

    return (<Table className='hook-instances-instanceentry-instancetable'
        pagination={{ showSizeChanger: false, hideOnSinglePage: true }}
        size='middle' dataSource={v2Instances} columns={columns} rowKey='id' title={headerForm} footer={footerForm}
    />);
}

InstancesTable.propTypes = {
    feed: PropTypes.object,
    instances: PropTypes.object,
    updateInstances: PropTypes.func,
    hideLabelColumn: PropTypes.bool,
    hideTemplateRevisionColumn: PropTypes.bool,
    updateValid: PropTypes.func,
    maximumInstanceCount: PropTypes.number
};

