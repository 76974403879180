import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Modal, Table, Button, Row, Col, Tooltip, PageHeader, Space, Result } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExclamationCircle, faTrashAlt, faInfoCircle, faWrench, faCode, faEdit } from '@fortawesome/free-solid-svg-icons';
import TemplateLogModal from './TemplateLogModal';
import TemplateViewModal from './TemplateViewModal';
import TemplateEditor from './TemplateEditor';
import { Ticker } from '../util';
import { NewOrUpdateTemplateModal } from './TemplateForm';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission } from '../../services';
import displayErrors from '../../services/DisplayErrors';


const TEMPLATES_REFRESH_PERIOD_MS = 3 * 1000;

// TODO: Templates admin per account!?
const CAN_ADMIN_TEMPLATES = 'templates.admin';

export default function Templates() {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);

    const {
        accountID,
        accountName
    } = userDetails;

    const [templatesList, updateTemplatesList] = useState([]);
    const [showNewTemplate, updateShowNewTemplate] = useState(false);
    const [showUpdateTemplate, updateTemplate] = useState(false);
    const [templateErrors, updateTemplateErrors] = useState(null);
    const [templateLog, updateTemplateLog] = useState(null);
    const [templateViewer, updateTemplateViewer] = useState(null);
    const [templateEditor, updateTemplateEditor] = useState(null);
    const [templateId, updateTemplateId] = useState(null);
    const [templateName, updateTemplateName] = useState(null);

    const canAdminTemplates = testPermission(CAN_ADMIN_TEMPLATES, permissionsMap);

    const viewTemplateLog = useCallback(async (record) => {

        const revision = await solutionApi.listTemplateRevisions(accountID, record.id);

        const dataFormattedDate = revision.data.map((elem) => {
            elem.datetime = elem.datetime.replace('T', ' ');
            elem.datetime = elem.datetime.replace('Z', ' ');
            return elem;
        });

        updateTemplateLog(dataFormattedDate);
    }, [accountID, solutionApi]);

    const closeTemplateLog = useCallback(() => {
        updateTemplateLog(null);
    }, []);

    const openTemplateViewer = useCallback((record) => {
        const viewData = {
            record,
        };
        updateTemplateViewer(viewData);
    }, []);

    const closeTemplateViewer = useCallback(() => {
        updateTemplateViewer(null);
    }, []);

    const openTemplateEditor = useCallback((record) => {
        const viewData = {
            record,
        };
        updateTemplateEditor(viewData);
    }, []);

    const closeTemplateEditor = useCallback(() => {
        updateTemplateEditor(null);
    }, []);

    const refreshTemplates = useCallback(async (quitContainer) => {
        try {
            const templatesResponse = await solutionApi.listTemplates(accountID);
            if (templatesResponse.ok) {
                if (!quitContainer || (!!quitContainer && !quitContainer.quitting)) {
                    updateTemplatesList(templatesResponse.data);
                }
            } else {
                console.log(templatesResponse.data);
                let jsonData = templatesResponse.data;
                displayErrors(jsonData.summary, jsonData.errors, 15);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, accountID]);

    const columns = [
        {
            title: 'Template Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: 'ascend',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                const tooltipTitle = `Description: ${record.description}`;
                return <Tooltip title={tooltipTitle}>{record.name}</Tooltip>;
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type)
        },
        {
            title: 'Quota Name',
            dataIndex: 'quota',
            key: 'quota',
            sorter: (a, b) => a.quota.localeCompare(b.quota)
        },
        {
            title: 'Revision',
            key: 'revision',
            width: '2%',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                let revisionId = record.revisions.slice(-1);

                return <>{revisionId}</>;
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                const templateId = record.id;
                const templateName = record.name;

                let deleteButtonText = 'Delete';
                const deleteProps = {
                    shape: 'circle',
                    icon: <FontAwesomeIcon icon={faTrashAlt} />
                };
                deleteProps.onClick = () => { deleteTemplate(templateId, templateName); };

                const templateLogProps = {
                    shape: 'circle',
                    icon: <FontAwesomeIcon icon={faInfoCircle} />
                };

                const updateTemplateProps = {
                    shape: 'circle',
                    icon: <FontAwesomeIcon icon={faWrench} />
                };

                const viewTemplateProps = {
                    shape: 'circle',
                    icon: <FontAwesomeIcon icon={faCode} />
                };

                const viewEditTemplate = {
                    shape: 'circle',
                    icon: <FontAwesomeIcon icon={faEdit} />
                };

                viewEditTemplate.onClick = () => { openTemplateEditor(record); };

                viewTemplateProps.onClick = () => { openTemplateViewer(record); };

                templateLogProps.onClick = () => { viewTemplateLog(record); };

                updateTemplateProps.onClick = () => { updateTemplate(true); updateTemplateId(templateId); updateTemplateName(templateName); };

                return <>
                    <Tooltip title={deleteButtonText}>
                        <Button {...deleteProps} />
                    </Tooltip>
                    <Tooltip title={'View Template'}>
                        <Button {...viewTemplateProps} />
                    </Tooltip>
                    <Tooltip title={'View Revision Log'}>
                        <Button {...templateLogProps} />
                    </Tooltip>
                    <Tooltip title={'Update Template'}>
                        <Button {...updateTemplateProps} />
                    </Tooltip>
                    {/* Remove the template edit option until relevant permissions / authorisation is in place */}
                    {/* <Tooltip title={'Edit Template'}>
                        <Button {...viewEditTemplate} />
                    </Tooltip> */}
                </>;
            }
        }
    ];

    const onAddNewTemplate = useCallback(async (newTemplate) => {
        let ok = false;

        try {
            console.log(`posting new template ${newTemplate}`, newTemplate);

            const response = await solutionApi.createTemplate(accountID, newTemplate);
            if (response.ok) {
                updateTemplateErrors(null);
                updateShowNewTemplate(false);
                ok = true;
            } else {
                console.log(response);
                updateTemplateErrors(response.data);
            }

        } catch (exception) {
            console.log(exception);
        }

        return ok;
    }, [solutionApi, accountID]);

    const onNewTemplateCancel = useCallback(() => {
        updateTemplateErrors(null);
        updateShowNewTemplate(false);
    }, []);

    const onUpdateTemplate = useCallback(async (UpdatedTemplate) => {
        let ok = false;

        try {
            console.log(`posting template revision ${UpdatedTemplate}`, UpdatedTemplate);

            const response = await solutionApi.updateTemplate(accountID, templateId, UpdatedTemplate);
            if (response.ok) {
                updateTemplateErrors(null);
                updateTemplate(false);
                ok = true;
            } else {
                console.log(response);
                updateTemplateErrors(response.data);
            }

        } catch (exception) {
            console.log(exception);
        }

        return ok;
    }, [solutionApi, accountID, templateId]);

    const onUpdateTemplateCancel = useCallback(() => {
        updateTemplateErrors(null);
        updateTemplate(false);
    }, []);

    const deleteTemplate = useCallback((templateId, templateName) => {
        Modal.confirm({
            title: `Are you sure you want to delete the template '${templateName}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
            async onOk() {
                await solutionApi.deleteTemplate(accountID, templateId);
            },
        });
    }, [accountID, solutionApi]);

    useEffect(() => {
        refreshTemplates(null);
    }, [refreshTemplates]);

    const createButtonProps = {
        onClick: () => {
            updateShowNewTemplate(true);
        }
    };
    let createButtonText = 'Press to create a new template';

    const refreshTemplatesTick = useCallback(async (quitContainer) => {
        await refreshTemplates(quitContainer);
    }, [refreshTemplates]);

    const headerForm = () => (
        <Row justify='space-between'>
            <Col>
                <Tooltip title={createButtonText}><Button {...createButtonProps}>New Template</Button></Tooltip>
            </Col>
        </Row>
    );

    let content = null;
    if (canAdminTemplates) {
        let mainContent = null;
        if (templateEditor) {
            mainContent = (
                <>
                    <TemplateEditor
                        accountId={accountID}
                        record={templateEditor ? templateEditor.record : null}
                        onExit={closeTemplateEditor}
                    />
                </>
            );
        } else {
            mainContent = (
                <>
                    <Table
                        pagination={false}
                        size='middle'
                        dataSource={templatesList}
                        columns={columns}
                        rowKey='id'
                        title={headerForm}
                        scroll={{ x: true }}
                    />
                    <NewOrUpdateTemplateModal
                        accountID={accountID}
                        accountName={accountName}
                        errors={templateErrors}
                        visible={showNewTemplate}
                        onConfirm={onAddNewTemplate}
                        onCancel={onNewTemplateCancel}
                    />
                    <NewOrUpdateTemplateModal
                        accountID={accountID}
                        accountName={accountName}
                        errors={templateErrors}
                        visible={showUpdateTemplate}
                        onConfirm={onUpdateTemplate}
                        onCancel={onUpdateTemplateCancel}
                        name={templateName}
                    />
                    <TemplateLogModal
                        revision={templateLog}
                        onOk={closeTemplateLog}
                    />
                    <TemplateViewModal
                        accountID={accountID}
                        record={templateViewer ? templateViewer.record : null}
                        onOk={closeTemplateViewer}
                    />
                </>
            );
        }
        content = (
            <>
                <Row align='middle'>
                    <Col>
                        <FontAwesomeIcon icon={faCopy} size='4x' />
                    </Col>
                    <Col>
                        <PageHeader backIcon={null} title="Templates" />
                    </Col>
                </Row>
                <Row className='gutter-bottom'>
                    <Col>
                        <Space>
                            <FontAwesomeIcon icon={faInfoCircle} size='2x' color='blue' />
                            Manage service templates.
                        </Space>
                    </Col>
                </Row>
                {mainContent}
            </>
        );
    } else {
        content = (
            <Result
                status="warning"
                title="We're sorry but your account does not have a required role associated with it to allow
                        you to administer templates."
            />
        );
    }

    return (
        <>
            <Ticker key='ticker' asyncCallback={refreshTemplatesTick} timeout={TEMPLATES_REFRESH_PERIOD_MS} />
            {content}
        </>
    );
}
