import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Tag } from 'antd';
import PropTypes from 'prop-types';

export default function AlarmsTable({
    alertGroup,
    channels,
    sources
}) {
    const [tableTitleText, setTableTitleText] = useState('Loading...');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(alertGroup && channels && sources);
    }, [alertGroup, channels, sources]);

    useEffect(() => {
        if (isLoaded) {
            let name = '';
            if (alertGroup.channelId && alertGroup.channelId != 'null') {
                const channel = channels.find(channel => channel.id == alertGroup.channelId);
                if (channel) {
                    name = <>Channel: <b>{channel.name}</b></>;
                } else {
                    name = <b>No channel found with that ID.</b>;
                }
            } else {
                name = <b>Other Alarms</b>;
            }
            setTableTitleText(name);
        }
    }, [isLoaded, alertGroup, channels]);

    function tableTitle() {
        return (
            <Row className='hook-alert-channel-name' justify='start'>
                <Col>{tableTitleText}</Col>
            </Row>
        );
    }

    const columns = [{
        title: 'Raised Date / Time',
        key: 'raisedTime',
        className: 'hook-alarms-raisedtime',
        sorter: (a, b) => new Date(a.raisedTime) > new Date(b.raisedTime),
        defaultSortOrder: 'descend',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const dt =  new Date(record.raisedTime);
            return dt.toString().slice(0, -21);
        }
    }, {
        title: 'Duration',
        key: 'duration',
        className: 'hook-alarms-duration',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const lastDate = new Date(record.lastUpdatedTime);
            const raisedDate = new Date(record.raisedTime);

            let seconds = Math.floor((lastDate - (raisedDate)) / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);

            hours = hours - (days * 24);
            minutes = minutes - (days * 24 * 60) - (hours * 60);
            let secondsDisplay = String(seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60)).padStart(2, '0');
            let minutesDisplay = String(minutes).padStart(2, '0');
            let hoursDisplay = String(hours).padStart(2, '0');
            const elapsedSecondsFmt = `${days} days, ${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`;

            return elapsedSecondsFmt;
        }
    }, {
        title: 'Severity',
        dataIndex: 'severity',
        key: 'severity',
        className: 'hook-alarms-severity',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const tagColor = record.severity === 'critical' ? '#ff0000' : '#ffa500';
            return <Tag color={tagColor}>{record.severity}</Tag>;
        }
    }, {
        title: 'Deployment',
        key: 'region',
        className: 'hook-alarms-region',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            let source = null;
            const fields = record['fields'];
            if (fields) {
                const sourceId = fields['sourceId'];
                if (sourceId) {
                    source = sources.find(source => source.id == sourceId);
                }
            }
            return source != null ? source.region : '...';
        }
    }, {
        title: 'Source',
        key: 'source',
        className: 'hook-alarms-source',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            let source = null;
            const fields = record['fields'];
            if (fields) {
                const sourceId = fields['sourceId'];
                if (sourceId) {
                    source = sources.find(source => source.id == sourceId);
                }
            }
            return source != null ? source.name : '...';
        }
    }, {
        title: 'Description',
        key: 'description',
        className: 'hook-alarms-description',
        dataIndex: 'description'
    }];

    return (
        <Table
            key='table'
            className='hook-alarms-table'
            size='middle'
            dataSource={isLoaded && alertGroup.alerts ? alertGroup.alerts : null}
            columns={columns}
            rowKey={(record) => {return `${record.code}${record.fields['sourceId']}`;}}
            title={tableTitle}
            scroll={{ x: true }}
            loading={!isLoaded}
        />
    );
}

AlarmsTable.propTypes = {
    alertGroup: PropTypes.object,
    channels: PropTypes.array,
    sources: PropTypes.array
};
