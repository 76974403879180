import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Modal, Select, Table, Row, Col, Tooltip, Alert } from 'antd';
import { SolutionApiContext, SelectedAccountContext, processResponseErrors } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { validateTrimmedWhitespace } from '../../services/FormUtils';
import LocationChooser from '../../components/LocationChooser';

const emptySource = {
    id: 0,
    endpointId: '',
    location: '',
    programNumber: '',
    role: 'primary'
};

const emptyFeed = {
    id: '',
    config:{
        name: 'NewFeed',                  // source name
        type: 'ingress',                  // feed type ingress
        routes: [{ ...emptySource }]
    },
    status: {
        usedBy: []
    }
};

export function FeedsForm(props) {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);

    const { feed, updateFeed, updateValid, editMode, errors } = props;
    const [form] = Form.useForm();
    const errorsClassName = errors ? '' : 'form-hidden';

    let errorObj = {
        summary: '',
        errorsLookup: {}
    };

    if (errors && errors.errors) {
        errorObj = processResponseErrors(errors);
    }

    const getErrorsProps = useCallback((fieldName) => {
        let props = {};

        const lookup = errorObj.errorsLookup[fieldName];

        if (lookup) {
            props.validateStatus = 'error';
            props.help = lookup.text;
        }

        return props;
    }, [errorObj.errorsLookup]);

    useEffect(() => {
        // on load
        form.resetFields();
    }, [form]);

    useEffect(() => {
        // Form validation
        // Cannot create or update a feed (click on Add new button) if not valid
        let isValid = feed.config.routes.length > 0;
        if (isValid) {
            for (const route of feed.config.routes) {
                isValid &= route.endpointId.length > 0
                    && route.role && route.role.length > 0
                    && route.location && route.location.length > 0;
            }
        }

        updateValid(isValid);

    }, [feed, updateValid]);

    const onValuesChanged = useCallback((...[, allValues]) => {
        const newFeed = {
            ...feed,
            config: {
                ...feed.config,
                name: allValues.config.name,
                type: allValues.config.type
            }
        };
        updateFeed(newFeed);
    }, [feed, updateFeed]);

    // code for the sources table
    const [sourcesLoading, updateSourcesLoading] = useState(false);
    const [allSources, updateAllSources] = useState([]);

    const getSources = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;

        updateSourcesLoading(true);
        const sourcesResponse = await solutionApi.listSources(accountId, ['id', 'name', 'region']);
        let sourcesRaw = sourcesResponse.data;
        if (!quitContainer || (!!quitContainer && !quitContainer.quitting)) {
            sourcesRaw.sort((a, b) => a.name.localeCompare(b.name));
            const sources = sourcesRaw.map(src => ({ ...src, locations: [] }));
            updateAllSources(sources);
            updateSourcesLoading(false);
        }
    },[solutionApi, userDetails.accountID]);

    const columns = [{
        title: 'Source',
        key: 'endpointId',
        className: 'hook-feeds-feededit-sourceId',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {

            const sourceIdProps = {
                labelInValue:true,
                placeholder: 'Please select',
                defaultValue: record.endpointName,
                onDropdownVisibleChange: open => open && getSources(),
                loading: sourcesLoading,
                onChange: changeEvent => {
                    const defaultLocation = allSources.find(({ id }) => id === changeEvent.value).region;
                    updateSource({ ...record, endpointId: changeEvent.value, location : defaultLocation });
                }
            };

            const children = allSources.map((e, index) =>
                <Select.Option key={e.id} value={e.id}
                    className={`hook-feeds-feededit-sourceId-option hook-feeds-feededit-sourceId-${index.toString()}`}>
                    {e.name}
                </Select.Option>);

            return <Tooltip title={'Select a source'}>
                <Select {...sourceIdProps} style={{ minWidth: '200px' }}>
                    {children}
                </Select>
            </Tooltip>;

        }
    }, {
        title: 'HA role',
        key: 'role',
        dataIndex: 'role',
        className: 'hook-feeds-feededit-sourcetable-role',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            return (<>
                <Tooltip title={'Choose the role to associate to the route'}>
                    <Select className='hook-feeds-feedentry-source-role' style={{ width: '112px' }} placeholder='Select role' defaultValue={record.role ? record.role : 'primary'}
                        onChange={ e => {
                            updateSource({ ...record, role: e });
                        }}>
                        <Select.Option value="primary" className='hook-feeds-feedentry-type-primary'>Primary</Select.Option>
                        <Select.Option value="secondary" className='hook-feeds-feedentry-type-secondary'>Secondary</Select.Option>
                    </Select>
                </Tooltip>
            </>);
        }
    }, {
        title: 'Available in location',
        key: 'location',
        className: 'hook-feeds-feededit-region',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            return (<>
                <LocationChooser
                    className='hook-feeds-feededit-sourcetable-row-location'
                    location={ record.location }
                    setLocation={ value => {
                        updateSource({ ...record, location: value });
                    }}
                />
            </>);
        }
    }, {
        title: 'Program number',
        key: 'programNumber',
        dataIndex: 'programNumber',
        className: 'hook-feeds-feededit-sourcetable-programNumber',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            return (<>
                <Tooltip title={'Enter a program number to select a single program from an MPTS source'}>
                    <Input className='hook-feeds-feededit-sourcetable-row-programNumber' style={{ width: '60px' }} value={ record.programNumber }
                        onChange={ e => {
                            updateSource({ ...record, programNumber: e.target.value });
                        }}/>
                </Tooltip>
            </>);
        }
    }, {
        title: 'Actions',
        key: 'actions',
        className: 'hook-feeds-feededit-sourcetable-actions',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const deleteButtonProps = {
                shape:'circle',
                icon: <FontAwesomeIcon icon={faTrashAlt}/>,
                className: 'hook-feeds-feededit-sourcetable-deletebutton',
                onClick: ()=>{ removeSource(record);}
            };

            let deleteButtonText = 'Click to delete this feed';
            return (<>
                <Tooltip title={deleteButtonText}>
                    <Button {...deleteButtonProps}/>
                </Tooltip>
            </>);
        }
    }];

    const createButtonProps = {
        onClick:() => {
            addSource({ ...emptySource });
        }
    };

    let addButtonText = 'Click to add a new route';

    const headerForm = () => (<Row justify='space-between'>
        <Col>
            Add routes to make sources available in locations channels will run in.
        </Col>
    </Row>);

    const footerForm = () => (<Row justify='space-between'>
        <Col>
            <Tooltip title={addButtonText}><Button className='hook-feeds-feededit-dashboard-addsource' {...createButtonProps}>Add route</Button></Tooltip>
        </Col>
    </Row>);

    let id = 0;
    for (const route of feed.config.routes) {
        route.id = id;
        id++;
    }

    const [nextRowId, updateNextRowId] = useState(id);

    function updateSource(route){
        updateFeed(
            {
                ...feed,
                config: {
                    ...feed.config,
                    routes: feed.config.routes.map(r => {
                        if (r.id === route.id) {
                            return route;
                        } else {
                            return r;
                        }
                    })
                }
            }
        );
    }

    function addSource(newSource){
        newSource.id = nextRowId;
        updateNextRowId(nextRowId + 1);
        updateFeed({
            ...feed,
            config: {
                ...feed.config,
                routes: [
                    ...feed.config.routes,
                    newSource
                ]
            }
        });
    }

    function removeSource(sourceToRemove){
        updateFeed({
            ...feed,
            config: {
                ...feed.config,
                routes: feed.config.routes.filter(src => src.id != sourceToRemove.id)
            }
        });
    }


    return (<Form key='form' labelCol={{ span: 8 }} wrapperCol= {{ span: 16 }} layout='horizontal' form={form}
        onValuesChange={onValuesChanged}
        initialValues={feed}>
        <Alert style={{ marginBottom: 8 }} className={errorsClassName} message={errors ? errorObj.summary : ''} type="error" />
        <Form.Item name={['config', 'name']} label='Name' {...getErrorsProps('name')} rules={[{ validator: validateTrimmedWhitespace }]}>
            <Input placeholder='Feed Name' className='hook-feeds-feedentry-name'/>
        </Form.Item>

        <Form.Item name={['config', 'type']} label='Feed type' {...getErrorsProps('type')}>
            <Select disabled={editMode} className='hook-feeds-feedentry-type' placeholder='Select type'>
                <Select.Option value="ingress" className='hook-feeds-feedentry-type-ingress'>Ingress</Select.Option>
                <Select.Option value="egress" className='hook-feeds-feedentry-type-egress'>Egress</Select.Option>
            </Select>
        </Form.Item>

        <Table className='hook-feeds-feedentry-sourcetable'
            pagination={{ showSizeChanger: false, hideOnSinglePage: true }}
            size='middle' dataSource={feed.config.routes} columns={columns} rowKey='id' title={headerForm} footer={footerForm}
        />
    </Form>);
}

FeedsForm.propTypes = {
    feed: PropTypes.object,
    updateFeed: PropTypes.func,
    updateValid: PropTypes.func,
    editMode: PropTypes.bool,
    errors: PropTypes.object,
    feeds: PropTypes.array
};

export function FeedsModal(props) {

    const {
        visible = true,
        onConfirm = () => {},
        onCancel = () => {},
        data = emptyFeed,
        errors = null,
        feeds
    } = props;

    const [feed, updateFeed] = useState({ ...data });
    const [isValid, updateIsValid] = useState(false);

    const resetSource = useCallback(() => {
        updateFeed({ ...emptyFeed });
    }, []);

    const doConfirm = useCallback(async () => {
        await onConfirm(feed);
    }, [feed, onConfirm]);

    const doCancel = useCallback(() => {
        onCancel();
        resetSource();
    }, [onCancel, resetSource]);

    const updateValid = useCallback((isValid) => {
        updateIsValid(isValid);
    },[]);

    return (<Modal width={'1245px'} title={ feed.id ? 'Edit Feed' : 'Create Feed'} open={visible} closable={false} className='hook-feeds-feedentry-addeditmodal'
        footer={[
            <Button disabled={!isValid} key="create" type="primary" className='hook-feeds-feedentry-addneworsavechanges' onClick={ doConfirm }>
                { feed.id ? 'Save changes' : 'Add New' }
            </Button>,
            <Button key="cancel" className='hook-feeds-feedentry-cancel' onClick={ doCancel }>
                Cancel
            </Button>]}>
        <FeedsForm updateValid={updateValid} feed={feed} updateFeed={updateFeed} editMode={!!props.data} errors={errors} feeds={feeds}/>
    </Modal>);
}

FeedsModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    data: PropTypes.object,
    errors: PropTypes.object,
    feeds: PropTypes.array
};
