function processResponseErrors(errors) {

    let errorsLookup = {};
    let errorSummary = '';

    try {
        errors.errors.forEach((error) => {
            errorsLookup[error.fieldName] = error;
        });
        errorSummary = errors.summary;
    } catch (ex) {
        errorSummary = 'Unidentified Error';
        console.log('Exception processing errors ', ex);
    }

    let ret = {
        summary: errorSummary,
        errorsLookup: errorsLookup
    };

    return ret;
}

export {
    processResponseErrors
};
