import React, { useState, useCallback, useContext, useEffect } from 'react';

import { Table, Row, Col, Button, Tooltip, Modal, Select, message, Space, PageHeader } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faInfoCircle, faTrashAlt, faExclamationCircle, faPencilAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission, newUniqueId } from '../../services';
import { SourceModal } from './SourceForm';
import { Ticker, CopyPaster } from '../util';

import { SearchFilter, onFilterIncludes } from '../../components/listview/ListView';

const CAN_CREATE_SOURCE = 'sources.add';
const CAN_DELETE_SOURCE = 'sources.delete';
const CAN_EDIT_SOURCE = 'sources.edit';
const SOURCES_REFRESH_PERIOD_MS = 3 * 1000;

export default function Sources() {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);
    const [sourcesList, updateSourcesList] = useState([]);
    const [showNewSource, updateShowNewSource] = useState(false);
    const [showEditSource, updateShowEditSource] = useState(null);
    const [sourceErrors, updateSourceErrors] = useState(null);
    const [books, updateBooks] = useState([]);
    const [whitelistLoading, updateWhitelistLoading] = useState(false);
    const [copyPaste, updateCopyPaste] = useState(null);

    const canCreateSource = testPermission(CAN_CREATE_SOURCE, permissionsMap);
    const canDeleteSource = testPermission(CAN_DELETE_SOURCE, permissionsMap);
    const canEditSource = testPermission(CAN_EDIT_SOURCE, permissionsMap);

    const copySourceToClipboard = useCallback((record) => {

        if (record && record.ipAddresses) {
            if (record.ipAddresses.length > 0) {
                const ip = record.ipAddresses[0];
                const text = `${ip.ipAddress}:${ip.port}`;
                updateCopyPaste(text);
            }
        }
    }, []);

    const refreshSources = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;
        const shallContinue = () => {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const sourcesResponseUI = await solutionApi.listSourcesUI(accountId);
            if (shallContinue()) {
                updateSourcesList(sourcesResponseUI.data.results);
            }
        } catch (exception) {
            console.log(exception);
            message.error('Failed to retrieve sources');
        }
    }, [solutionApi, userDetails.accountID]);



    const deleteSource = useCallback((sourceId, sourceName) => {

        Modal.confirm({
            title: `Are you sure you want to delete Source ${sourceName}?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
            okButtonProps: { className: 'hook-sources-dashboard-sourcedeleteconfirm' },
            cancelButtonProps: { className: 'hook-sources-dashboard-sourcedeletecancel' },
            async onOk() {
                try {
                    await solutionApi.deleteSource(userDetails.accountID, sourceId);
                }
                catch (ex) {
                    console.log(ex);
                    message.error(`Failed to delete source ${sourceName}`);
                }
            },
        });
    }, [solutionApi, userDetails.accountID]);

    const onShowEditSource = useCallback(async (sourceId) => {
        const sourceData = sourcesList.filter(source => source.id === sourceId)[0];
        sourceData.srtPassphrase = sourceData.properties.srtPassphrase || '';
        sourceData.srtAESEncryptionType = sourceData.properties.srtAESEncryptionType || 0;
        sourceData.channelUseMode = sourceData.properties.channelUseMode || 'default';
        sourceData.ipAddress = (sourceData.ipAddresses && sourceData.ipAddresses.length > 0) ? sourceData.ipAddresses[0].ipAddress : '';
        sourceData.port = (sourceData.ipAddresses && sourceData.ipAddresses.length > 0) ? sourceData.ipAddresses[0].port : 6000;
        sourceData.ipType = (sourceData.ipAddresses && sourceData.ipAddresses.length > 0) ? sourceData.ipAddresses[0].type : '';
        sourceData.ssmSourceIpAddress = (sourceData.ipAddresses && sourceData.ipAddresses.length > 0) ? sourceData.ipAddresses[0].ssmSourceIpAddress : '';

        updateShowEditSource(sourceData);
    }, [sourcesList]);

    const onWhitelistChange = useCallback(async (select, record) => {

        const updateToApply = { whitelistGroups: select.map(x => x.value) };
        await solutionApi.patchSource(userDetails.accountID, updateToApply, record.id);

    }, [solutionApi, userDetails.accountID]);

    const getWhitelists = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;

        updateWhitelistLoading(true);
        const booksResponse = await solutionApi.listWhitelistGroups(accountId);
        let booksRaw = booksResponse.data;
        if (!quitContainer || (!!quitContainer && !quitContainer.quitting)) {
            booksRaw.sort((a, b) => a.name.localeCompare(b.name));
            updateBooks(booksRaw);
            updateWhitelistLoading(false);
        }
    }, [solutionApi, userDetails.accountID]);

    const columns = [{
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterDropdown: SearchFilter,
        onFilter: onFilterIncludes('name'),
        dataIndex: 'name',
        key: 'name',
        className: 'hook-sources-sourcetable-name',
        defaultSortOrder: 'ascend'
    }, {
        title: 'Deployment',
        sorter: (a, b) => a.region.localeCompare(b.region),
        onFilter: onFilterIncludes('region'),
        filterDropdown: SearchFilter,
        key: 'region',
        dataIndex: 'region',
        className: 'hook-sources-sourcetable-region'
    },
    {
        title: 'Mode',
        sorter: (a, b) => a.inputMode.localeCompare(b.inputMode),
        onFilter: onFilterIncludes('inputMode'),
        filterDropdown: SearchFilter,
        key: 'inputMode',
        dataIndex: 'inputMode',
        className: 'hook-sources-sourcetable-mode'
    },
    {
        title: 'Content Type',
        key: 'programType',
        sorter: (a, b) => a.inputMode.localeCompare(b.inputMode),
        onFilter: onFilterIncludes('inputMode'),
        filterDropdown: SearchFilter,
        dataIndex: 'programType',
        className: 'hook-sources-sourcetable-programtype'
    },
    {
        title: 'Inputs',
        className: 'hook-sources-sourcetable-inputs',
        children: [{
            title: 'IP address',
            key: 'sourcesIp',
            sorter: (a, b) => a.ipAddresses[0].ipAddress.localeCompare(b.ipAddresses[0].ipAddress),
            onFilter: (value, record) => record.ipAddresses[0].ipAddress.includes(value.trim()),
            filterDropdown: SearchFilter,
            className: 'hook-sources-sourcetable-ipaddress',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                const allIps = record.ipAddresses.map(x => <div key={x.id}>{x.ipAddress}</div>);
                return (<>{allIps}</>);
            }
        }, {
            title: 'Port',
            key: 'sourcesPort',
            className: 'hook-sources-sourcetable-port',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                const allPorts = record.ipAddresses.map(x => <div key={x.id}>{x.port}</div>);
                return (<>{allPorts}</>);
            }
        }, {
            key: 'copy',
            className: 'hook-sources-sourcetable-copy',
            // eslint-disable-next-line react/display-name
            render: (...[, record,]) => {
                return <Button className='mk-link-button hook-sources-sourcetable-copybutton' type='link' icon={<FontAwesomeIcon icon={faCopy} />} onClick={() => { copySourceToClipboard(record); }} />;
            }
        }]
    }, {
        title: 'Action',
        key: 'actions',
        className: 'hook-sources-sourcetable-actions',
        // eslint-disable-next-line react/display-name
        render: (...[, record,]) => {
            const sourceId = record.id;

            const editButtonProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={faPencilAlt} />,
                className: 'hook-sources-sourcetable-editbutton',
                onClick: () => { onShowEditSource(sourceId); }
            };

            let editButtonText = 'Click to edit this source';
            if (!canEditSource) {
                editButtonText = 'You lack the required permissions to make changes to any sources.';
                editButtonProps.disabled = true;
            }

            const deleteButtonProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={faTrashAlt} />,
                className: 'hook-sources-sourcetable-deletebutton',
                onClick: () => { deleteSource(sourceId, record.name); },
                disabled: !record.usedBy || record.usedBy.length > 0
            };

            let deleteButtonText = 'Click to delete this source';
            if (!canDeleteSource) {
                deleteButtonText = 'You lack the required permissions to delete any channels';
                deleteButtonProps.disabled = true;
            }

            return (<>
                <Tooltip title={editButtonText}>
                    <Button {...editButtonProps} />
                </Tooltip>
                <Tooltip title={deleteButtonText}>
                    <Button {...deleteButtonProps} />
                </Tooltip>
            </>);
        }
    }, {
        title: 'Whitelist groups',
        key: 'whitelist',
        className: 'hook-sources-sourcetable-whitelistgroups',
        // eslint-disable-next-line react/display-name
        render: (...[, record,]) => {

            const disableAllowlist = !!record.ipAddresses
                && (record.ipAddresses.length > 0)
                && record.ipAddresses[0].type === 'interconnect';

            const whitelistNames = record.whitelistGroups.map(x => { return { value: x.id, label: x.name }; });

            let whitelistText = 'make instant changes to a source\'s whitelist';
            let whitelistProps = {};
            if (!canEditSource) {
                whitelistProps.disabled = true;
                whitelistText = 'You lack the required permissions to make changes to a source\'s whitelist';
            }

            const options = books.map((e, index) => {
                return {
                    value: e.id,
                    label: e.name,
                    className: `hook-sources-sourcetable-whitelist-option hook-sources-sourcetable-whitelist-${index.toString()}`
                };
            });

            return (
                <Tooltip title={whitelistText}>
                    <Select
                        labelInValue={true}
                        disabled={disableAllowlist}
                        mode='multiple'
                        placeholder='Please select'
                        defaultValue={whitelistNames}
                        onChange={(e) => onWhitelistChange(e, record)}
                        style={{ width: '150px' }}
                        onDropdownVisibleChange={open => open && getWhitelists()}
                        loading={whitelistLoading}
                        options={options}
                        optionFilterProp="label"
                        {...whitelistProps}
                    />
                </Tooltip>
            );

        }
    }, {
        title: 'Used by',
        key: 'usedBy',
        className: 'hook-sources-sourcetable-usedby',
        // eslint-disable-next-line react/display-name
        render: (...[, record,]) => {
            if (record.usedBy) {
                const allPorts = record.usedBy.map(x => <div key={x}>{x}</div>);
                return (<>{allPorts}</>);
            }
            else
                return <></>;
        }
    }];


    useEffect(() => {
        // once only on load
        refreshSources(null);
    }, [refreshSources]);


    const createButtonProps = {
        onClick: () => {
            updateShowNewSource(true);
        }
    };

    let createButtonText = 'Click to add a new source';
    if (!canCreateSource) {
        createButtonProps['disabled'] = true;
        createButtonText = 'You do not have the required permissions to create a source';
    }

    const headerForm = () => (
        <Row gutter={[8, 8]} justify='space-between'>
            <Col>
                <Tooltip title={createButtonText}>
                    <Button className='hook-sources-dashboard-newsource' {...createButtonProps}>New Source</Button>
                </Tooltip>
            </Col>
            <Col>
                <Link to="/whitelists">
                    <Button className='hook-sources-dashboard-whitelists'>Manage Whitelists...</Button>
                </Link>
            </Col>
        </Row>
    );

    const addNewSource = useCallback(async (newSource) => {

        const payload = {
            name: newSource.name,
            region: newSource.region,
            inputMode: newSource.inputMode,
            programType: newSource.programType,
            ipAddresses: [{
                id: newUniqueId(),
                type: newSource.ipType,
                ipAddress: newSource.ipAddress,
                port: newSource.port,
                ssmSourceIpAddress: newSource.ssmSourceIpAddress
            }],
            properties: {
                srtPassphrase: newSource.srtPassphrase,
                srtAESEncryptionType: newSource.srtAESEncryptionType,
            }
        };

        if (newSource.channelUseMode !== 'default') {
            payload.properties.channelUseMode = newSource.channelUseMode;
        }

        let success = false;
        try {
            const accountId = userDetails.accountID;
            const response = await solutionApi.createSource(accountId, payload);
            if (response.ok) {
                updateSourceErrors(null);
                updateShowNewSource(false);
                success = true;
            } else {
                updateSourceErrors(response.data);
            }

        } catch (exception) {
            console.log(exception);
            message.error('Failed to add new source');
        }

        return success;

    }, [solutionApi, userDetails.accountID]);

    const onEditExistingSource = useCallback(async (newSource) => {
        const accountId = userDetails.accountID;
        if ('usedBy' in newSource)
            delete newSource['usedBy'];
        if ('activeUsedBy' in newSource)
            delete newSource['activeUsedBy'];
        const response = await solutionApi.editSource(accountId, newSource);

        if (response.ok) {
            updateShowEditSource(null);
            updateSourceErrors(null);
        } else {
            updateSourceErrors(response.data);
        }

        return response.ok;

    }, [solutionApi, userDetails.accountID]);

    const onSourceCancel = useCallback(() => {
        updateShowNewSource(false);
        updateShowEditSource(null);
        updateSourceErrors(null);
    }, []);

    return (
        <>
            <Ticker asyncCallback={refreshSources} timeout={SOURCES_REFRESH_PERIOD_MS} />
            <CopyPaster text={copyPaste} onComplete={() => { updateCopyPaste(null); }} />
            <Row align='middle'>
                <Col>
                    <FontAwesomeIcon icon={faSignInAlt} size='4x' />
                </Col>
                <Col>
                    <PageHeader backIcon={null} title="Sources" />
                </Col>
            </Row>
            <Row className='gutter-bottom'>
                <Col>
                    <Space>
                        <FontAwesomeIcon icon={faInfoCircle} size='2x' color='blue' />
                        Enter sources which will ingress or egress content from the system.
                    </Space>
                </Col>
            </Row>
            <Table
                className='hook-sources-dashboard-sourcestable'
                size='middle'
                dataSource={sourcesList}
                columns={columns}
                rowKey='id'
                title={headerForm}
                scroll={{ x: true }}
                pagination={{
                    showSizeChanger: true,
                }}
            />
            {
                showNewSource ? <SourceModal key='new' onConfirm={addNewSource} onCancel={onSourceCancel} errors={sourceErrors} /> : null
            }
            {
                (showEditSource != null) ? <SourceModal key='edit' onConfirm={onEditExistingSource} onCancel={onSourceCancel} data={showEditSource} errors={sourceErrors} /> : null
            }
        </>
    );
}
