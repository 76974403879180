import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Alert, InputNumber, Checkbox } from 'antd';
import { getErrorProps } from '../../services';
import { validateThirtyTwoAlphaNumericDashesUnderscore } from '../../services/FormUtils';

export default function StatmuxPoolForm(
    {
        statmuxPool,
        form,
        errors,
        updateValid = () => {},
        editEnabled = true
    }) {

    useEffect(() => {
        //should run once on load
        form.setFieldsValue(statmuxPool);
    }, [statmuxPool, form]);

    const onValuesChanged = useCallback((...[, allValues]) => {
        // ideally we would have the list of required fields to check if their default value has changed
        const requiredFieldsValues = [allValues.name, allValues.bitrate];
        updateValid(requiredFieldsValues.every(fieldValue => fieldValue !== ''));
    }, [updateValid]);

    const currentFormValues = form.getFieldsValue();
    const fieldLoadComplete = currentFormValues.id === statmuxPool.id;
    const disableFields = !fieldLoadComplete || !editEnabled;

    return <Form labelCol={{ span: 9 }} wrapperCol={{ span: 16 }} layout='horizontal' form={form}
        onValuesChange={onValuesChanged}
    >
        {
            errors && <Alert style={{ marginBottom: 8 }} message={errors.summary || ''}
                description={errors.errors?.map(item => item.text).join('\n') || ''} type='error'
            />
        }
        <Form.Item key='id' name='id' label='id' className='form-hidden'>
            <Input type='hidden'/>
        </Form.Item>
        <Form.Item key='name' name='name' label='Name' {...getErrorProps('name', errors)} rules={[{ required: true, validator: validateThirtyTwoAlphaNumericDashesUnderscore }]}>
            <Input disabled={disableFields} className='hook-statmuxpools-statmuxpoolconfig-name'
                placeholder='Statmux Pool Name'
            />
        </Form.Item>
        <Form.Item name='bitrate' label='Bitrate (bps)' rules={[{ required: true }]}
            {...getErrorProps('bitrate', errors)}
        >
            <InputNumber disabled={disableFields} className='hook-statmuxpools-statmuxpoolconfig-bitrate'
                placeholder='Bitrate' style={{ width: '295px' }}
            />
        </Form.Item>
        <Form.Item name='videoOnly' label='Video Only' valuePropName='checked' {...getErrorProps('videoOnly', errors)}>
            <Checkbox disabled={disableFields} className='hook-statmuxpools-statmuxpoolconfig-videoOnly'/>
        </Form.Item>
    </Form>;
}

StatmuxPoolForm.propTypes = {
    form: PropTypes.object,
    statmuxPool: PropTypes.object,
    errors: PropTypes.object,
    updateValid: PropTypes.func,
    editEnabled: PropTypes.bool
};
