import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useParams, Link } from 'react-router-dom';
import InlineMonitor from '../../components/InlineMonitor';
import { SolutionApiContext, SelectedAccountContext } from '../../services';
import { Row, Col, Breadcrumb } from 'antd';


export default function ViewChannel({ updateNavigateCallback }) {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;

    const [channelDetails, updateChannelDetails] = useState(null);

    let { channelId } = useParams(); // from the URL

    const breadcrumbOverride = useCallback((path, breadcrumbItems) => {
        const pathItems = path.pathname.split('/');
        let compound = '';

        for (let index = 0; index < pathItems.length; index++) {
            const component = pathItems[index];
            compound = `${compound}${component}/`;

            // don't add for empty text due to the 'split';
            if (index === 0) {
                breadcrumbItems.push(<Breadcrumb.Item className='hook-dashboard-home' key={compound}><Link to={compound}>Home</Link></Breadcrumb.Item>);
            } else if (index === 1) {
                const displayText = component.replace(/^\w/, c => c.toUpperCase());
                const classHook = 'hook-dashboard-'.concat(displayText);
                breadcrumbItems.push(<Breadcrumb.Item className={classHook} key={compound}>{displayText}</Breadcrumb.Item>);
            } else if (component.length > 0) {
                const idDisplayText = component.replace(/^\w/, c => c.toUpperCase());
                const classHook = 'hook-dashboard-'.concat(idDisplayText);
                const displayText = (channelDetails != null) ? channelDetails.name.replace(/^\w/, c => c.toUpperCase()) : '.....';
                breadcrumbItems.push(<Breadcrumb.Item className={classHook} key={compound}><Link to={compound}>{displayText}</Link></Breadcrumb.Item>);

            }
        }
    }, [channelDetails]);

    useEffect(() => {
        if (updateNavigateCallback) {
            updateNavigateCallback(breadcrumbOverride);
        }
    }, [updateNavigateCallback, breadcrumbOverride]);

    const onLoad = useCallback(async () => {

        let channelResponse = await solutionApi.getChannel(accountId, channelId);
        if (channelResponse.ok) {
            updateChannelDetails(channelResponse.data);
            console.log(channelResponse);
        }

    }, [accountId, channelId, solutionApi]);

    useEffect(() => {onLoad();}, [onLoad]);

    const channelText = (channelDetails != null) ? `Channel ${channelDetails.name}` : 'Loading Channel Details.....';

    return <>
        <div>{channelText}</div>
        <Row justify='center'>
            <Col>
                <InlineMonitor scope='inline' channelId={channelId}/>
            </Col>
        </Row>
    </>;
}

ViewChannel.propTypes = {
    updateNavigateCallback: PropTypes.func
};
