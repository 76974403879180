import displayErrors from '../../services/DisplayErrors';

export async function refreshChannels(solutionApi, accountId) {
    try {
        const channelsResponse = await solutionApi.listChannelsUI(accountId);

        if (channelsResponse.ok) {
            const activeChannels = channelsResponse.data.results.filter(channel =>
                channel.state === 'started' || channel.state === 'starting'
            );
            return [channelsResponse.data.results, activeChannels.length];
        } else {
            console.log(channelsResponse);
            let jsonData = channelsResponse.data;
            displayErrors(jsonData.summary, jsonData.errors, 15);
        }
    } catch (exception) {
        console.log(`Exception encountered while trying to refresh channels, error was ${exception}`);
    }
    return [null, null];
}

export async function refreshAlerts(solutionApi, accountId) {
    try {
        const alertsResponse = await solutionApi.listAlerts(accountId);

        if (alertsResponse.ok) {
            const alerts = {};
            alertsResponse.data.forEach(item => {
                if (item.channelId == 'null') {
                    alerts['other'] = item;
                } else {
                    alerts[item.channelId] = item;
                }
            });
            return alerts;
        } else {
            console.log(alertsResponse);
            let jsonData = alertsResponse.data;
            displayErrors(jsonData.summary, jsonData.errors, 15);
        }
    } catch (exception) {
        console.log(`Exception encountered while trying to refresh alerts, error was ${exception}`);
    }
    return null;
}

export async function refreshSources(solutionApi, accountId) {
    try {
        const sourcesResponse = await solutionApi.listSources(accountId, ['id', 'name', 'region']);

        if (sourcesResponse.ok) {
            return sourcesResponse.data;
        } else {
            console.log(sourcesResponse);
            let jsonData = sourcesResponse.data;
            displayErrors(jsonData.summary, jsonData.errors, 15);
        }
    } catch (exception) {
        console.log(`Exception encountered while trying to refresh sources, error was ${exception}`);
    }
    return null;
}