import React , { useContext, useEffect, useState, useCallback } from 'react';
import { SelectedAccountContext, SolutionApiContext } from '../../services';
import { Row, Col } from 'antd';


export default function Usage() {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;

    const [entitlements, updateEntitlements] = useState({});

    const loadEntitlements = useCallback(async ()=>{
        const entitlementsResponse = await solutionApi.getEntitlements(accountId);
        console.log(entitlementsResponse);
        if (entitlementsResponse.ok) {
            updateEntitlements(entitlementsResponse.data);
        }
    } , [accountId, solutionApi]);

    useEffect(() => {
        loadEntitlements();
    }, [loadEntitlements]);

    const entitlementItems = [];
    for (let entitlementKey in entitlements.entitlements) {
        entitlementItems.push(<Row key={entitlementKey}><Col span={4}>{entitlements.entitlements[entitlementKey].name}</Col><Col>{entitlements.entitlements[entitlementKey].allowed}</Col></Row>);
    }

    const useItems = [];
    for (let useKey in entitlements.usage) {
        useItems.push(<Row key={useKey}><Col span={4}>{useKey}</Col><Col>{entitlements.usage[useKey]}</Col></Row>);
    }

    return <><Row style={{ marginBottom: '12px' }}><Col>Your account entitles you to the following:</Col></Row>
        {
            entitlementItems
        }
        <Row style={{ marginTop: '12px', marginBottom: '12px' }}><Col>Your current use is:</Col></Row>
        {
            useItems
        }
    </>;
}
