import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Form, Input, InputNumber, Select, Switch, Tabs } from 'antd';
import { processResponseErrors, SolutionApiContext, SelectedAccountContext, EntitlementsContext, checkEntitlement } from '../../services';
import { validateTrimmedWhitespace } from '../../services/FormUtils';
import * as STATE from './ChannelStates';
import { InstancesTable } from './InstancesTable';

const enableProgramNumber = (sourceOptions, channelSource) => {
    let selectedSource = sourceOptions.filter(source => source.id === channelSource);
    const showProgNumber = (selectedSource.length > 0) && (selectedSource[0].programType === 'mpts');
    return showProgNumber;
};

const CHANNEL_TYPE = {
    OTT: 'OTT',
    BROADCAST: 'Broadcast',
};

const MUXES_QUOTA_NAME = 'Muxes';

const TemplateSelect = ({ disableFields, accountId, errorObj, channelType }) => {
    const solutionApi = useContext(SolutionApiContext);
    const [templateOptions, updateTemplateOptions] = useState([]);
    const [loading, updateLoading] = useState(false);

    const getErrorsProps = (fieldName, errorObj) => {
        let props = {};

        const lookup = errorObj.errorsLookup[fieldName];

        if (lookup) {
            props.validateStatus = 'error';
            props.help = lookup.text;
        }

        return props;
    };

    const getTemplates = useCallback(async (channelType) => {
        updateLoading(true);

        try {
            const templatesResponse = await solutionApi.listTemplates(
                accountId,
                channelType
            );
            if (templatesResponse.ok) {
                updateTemplateOptions(templatesResponse.data.sort((a, b) => a.name.localeCompare(b.name)));
            }
            updateLoading(false);
        } catch (ex) {
            console.log('templates problems', ex);
            updateLoading(false);
        }
    }, [accountId, solutionApi]);


    useEffect(() => {
        getTemplates(channelType);
    }, [getTemplates, channelType]);


    return (
        <Form.Item
            key='template'
            name='template'
            label='Template'
            {...getErrorsProps('template', errorObj)}
            rules={[{ required: true }]}
        >
            <Select
                disabled={disableFields}
                loading={loading}
                className='hook-channels-channelconfig-template'
                optionFilterProp="label"
                showSearch={true}
                options={templateOptions.map((template, index) => ({
                    className: `hook-channels-channelconfig-template-${index.toString()}`,
                    value: template.id ? template.id : '',
                    label: template.name ? template.name : ''
                }))}
            />
        </Form.Item>
    );
};

TemplateSelect.propTypes = {
    disableFields: PropTypes.bool,
    accountId: PropTypes.string,
    errorObj: PropTypes.object,
    channelType: PropTypes.string
};

export default function ChannelForm({
    channel,
    form,
    errors,
    editEnabled = true,
    updateValid = () => { }
}) {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;
    const entitlementsMap = useContext(EntitlementsContext);

    const hasMuxesEntitlement = checkEntitlement(MUXES_QUOTA_NAME, entitlementsMap) > 0;

    const [sourceOptions, updateSourceOptions] = useState([]);
    const [feedsList, updateFeedsList] = useState([]);
    const [backupEnabled, updateBackupEnabled] = useState(false);
    const [haBackupEnabled, updateHaBackupEnabled] = useState(false);
    const [showProgramNumber, updateShowProgramNumber] = useState(false);
    const [showBackupProgramNumber, updateShowBackupProgramNumber] = useState(false);
    const [haShowProgramNumber, updateShowHaProgramNumber] = useState(false);
    const [haShowBackupProgramNumber, updateShowHaBackupProgramNumber] = useState(false);
    const [activePane, updateActivePane] = useState('1');
    const [secondChannelInstance, updateSecondChannelInstance] = useState(false);
    const [selectedFeed, updateSelectedFeed] = useState('');
    const [channelType, updateChannelType] = useState();
    const [templateRevisions, updateTemplateRevisions] = useState([]);
    const [disableRevisions, updateDisableRevisions] = useState(true);

    const updatev2Instances = useCallback(newInstances => {
        if(newInstances.instances){
            const newConfig = {
                'feed': selectedFeed,
                'instances': { ...newInstances.instances }
            };
            form.setFieldValue('config', newConfig);
        }
    }, [selectedFeed, form]);

    const getTemplateRevisions = useCallback(async (templateValue) => {

        try {
            const templatesRevisionsResponse = await solutionApi.listTemplateRevisions(accountId, templateValue);

            if (templatesRevisionsResponse.ok) {
                // Reverse the array so latest is first
                updateTemplateRevisions(templatesRevisionsResponse.data.concat().reverse().sort());
            }
        } catch (ex) {
            console.log('template revisions problems', ex);
        }
    }, [accountId, solutionApi]);

    const onLoad = useCallback(async () => {
        try {

            if(!channel || !channel.type){
                // The OnLoad can be fired when channel is set properly on very first load, so need to protect against this.
                return;
            }

            updateActivePane('1');
            updateDisableRevisions(true);

            const sourcesResponse = await solutionApi.listSources(accountId);
            if (sourcesResponse.ok) {
                updateSourceOptions(sourcesResponse.data);
                updateShowProgramNumber(false);

                // Reset to default
                form.setFieldsValue({
                    'programNumber': '',
                    'haProgramNumber': '',
                    'backupProgramNumber': '',
                    'haBackupProgramNumber': '',
                    'haTemplateRevision': 'latest'
                });

                const enableProgNumber = enableProgramNumber(sourcesResponse.data, channel.source);
                updateShowProgramNumber(enableProgNumber);

                const enableBackupProgNumber = enableProgramNumber(sourcesResponse.data, channel.backupSource);
                updateShowBackupProgramNumber(enableBackupProgNumber);

                const enableHaProgNumber = enableProgramNumber(sourcesResponse.data, channel.haSource);
                updateShowHaProgramNumber(enableHaProgNumber);

                const enableHaBackupProgNumber = enableProgramNumber(sourcesResponse.data, channel.haBackupSource);
                updateShowHaBackupProgramNumber(enableHaBackupProgNumber);
            }
            const feedsResponse = await solutionApi.listFeeds(accountId);
            if (feedsResponse.ok) {
                updateFeedsList(feedsResponse.data);

                if(channel.config && channel.config.feed){
                    updateSelectedFeed(channel.config.feed);
                }
            }

        } catch (ex) {
            console.log('problems', ex);
        }
        channel.backupEnabled = !!channel.backupSource;

        if (channel.template && channel.type == CHANNEL_TYPE.OTT) {
            updateDisableRevisions(false);
            getTemplateRevisions(channel.template);

            form.setFieldsValue({
                'templateRevision': channel.instances[0].templateRevision,
            });

            if (channel.instances.length > 1) {
                form.setFieldsValue({
                    'haTemplateRevision': channel.instances[1].templateRevision,
                });
            }
        }

        if(channel.type == CHANNEL_TYPE.OTT){
            form.setFieldsValue(channel);
        } else {
            form.setFieldsValue({
                'name': channel.name,
                'id': channel.id,
                'type': channel.type,
                'template': channel.template,
                'config': {
                    'feed': channel.config.feed,
                    'instances': channel.config.instances
                }
            });
        }

        // These control vars have spilled over into the channel, could be inferred for cleaner approach
        updateBackupEnabled(channel.backupEnabled);
        updateSecondChannelInstance(channel.haEnabled);
        updateHaBackupEnabled(channel.haBackupEnabled);
        updateChannelType(channel.type);
    }, [accountId, channel, form, solutionApi, getTemplateRevisions]);

    useEffect(() => {
        if (!showProgramNumber) {
            form.setFieldsValue({ 'programNumber': '' });
        }
    }, [form, showProgramNumber]);

    useEffect(() => {
        if (!showBackupProgramNumber) {
            form.setFieldsValue({ 'backupProgramNumber': '' });
        }
    }, [form, showBackupProgramNumber]);

    useEffect(() => {
        if (!haShowProgramNumber) {
            form.setFieldsValue({ 'haProgramNumber': '' });
        }
    }, [form, haShowProgramNumber]);

    useEffect(() => {
        if (!haShowBackupProgramNumber) {
            form.setFieldsValue({ 'haBackupProgramNumber': '' });
        }
    }, [form, haShowBackupProgramNumber]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    const errorsClassName = errors ? '' : 'form-hidden';

    let errorObj = {
        summary: '',
        errorsLookup: {}
    };

    if (errors && errors.errors) {
        errorObj = processResponseErrors(errors);
    }

    const getErrorsProps = useCallback((fieldName) => {
        let props = {};

        const lookup = errorObj.errorsLookup[fieldName];

        if (lookup) {
            props.validateStatus = 'error';
            props.help = lookup.text;
        }

        return props;
    }, [errorObj.errorsLookup]);

    const onValuesChanged = useCallback((...[, allValues]) => {
        // ideally we would have the list of required fields to check if their default value has changed.
        if (allValues.type == CHANNEL_TYPE.OTT) {
            const requiredLiveFieldsValues = [allValues.name, allValues.source, allValues.template];
            updateValid(requiredLiveFieldsValues.every(fieldValue => fieldValue !== undefined && fieldValue !== ''));
        }
        else {
            const requiredBroadcastFieldsValues = [allValues.name, allValues.template];
            if (allValues.config) {
                // When switching channel type, the values will alter between the transition of the render
                requiredBroadcastFieldsValues.push(allValues.config.feed);
                updateValid(allValues.config.instances !== undefined && Object.keys(allValues.config.instances).length > 0);
            } else {
                updateValid(requiredBroadcastFieldsValues.every(fieldValue => fieldValue !== undefined && fieldValue !== ''));
            }
        }
        updateBackupEnabled(allValues.backupEnabled);
        updateHaBackupEnabled(allValues.haBackupEnabled);

        if (allValues.source !== null) {
            let enableProgNumber = enableProgramNumber(sourceOptions, allValues.source);
            updateShowProgramNumber(enableProgNumber);
        }

        if (allValues.backupSource !== null) {
            let enableBackupProgNumber = enableProgramNumber(sourceOptions, allValues.backupSource);
            updateShowBackupProgramNumber(enableBackupProgNumber);
        }

        if (allValues.haSource !== null) {
            let enableProgNumber = enableProgramNumber(sourceOptions, allValues.haSource);
            updateShowHaProgramNumber(enableProgNumber);
        }
        if (allValues.haBackupSource !== null) {
            let enableBackupProgNumber = enableProgramNumber(sourceOptions, allValues.haBackupSource);
            updateShowHaBackupProgramNumber(enableBackupProgNumber);
        }

        if (allValues.template !== '') {
            getTemplateRevisions(allValues.template);
            updateDisableRevisions(false);
        }

    }, [updateValid, sourceOptions, getTemplateRevisions]);


    const onPaneChange = useCallback((key) => {
        updateActivePane(key);
    }, []);

    function mapRevisions(templateRevisions) {
        let map = templateRevisions.map((revision, index) => ({
            className: `hook-channels-channelconfig-revision-${index.toString()}`,
            value: revision.id ? revision.id : '',
            label: revision.id ? revision.id : ''
        }));

        const orderedRevisions = [{
            classname: 'hook-channels-channelconfig-revision-latest',
            value: 'latest',
            label: 'Latest'
        }, ...map];

        return orderedRevisions;
    }

    const handleChannelTypeChange = useCallback(value => {
        updateChannelType(value);
        if(value == CHANNEL_TYPE.BROADCAST){

            const emptyInstances = {};

            updatev2Instances(emptyInstances);

            const newConfig = {
                'feed': '',
                'instances': emptyInstances
            };

            form.setFieldsValue({
                'config': newConfig
            });

            updateSelectedFeed('');
        }

        form.setFieldsValue({
            'template': '',
            'templateRevision': 'latest',
            'haTemplateRevision': 'latest'
        });
        updateDisableRevisions(true);
    }, [form, updatev2Instances]);

    const handleFeedChange = useCallback(value => {
        updateSelectedFeed(value);
    }, []);

    const currentFormValues = form.getFieldsValue();
    const fieldLoadComplete = currentFormValues.id === channel.id;
    const disableFields = !fieldLoadComplete || !editEnabled;

    const tabItems = [
        {
            key: '1',
            label: 'Source',
            className: 'channels-form-tabpane hook-channel-main-tab',
            children: (
                <>
                    <Form.Item
                        key='source'
                        name='source'
                        label="Main source"
                        {...getErrorsProps('instances.0.sources.0.sourceId')}
                        rules={[{ required: true }]}
                    >
                        <Select
                            disabled={disableFields}
                            className="hook-channels-channelconfig-mainsource"
                            placeholder="Search Source to select"
                            optionFilterProp="label"
                            showSearch={true}
                            options={sourceOptions.map((source, index) => ({
                                className: `hook-channels-channelconfig-mainsource-${index.toString()}`,
                                value: source.id ? source.id : '',
                                label: `${source.name ? source.name : ''} - ${source.region ? source.region : ''}`
                            }))}
                        />
                    </Form.Item>

                    <Form.Item
                        key='programNumber'
                        name='programNumber'
                        label='Program number'
                        {...getErrorsProps('instances.0.sources.0.programNumber')}
                        rules={[
                            {
                                required: showProgramNumber,
                                message: 'Program ID range: [1-65535]'
                            },
                            {
                                type: 'number',
                                min: 1,
                                max: 65535
                            }
                        ]}
                    >
                        <InputNumber
                            disabled={disableFields || !showProgramNumber}
                            min={1}
                            max={65535}
                            placeholder="Number"
                        />
                    </Form.Item>

                    <Form.Item
                        key='backupEnabled'
                        name='backupEnabled'
                        label='Backup source enabled'
                        {...getErrorsProps('backupEnabled')}
                        valuePropName="checked"
                    >
                        <Switch
                            disabled={disableFields}
                            className='hook-channels-channelconfig-enablebackupsource'
                        >
                            Enable backup source
                        </Switch>
                    </Form.Item>
                    <Form.Item
                        key="backupSource"
                        name="backupSource"
                        label="Backup source"
                        {...getErrorsProps('instances.0.sources.1.sourceId')}
                    >
                        <Select
                            disabled={disableFields || !backupEnabled}
                            className="hook-channels-channelconfig-backupsource"
                            placeholder="Search Backup Source to select"
                            optionFilterProp="label"
                            showSearch={true}
                            options={sourceOptions.map((source, index) => ({
                                className: `hook-channels-channelconfig-backupsource-${index.toString()}`,
                                value: source.id ? source.id : '',
                                label: `${source.name ? source.name : ''} - ${source.region ? source.region : ''}`
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        key='backupProgramNumber'
                        name='backupProgramNumber'
                        label='Program number'
                        {...getErrorsProps('instances.0.sources.1.programNumber')}
                        rules={[{
                            type: 'number',
                            min: 1,
                            max: 65535,
                            message: 'Program ID range: [1-65535]'
                        }]}
                    >
                        <InputNumber
                            disabled={disableFields || !showBackupProgramNumber}
                            min={1}
                            max={65535}
                            placeholder="Number"
                        />
                    </Form.Item>
                    <Form.Item
                        key='templateRevision'
                        name='templateRevision'
                        label='Template Revision'
                        {...getErrorsProps('instances.0.templateRevision', errorObj)}
                        rules={[{ required: true }]}
                    >
                        <Select
                            disabled={disableFields || disableRevisions}
                            className='hook-channels-channelconfig-revision'
                            optionFilterProp="label"
                            showSearch={true}
                            options={mapRevisions(templateRevisions)}
                        />
                    </Form.Item>
                </>
            ),
        },
        {
            key: '2',
            label: 'High Availability',
            className: 'channels-form-tabpane hook-channel-main-ha-tab',
            children: (
                <>
                    <Form.Item
                        key='haEnabled'
                        name='haEnabled'
                        label='High availability enabled'
                        {...getErrorsProps('haEnabled')}
                        valuePropName="checked"
                    >
                        <Switch
                            className='hook-channels-channelconfig-channel-secondinstance'
                            disabled={channel.state === STATE.STARTED}
                            onChange={() => { updateSecondChannelInstance(!secondChannelInstance); }}
                        >
                            Enable a 2nd instance for HA
                        </Switch>
                    </Form.Item>
                    <Form.Item
                        key="haSource"
                        name="haSource"
                        label="Main source"
                        {...getErrorsProps('instances.1.sources.0.sourceId')}
                        rules={[{ required: secondChannelInstance }]}
                    >
                        <Select
                            disabled={disableFields || !secondChannelInstance}
                            className="hook-channels-channelconfig-ha-mainsource"
                            placeholder="Search Source to select"
                            optionFilterProp="label"
                            showSearch={true}
                            options={sourceOptions.map((source, index) => ({
                                className: `hook-channels-channelconfig-ha-mainsource-${index.toString()}`,
                                value: source.id ? source.id : '',
                                label: `${source.name ? source.name : ''} - ${source.region ? source.region : ''}`
                            }))}
                        />
                    </Form.Item>

                    <Form.Item
                        key='haProgramNumber'
                        name='haProgramNumber'
                        label='Program number'
                        {...getErrorsProps('instances.1.sources.0.programNumber')}
                        rules={[
                            {
                                required: haShowProgramNumber,
                                message: 'Program ID range: [1-65535]'
                            },
                            {
                                type: 'number',
                                min: 1,
                                max: 65535
                            }
                        ]}
                    >
                        <InputNumber
                            disabled={disableFields || !haShowProgramNumber || !secondChannelInstance}
                            min={1}
                            max={65535}
                            placeholder="Number"
                        />
                    </Form.Item>

                    <Form.Item
                        key='haBackupEnabled'
                        name='haBackupEnabled'
                        label='Backup source enabled'
                        {...getErrorsProps('haBackupEnabled')}
                        valuePropName="checked"
                    >
                        <Switch
                            disabled={disableFields || !secondChannelInstance}
                            className='hook-channels-channelconfig-enable-ha-backupsource'
                        >
                            Enable backup source
                        </Switch>
                    </Form.Item>
                    <Form.Item
                        key="haBackupSource"
                        name="haBackupSource"
                        label="Backup source"
                        {...getErrorsProps('instances.1.sources.1.sourceId')}
                    >
                        <Select
                            disabled={disableFields || !haBackupEnabled || !secondChannelInstance}
                            className="hook-channels-channelconfig-ha-backupsource"
                            placeholder="Search Backup Source to select"
                            optionFilterProp="label"
                            showSearch={true}
                            options={sourceOptions.map((source, index) => ({
                                className: `hook-channels-channelconfig-ha-backupsource-${index.toString()}`,
                                value: source.id ? source.id : '',
                                label: `${source.name ? source.name : ''} - ${source.region ? source.region : ''}`
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        key='haBackupProgramNumber'
                        name='haBackupProgramNumber'
                        label='Program number' {...getErrorsProps('instances.1.sources.1.programNumber')}
                        rules={[{
                            required: haShowBackupProgramNumber,
                            type: 'number',
                            min: 1,
                            max: 65535,
                            message: 'Program ID range: [1-65535]'
                        }]}
                    >
                        <InputNumber
                            disabled={disableFields || !haShowBackupProgramNumber || !secondChannelInstance}
                            min={1}
                            max={65535}
                            placeholder="Number"
                        />
                    </Form.Item>
                    <Form.Item
                        key='haTemplateRevision'
                        name='haTemplateRevision'
                        label='Template Revision'
                        {...getErrorsProps('instances.1.templateRevision', errorObj)}
                        rules={[{ required: secondChannelInstance }]}
                    >
                        <Select
                            disabled={disableFields || disableRevisions || !secondChannelInstance}
                            className='hook-channels-channelconfig-revision'
                            optionFilterProp="label"
                            showSearch={true}
                            options={mapRevisions(templateRevisions)}
                        />
                    </Form.Item>
                </>
            )
        }
    ];

    return (
        <Form
            initialValues={{ type: CHANNEL_TYPE.OTT }}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 16 }}
            layout='horizontal'
            form={form}
            onValuesChange={onValuesChanged}
        >
            <Alert
                style={{ marginBottom: 8, whiteSpace: 'pre-line' }}
                className={errorsClassName}
                message={errors ? errorObj.summary : ''}
                description={errors && errors.errors ? errors.errors.map(item => item.text).join('\n') : ''}
                type="error"
            />
            <Form.Item key='id' name='id' label='id' className='form-hidden'>
                <Input type='hidden' />
            </Form.Item>
            <Form.Item key='name' name='name' label='Name' {...getErrorsProps('name')}
                rules={[{ required: true, validator: validateTrimmedWhitespace, }]}>
                <Input
                    disabled={disableFields}
                    className='hook-channels-channelconfig-name'
                    placeholder="Channel Name"
                />
            </Form.Item>
            <Form.Item key='type' name='type' label='Channel Type' {...getErrorsProps('type')} valuePropName="option">
                <Select
                    value={channelType}
                    disabled={disableFields}
                    className='hook-channels-channelconfig-channeltype'
                    onChange={handleChannelTypeChange}
                >
                    <Select.Option
                        key={CHANNEL_TYPE.OTT}
                        className='hook-channels-channelconfig-channeltype-ott'
                        value={CHANNEL_TYPE.OTT}
                    >
                        OTT
                    </Select.Option>
                    <Select.Option
                        disabled={!hasMuxesEntitlement}
                        className='hook-channels-channelconfig-channeltype-broadcast'
                        key={CHANNEL_TYPE.BROADCAST}
                        value={CHANNEL_TYPE.BROADCAST}
                    >
                        Broadcast
                    </Select.Option>
                </Select>
            </Form.Item>

            <TemplateSelect
                disableFields={disableFields}
                accountId={accountId}
                errorObj={errorObj}
                channelType={channelType}
            />
            {
                channelType !== CHANNEL_TYPE.BROADCAST &&
                <Form.Item
                    key='timeshiftDuration'
                    name='timeshiftDuration'
                    label='Timeshift buffer duration'
                    {...getErrorsProps('timeshiftDuration')}
                >
                    <Select
                        disabled={disableFields}
                        className='hook-channels-channelconfig-timeshiftduration'
                        options={[0, 24, 48].map((duration) => ({
                            value: duration,
                            label: `${duration} hours`,
                            className: `hook-channels-channelconfig-timeshiftduration-${duration}`
                        }))}
                    />
                </Form.Item>
            }
            {
                channelType !== CHANNEL_TYPE.BROADCAST &&
                <Form.Item key='publishPoint' name='publishPoint' label='Publish Point' {...getErrorsProps('publishPoint')}
                    rules={[{ required: false, validator: validateTrimmedWhitespace, }]}>
                    <Input
                        disabled={disableFields}
                        className='hook-channels-channelconfig-publishpoint'
                        placeholder="Publish Point"
                    />
                </Form.Item>
            }
            {
                channelType === CHANNEL_TYPE.BROADCAST &&
                <>
                    <Form.Item
                        key='feed'
                        name={['config', 'feed']}
                        label='Feed'
                        {...getErrorsProps('feed')}
                        rules={[{ required: true }]}
                        valuePropName="option"
                    >
                        <Select
                            disabled={disableFields}
                            className='hook-channels-channelconfig-feed'
                            onChange={handleFeedChange}
                            value={selectedFeed}
                            showSearch={true}
                            optionFilterProp='label'
                            options={feedsList.map((feed) => ({
                                value: feed.id,
                                label: feed.config.name,
                                className: `hook-channels-channelconfig-feed-${feed.config.name}`
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        key='instance'
                        name={['config', 'instances']}
                        {...getErrorsProps('instances')}
                        wrapperCol= {{ span: 24 }}
                    >
                        <InstancesTable
                            feed={feedsList.find(feed => feed.id == selectedFeed)}
                            instances={channel.config && channel.config.instances ? channel.config.instances : null}
                            updateInstances={updatev2Instances}
                            hideLabelColumn={true}
                            hideTemplateRevisionColumn={false}
                            updateValid={updateValid}
                            maximumInstanceCount={2}
                        />
                    </Form.Item>
                </>
            }

            {
                channelType !== CHANNEL_TYPE.BROADCAST &&
                <Tabs
                    onChange={onPaneChange}
                    activeKey={activePane}
                    type='card'
                    items={tabItems}
                />
            }

        </Form>
    );
}

ChannelForm.propTypes = {
    form: PropTypes.object,
    channel: PropTypes.object,
    errors: PropTypes.object,
    updateValid: PropTypes.func,
    editEnabled: PropTypes.bool
};
