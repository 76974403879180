import React, { useState, useCallback, useContext, useEffect } from 'react';

import { Table, Row, Col, Button, Tooltip, Modal, Space, PageHeader, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faInfoCircle, faTrashAlt, faExclamationCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission } from '../../services';
import { FeedsModal } from './FeedsForm';
import { Ticker } from '../util';

import './Feeds.css';
import { SearchFilter, onFilterChildIncludes } from '../../components/listview/ListView';

const CAN_CREATE_SOURCE = 'sources.add';
const CAN_DELETE_SOURCE = 'sources.delete';
const CAN_EDIT_SOURCE = 'sources.edit';
const FEEDS_REFRESH_PERIOD_MS = 3 * 1000;

export default function Feeds() {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);
    const [feedsList, updateFeedsList] = useState([]);
    const [showNewFeed, updateShowNewFeed] = useState(false);
    const [showEditFeed, updateShowEditFeed] = useState(null);
    const [feedErrors, updateFeedErrors] = useState(null);
    const [sourcesSortOrder, updateSourcesSortOrder] = useState(null);

    const canCreateFeed = testPermission(CAN_CREATE_SOURCE, permissionsMap);
    const canDeleteFeed = testPermission(CAN_DELETE_SOURCE, permissionsMap);
    const canEditFeed = testPermission(CAN_EDIT_SOURCE, permissionsMap);

    const refreshFeeds = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;
        const shallContinue = () => {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const feedsResponse = await solutionApi.listFeeds(accountId);
            if (shallContinue()) {
                if (feedsResponse.data) {
                    updateFeedsList(feedsResponse.data);
                }
            }
        } catch (exception) {
            console.log(exception);
            message.error('Failed to retrieve Feeds');
        }
    }, [solutionApi, userDetails.accountID]);

    const deleteFeed = useCallback((feedId, feedName) => {

        Modal.confirm({
            title: `Are you sure you want to delete Feed ${feedName}?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
            okButtonProps: { className: 'hook-feeds-action-feeddeleteconfirm' },
            cancelButtonProps: { className: 'hook-feeds-action-feeddeletecancel' },
            async onOk() {
                try {
                    await solutionApi.deleteFeed(userDetails.accountID, feedId);
                }
                catch (ex) {
                    console.log(ex);
                    message.error(`Failed to delete Feed ${feedName}`);
                }
            },
        });
    }, [solutionApi, userDetails.accountID]);

    const onShowEditFeed = useCallback(async (feedId) => {
        const feedData = feedsList.filter(feed => feed.id === feedId)[0];
        updateShowEditFeed(feedData);
    }, [feedsList]);

    const onFeedsSourcesFilterIncludes = dataIndex => (value, record) => {
        return record['config'][dataIndex].find((source) => {
            return source.endpointName.includes(value.trim()) || source.location.includes(value.trim());
        });
    };

    const onChangeTable = useCallback(async (pagination, filters, sorter) => {
        if(sorter.columnKey == 'routes'){
            updateSourcesSortOrder(sorter.order);
        }
    }, []);

    const columns = [{
        title: 'Name',
        sorter: (a, b) => a.config.name.localeCompare(b.config.name),
        defaultSortOrder: 'ascend',
        filterDropdown: SearchFilter,
        onFilter: onFilterChildIncludes(['config', 'name']),
        dataIndex: ['config', 'name'],
        key: 'name',
        className: 'hook-feeds-feedstable-name'
    }, {
        // eslint-disable-next-line react/display-name
        title: () => {
            return (
                <div className='feeds-sourcestable-sources-heading'>Routes<br />
                    <div className='feeds-sourcestable-sources-subheading'>Location</div><div className='feeds-sourcestable-sources-subheading feeds-sourcestable-sourcename-subheading'>Source name</div>
                </div>);
        },
        dataIndex: ['config', 'routes'],
        key: 'routes',
        className: 'feeds-sourcestable-sources-column hook-feeds-feedstable-sourcename',
        sorter: (a, b, sortOrder) => {
            // Probably should not have a feed with empty sources but protect anyway.
            if(a.config.routes && a.config.routes.length === 0)
                return 1;
            else if(b.config.routes && b.config.routes.length === 0)
                return 1;
            else {
                // Pick the highest/lowest item to compare via sort of a and b
                const sourcesA = a.config.routes.slice(), sourcesB = b.config.routes.slice();
                if (sortOrder == 'ascend'){
                    sourcesA.sort();
                    sourcesB.sort();
                } else if (sortOrder == 'descend'){
                    sourcesA.sort().reverse();
                    sourcesB.sort().reverse();
                }

                return sourcesA[0].endpointName.localeCompare(sourcesB[0].endpointName);
            }
        },
        filterDropdown: SearchFilter,
        onFilter: onFeedsSourcesFilterIncludes(['routes']),
        // eslint-disable-next-line react/display-name
        render: (...[, record,]) => {
            record.endpointKeys = record.config.routes.map((value,index) => {return value.endpointName.concat(index);});
            const sourcesColumns = [{
                title: 'Location',
                filterDropdown: SearchFilter,
                key: 'location',
                dataIndex: 'location',
                className: 'feeds-sourcestable-sources-cell hook-feeds-sourcetable-location'
            },{
                title: 'Name',
                dataIndex: 'endpointName',
                key: 'endpointKeys',
                sortOrder: sourcesSortOrder,
                sorter: (a, b) => a.endpointName.localeCompare(b.endpointName),
                className: 'feeds-sourcestable-sources-cell hook-feeds-sourcetable-name'
            }];

            return (<>
                { <Table className='feeds-sources-table sources-table hook-sources-dashboard-sourcestable' style={{ marginLeft: '0px', marginRight: '34px' }}
                    pagination={{ showSizeChanger: false, hideOnSinglePage: true }} showHeader={false}
                    key={record.id}
                    size='small' dataSource={record.config.routes} columns={sourcesColumns} rowKey='endpointKeys'
                /> }
            </>);
        }
    }, {
        title: 'Type',
        sorter: (a, b) => a.config.type.localeCompare(b.config.type),
        filterDropdown: SearchFilter,
        onFilter: onFilterChildIncludes(['config', 'type']),
        dataIndex: ['config', 'type'],
        key: 'type',
        className: 'hook-feeds-feedstable-type'
    }, {
        title: 'Actions',
        key: 'actions',
        className: 'hook-feeds-feedstable-actions',
        // eslint-disable-next-line react/display-name
        render: (...[, record,]) => {
            const feedId = record.id;

            const editButtonProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={faPencilAlt} />,
                className: 'hook-feeds-feedstable-editbutton',
                onClick: () => { onShowEditFeed(feedId); },
                disabled: !record.status.usedBy || record.status.usedBy.length > 0
            };

            let editButtonText = 'Click to edit this feed';
            if (!canEditFeed) {
                editButtonText = 'You lack the required permissions to make changes to any sources or feeds.';
                editButtonProps.disabled = true;
            }

            const deleteButtonProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={faTrashAlt} />,
                className: 'hook-feeds-feedstable-deletebutton',
                onClick: () => { deleteFeed(feedId, record.config.name); },
                disabled: !record.status.usedBy || record.status.usedBy.length > 0
            };

            let deleteButtonText = 'Click to delete this feed';
            if (!canDeleteFeed) {
                deleteButtonText = 'You lack the required permissions to delete any sources or feeds';
                deleteButtonProps.disabled = true;
            }

            return (<>
                <Tooltip title={editButtonText}>
                    <Button {...editButtonProps} />
                </Tooltip>
                <Tooltip title={deleteButtonText}>
                    <Button {...deleteButtonProps} />
                </Tooltip>
            </>);
        }
    }, {
        title: 'Used by',
        key: 'usedBy',
        className: 'hook-feeds-feedstable-usedby',
        // eslint-disable-next-line react/display-name
        render: (...[, record,]) => {
            if (record.usedBy) {
                const allPorts = record.status.usedBy.map(x => <div key={x}>{x}</div>);
                return (<>{allPorts}</>);
            }
            else
                return <></>;
        }
    }];


    useEffect(() => {
        // once only on load
        refreshFeeds(null);
    }, [refreshFeeds]);

    const createButtonProps = {
        onClick: () => {
            updateShowNewFeed(true);
        }
    };

    let createButtonText = 'Click to add a new Feed';
    if (!canCreateFeed) {
        createButtonProps['disabled'] = true;
        createButtonText = 'You do not have the required permissions to create a channel';
    }

    const headerForm = () => (<Row justify='space-between'>
        <Col>
            <Tooltip title={createButtonText}><Button className='hook-feeds-feedstable-newfeed' {...createButtonProps}>New Feed</Button></Tooltip>
        </Col>
    </Row>);

    const addNewFeed = useCallback(async (newFeed) => {

        let success = false;
        try {
            const accountId = userDetails.accountID;
            for (const route of newFeed.config.routes){
                if (route.programNumber === ''){
                    delete route.programNumber;
                }
            }
            const response = await solutionApi.createFeed(accountId, newFeed);
            if (response.ok) {
                updateFeedErrors(null);
                updateShowNewFeed(false);
                success = true;
            } else {
                updateFeedErrors(response.data);
            }

        } catch (exception) {
            console.log(exception);
            message.error('Failed to add new feed');
        }

        return success;

    }, [solutionApi, userDetails.accountID]);

    const onEditExistingFeed = useCallback(async (updatedFeed) => {
        const accountId = userDetails.accountID;
        if ('usedBy' in updatedFeed)
            delete updatedFeed['usedBy'];
        const response = await solutionApi.editFeed(accountId, updatedFeed);

        if (response.ok) {
            updateShowEditFeed(null);
            updateFeedErrors(null);
            refreshFeeds();
        } else {
            updateFeedErrors(response.data);
        }

        return response.ok;

    }, [solutionApi, refreshFeeds, userDetails.accountID]);

    const onFeedCancel = useCallback(() => {
        updateShowNewFeed(false);
        updateShowEditFeed(null);
        updateFeedErrors(null);
    }, []);

    return (<>
        <Ticker asyncCallback={refreshFeeds} timeout={FEEDS_REFRESH_PERIOD_MS} />
        <Row align='middle'>
            <Col>
                <FontAwesomeIcon icon={faSignInAlt} size='4x' />
            </Col>
            <Col>
                <PageHeader backIcon={null} title="Feeds" />
            </Col>
        </Row>
        <Row className='gutter-bottom feeds-info-row '>
            <Col>
                <Space>
                    <FontAwesomeIcon className='feeds-info-icon' icon={faInfoCircle} size='2x' color='blue' />
                    <span>A feed describes the transport routes that carry content (media data) from its origin to the media services that consume it.</span>
                </Space>
            </Col>
        </Row>
        <Table className='sources-table hook-feeds-feedstable'
            rowClassName={() => {return 'feeds-row';}}
            onChange={onChangeTable}
            pagination={{ showSizeChanger: false, hideOnSinglePage: true }}
            size='middle' dataSource={feedsList} columns={columns} rowKey={(item) => {return item.id;}} title={headerForm}
        />
        {
            showNewFeed ? <FeedsModal key='new' onConfirm={addNewFeed} onCancel={onFeedCancel} errors={feedErrors} feeds={feedsList}/> : null
        }
        {
            (showEditFeed != null) ? <FeedsModal key='edit' onConfirm={onEditExistingFeed} onCancel={onFeedCancel} data={showEditFeed} errors={feedErrors} feeds={feedsList}/> : null
        }
    </>);
}
