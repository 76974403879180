import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal } from 'antd';
import { useState, useEffect } from 'react';
import StatmuxPoolForm from './StatmuxPoolForm';

export default function EditStatmuxPoolModal(
    {
        visible = false,
        statmuxPoolData,
        onConfirm = () => {},
        onCancel = () => {},
        errors = null,
        editEnabled = true
    }) {

    const [form] = Form.useForm();

    const [statmuxPoolEdit, updateStatmuxPoolEdit] = useState({});

    useEffect(() => {
        updateStatmuxPoolEdit({ ...statmuxPoolData });
    }, [statmuxPoolData]);

    const title = `${editEnabled ? 'Edit' : 'View'} Statmux Pool`;
    const closeButtonText = editEnabled ? 'Close' : 'Cancel';

    const footer = [
        editEnabled && <Button key="confirm" type="primary"
            className="hook-statmuxpools-statmuxpoolconfig-editstatmuxpoolconfirm"
            onClick={() => onConfirm(form.getFieldsValue())}>
            Save changes
        </Button>,
        <Button key="cancel" className="hook-statmuxpools-statmuxpoolconfig-editstatmuxpoolcancel" onClick={onCancel}>
            {closeButtonText}
        </Button>];

    return <Modal title={title} open={visible} closable={false}
        className="hook-statmuxpools-editstatmuxpool-menu" footer={footer}
    >
        <StatmuxPoolForm statmuxPool={statmuxPoolEdit} form={form} errors={errors} editEnabled={editEnabled}/>
    </Modal>;
}

EditStatmuxPoolModal.propTypes = {
    statmuxPoolData: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    editEnabled: PropTypes.bool,
    errors: PropTypes.object
};
