import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Popover, Button, message, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { SaasApiContext, UserContext } from '../services';
import { useHistory } from 'react-router-dom';
import './User.css';

function UserPane(props) {
    const userDetails = useContext(UserContext);
    const history = useHistory();

    let { onExit, onClose } = props;

    return (
        <Space direction='vertical' size='middle' style={{ textAlign: 'center' }}>
            {userDetails.userName}
            <Button onClick={onExit} block={true}>Sign out</Button>
            <Button
                block={true}
                onClick={() => {
                    navigator.clipboard.writeText(userDetails.accountID);
                    message.success('Copied account ID to clipboard.');
                }}
            >
                Copy customer account ID
            </Button>
            <Button
                block={true}
                onClick={() => {
                    history.push('/user');
                    onClose();
                }}
            >
                View My Permissions
            </Button>
            <Button
                block={true}
                onClick={() => {
                    history.push('/usage');
                    onClose();
                }}
                style= {{ borderRadius: '0 0 16px 16px' }}
            >
                View My Current Usage
            </Button>
        </Space>
    );
}

UserPane.propTypes = {
    onExit: PropTypes.func,
    onClose: PropTypes.func
};

export default function User() {
    const [showPopover, updateShowPopover] = useState(false);
    const saasApi = useContext(SaasApiContext);

    return (
        <Popover
            placement='bottomRight'
            content={
                <UserPane
                    onClose={() => updateShowPopover(false)}
                    onExit={() => saasApi.logout()}
                />
            }
            trigger="click"
            open={showPopover}
            onOpenChange={updateShowPopover}
            overlayClassName='user-popover'
            overlayInnerStyle={{
                borderRadius: '24px'
            }}
        >
            <FontAwesomeIcon
                style={{ cursor: 'pointer' }}
                icon={faUser}
                size='2x'
                onClick={() => updateShowPopover(true)}
            />
        </Popover>
    );
}
