import React from 'react';
import { Button, message } from 'antd';

export default function displayErrors(summary, errors, timeout) {

    function closeMessage(){
        message.destroy('displayErrors');
    }

    let errorDisplay = [<p style={{ marginTop: '10px', fontWeight: 'bold' }} key='mainError'> {summary} </p>];

    if (errors) {
        for (let error = 0; error < errors.length; error++) {
            errorDisplay.push(<p style={{ marginTop: '-10px' }} key={errors[error].text}> {errors[error].text} </p>);
        }
        errorDisplay.push(<Button key='closeBtn' onClick={closeMessage}>Close</Button>);
        message.error({ key: 'displayErrors', content: errorDisplay, duration: timeout });
    }
}
