
import React, { useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row, Switch, Button, Tooltip, Table } from 'antd';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faSpinner } from '@fortawesome/free-solid-svg-icons';

const CAN_START_MUX = 'muxes.start';
const CAN_STOP_MUX = 'muxes.stop';

export default function AdvancedMxControlModal({ mux, onOk }) {
    const [enabledControls, updateEnabledControls] = useState(false);
    const [muxName, updateMuxName] = useState('');
    const [muxId, updateMuxId] = useState('');
    const [instances, updateInstances] = useState([]);

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;
    const permissionsMap = useContext(PermissionsContext);
    const canStartMux = testPermission(CAN_START_MUX, permissionsMap);
    const canStopMux = testPermission(CAN_STOP_MUX, permissionsMap);

    const refreshMux = useCallback(async () => {

        if (mux != null) {
            updateMuxName(mux.config.name);
            updateMuxId(mux.id);

            const newInstances = [];
            for(let instance in mux.config.instances){
                newInstances.push({ ...mux.config.instances[instance],
                    'label': instance,
                    'state': mux.status.instances[instance].state
                });
                updateInstances(newInstances);
            }
        } else {
            updateMuxName(null);
            updateMuxId(null);
            updateInstances([]);
        }

    }, [mux]);

    const updateMux = useCallback(async (instanceLabel, newState) => {
        try {
            const muxUpdate = { ...mux };
            muxUpdate.config.instances[instanceLabel] = {
                state: newState,
                location: muxUpdate.config.instances[instanceLabel].location,
                role: muxUpdate.config.instances[instanceLabel].role
            };

            await solutionApi.updateMultiplex(accountId, mux.id, muxUpdate);
        } catch (exception) {
            console.log('Error when attempting to updateMultiplex');
        }
    }, [accountId, mux, solutionApi]);


    useEffect(() => {
        refreshMux();
    }, [refreshMux]);

    const columns = [{
        title: 'Label',
        dataIndex: 'label',
        key: 'label',
        className: 'hook-muxes-muxinstancestable-label',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.label.localeCompare(b.label)
    }, {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        className: 'hook-muxes-muxinstancestable-location'
    }, {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        className: 'hook-muxes-muxinstancestable-role'
    }, {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        className: 'hook-muxes-muxinstancestable-state',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            let text = '';
            switch (record.state) {
            case 'stopped':
                text = 'Inactive';
                break;
            case 'start_requested':
                text = 'About to activate';
                break;
            case 'infra_creating':
            case 'starting':
                text = 'Going active';
                break;
            case 'started':
                text = 'Active';
                break;
            case 'start_error':
                text = 'Error activating';
                break;
            case 'stop_requested':
                text = 'About to deactivate';
                break;
            case 'stopping':
                text = 'Deactivating';
                break;
            }
            return text;
        }
    }, {
        title: 'Actions',
        key: 'actions',
        className: 'hook-muxes-muxinstancestable-actions',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {

            // Play/Stop Icon
            const muxState = record.state;

            let startButtonText = '';
            const startStopProps = {
                shape: 'circle'
            };

            switch (muxState) {
            case 'stopped':
                startStopProps.icon = <FontAwesomeIcon icon={faPlay}/>;
                startStopProps.onClick = ()=>{ updateMux(record.label, 'started'); };
                startStopProps.disabled = false;
                startButtonText = 'Activate';

                if (!canStartMux) {
                    startButtonText = 'You lack the required permissions to start a mux';
                    startStopProps.disabled = true;
                }

                break;

            case 'start_requested':
            case 'infra_creating':
            case 'starting':
                startStopProps.icon = <FontAwesomeIcon icon={faSpinner} pulse/>;
                startStopProps.disabled = true;
                startButtonText = 'Already Activating';

                break;
            case 'started':
            case 'start_error':
                startStopProps.icon = <FontAwesomeIcon icon={faStop}/>;
                startStopProps.disabled = false;
                startStopProps.onClick = ()=>{ updateMux(record.label, 'stopped'); };
                startButtonText = 'Deactivate';

                if (!canStopMux) {
                    startButtonText = 'You lack the required permissions to stop a mux';
                    startStopProps.disabled = true;
                }

                break;
            case 'stop_requested':
            case 'stopping':
                startStopProps.icon = <FontAwesomeIcon icon={faSpinner} pulse/>;
                startStopProps.disabled = true;
                startButtonText = 'Already Deactivating';

                break;
            default:
                break;
            }

            // The toggle switch overall controls whether allowed to manage the mux.
            startStopProps.disabled += !enabledControls;

            return <>
                <Tooltip title={startButtonText}>
                    <Button className='hook-muxes-muxinstancestable-startstopbutton' { ...startStopProps } />
                </Tooltip>
            </>;
        }
    },
    ];

    return (
        <>
            <Modal
                title='Advanced multiplex control'
                open={mux != null}
                className='hook-advancedmuxcontrol-modal'
                width={'auto'}
                cancelText='Close'
                style={{ display: 'flex', justifyContent: 'center', maxWidth: '80%' }}
                onCancel={() => {
                    onOk();
                    updateEnabledControls(false);
                }}
                cancelButtonProps={{ className: 'hook-advancedmuxcontrol-close' }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <Row>
                    <Col offset={2}>
                        Multiplex: {muxName}
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} className='advanced-muxcontrol-text-col-id'>
                        ID: {muxId}
                    </Col>
                </Row>
                <Row>
                    <Col offset={2}>
                        Enable advanced actions:
                    </Col>
                    <Col offset={3}>
                        <Switch
                            checked={enabledControls}
                            className='hook-advancedmuxcontrol-toggleEnable'
                            onChange={ () => { updateEnabledControls(!enabledControls); }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2}>
                        <span className='advanced-muxcontrol-warning-text'>Warning: Using advanced actions will affect Multiplex operation.</span>
                    </Col>
                </Row>
                <br/>
                <Table
                    pagination={false}
                    size='middle'
                    dataSource={instances}
                    columns={columns}
                    rowKey='label'
                />
            </Modal>
        </>
    );
}

AdvancedMxControlModal.propTypes = {
    mux: PropTypes.object,
    onOk: PropTypes.func,
    muxName: PropTypes.string
};
