import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DeploymentForm } from './DeploymentForm';

export function DeploymentModal({
    onCancel,
    onConfirm,
    errors = null,
    accountID = null,
    originalDeploymentData,
    isDeploymentModalNew
}) {
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setIsValid(false);
    }, [originalDeploymentData]);

    return (
        <Modal
            title={isDeploymentModalNew ? 'New Deployment' : 'Edit Deployment'}
            open={Boolean(originalDeploymentData)}
            okText={isDeploymentModalNew ? 'Create' : 'Confirm'}
            onCancel={onCancel}
            okButtonProps={{
                disabled: !isValid,
                form: 'deployment-modal-form',
                key: 'submit',
                htmlType: 'submit'
            }}
        >
            <DeploymentForm
                updateValid={setIsValid}
                originalDeploymentData={originalDeploymentData}
                errors={errors}
                accountID={accountID}
                editEnabled={!isDeploymentModalNew}
                onSubmit={(deploymentData) => {
                    onConfirm(deploymentData, isDeploymentModalNew);
                }}
            />
        </Modal>
    );
}

DeploymentModal.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    errors: PropTypes.object,
    accountID: PropTypes.string,
    originalDeploymentData: PropTypes.object,
    isDeploymentModalNew: PropTypes.bool
};
