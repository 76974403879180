import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Alert } from 'antd';
import { useCallback, useState, useEffect, useContext } from 'react';
import { SolutionApiContext, SelectedAccountContext, processResponseErrors } from '../../services';
import { InstancesTable } from '../channels/InstancesTable';

export default function MuxSetupForm({
    mux,
    form,
    errors,
    editEnabled = true,
    updateValid,
    updateDeployLocations,
    multiplexId
}) {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const [outputOptions, updateOutputOptions] = useState([]);
    const [selectedOutput, updateSelectedOutput] = useState();

    const refreshOutputList = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;
        const shallContinue = () =>  {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const feedsResponse = await solutionApi.listFeeds(accountId);
            if (shallContinue()) {
                const outputFeeds = feedsResponse.data.filter(feed =>
                    feed.config.type == 'egress' &&
                    (feed.config.mediaService == '' || feed.config.mediaService == `multiplexes/${multiplexId}`)
                );
                updateOutputOptions(outputFeeds);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, userDetails.accountID, multiplexId]);

    const updatev2Instances = useCallback(newInstances => {
        if(newInstances.instances){
            const newConfig = {
                'instances': { ...newInstances.instances }
            };
            form.setFieldValue('config', newConfig);
            updateDeployLocations(newConfig.instances);
        }
    }, [form, updateDeployLocations]);

    const updateOutput = useCallback(async (outputFeedName) => {
        const outputFeed = outputOptions.find(feed => feed.config.name == outputFeedName);
        updateSelectedOutput(outputFeed);
    },
    [outputOptions]);

    const onLoad = useCallback(async () => {
        refreshOutputList();
        form.setFieldsValue(mux);
    },
    [refreshOutputList, mux, form]);

    useEffect(() => {
        if (mux && mux.config && mux.config.instances){
            updatev2Instances(mux);
            updateDeployLocations(mux.config.instances);
            if(selectedOutput) {
                if (mux.config.instances.length == 0){
                    mux.config.instances = selectedOutput.config.routes;
                }
            }
        }
    }, [outputOptions, selectedOutput, updatev2Instances, mux, form, updateDeployLocations]);

    useEffect(() => {
        if (mux && mux.egress_feed != ''){
            updateOutput(mux.egress_feed);
        }
    }, [mux, updateOutput]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    const errorsClassName = errors ? '' : 'form-hidden';

    let errorObj = {
        summary: '',
        errorsLookup: {}
    };

    if (errors && errors.errors) {
        errorObj = processResponseErrors(errors);
    }

    const getErrorsProps = useCallback((fieldName) => {
        let props = {};

        const lookup = errorObj.errorsLookup[fieldName];

        if (lookup) {
            props.validateStatus = 'error';
            props.help = lookup.text;
        }

        return props;
    }, [errorObj.errorsLookup]);

    const onValuesChanged = useCallback((...[, allValues]) => {
        if (updateValid) {
            const requiredFieldsValues = [allValues.name, allValues.region];
            updateValid(requiredFieldsValues.every(fieldValue => fieldValue !== ''));
        }
    }, [updateValid]);

    const currentFormValues = form.getFieldsValue();
    const fieldLoadComplete = currentFormValues.id === mux.id;
    const disableFields = !fieldLoadComplete || !editEnabled;

    return (
        <Form
            labelCol={{ span: 9 }}
            wrapperCol= {{ span: 8 }}
            layout='horizontal'
            form={form}
            onValuesChange={onValuesChanged}
        >
            <Alert
                style={{ marginBottom: 8 }}
                className={errorsClassName}
                message={errors ? errorObj.summary : ''}
                description={errors && errors.errors ? errors.errors.map(item => item.text).join('\n') : ''}
                type="error"
            />
            <Form.Item key='id' name='id' label='id' className='form-hidden' >
                <Input type='hidden' />
            </Form.Item>
            <Form.Item key='name' name='name' label='Name' {...getErrorsProps('name')} rules={[{ required: true }]}>
                <Input disabled={disableFields} className='hook-muxes-muxconfig-name' placeholder="Mux Name" />
            </Form.Item>

            <Form.Item
                key='egress_feed'
                name='egress_feed'
                label="Output"
                {...getErrorsProps('Output')}
                rules={[{ required: true }]}
            >
                <Select
                    disabled={disableFields}
                    className='hook-muxes-muxconfig-output'
                    placeholder='Select an existing output for this mux'
                    optionFilterProp='label'
                    onChange={(value) => {
                        updateOutput(value);
                    }}
                    showSearch={true}
                    options={outputOptions.map((feed) => ({
                        className: `hook-muxes-muxconfig-output-${feed.config.name}`,
                        value: feed.config.name,
                        label: feed.config.name
                    }))}
                />
            </Form.Item>
            <Form.Item
                key='instance'
                name={['config', 'instances']}
                {...getErrorsProps('instances')}
                wrapperCol= {{ span: 24, offset: 0 }}
            >
                <InstancesTable
                    wrapperCol= {{ span: 24, offset: 0 }}
                    feed={selectedOutput}
                    instances={mux.config && mux.config.instances ? mux.config.instances : null}
                    updateInstances={updatev2Instances}
                    hideLabelColumn={false}
                    hideTemplateRevisionColumn={true}
                    updateValid={updateValid}
                    maximumInstanceCount={2}
                />
            </Form.Item>
        </Form>
    );
}

MuxSetupForm.propTypes = {
    form: PropTypes.object,
    mux: PropTypes.object,
    errors: PropTypes.object,
    updateValid: PropTypes.func,
    editEnabled: PropTypes.bool,
    updateDeployLocations: PropTypes.func,
    multiplexId: PropTypes.string
};
