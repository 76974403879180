import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Breadcrumb, Row, Col, Tooltip } from 'antd';
import MediaKindLogo from './MK-Logo.svg';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

import Bell from './Bell';
import Time from './Time';
import User from './User';

import AccountChooser from '../components/AccountChooser';

const { Header } = Layout;

export default function MKHeader({ pathOverride, setAccountDetails }) {
    const location = useLocation();
    // Path starts with a '/', so the first item after the split
    // will be an empty string.
    const pathItems = location.pathname.split('/');

    const breadcrumbItems = [];
    let compound = '';

    if (pathOverride != null) {
        pathOverride(location, breadcrumbItems);
    } else {
        for (let index = 0; index < pathItems.length; index++) {
            const component = pathItems[index];
            compound = `${compound}${component}/`;

            // don't add for empty text due to the 'split';
            if (index === 0) {
                breadcrumbItems.push(
                    <Breadcrumb.Item className='hook-dashboard-home' key={compound}>
                        <Link to={compound}>Home</Link>
                    </Breadcrumb.Item>
                );
            } else if ((index > 0) && (component.length > 0)) {
                const displayText = component.replace(/^\w/, c => c.toUpperCase());
                const classHook = 'hook-dashboard-'.concat(displayText);
                breadcrumbItems.push(
                    <Breadcrumb.Item className={classHook} key={compound}>
                        <Link to={compound}>{displayText}</Link>
                    </Breadcrumb.Item>
                );
            }
        }
    }

    // Notification coming soon via another branch
    return (
        <Header
            style={{
                backgroundColor: '#f0f2f5',
                borderBottom: '1px solid #19508B',
                lineHeight: 'normal',
                padding: '16px 16px 4px 16px',
                height: 'initial'
            }}
        >
            <Row align='bottom' gutter={[16, 4]}>
                <Col>
                    <Link to='/'>
                        <img
                            src={MediaKindLogo}
                            height={30}
                            alt='MediaKind Logo'
                        />
                    </Link>
                </Col>
                <Col flex="auto" style={{ lineHeight: '1' }}>
                    <span style={{ fontSize: '1.125rem' }}>Solution Management</span>
                </Col>
                <Col>
                    <AccountChooser setAccountDetails={setAccountDetails} />
                </Col>
                <Col>
                    <a href="assets/userguide/index.html" target='_blank' style={{ color: 'unset' }}>
                        <Tooltip title='Open User Guide in a new window.' arrowPointAtCenter={true} >
                            <FontAwesomeIcon icon={faQuestion} size='2x' />
                        </Tooltip>
                    </a>
                </Col>
                <Col>
                    <Bell />
                </Col>
                <Col>
                    <User />
                </Col>
            </Row>

            <Row gutter={[8, 0]} align='bottom' justify="space-between" style={{ marginTop: '4px' }}>
                <Col>
                    <Breadcrumb separator='>'>
                        {breadcrumbItems}
                    </Breadcrumb>
                </Col>
                <Col>
                    <Time />
                </Col>
            </Row>
        </Header>
    );
}

MKHeader.propTypes = {
    // used for providing uniqueness to this component due to 3rd party comp - TODO could UUID it
    pathOverride: PropTypes.func,
    setAccountDetails: PropTypes.func
};
