
import React, { useCallback, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission } from '../../services';
import { Button, Table, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getInstanceStatus, getInstanceIcon } from './DvrStates';
import * as STATE from './DvrStates';

const CAN_START_DVR = 'dvrs.start';
const CAN_STOP_DVR = 'dvrs.stop';

export default function DvrInstancesTable({ dvr, enabledControls = null, showActions }) {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);

    const canStartDvr = testPermission(CAN_START_DVR, permissionsMap);
    const canStopDvr = testPermission(CAN_STOP_DVR, permissionsMap);

    const [loading, updateLoading] = useState(true);
    const [tableData, updateTableData] = useState(null);

    const accountId = userDetails.accountID;

    const refreshDvr = useCallback(async (dvr) => {
        if (dvr != null) {
            updateTableData(dvr.instances);
            updateLoading(false);
        }
    }, []);

    useEffect(() => {
        refreshDvr(dvr);
    }, [refreshDvr, dvr]);

    const startDvr = useCallback(async (instanceId) => {
        await solutionApi.startDvr(accountId, dvr.id, instanceId);
    },[solutionApi, accountId, dvr]);

    const stopDvr = useCallback(async (instanceId) => {
        await solutionApi.stopDvr(accountId, dvr.id, instanceId);
    }, [solutionApi, accountId, dvr]);

    const columns = [
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            className: 'hook-dvrs-advanceddvrcontrol-location',
            render: (...[, record ]) => {
                if (record && record.location !== ''){
                    return record.location;
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            className: 'hook-dvrs-advanceddvrcontrol-name',
            render: (...[, record ]) => {
                if (record && record.name !== ''){
                    return record.name;
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'state',
            key: 'state',
            className: 'hook-dvrs-advanceddvrcontrol-state',
            render: (...[, record ]) => {
                return getInstanceStatus(record.status.state);
            }
        }
    ];

    const actionColumn = {
        title: 'Actions',
        key: 'actions',
        className: 'hook-dvrs-advanceddvrcontrol-actions',
        render: (...[, record ]) => {
            let startButtonText = '';
            const startStopProps = {
                shape: 'circle'
            };

            switch (record.status.state) {
            case STATE.STOPPED:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.status.state)}/>;
                startStopProps.onClick = ()=>{ startDvr(record.id); };
                startStopProps.disabled = !enabledControls;
                startButtonText = 'Start DVR instance';

                if (!canStartDvr) {
                    startButtonText = 'You lack the required permissions to start a DVR';
                    startStopProps.disabled = true;
                }

                break;

            case STATE.STARTING:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.status.state)} pulse/>;
                startStopProps.disabled = true;
                startButtonText = 'Already starting';
                break;

            case STATE.STARTED:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.status.state)}/>;
                startStopProps.disabled = !enabledControls;
                startStopProps.onClick = ()=>{ stopDvr(record.id); };
                startButtonText = 'Stop DVR instance';
                break;

            case STATE.ERROR:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.status.state)}/>;
                startStopProps.disabled = !enabledControls;
                startStopProps.onClick = ()=>{ stopDvr(record.id); };
                startButtonText = 'Stop DVR instance';

                if (!canStopDvr) {
                    startButtonText = 'You lack the required permissions to stop a DVR';
                    startStopProps.disabled = true;
                }
                break;

            case STATE.STOPPING:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.status.state)} pulse/>;
                startStopProps.disabled = true;
                startButtonText = 'Already stopping';
                break;
            default:
                break;
            }

            return <>
                <Tooltip title={startButtonText}>
                    <Button className='hook-dvrs-advanceddvrcontrol-startstopbutton' { ...startStopProps } />
                </Tooltip>
            </>;
        }
    };

    if (showActions) {
        columns.push(actionColumn);
    }

    return (<Table className='hook-dvrs-advanceddvrcontrol-actiontable'
        size='middle'
        loading={{
            spinning: loading,
            indicator: <FontAwesomeIcon icon={faSpinner} pulse />
        }}
        dataSource={tableData}
        columns={columns}
        rowKey='id'
        pagination={{ defaultPageSize: 2, hideOnSinglePage: true }}
    />);
}

DvrInstancesTable.propTypes = {
    dvr: PropTypes.object,
    enabledControls: PropTypes.bool,
    showActions: PropTypes.bool
};
