import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useState, useCallback } from 'react';
import ChannelForm from './ChannelForm';

const emptyChannel = {
    name: 'NewChannel',
    template: '',
    timeshiftDuration: '',
    source: undefined,
    backupEnabled: false,
    backupSource: undefined,
    haEnabled: false,
    haSource: undefined,
    haBackupEnabled: false,
    haBackupSource: undefined,
    type: 'OTT',
    statmuxPool: '',
    minVideoBitrate: 0,
    maxVideoBitrate: 0,
    templateRevision: 'latest',
    haTemplateRevision: 'latest',
    config: {
        name: '',
        feed: '',
        instances: {}
    }
};

export default function NewChannelModal(props) {

    const {
        visible = false,
        onConfirm = () => {},
        onCancel = () => {},
        errors = null
    } = props;

    const [form] = Form.useForm();

    const [okToPost, updateOkToPost] = useState(false);
    const updateValid = useCallback((isValid) => {
        updateOkToPost(isValid);
    }, [updateOkToPost]);

    const [channel, updateChannel] = useState({ ...emptyChannel });

    const resetChannel = useCallback(() => {
        updateChannel({ ...emptyChannel });
        updateValid(false);
        form.resetFields();
    }, [form, updateValid]);

    const doConfirm = useCallback(async () => {

        form.validateFields().then(async values => {
            if (await onConfirm(values)) {
                resetChannel();
            }
        }).catch(err => {console.log('err', err);});
    }, [onConfirm, form, resetChannel]);

    const doCancel = useCallback(() => {
        onCancel();
        resetChannel();
    }, [onCancel, resetChannel]);

    return (
        <Modal
            title="New Channel"
            width={'1069px'}
            open={visible}
            className='hook-channels-newchannel-menu'
            onOk={doConfirm}
            okText='Add Channel'
            okButtonProps={{ disabled: !okToPost, className: 'hook-channels-channelconfig-addchannel' }}
            onCancel={doCancel}
            cancelButtonProps={{ className: 'hook-channels-channelconfig-canceladdchannel' }}
        >
            <ChannelForm channel={channel} form={ form } errors={errors} updateValid={updateValid}/>
        </Modal>
    );
}

NewChannelModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    errors:PropTypes.object
};
