import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Tooltip, PageHeader, Space, Result } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCloud } from '@fortawesome/free-solid-svg-icons';
import { Ticker } from '../util';
import { DeploymentModal } from './DeploymentModal';
import InfoModal from './InfoModal';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission } from '../../services';
import displayErrors from '../../services/DisplayErrors';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import DeploymentCard from './DeploymentCard';

export const DEPLOYMENTS_REFRESH_PERIOD_MS = 3 * 1000;

const CAN_ADMIN_DEPLOYMENTS = 'deployments.admin';
function Header({ accountID, openNewDeploymentModal }) {
    const solutionApi = useContext(SolutionApiContext);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    async function handleUpload() {
        setUploading(true);

        await solutionApi.uploadDeployment(accountID, fileList[0])
            .then(() => {
                setFileList([]);
            })
            .catch(() => {
            })
            .finally(() => {
                setUploading(false);
            });
    }

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList
    };

    return (
        <>
            <Row align='middle'>
                <Col>
                    <FontAwesomeIcon icon={faCloud} size='4x' />
                </Col>
                <Col>
                    <PageHeader backIcon={null} title="Deployments" />
                </Col>
            </Row>
            <Row className='gutter-bottom'>
                <Col>
                    <Space>
                        <FontAwesomeIcon icon={faInfoCircle} size='2x' color='blue' />
                        Manage deployments for Media Services.
                    </Space>
                </Col>
            </Row>
            <Row className='gutter-bottom'>
                <Col>
                    <Space wrap={true} align='start'>
                        <Tooltip title='Click to create a new Deployment'>
                            <Button onClick={() => openNewDeploymentModal()}>New Deployment</Button>
                        </Tooltip>
                        <Upload {...props} maxCount={1} multiple={false}>
                            <Button icon={<UploadOutlined />} disabled={fileList.length > 0}>
                                Select Deployment File
                            </Button>
                        </Upload>
                        <Button
                            type="primary"
                            onClick={handleUpload}
                            disabled={fileList.length === 0}
                            loading={uploading}
                        >
                            {uploading ? 'Uploading' : 'Start Upload'}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </>
    );
}

Header.propTypes = {
    accountID: PropTypes.string,
    setAccountDetails: PropTypes.func,
    openNewDeploymentModal: PropTypes.func
};

export default function Deployments() {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);

    const {
        accountID,
        accountName
    } = userDetails;

    const [deploymentsList, updateDeploymentsList] = useState([]);
    const [deploymentModalData, setDeploymentModalData] = useState(null);
    const [isDeploymentModalNew, setIsDeploymentModalNew] = useState(true);
    const [infoModalData, updateInfoModalData] = useState(false);
    const [deploymentErrors, updateDeploymentErrors] = useState(null);

    const canAdminDeployments = testPermission(CAN_ADMIN_DEPLOYMENTS, permissionsMap);

    const refreshDeployments = useCallback(async (quitContainer) => {
        try {
            const deploymentsResponse = await solutionApi.listDeployments(accountID);
            if (deploymentsResponse.ok) {
                if (!quitContainer || (!!quitContainer && ! quitContainer.quitting)) {
                    updateDeploymentsList(deploymentsResponse.data);
                }
            } else {
                console.log(deploymentsResponse.data);
                let jsonData = deploymentsResponse.data;
                displayErrors(jsonData.summary, jsonData.errors, 15);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, accountID]);

    const onAddNewDeployment = useCallback(async (newDeployment) => {
        let ok = false;

        try {
            console.log('posting new deployment', newDeployment);
            const response = await solutionApi.createDeployment(
                accountID,
                newDeployment.id,
                newDeployment.type,
                newDeployment.cloud,
                newDeployment.region,
                newDeployment.zone1,
                newDeployment.zone2,
                newDeployment.zone3,
                newDeployment.zone1WorkerNodePool,
                newDeployment.zone2WorkerNodePool,
                newDeployment.zone3WorkerNodePool,
                newDeployment.version
            );
            if (response.ok) {
                updateDeploymentErrors(null);
                setDeploymentModalData(null);
                ok = true;
            } else {
                console.log(response);
                updateDeploymentErrors(response.data);
            }
        } catch (exception) {
            console.log(exception);
        }

        return ok;
    }, [solutionApi, accountID]);

    const onEditDeployment = useCallback(async (deployment) => {
        let ok = false;

        try {
            console.log(`patching deployment ${deployment.id}`);
            const response = await solutionApi.patchDeployment(
                accountID,
                { 'version': deployment.version },
                deployment.id
            );
            if (response.ok) {
                updateDeploymentErrors(null);
                setDeploymentModalData(null);
                ok = true;
            } else {
                console.log(response);
                updateDeploymentErrors(response.data);
            }
        } catch (exception) {
            console.log(exception);
        }

        return ok;
    }, [solutionApi, accountID]);

    useEffect(() => {
        refreshDeployments(null);
    }, [refreshDeployments]);

    const refreshDeploymentsTick = useCallback(async (quitContainer) => {
        await refreshDeployments(quitContainer);
    }, [refreshDeployments]);

    let content = null;

    const deployments = deploymentsList.map((deployment) => {
        return (
            <DeploymentCard
                key={deployment.id}
                deploymentId={deployment.id}
                deployment={deployment.config}
                deploymentStatus={deployment.status}
                updateInfoModalData={updateInfoModalData}
                setDeploymentModalData={(deploymentModalData) => {
                    setDeploymentModalData(deploymentModalData);
                    setIsDeploymentModalNew(false);
                }}
            />
        );
    });

    if (canAdminDeployments) {
        content = (
            <>
                <Header
                    accountID={accountID}
                    openNewDeploymentModal={() => {
                        setDeploymentModalData({
                            id: undefined,
                            type: undefined,
                            cloud: undefined,
                            region: undefined,
                            version: undefined
                        });
                        setIsDeploymentModalNew(true);
                    }}
                />
                {deployments}
                <DeploymentModal
                    accountID={accountID}
                    accountName={accountName}
                    errors={deploymentErrors}
                    onConfirm={(deploymentData, isDeploymentNew) => isDeploymentNew ?
                        onAddNewDeployment(deploymentData) : onEditDeployment(deploymentData)}
                    onCancel={() => {
                        updateDeploymentErrors(null);
                        setDeploymentModalData(null);
                    }}
                    originalDeploymentData={deploymentModalData}
                    isDeploymentModalNew={isDeploymentModalNew}
                />
                <InfoModal
                    open={Boolean(infoModalData.data)}
                    data={infoModalData.data ? infoModalData.data : null}
                    onOK={() => updateInfoModalData(false)}
                    title={infoModalData.title ? infoModalData.title : 'Info'}
                />
            </>
        );
    } else {
        content = (
            <Result
                status="warning"
                title="We're sorry but your account does not have a required role
                         associated with it to allow you to administer Deployments."
            />
        );
    }

    return (
        <>
            <Ticker key='ticker' asyncCallback={refreshDeploymentsTick} timeout={DEPLOYMENTS_REFRESH_PERIOD_MS} />
            {content}
        </>
    );
}
