import { Button, Tooltip, Form, Input, Alert, Spin, Table, Row, Col } from "antd";
import React, { useContext, useCallback, useState, useEffect } from "react";
/// @ts-ignore
import { SolutionApiContext, SolutionApi } from "../../services";
import PropTypes from "prop-types";
import { RuleObject } from "antd/lib/form";
/// @ts-ignore
import postRobot from "post-robot";
import { ColumnsType } from "antd/lib/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { compareVersions } from "compare-versions";

type TemplateDataType = Record<string, string>;

interface TemplateFileDetails {
    name: string,
    type: string,
    supported: boolean,
    currentVersion: string,
    upgradingTo: string,
    connectorUrl: string,
}

enum EditorStatus {
    Loading,
    Unsupported,
    Supported,
    Selecting,
}
export default function TemplateEditor({
    accountId,
    record,
    onExit,
}: {
    accountId: string;
    record: {
        id: string;
        name: string;
        revisions: number[];
    };
    onExit: () => void;
}) {
    const solutionApi: SolutionApi = useContext(SolutionApiContext);

    const hasMultipleRevisions = record.revisions.length > 1;
    const latestRevision = hasMultipleRevisions ? record.revisions[record.revisions.length - 1] : 1;
    const [form] = Form.useForm();
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState("");
    const [templateToEditDetails, setTemplateToEdit] = useState<TemplateFileDetails | undefined>();
    const [templateEditorStatus, setEditorStatus] = useState<EditorStatus>(EditorStatus.Loading);
    const [templateFileList, setTemplateFileList] = useState<TemplateFileDetails[]>([]);

    // base64 encode the template and account IDs, and strip off any padding
    const encodedTemplateId = btoa(record.id).replace(/=/gi, "");
    const encodedAccountId = btoa(accountId).replace(/=/gi, "");
    const encodedFilename = templateToEditDetails ? btoa(templateToEditDetails.name).replace(/=/gi, "") : "";
    const encodedId = `${encodedTemplateId}-${latestRevision}-${encodedAccountId}-${encodedFilename}`;
    const errorsClassName = errors ? "" : "form-hidden";

    const selectTemplateSection = useCallback(
        async (selectedFile) => {
            console.log(`Selected file is ${selectedFile.name}`);
            setTemplateToEdit(selectedFile);
            setEditorStatus(EditorStatus.Supported);
        },
        [setTemplateToEdit, setEditorStatus]
    );

    const getTemplateRevisionData = useCallback(
        async (id, revision) => {
            const resp = await solutionApi.getTemplate(accountId, id, revision.toString());
            const formData = await resp.formData();
            const templateData: TemplateDataType = {};
            for (const [filename, content] of formData.entries()) {
                if (filename.endsWith(".json")) {
                    templateData[filename] = JSON.stringify(JSON.parse(content.toString()), null, 2);
                } else {
                    templateData[filename] = content.toString();
                }
            }
            return templateData;
        },
        [accountId, solutionApi]
    );

    // Find the lowest available version that is greater than the target version
    const findNextHighest = useCallback(
        async (targetVersion, availableVersions) => {
            let bestMatch = "";
            for (const availableVersion of availableVersions) {
                if (compareVersions(availableVersion, targetVersion) === 1) {
                    if (bestMatch === "") {
                        bestMatch = availableVersion;
                    } else if (compareVersions(availableVersion, bestMatch) === -1) {
                        bestMatch = availableVersion;
                    }
                }
            }
            return bestMatch;
        },
        []
    );

    const getTemplateFilename = useCallback(async () => {
        // Hardcoded mapping between config type -> config version -> connector URL
        // The periscope microservice will map the first two elements to a service
        // e.g. encoding-live/20.0.0/public/services/live_encoding/##encodedId##/edit/#/general
        // Will be mapped to http://encoding-live-ui-connector-20-0-0/public/services/live_encoding/##encodedId##/edit/#/general
        // The tag ##encodedId## is replaced with the template ID before passing to periscope

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const editorConfig: any = {
            "MKEL Config": {
                "20.0.0": "encoding-live/20.0.0/public/services/live_encoding/##encodedId##/edit/#/general",
            }
        };

        const templateData: TemplateDataType = await getTemplateRevisionData(record.id, latestRevision);
        const manifest = templateData["manifest.json"];
        const jsonManifest = JSON.parse(manifest);
        let templateFileToEdit: TemplateFileDetails | undefined;
        let multipleMatches = false;
        const templateFileList: Array<TemplateFileDetails> = [];
        for (const cfgFilename in jsonManifest["files"]) {
            const workingType = jsonManifest["files"][cfgFilename]["type"];
            let supported = false;
            let connectorUrl = "";
            let upgradingTo = "";
            let cfgVersion = "";
            const supportedType = workingType in editorConfig;
            if (supportedType) {
                cfgVersion = JSON.parse(templateData[cfgFilename])["config_version"];
                if (cfgVersion) {
                    if (cfgVersion in editorConfig[workingType]) {
                        supported = true;
                        connectorUrl = editorConfig[workingType][cfgVersion];
                    } else {
                        const bestVersionMatch = await findNextHighest(cfgVersion, Object.keys(editorConfig[workingType]));
                        if (bestVersionMatch !== "") {
                            supported = true;
                            upgradingTo = bestVersionMatch;
                            connectorUrl = editorConfig[workingType][bestVersionMatch];
                        }
                    }
                }
            }
            const templateDetails: TemplateFileDetails = {
                "name": cfgFilename,
                "type": workingType,
                "supported": supported,
                "currentVersion": cfgVersion,
                "upgradingTo": upgradingTo,
                "connectorUrl": connectorUrl
            };

            templateFileList.push(templateDetails);
            if (supported) {
                if (templateFileToEdit !== undefined) {
                    multipleMatches = true;
                }
                templateFileToEdit = templateDetails;
            }
        }

        if (multipleMatches) {
            setTemplateFileList(templateFileList);
            setEditorStatus(EditorStatus.Selecting);
        }
        else if (templateFileToEdit === undefined) {
            setEditorStatus(EditorStatus.Unsupported);
        } else {
            setTemplateToEdit(templateFileToEdit);
            setEditorStatus(EditorStatus.Supported);
        }
    }, [getTemplateRevisionData, setEditorStatus, setTemplateFileList, findNextHighest, latestRevision, record]);

    const getTemplateInitialInformation = useCallback(
        async () => {
            await getTemplateFilename();
        }, [getTemplateFilename]
    );

    useEffect(() => {
        if (templateEditorStatus === EditorStatus.Loading) {
            getTemplateInitialInformation();
        }
    }, [getTemplateInitialInformation, templateEditorStatus]);

    async function validateComment(_: RuleObject, value: string) {
        if (typeof value == "string" && value === "") {
            throw new Error("Comment must not be empty");
        }
    }

    const saveAndCloseTemplateEditor = useCallback(async () => {
        if (templateToEditDetails === undefined) {
            setErrors("Template is not supported");
            return;
        }
        if (comment.trim() === "") {
            setErrors("A comment must be specified");
            return;
        }
        const connector = document.querySelector("iframe")?.contentWindow;
        try {
            const postResponse = await postRobot.send(connector, "solutionHubValidate", {
                id: `${encodedId}`
            });
            const data = postResponse.data;
            const templateData: TemplateDataType = await getTemplateRevisionData(record.id, latestRevision);
            const manifest = templateData["manifest.json"];
            const manifestBlob = new File([manifest], "manifest.json", { type: "application/json" });
            const jsonManifest = JSON.parse(manifest);
            const updatedTemplateData = {
                "comment": comment.trim(),
                templateFiles: [manifestBlob]
            };
            for (const cfgFilename in jsonManifest["files"]) {
                if (cfgFilename !== templateToEditDetails.name) {
                    const copiedFile = new File([templateData[cfgFilename]], cfgFilename, { type: "application/json" });
                    updatedTemplateData["templateFiles"].push(copiedFile);
                }
            }
            const dataBlob = new File([JSON.stringify(data.config)], templateToEditDetails.name, { type: "application/json" });
            updatedTemplateData["templateFiles"].push(dataBlob);
            if (!data.errors) {
                const result = await solutionApi.updateTemplate(accountId, record.id, updatedTemplateData);
                if (result.status !== 200) {
                    setErrors(`${result.data.summary}`);
                }
                else {
                    onExit();
                }
            }
            else {
                setErrors("The template contains errors and could not be saved");
            }
        } catch (err) {
            console.log("Got an error reply");
            console.log(err);
        }
    }, [solutionApi, accountId, comment, latestRevision, record, templateToEditDetails, encodedId,
        getTemplateRevisionData, onExit]);

    const onValuesChanged = useCallback((...[, allValues]) => {
        setComment(allValues.comment);
    }, []);

    const header = (
        <>
            <Row justify="start">
                <Col>Template Name: <b>{record.name}</b></Col>
                <Col>&nbsp;&nbsp;</Col>
                <Col>Template ID: <b>{record.id}</b></Col>
                <Col>&nbsp;&nbsp;</Col>
                <Col>Template Revision: <b>{latestRevision}</b></Col>
                {templateToEditDetails !== undefined ? (<><Col>&nbsp;&nbsp;</Col>
                    <Col>Template Filename: <b>{templateToEditDetails.name}</b></Col></>)
                    : null}
            </Row>
            {templateToEditDetails !== undefined && templateToEditDetails.upgradingTo !== "" ? (
                <>
                    <Row justify="start">
                        <Col>
                            <b>NOTE:</b> Saving this template will upgrade the configuration from
                            V{templateToEditDetails.currentVersion} to V{templateToEditDetails.upgradingTo}
                        </Col>
                    </Row>
                </>
            )
                : null}
        </>
    );

    let content;
    if (templateEditorStatus === EditorStatus.Loading) {
        content = (<Spin tip="Loading..." size="large" style={{ marginTop: "16px" }} />);
    }
    else if (templateEditorStatus === EditorStatus.Selecting) {
        content = (
            <TemplateFileSelector
                templateConfigFileList={templateFileList}
                onMakeSelection={selectTemplateSection}
                onExit={onExit}
            />
        );
    }
    else if (templateEditorStatus === EditorStatus.Supported) {
        const targetUrl = templateToEditDetails?.connectorUrl.replace(/##encodedId##/gi, encodedId);
        content = (
            <>
                <Alert
                    style={{ marginBottom: 8 }}
                    className={errorsClassName}
                    message={errors}
                    type='error'
                />
                <Form
                    key='form'
                    form={form}
                    layout='inline'
                    initialValues={{ comment: "" }}
                    style={{ width: "100%" }}
                    onValuesChange={onValuesChanged}
                >
                    <Form.Item
                        name='comment'
                        label='Comment'
                        style={{ marginBottom: 8 }}
                        rules={[{ required: true, validator: validateComment }]}>
                        <Input placeholder='Add comment for revision' />
                    </Form.Item>
                    <Form.Item >
                        <Tooltip title="Cancel"><Button onClick={() => onExit()}>Cancel</Button></Tooltip>
                        <Tooltip title=
                            {
                                `Save ${templateToEditDetails?.upgradingTo !== "" ? "and Upgrade" : ""} Template`
                            }
                        >
                            <Button onClick={() => saveAndCloseTemplateEditor()}>
                                {
                                    `Save ${templateToEditDetails?.upgradingTo !== "" ? "and Upgrade" : ""} Template`
                                }
                            </Button>
                        </Tooltip>
                    </Form.Item>
                </Form>
                <iframe className="connector-iframe" id={"ui-connector"} title="UI connector"
                    sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-downloads"
                    src={`/proxy/accounts/${accountId}/templates/edit/${targetUrl}`}
                />
            </>
        );
    }
    else {
        content = (
            <Row justify='space-between'>
                <Col>
                    <Alert
                        style={{ marginBottom: 8 }}
                        message={"The template you selected can not be edited"}
                        type='error'
                    />
                </Col>
                <Col>
                    <Tooltip title="Go back to the template list">
                        <Button onClick={() => onExit()}>Back to template list</Button>
                    </Tooltip>
                </Col>
            </Row>
        );
    }
    return (<>{header}{content}</>);
}

TemplateEditor.propTypes = {
    comment: PropTypes.string,
    errors: PropTypes.string,
};


export function TemplateFileSelector({
    templateConfigFileList,
    onMakeSelection,
    onExit
}: {
    templateConfigFileList: TemplateFileDetails[];
    onMakeSelection: (selection: TemplateFileDetails) => void;
    onExit: () => void;
}) {
    const headerForm = () => (
        <Row justify='space-between'>
            <Col>
                <span>Your template contains multiple editable files, please select the file to edit.</span>
            </Col>
            <Col>
                <Tooltip title="Go back to the template list">
                    <Button onClick={() => onExit()}>Back to template list</Button>
                </Tooltip>
            </Col>
        </Row>
    );

    const columns: ColumnsType<TemplateFileDetails> = [
        {
            title: "Name",
            dataIndex: "name",
            render: (text) => <span>{text}</span>

        },
        {
            title: "Type",
            dataIndex: "type",
            render: (text) => <span>{text}</span>
        },
        {
            title: "Config Version",
            dataIndex: "currentVersion",
            render: (text) => <span>{text || "Unknown"}</span>
        },
        {
            title: "Editor Version",
            dataIndex: "upgradingTo",
            render: (...[, record,]) => {
                return <>
                    <span>{record.supported ? record.upgradingTo || record.currentVersion : "Not supported"}</span>
                </>;
            }
        },
        {
            title: "Action",
            dataIndex: "name",
            render: (...[, record,]) => {
                return <>
                    <Tooltip title={record.supported ? "Edit this file" : "Not supported"}>
                        <Button
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faEdit} />}
                            onClick={() => onMakeSelection(record)}
                            disabled={!record.supported} />
                    </Tooltip>
                </>;
            }
        },
    ];

    return (
        <Table
            pagination={false}
            size='middle'
            columns={columns}
            dataSource={templateConfigFileList}
            scroll={{ x: true }}
            title={headerForm}
        />);
}
