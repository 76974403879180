
import { faPlay, faSpinner, faStop } from '@fortawesome/free-solid-svg-icons';

export const STOPPED = 'stopped';
export const START_REQUESTED = 'start_requested';
export const STARTING = 'starting';
export const STARTED = 'started';
export const START_ERROR = 'start_error';
export const STOP_REQUESTED = 'stop_requested';
export const STOPPING = 'stopping';

const DISPLAY_INACTIVE = 'Inactive';
const DISPLAY_ABOUT_TO_ACTIVATE = 'About to activate';
const DISPLAY_GOING_ACTIVE = 'Going active';
const DISPLAY_ACTIVE = 'Active';
const DISPLAY_ERROR_ACTIVATING = 'Error activating';
const DISPLAY_ABOUT_TO_DEACTIVATE = 'About to deactivate';
const DISPLAY_DEACTIVATING = 'Deactivating';
const DISPLAY_ACTIVE_DEGRADED = 'Active (degraded)';
const DISPLAY_ACTIVE_UPGRADING = 'Active (upgrading)';
const DISPLAY_ACTIVE_DEGRADING = 'Active (degrading)';

const PLAY_ICON = faPlay;
const STOP_ICON = faStop;
const SPINNER_ICON = faSpinner;

const aggregatedStatusMatrix = {};

// Single instance
aggregatedStatusMatrix[STOPPED] = { text: DISPLAY_INACTIVE, icon: PLAY_ICON };
aggregatedStatusMatrix[START_REQUESTED] = { text: DISPLAY_ABOUT_TO_ACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[STARTING] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[STARTED] = { text: DISPLAY_ACTIVE, icon: STOP_ICON };
aggregatedStatusMatrix[START_ERROR] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };
aggregatedStatusMatrix[STOP_REQUESTED] = { text: DISPLAY_ABOUT_TO_DEACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[STOPPING] = { text: DISPLAY_DEACTIVATING, icon: SPINNER_ICON };

// 1+1 Format is instance0 status then instance1
aggregatedStatusMatrix[`${STOPPED}.${STOPPED}`] = { text: DISPLAY_INACTIVE, icon: PLAY_ICON };
aggregatedStatusMatrix[`${START_REQUESTED}.${STOPPED}`] = { text: DISPLAY_ABOUT_TO_ACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTING}.${STOPPED}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTED}.${STOPPED}`] = { text: DISPLAY_ACTIVE_DEGRADED, icon: STOP_ICON };
aggregatedStatusMatrix[`${START_ERROR}.${STOPPED}`] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };
aggregatedStatusMatrix[`${STOP_REQUESTED}.${STOPPED}`] = { text: DISPLAY_ABOUT_TO_DEACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STOPPING}.${STOPPED}`] = { text: DISPLAY_DEACTIVATING, icon: SPINNER_ICON };

aggregatedStatusMatrix[`${STOPPED}.${START_REQUESTED}`] = { text: DISPLAY_ABOUT_TO_ACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${START_REQUESTED}.${START_REQUESTED}`] = { text: DISPLAY_ABOUT_TO_ACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTING}.${START_REQUESTED}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTED}.${START_REQUESTED}`] = { text: DISPLAY_ACTIVE_UPGRADING, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${START_ERROR}.${START_REQUESTED}`] = { text: DISPLAY_ABOUT_TO_ACTIVATE, icon: STOP_ICON };
aggregatedStatusMatrix[`${STOP_REQUESTED}.${START_REQUESTED}`] = { text: DISPLAY_ABOUT_TO_ACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STOPPING}.${START_REQUESTED}`] = { text: DISPLAY_ABOUT_TO_ACTIVATE, icon: SPINNER_ICON };

aggregatedStatusMatrix[`${STOPPED}.${STARTING}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${START_REQUESTED}.${STARTING}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTING}.${STARTING}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTED}.${STARTING}`] = { text: DISPLAY_ACTIVE_UPGRADING, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${START_ERROR}.${STARTING}`] = { text: DISPLAY_GOING_ACTIVE, icon: STOP_ICON };
aggregatedStatusMatrix[`${STOP_REQUESTED}.${STARTING}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STOPPING}.${STARTING}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };

aggregatedStatusMatrix[`${STOPPED}.${STARTED}`] = { text: DISPLAY_ACTIVE_DEGRADED, icon: STOP_ICON };
aggregatedStatusMatrix[`${START_REQUESTED}.${STARTED}`] = { text: DISPLAY_ACTIVE_UPGRADING, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTING}.${STARTED}`] = { text: DISPLAY_ACTIVE_UPGRADING, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTED}.${STARTED}`] = { text: DISPLAY_ACTIVE, icon: STOP_ICON };
aggregatedStatusMatrix[`${START_ERROR}.${STARTED}`] = { text: DISPLAY_ACTIVE_DEGRADED, icon: STOP_ICON };
aggregatedStatusMatrix[`${STOP_REQUESTED}.${STARTED}`] = { text: DISPLAY_ACTIVE_DEGRADING, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STOPPING}.${STARTED}`] = { text: DISPLAY_ACTIVE_DEGRADING, icon: SPINNER_ICON };

aggregatedStatusMatrix[`${STOPPED}.${START_ERROR}`] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };
aggregatedStatusMatrix[`${START_REQUESTED}.${START_ERROR}`] = { text: DISPLAY_ABOUT_TO_ACTIVATE, icon: STOP_ICON };
aggregatedStatusMatrix[`${STARTING}.${START_ERROR}`] = { text: DISPLAY_GOING_ACTIVE, icon: STOP_ICON };
aggregatedStatusMatrix[`${STARTED}.${START_ERROR}`] = { text: DISPLAY_ACTIVE_DEGRADED, icon: STOP_ICON };
aggregatedStatusMatrix[`${START_ERROR}.${START_ERROR}`] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };
aggregatedStatusMatrix[`${STOP_REQUESTED}.${START_ERROR}`] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };
aggregatedStatusMatrix[`${STOPPING}.${START_ERROR}`] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };

aggregatedStatusMatrix[`${STOPPED}.${STOP_REQUESTED}`] = { text: DISPLAY_ABOUT_TO_DEACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${START_REQUESTED}.${STOP_REQUESTED}`] = { text: DISPLAY_ABOUT_TO_DEACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTING}.${STOP_REQUESTED}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTED}.${STOP_REQUESTED}`] = { text: DISPLAY_ACTIVE_DEGRADING, icon: STOP_ICON };
aggregatedStatusMatrix[`${START_ERROR}.${STOP_REQUESTED}`] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };
aggregatedStatusMatrix[`${STOP_REQUESTED}.${STOP_REQUESTED}`] = { text: DISPLAY_ABOUT_TO_DEACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STOPPING}.${STOP_REQUESTED}`] = { text: DISPLAY_DEACTIVATING, icon: SPINNER_ICON };

aggregatedStatusMatrix[`${STOPPED}.${STOPPING}`] = { text: DISPLAY_DEACTIVATING, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${START_REQUESTED}.${STOPPING}`] = { text: DISPLAY_ABOUT_TO_DEACTIVATE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTING}.${STOPPING}`] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STARTED}.${STOPPING}`] = { text: DISPLAY_ACTIVE_DEGRADING, icon: STOP_ICON };
aggregatedStatusMatrix[`${START_ERROR}.${STOPPING}`] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };
aggregatedStatusMatrix[`${STOP_REQUESTED}.${STOPPING}`] = { text: DISPLAY_DEACTIVATING, icon: SPINNER_ICON };
aggregatedStatusMatrix[`${STOPPING}.${STOPPING}`] = { text: DISPLAY_DEACTIVATING, icon: SPINNER_ICON };

const getMappedStatus = function (channel) {
    if (channel.status.instances.length === 0) {
        return aggregatedStatusMatrix[STOPPED].text;
    } else if (channel.status.instances.length === 1) {
        return aggregatedStatusMatrix[channel.status.instances[0].state].text;
    } else {
        return aggregatedStatusMatrix[`${channel.status.instances[0].state}.${channel.status.instances[1].state}`].text;
    }
};

export const getMappedIcon = function (channel) {
    if (channel.status.instances.length === 0) {
        return aggregatedStatusMatrix[STOPPED].icon;
    } else if (channel.status.instances.length === 1) {
        return aggregatedStatusMatrix[channel.status.instances[0].state].icon;
    } else {
        return aggregatedStatusMatrix[`${channel.status.instances[0].state}.${channel.status.instances[1].state}`].icon;
    }
};

export const getMappedDisabledIconState = function (icon, channel) {

    if (icon === SPINNER_ICON) {
        return true;
    } else {
        if (channel.status.instances.length === 0) {
            return true;
        } else if (channel.status.instances.length > 1) {
            if (channel.status.instances[0].state === STOPPED && channel.status.instances[1].state === STOPPED) {
                return false;
            } else if (channel.status.instances[0].state === STARTED && channel.status.instances[1].state === STARTED) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
};

export const getMappedMuxStatus = function (mux) {
    const instancesArr = [];
    for(let instance in mux.status.instances){
        instancesArr.push(mux.status.instances[instance].state);
    }

    if (instancesArr.length === 1) {
        return aggregatedStatusMatrix[instancesArr[0]].text;
    } else {
        return aggregatedStatusMatrix[`${instancesArr[0]}.${instancesArr[1]}`].text;
    }
};

export const getMappedMuxIcon = function (mux) {
    const instancesArr = [];
    for(let instance in mux.status.instances){
        instancesArr.push(mux.status.instances[instance].state);
    }

    if (instancesArr.length === 1) {
        return aggregatedStatusMatrix[instancesArr[0]].icon;
    } else {
        return aggregatedStatusMatrix[`${instancesArr[0]}.${instancesArr[1]}`].icon;
    }
};

export const getMappedMuxDisabledIconState = function (icon, mux) {

    if (icon === SPINNER_ICON) {
        return true;
    }
    const instancesArr = [];
    for(let instance in mux.status.instances){
        instancesArr.push(mux.status.instances[instance].state);
    }

    if (instancesArr.length <= 0) {
        return false;
    }

    if (instancesArr.every(state => state === STOPPED)) {
        return false;
    }

    if (instancesArr.every(state => state === STARTED)) {
        return false;
    }

    return true;
};

export const getInstanceStatus = function (state) {
    return aggregatedStatusMatrix[state].text;
};

export const getInstanceIcon = function (state) {
    return aggregatedStatusMatrix[state].icon;
};

export default getMappedStatus;
