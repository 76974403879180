import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'antd';
import { useState, useCallback } from 'react';
import StatmuxPoolForm from './StatmuxPoolForm';

const emptyStatmuxPool = {
    name: 'NewStatmuxPool',
    bitrate: 0,
    videoOnly: false
};

export default function NewStatmuxPoolModal(
    {
        visible = false,
        onConfirm = () => {},
        onCancel = () => {},
        errors = null
    }) {

    const [form] = Form.useForm();

    const [okToPost, updateOkToPost] = useState(false);
    const [statmuxPool, updateStatmuxPool] = useState({ ...emptyStatmuxPool });

    const updateValid = useCallback((isValid) => {
        updateOkToPost(isValid);
    }, [updateOkToPost]);


    const resetStatmuxPool = useCallback(() => {
        updateStatmuxPool({ ...emptyStatmuxPool });
        updateValid(false);
    }, [updateValid]);

    const doConfirm = useCallback(async () => {

        form.validateFields().then(async values => {
            console.log('values', values);
            if (await onConfirm(values)) {
                resetStatmuxPool();
            }
        }).catch(err => {
            console.log('err', err);
        });
    }, [onConfirm, form, resetStatmuxPool]);

    const doCancel = useCallback(() => {
        onCancel();
        resetStatmuxPool();
    }, [onCancel, resetStatmuxPool]);

    const footer = [
        <Button disabled={!okToPost} key="create" type="primary"
            className='hook-statmuxpools-statmuxpoolconfig-addstatmuxpool' onClick={doConfirm}>
            Add Statmux Pool
        </Button>,
        <Button key="cancel" className='hook-statmuxpools-statmuxpoolconfig-canceladdstatmuxpool'
            onClick={doCancel}
        >
            Cancel
        </Button>];

    return <Modal title="New Statmux Pool" open={visible} closable={false}
        className='hook-statmuxpools-newstatmuxpool-menu' footer={footer}
    >
        <StatmuxPoolForm statmuxPool={statmuxPool} form={form} errors={errors} updateValid={updateValid}/>
    </Modal>;
}

NewStatmuxPoolModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    errors: PropTypes.object
};
