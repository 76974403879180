import React, { useContext, useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Form, Input, Button, Modal, Select, Alert } from 'antd';
import { SolutionApiContext, SelectedAccountContext, processResponseErrors } from '../../services';
import { validateTrimmedWhitespace } from '../../services/FormUtils';

const emptySource = {
    name: 'NewSource',                  // source name
    region: '',                         // deployment name
    inputMode: 'srtListener',           // must be srtCaller, srtListener, rtp, rtp_fec, rtpFecDestination
    programType: 'spts',                // optional for pull input modes
    ipType: 'external',                 // external or interconnect
    ipAddress: '',                      // ip address. Required for some input mode, or automatically assigned
    port: 6000,                         // port. automatically assigned if omitted
    srtPassphrase: '',                  // SRT password
    srtAESEncryptionType: 0,            // SRT encryption mode
    channelUseMode: 'default',          // Whether the source can be used for multiple channels
    ssmSourceIpAddress: '',             // Source IP for SSM. Optional for rtp interconnect, or empty
};


export function SourceForm(props) {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);

    const { source, updateSource, updateValid, editMode, errors, originalSourceData } = props;
    const [form] = Form.useForm();

    const [accountRegions, updateAccountRegions] = useState([]);
    const [enableSrtPassword, updateEnableSrtPassword] = useState(source.inputMode === 'srtListener' || source.inputMode === 'srtCaller');
    const [enableNonNameChanges, updateEnableNonNameChanges] = useState(true);
    const [enableIpType, updateEnableIpType] = useState(true);
    const [enableProgramType, updateEnableProgramType] = useState(true);
    const [enableInterconnectChanges, updateEnableInterconnectChanges] = useState(source.ipType === 'interconnect');
    const [ipAddressPlaceholder, updateIpAddressPlaceholder] = useState('<Automatically assign>');
    const [ipAddressRequired, updateIpAddressRequired] = useState(source.inputMode === 'srtCaller' || source.inputMode === 'rtpFecDestination');
    const [ssmSourceIpAddressPlaceholder, updateSsmSourceIpAddressPlaceholder] = useState(true);
    const [ssmSourceIpAddressRequired, updateSsmSourceIpAddressRequired] = useState(source.inputMode === 'rtp' && source.ipType === 'interconnect');


    const updateRegions = useCallback(async () => {
        const regionsResponse = await solutionApi.getAccountRegions(userDetails.accountID);
        if (regionsResponse.ok) {
            const regions = regionsResponse.data;

            updateAccountRegions(regions);
        }
    }, [solutionApi, userDetails.accountID]);

    const errorsClassName = errors ? '' : 'form-hidden';

    let errorObj = {
        summary: '',
        errorsLookup: {}
    };

    if (errors && errors.errors) {
        errorObj = processResponseErrors(errors);
    }

    const getErrorsProps = useCallback((fieldName) => {
        let props = {};

        const lookup = errorObj.errorsLookup[fieldName];

        if (lookup) {
            props.validateStatus = 'error';
            props.help = lookup.text;
        }

        return props;
    }, [errorObj.errorsLookup]);

    useEffect(() => {
        updateRegions();
    }, [updateRegions]);

    useEffect(() => {
        // on load
        form.resetFields();
    }, [form]);

    useEffect(() => {
        const notExternalSource = (source.ipType === 'interconnect');
        const useSrtPassword = (source.inputMode === 'srtListener' || source.inputMode === 'srtCaller');
        const useIpType = true;
        const _ipAddressRequired = (source.inputMode === 'srtCaller' || source.inputMode === 'rtpFecDestination');
        const nameOnlyChanges = source.activeUsedBy && source.activeUsedBy.length > 0;
        const _updateSsmSourceIpAddressRequired = (source.inputMode === 'rtp' && source.ipType === 'interconnect');

        // Form validation
        // Cannot create a source (click on Add new button) if not valid
        let isValid = source.region.length > 0;
        if (isValid) {
            if (!editMode && _ipAddressRequired && source.ipAddress.length == 0) {
                isValid = false;
            }
        }

        updateValid(isValid);
        updateEnableProgramType(true);
        updateEnableInterconnectChanges(notExternalSource);
        updateEnableSrtPassword(useSrtPassword);
        updateEnableIpType(useIpType);
        updateIpAddressPlaceholder(editMode ? originalSourceData.ipAddress : _ipAddressRequired ? 'Enter IP address' : '<Automatically assign>');
        updateEnableNonNameChanges(!nameOnlyChanges);
        updateIpAddressRequired(_ipAddressRequired);
        updateSsmSourceIpAddressRequired(_updateSsmSourceIpAddressRequired);
        updateSsmSourceIpAddressPlaceholder(!_updateSsmSourceIpAddressRequired ? '' : editMode ? originalSourceData.ssmSourceIpAddress : 'Enter IP address');

        // Reset display to avoid validation errors persistence when changing input mode
        if (!useSrtPassword) {
            form.setFields([
                { name: 'srtPassphrase', value: '', errors: [] },
                { name: 'srtAESEncryptionType', value: 0, errors: [] },
            ]);
        }
        if (!_updateSsmSourceIpAddressRequired)
        {
            form.setFields([
                { name: 'ssmSourceIpAddress', value: '', errors: [] }
            ]);
        }

        form.setFieldValue('ipType', source.ipType);
        form.setFieldValue('channelUseMode', source.channelUseMode);
    }, [form, source, updateValid, editMode, originalSourceData.ipAddress, originalSourceData.ssmSourceIpAddress]);


    const onValuesChanged = useCallback((...[, allValues]) => {
        const newSource = { ...source };
        Object.assign(newSource, allValues);

        if (newSource.inputMode !== 'srtListener' && newSource.inputMode !== 'srtCaller') {
            // Reset model
            newSource.srtPassphrase = '';
            newSource.srtAESEncryptionType = 0;
        }

        if (newSource.inputMode !== 'rtp') {
            // Reset model
            newSource.ssmSourceIpAddress = '';
        }

        updateSource(newSource);
    }, [source, updateSource]);


    return (<Form key='form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout='horizontal' form={form}
        onValuesChange={onValuesChanged}
        initialValues={source}>
        <Alert style={{ marginBottom: 8 }} className={errorsClassName} message={errors ? errorObj.summary : ''} type="error" />
        <Form.Item name='name' label='Name' {...getErrorsProps('name')} rules={[{ validator: validateTrimmedWhitespace }]}>
            <Input placeholder='Source Name' className='hook-sources-sourceentry-name' />
        </Form.Item>

        <Form.Item name='inputMode' label='Input Mode' {...getErrorsProps('inputMode')}>
            <Select disabled={editMode && (!enableNonNameChanges || !enableInterconnectChanges)} className='hook-sources-sourceentry-inputmode'>
                <Select.Option value="srtListener" className='hook-sources-sourceentry-srtlistenerinputmode'>SRT Listener</Select.Option>
                <Select.Option value="srtCaller" className='hook-sources-sourceentry-srtcallerinputmode'>SRT Caller</Select.Option>
                <Select.Option value="rtp" className='hook-sources-sourceentry-rtpinputmode'>RTP / UDP</Select.Option>
                <Select.Option value="rtp_fec" className='hook-sources-sourceentry-rtpfecinputmode'>RTP + FEC</Select.Option>
                <Select.Option value="rtpFecDestination" className='hook-sources-sourceentry-rtpfecdestinationinputmode'>RTP + FEC Destination</Select.Option>
            </Select>
        </Form.Item>

        <Form.Item name='programType' label='Content type' {...getErrorsProps('programType')}>
            <Select disabled={editMode || !enableProgramType} className='hook-sources-sourceentry-contenttype'>
                <Select.Option value="spts" className='hook-sources-sourceentry-sptscontenttype'>SPTS</Select.Option>
                <Select.Option value="mpts" className='hook-sources-sourceentry-mptscontenttype'>MPTS</Select.Option>
            </Select>
        </Form.Item>

        <Form.Item name='srtPassphrase' label='SRT Passphrase' {...getErrorsProps('srtPassphrase')}
            rules={[
                {
                    min: 10,
                    message: 'Minimum length is 10 characters',
                }
            ]}>
            <Input disabled={editMode && (!enableNonNameChanges || !enableInterconnectChanges) || !enableSrtPassword} placeholder='<No Passphrase>' className='hook-sources-sourceentry-srtpassword' />
        </Form.Item>

        <Form.Item name='srtAESEncryptionType' label='AES Encryption Type' {...getErrorsProps('srtAESEncryptionType')}>
            <Select disabled={editMode && (!enableNonNameChanges || !enableInterconnectChanges) || !enableSrtPassword} className='hook-sources-sourceentry-srtaesencryptiontype' placeholder='No Encryption'>
                <Select.Option value={0} className='hook-sources-sourceentry-srtaesencryptiontype-0'>No Encryption</Select.Option>
                <Select.Option value={16} className='hook-sources-sourceentry-srtaesencryptiontype-128'>128 Bits</Select.Option>
                <Select.Option value={24} className='hook-sources-sourceentry-srtaesencryptiontype-192'>192 Bits</Select.Option>
                <Select.Option value={32} className='hook-sources-sourceentry-srtaesencryptiontype-256'>256 Bits</Select.Option>
            </Select>
        </Form.Item>

        <Form.Item
            name='channelUseMode'
            label='Channel Use Mode'
            tooltip='Single: The source can only be used by a maximum of one active channel. Multiple: No limit on the number of active channels using this source.'
            {...getErrorsProps('channelUseMode')}
        >
            <Select disabled={editMode} className='hook-sources-sourceentry-channelmode'>
                <Select.Option value={'default'} className='hook-sources-sourceentry-channelmode-default'>Default (Multiple)</Select.Option>
                <Select.Option value={'single'} className='hook-sources-sourceentry-channelmode-single'>Single</Select.Option>
                <Select.Option value={'multiple'} className='hook-sources-sourceentry-channelmode-multiple'>Multiple</Select.Option>
            </Select>
        </Form.Item>

        <Form.Item name='region' label='Deployment' {...getErrorsProps('region')}>
            <Select placeholder='Select a deployment for this source'
                className='hook-sources-sourceentry-region'
                disabled={editMode && (!enableNonNameChanges || !enableInterconnectChanges)}
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) =>
                    option.children.includes(input)
                }>
                {
                    _.sortBy(accountRegions, 'name').map((region, index) => {
                        return <Select.Option key={region.id} className={`hook-sources-sourceentry-region-${index.toString()}`} value={region.name}>{region.name}</Select.Option>;
                    })
                }
            </Select>
        </Form.Item>

        <Form.Item name='ipType' label='IP Address type' {...getErrorsProps('ipType')}>
            <Select disabled={editMode || !enableIpType} className='hook-sources-sourceentry-ipaddresstype'>
                <Select.Option value="external" className='hook-sources-sourceentry-externalipaddresstype'>External</Select.Option>
                <Select.Option value="interconnect" className='hook-sources-sourceentry-interconnectipaddresstype'>Internal - Interconnect</Select.Option>
            </Select>
        </Form.Item>

        <Form.Item name='ipAddress' label='IP Address' {...getErrorsProps('ipAddress')}>
            <Input disabled={!((enableInterconnectChanges || (ipAddressRequired && !editMode)) && (!editMode || enableNonNameChanges))} placeholder={ipAddressPlaceholder} className='hook-sources-sourceentry-ipaddress' />
        </Form.Item>

        <Form.Item name='port' label='Destination Port' {...getErrorsProps('port')}>
            <Input disabled={(editMode && (!enableNonNameChanges || !enableInterconnectChanges))} placeholder='6000' className='hook-sources-sourceentry-port' />
        </Form.Item>

        <Form.Item
            name='ssmSourceIpAddress'
            label='SSM Source IP Address' {...getErrorsProps('ssmSourceIpAddress')}
            tooltip='Only applicable for RTP/UDP Interconnect. When defining a Source Specific Multicast address, the ipAddress will contain the multicast destination IP address and this field will contain the source IP address'
        >
            <Input disabled={!ssmSourceIpAddressRequired} placeholder={ssmSourceIpAddressPlaceholder} className='hook-sources-sourceentry-ssmSourceIpAddress' />
        </Form.Item>
    </Form>);
}

SourceForm.propTypes = {
    source: PropTypes.object,
    updateSource: PropTypes.func,
    updateValid: PropTypes.func,
    editMode: PropTypes.bool,
    errors: PropTypes.object,
    originalSourceData: PropTypes.object
};

export function SourceModal(props) {

    const {
        visible = true,
        onConfirm = () => { },
        onCancel = () => { },
        data = emptySource,
        errors = null,
    } = props;

    const [source, updateSource] = useState({ ...data });
    const [isValid, updateIsValid] = useState(false);

    const resetSource = useCallback(() => {
        updateSource({ ...emptySource });
    }, []);

    const doConfirm = useCallback(async () => {
        await onConfirm(source);
    }, [source, onConfirm]);

    const doCancel = useCallback(() => {
        onCancel();
        resetSource();
    }, [onCancel, resetSource]);

    const updateValid = useCallback((isValid) => {
        updateIsValid(isValid);
    }, []);

    return (<Modal title={source.id ? 'Edit Source' : 'Create Source'} open={visible} closable={false} className='hook-sources-sourceentry-addeditmodal'
        footer={[
            <Button disabled={!isValid} key="create" type="primary" className='hook-sources-sourceentry-addneworsavechanges' onClick={doConfirm}>
                {source.id ? 'Save changes' : 'Add New'}
            </Button>,
            <Button key="cancel" className='hook-sources-sourceentry-cancel' onClick={doCancel}>
                Cancel
            </Button>]}>
        <SourceForm updateValid={updateValid} source={source} updateSource={updateSource} editMode={!!props.data} errors={errors} originalSourceData={data} />
    </Modal>);
}

SourceModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    data: PropTypes.object,
    errors: PropTypes.object,
};
