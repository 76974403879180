import React from 'react';
import PropTypes from 'prop-types';
import { Form, Transfer } from 'antd';
import { useEffect } from 'react';
import { useCallback, useState } from 'react';

export default function InputPicker({
    updateTargetInputsList,
    feeds,
    inputPickerFormItemName,
    initialTargetKeys,
    form
}) {
    const [inputsList, setInputsList] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);

    const onLoad = useCallback(async () => {
        try {
            // Parse data into form properties for Transfer components
            const availableInputs = feeds.map(input => ({
                key: input.id,
                title: input.config.name,
                description: input.config.name,
                chosen: false,
            }));

            setInputsList(availableInputs);
            setTargetKeys(initialTargetKeys);
        } catch (ex) {
            console.log('onLoad: exception ', ex);
        }
    }, [feeds, initialTargetKeys]);

    const onChange = useCallback((newTargetKeys/*, direction, moveKeys*/) => {
        setTargetKeys(newTargetKeys);
        updateTargetInputsList(newTargetKeys);
    }, [updateTargetInputsList]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        form.setFieldsValue({
            inputPickerFormItemName: targetKeys
        });
    }, [form, targetKeys]);

    return (<>
        <Form
            layout='horizontal'
            form={form}
        >
            <Form.Item key={inputPickerFormItemName} name={inputPickerFormItemName} >
                <Transfer
                    style={{ justifyContent: 'center' }}
                    dataSource={inputsList}
                    targetKeys={targetKeys}
                    onChange={onChange}
                    operations={['include', 'exclude']}
                    showSearch
                    render={(item) => item.title}
                    pagination
                />
            </Form.Item>
        </Form>
    </>
    );
}

InputPicker.propTypes = {
    form: PropTypes.object,
    inputPickerFormItemName: PropTypes.string,
    feeds: PropTypes.array,
    updateTargetInputsList: PropTypes.func,
    initialTargetKeys: PropTypes.array,
};
