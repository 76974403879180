import React, { useState, useCallback, useContext } from 'react';
import { Row, Col, PageHeader, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { refreshAlerts, refreshChannels, refreshSources } from '../util/refresh';
import { Ticker } from '../util';
import { SolutionApiContext, SelectedAccountContext } from '../../services';
import AlarmsTable from './AlarmsTable';
import Indicators from '../dashboard/Indicators';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const REFRESH_PERIOD_MS = 5 * 1000;

export default function Alarms() {
    let query = useQuery();
    const alertIdToUse = query.get('channelId') ? query.get('channelId') : 'other';

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;

    const [alerts, setAlerts] = useState();
    const [sources, setSources] = useState();
    const [channels, setChannels] = useState();
    const [numberOfActiveChannels, setNumberOfActiveChannels] = useState();

    const refresh = useCallback(async () => {
        const [channels, numberOfActiveChannels] = await refreshChannels(solutionApi, accountId);
        setChannels(channels);
        numberOfActiveChannels >= 0 ? setNumberOfActiveChannels(numberOfActiveChannels) : null;

        const sources = await refreshSources(solutionApi, accountId);
        sources ? setSources(sources) : null;

        const alerts = await refreshAlerts(solutionApi, accountId);
        alerts ? setAlerts(alerts) : null;
    }, [accountId, solutionApi]);

    let alertGroup = null;
    if (alerts) {
        if (alerts[alertIdToUse]) {
            alertGroup = alerts[alertIdToUse];
        } else {
            alertGroup = { 'channelId': alertIdToUse == 'other' ? 'null' : alertIdToUse };
        }
    }

    return (
        <>
            <Ticker asyncCallback={refresh} timeout={REFRESH_PERIOD_MS} immediate={true} />
            <Row gutter={[16, 16]} className='gutter-bottom'>
                <Col>
                    <Row align='middle'>
                        <Col>
                            <FontAwesomeIcon icon={faExclamationTriangle} size='4x' />
                        </Col>
                        <Col>
                            <PageHeader backIcon={null} title="Alarms" />
                        </Col>
                    </Row>
                    <Row className='gutter-bottom'>
                        <Col>
                            <Space>
                                <FontAwesomeIcon icon={faInfoCircle} size='2x' color='blue' />
                                <span>Status of {alertIdToUse == 'other' ? 'other' : 'channel'} alarms.</span>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col flex='auto' style={{ marginTop: '16px' }}>
                    <Indicators
                        alertGroups={alerts}
                        alertsLoading={!alerts}
                        numberOfActiveChannels={numberOfActiveChannels}
                        numberOfSources={sources ? sources.length : undefined}
                    />
                </Col>
            </Row>
            <AlarmsTable
                alertGroup={alertGroup}
                channels={channels}
                sources={sources}
            />
        </>
    );
}
