import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Button, Form, Row, Col, Steps, Divider, List, Space } from 'antd';
import { useParams, useHistory, Link, Prompt } from 'react-router-dom';
const { Step } = Steps;
import { useState, useCallback, useEffect, useContext } from 'react';
import MuxSetupForm from './MuxSetupForm';
import InputPicker from './InputPicker';
import { SolutionApiContext, SelectedAccountContext } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faSignInAlt, faVideo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import MuxServices from '../mux_services/MuxServices';
import { Ticker } from '../util';
import { extractInstancesToArray }  from '../channels/InstancesTable';
import './muxes.css';

const emptyMux = {
    name: 'NewMux',
    egress_feed: '',
    instances: []
};

const NEW_MUXID = 'new';
const SANDBOX_REFRESH_PERIOD_MS = 3 * 1000;

export default function MultiplexDetails(props) {

    const [muxDetails] = useState(null);

    const {
        errors = null,
        updateNavigateCallback
    } = props;

    let { multiplexId } = useParams();
    const history = useHistory();
    const [isBlocking, updateIsBlocking] = useState(true);

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const [currentStep, setCurrentStep] = useState(0);
    const [okToPost, updateOkToPost] = useState(false);
    const [feedsList, updateFeedsList] = useState([]);
    const [channelsList, updateChannelsList] = useState([]);

    const [targetFeedsList, updateTargetFeedsList] = useState([]);
    const [targetChannelsList, updateTargetChannelsList] = useState([]);
    const [deployLocations, updateDeployLocations] = useState([]);
    const [deployLocationsSummary, updateDeployLocationsSummary] = useState([]);

    const [mux, updateMux] = useState({ ...emptyMux });
    const [form] = Form.useForm();
    const [feedsForm] = Form.useForm();
    const [channelsForm] = Form.useForm();

    const [muxSandbox, updateMuxSandbox] = useState(null);
    const [muxId, updateMuxId] = useState(null); // Controller config ID
    const [deploymentId, updateDeploymentId] = useState(null);

    const updateValid = useCallback((isValid) => {
        updateOkToPost(isValid);
    }, [updateOkToPost]);

    const refreshInputs = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;
        const shallContinue = () =>  {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const feedsResponse = await solutionApi.listFeeds(accountId);
            if (shallContinue()) {
                const inputFeeds = feedsResponse.data.filter(feed => feed.config.type == 'ingress');
                updateFeedsList(inputFeeds);
            }

            const channelsResponse = await solutionApi.listChannels(accountId);
            if (shallContinue()) {
                const filterChannels = channelsResponse.data.filter(channel => channel.type == 'Broadcast');
                // For v1 channels reformat the data into a common format (feeds schema) so the InputPicker
                // can operate polymorphically
                const channelsAsFeeds = filterChannels.map(channel => ({
                    id: channel.id,
                    config: { name: channel.name }
                }));
                updateChannelsList(channelsAsFeeds);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, userDetails.accountID]);

    const refreshSandbox = useCallback(async (quitContainer) => {
        const shallContinue = () =>  {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const accountId = userDetails.accountID;
            if (shallContinue() && muxSandbox != null) {
                await solutionApi.touchSandboxMultiplex(accountId, muxSandbox.sandbox_id);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, userDetails.accountID, muxSandbox]);

    const breadcrumbOverride = useCallback((path, breadcrumbItems) => {
        const pathItems = path.pathname.split('/');
        let compound = '';

        for (let index = 0; index < pathItems.length; index++) {
            const component = pathItems[index];
            compound = `${compound}${component}/`;

            // don't add for empty text due to the 'split';
            if (index === 0) {
                breadcrumbItems.push(<Breadcrumb.Item className='hook-dashboard-home' key={compound}><Link to={compound}>Home</Link></Breadcrumb.Item>);
            } else if (index === 1) {
                const displayText = component.replace(/^\w/, c => c.toUpperCase());
                const classHook = 'hook-mux-services-'.concat(displayText);
                breadcrumbItems.push(<Breadcrumb.Item className={classHook} key={compound}><Link to={compound}>{displayText}</Link></Breadcrumb.Item>);
            } else if (component.length > 0) {
                const idDisplayText = component.replace(/^\w/, c => c.toUpperCase());
                const classHook = 'hook-mux-services-'.concat(idDisplayText);
                const displayText = (muxDetails != null) ? muxDetails.name.replace(/^\w/, c => c.toUpperCase()) : '.....';
                breadcrumbItems.push(<Breadcrumb.Item className={classHook} key={compound}><Link to={compound}>{displayText}</Link></Breadcrumb.Item>);
            }
        }
    }, [muxDetails]);

    useEffect(() => {
        updateDeployLocationsSummary(extractInstancesToArray(deployLocations));
    }, [deployLocations, updateDeployLocationsSummary]);

    useEffect(() => {
        if (updateNavigateCallback) {
            updateNavigateCallback(breadcrumbOverride);
        }
    }, [updateNavigateCallback, breadcrumbOverride]);

    useEffect(() => {
        // on load
        refreshInputs(null);
        return async () => {
            console.log('Cleanup Multiplex page');
            if (muxSandbox != null) {
                try {
                    const accountId = userDetails.accountID;

                    let response = await solutionApi.deleteSandboxMultiplex(accountId, { ...emptyMux }, muxSandbox, false);
                    if (response.ok) {
                        updateMuxSandbox(null);
                        updateMuxId(null);
                        updateDeploymentId(null);
                    } else {
                        console.log('failed to delete sandbox Multiplex');
                    }
                } catch (exception) {
                    console.log(exception);
                }
            }
        };
    }, [refreshInputs, muxSandbox, solutionApi, userDetails.accountID]);

    const resetMux = useCallback(() => {
        updateMux({ ...emptyMux });
        updateValid(false);
    }, [updateValid]);

    const onLoad = useCallback(async () => {
        if(multiplexId != NEW_MUXID){
            const muxDataResponse = await solutionApi.getMultiplex(userDetails.accountID, multiplexId);

            if (muxDataResponse.ok) {
                const egress_feeds = await solutionApi.listFeedsForMediaService(userDetails.accountID, `multiplexes/${multiplexId}`);
                const editMux = {
                    name: muxDataResponse.data.config.name,
                };

                console.log(`egress_feeds.data.length = ${egress_feeds.data.length}`);
                if (egress_feeds.data.length > 0){
                    editMux['egress_feed'] = egress_feeds.data[0].config.name;
                    editMux['config'] = muxDataResponse.data.config;
                    for (const [key, instance] of Object.entries(editMux.config.instances)) {
                        instance.label = key;
                    }
                    updateOkToPost(true);
                    updateDeployLocations(editMux.config.instances);
                }

                updateMux(editMux);

                const allInputs = muxDataResponse.data.config.inputs;
                let feeds = [], channels = [];
                Object.values(allInputs).forEach(input => {
                    const inputDetails = input.split('/');
                    const inputType = inputDetails[0];
                    const inputRef = inputDetails[1];
                    if(inputType == 'feeds'){
                        feeds.push(inputRef);
                    }else{
                        channels.push(inputRef);
                    }
                });
                updateTargetFeedsList(feeds);
                feedsForm.setFieldsValue({ 'targetFeeds': feeds });
                updateTargetChannelsList(channels);
                channelsForm.setFieldsValue({ 'targetChannels': channels });
            }
        }
    }, [solutionApi, userDetails.accountID, multiplexId, feedsForm, channelsForm]);

    useEffect(() => {onLoad();}, [onLoad]);

    const onConfirm = useCallback(async (updateMux, saveOption) => {
        try {
            updateIsBlocking(false);
            const accountId = userDetails.accountID;
            let response = await solutionApi.deleteSandboxMultiplex(accountId, updateMux, muxSandbox, saveOption);
            if (response.ok) {
                updateMuxSandbox(null);
                updateMuxId(null);
                updateDeploymentId(null);
                history.push('/muxes/');
            } else {
                console.log('failed to delete sandbox Multiplex');
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, userDetails.accountID, muxSandbox, updateMuxSandbox, history]);

    const sanitizeInstanceName = (name) => {
        // Temporary measure until HA is done. Use the source name to
        // create the instance ID but sanitize it so is valid for the API
        return name.toLowerCase().replaceAll(/[^a-z0-9]/g, '').slice(0, 10);
    };

    const buildMultiplexConfig = useCallback(async () => {
        let config = {};
        let values = await form.validateFields();
        try{
            config['id'] = multiplexId != NEW_MUXID ? multiplexId : '';

            // Expand form items into post schema data
            const targetFeeds = feedsForm.getFieldValue('targetFeeds');
            if(targetFeeds){
                config['remux_feeds'] = targetFeeds;
            }

            const targetChannels = channelsForm.getFieldValue('targetChannels');
            if(targetChannels){
                config['channels'] = targetChannels;
            }

            const outputInstances = values.config.instances;
            config.instances = {};
            if(outputInstances){
                for (const [key, instance] of Object.entries(outputInstances)) {
                    config.instances[sanitizeInstanceName(key)] = {
                        'location': instance.location,
                        'role': instance.role
                    };
                }
            }
            config.name = values.name;
            config.egress_feed = values.egress_feed;
        }
        catch(err){
            console.log('err', err);
        }
        return config;
    }, [form, feedsForm, channelsForm, multiplexId]);

    const doConfirm = useCallback(async () => {
        console.log('Saving new/update of multiplex ' + updateMux);
        const config = await buildMultiplexConfig();

        if (await onConfirm(config, true)) {
            resetMux();
        }
    }, [onConfirm, resetMux, buildMultiplexConfig]);

    const doCancel = useCallback(async () => {
        console.log('Cancelling new/update of multiplex');

        if(muxSandbox){
            const config = await buildMultiplexConfig();

            if (await onConfirm(config, false)) {
                resetMux();
            }
        }
    }, [muxSandbox, onConfirm, resetMux, buildMultiplexConfig]);

    const toggleSteps = useCallback((step) => {
        if(step !== currentStep){
            return 'step-hidden';
        }
        return '';
    }, [currentStep]);

    const startSandbox = useCallback(async (config) => {
        try {
            const accountId = userDetails.accountID;
            let response;
            if(muxSandbox == null){
                response = await solutionApi.createSandboxMultiplex(accountId, config);
                if (response.ok) {
                    console.log('created sandbox Multiplex');
                    updateMuxSandbox(response.data);
                    updateMuxId(response.data.mksp_config_id);
                    updateDeploymentId(response.data.deployment_id);
                } else {
                    console.log('failed to create sandbox Multiplex');
                }
            }
            else{
                response = await solutionApi.updateSandboxMultiplex(accountId, config, muxSandbox);
                if (response.ok) {
                    console.log('updated sandbox Multiplex');
                    updateMuxSandbox(response.data);
                } else {
                    console.log('failed to update sandbox Multiplex');
                }
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, userDetails.accountID, updateMuxSandbox, muxSandbox]);

    const onStepChange = useCallback(async (step) => {
        setCurrentStep(step);

        if(step == 3){
            // When showing the MKSP Connector, we need a sandbox
            try {
                const config = await buildMultiplexConfig();
                startSandbox(config);
            } catch (exception) {
                console.log(exception);
            }

        }
    }, [setCurrentStep, buildMultiplexConfig, startSandbox]);

    return (<>
        <Ticker asyncCallback={refreshSandbox} timeout={SANDBOX_REFRESH_PERIOD_MS} />
        <Row>
            <Col className='stepsInfo'>
                <Space>
                    <FontAwesomeIcon icon={faInfoCircle} size='2x' color='blue'/>
                    Enter the information in each step of this Multiplex configuration assistant to complete your Multiplex setup.
                </Space>
            </Col>
        </Row>
        <Row className='stepsHeader'>
            <Col span={24}>
                <Steps
                    current={currentStep}
                    onChange={onStepChange}>
                    <Step title='Main details' />
                    <Step title='Remux inputs' />
                    <Step title='Channel inputs' />
                    <Step title='Multiplex definition' disabled={!okToPost} />
                    <Step title='Summary' />
                </Steps>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <div className={toggleSteps(0)}>
                    <Divider>
                        Multiplex details and availability
                    </Divider>
                    <MuxSetupForm
                        mux={mux}
                        form={form}
                        errors={errors}
                        updateValid={updateValid}
                        updateDeployLocations={updateDeployLocations}
                        multiplexId={multiplexId} />
                </div>
                <div className={toggleSteps(1)}>
                    <Divider>Remux feeds selection</Divider>
                    <InputPicker form={feedsForm} inputPickerFormItemName='targetFeeds' feeds={feedsList} updateTargetInputsList={updateTargetFeedsList} initialTargetKeys={targetFeedsList}/>
                </div>
                <div className={toggleSteps(2)}>
                    <Divider>Channels selection</Divider>
                    <InputPicker form={channelsForm} inputPickerFormItemName='targetChannels' feeds={channelsList} updateTargetInputsList={updateTargetChannelsList} initialTargetKeys={targetChannelsList}/>
                </div>
                <div className={toggleSteps(3)}>
                    <Divider>Multiplex definition</Divider>
                    {currentStep == 3 ?
                        <MuxServices deploymentId={deploymentId} muxId={muxId}/> :
                        <></>
                    }
                </div>
                <div className={toggleSteps(4)}>
                    <Divider>Summary</Divider>
                    <Row>
                        <Col offset={4}>
                            This Mulitplex will be configured the following inputs:
                            <List
                                itemLayout="horizontal">
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<FontAwesomeIcon fixedWidth={true} icon={faSignInAlt} />}
                                        title={`${targetFeedsList.length} remux inputs`}
                                    />
                                </List.Item>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<FontAwesomeIcon fixedWidth={true} icon={faVideo} />}
                                        title={`${targetChannelsList.length} channel inputs`}
                                    />
                                </List.Item>
                            </List>
                            {deployLocationsSummary.length > 0 ?
                                <>
                                    <div>and will be deployed in the following locations:</div>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={deployLocationsSummary}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<FontAwesomeIcon fixedWidth={true} icon={faCloud} />}
                                                    title={item.location}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </>
                                :
                                <div className='stepSummaryErrorText'><br/>No Output selected in Step 1.<br/></div>
                            }
                            {muxSandbox == null ?
                                <div className='stepSummaryErrorText'><br/>No Multiplex definition set in Step 4.<br/><br/></div> : <br/>
                            }
                            To deploy the Multiplex, click the start button on the Multiplex table after saving.
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                    <Row justify='center'>
                        <Col>
                            <Button disabled={!okToPost || muxSandbox == null} key="create" type="primary" className='stepsMultiplexSaveButton hook-muxes-muxconfig-addmux' onClick={doConfirm}>
                                Save Multiplex
                            </Button>
                            <Link to="/muxes/">
                                <Button key="cancel" className='hook-muxes-muxconfig-canceladdmux' onClick={doCancel}>
                                    Cancel
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
        <Prompt
            when={isBlocking}
            message={'Are you sure you want to navigate away from this Multiplex, any changes will be lost?'}
        />
    </>
    );
}

MultiplexDetails.propTypes = {
    updateNavigateCallback: PropTypes.func,
    errors: PropTypes.object
};
