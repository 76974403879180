import React from 'react';
import EventSource from './EventSource';
import displayErrors from './DisplayErrors';

class BaseConnection {
    constructor(config, token) {
        // Don't include baseUrl by default, so will always refer to the same server as the UI
        this.baseUrl = config['general']['aquila-hub']['base'];
        this.serverUrl = /* this.baseUrl + */ `${config['general']['aquila-hub']['api']}/`;

        this.bearerToken = `Bearer ${token}`;
        this.token = token;
        this.onAuthFailureEvent = new EventSource();
    }

    authFailureRedirect() {
        console.log('authfailure');
        this.onAuthFailureEvent.raise();
    }

    async baseFetch(path, { method = 'GET', headers = {}, body = undefined, auth = true, setContentType = true }) {
        let httpResponse = {};
        try {
            if (auth)
                headers['Authorization'] = this.bearerToken;
            if (method !== 'GET' && setContentType)
                headers['content-type'] = 'application/json';
            httpResponse = await fetch(this.serverUrl + path, {
                method,
                headers,
                body,
            });
            try {
                httpResponse.data = await httpResponse.json();
            } catch (ex) {
                console.log(ex);
            }
        } catch (ex) {
            console.log(ex);
        }

        if (httpResponse.status === 403) {
            this.authFailureRedirect();
        }

        return httpResponse;
    }

    async getObject(path, auth = true) {
        this.markOperation();
        return await this.baseFetch(path, { auth });
    }

    async getMultipart(path, auth = true) {
        let httpResponse = {};
        let headers = {};
        try {
            if (auth)
                headers['Authorization'] = this.bearerToken;
            httpResponse = await fetch(this.serverUrl + path, {
                method: 'GET',
            });
        } catch (ex) {
            console.log(ex);
        }

        if (httpResponse.status === 403) {
            this.authFailureRedirect();
        }

        return httpResponse;
    }

    async createObject(path, contents, additionalHeaders = {}, autoError = true) {
        const response = await this.baseFetch(path, { method: 'POST', headers: additionalHeaders, body: contents });
        if (autoError && !response.ok) {
            let jsonData = response.data;
            displayErrors(jsonData.summary, jsonData.errors, 10);
        }
        return response;
    }

    async updateObject(path, contents, autoError = true) {
        const response = await this.baseFetch(path, { method: 'PUT', body: contents });
        if (autoError && !response.ok) {
            let jsonData = response.data;
            displayErrors(jsonData.summary, jsonData.errors, 10);
        }
        return response;
    }

    async patchObject(path, contents, autoError = true) {
        const response = await this.baseFetch(path, { method: 'PATCH', body: contents });
        if (autoError && !response.ok) {
            let jsonData = response.data;
            displayErrors(jsonData.summary, jsonData.errors, 10);
        }
        return response;
    }

    async deleteObject(path, autoError = true, contents = null) {
        const response = await this.baseFetch(path, { method: 'DELETE',  body: contents });
        if (autoError && !response.ok) {
            let jsonData = response.data;
            displayErrors(jsonData.summary, jsonData.errors, 10);
        }
        return response;
    }
}


class SolutionApi extends BaseConnection {
    constructor(config, token, saasHubApi) {
        super(config, token);
        this.saasHubApi = saasHubApi || null;
    }

    // Channels
    async listChannels(accountId) {
        const url = `v1.0/accounts/${accountId}/channels`;
        return await this.getObject(url, false);
    }

    // UIChannels
    async listChannelsUI(accountId) {
        const url = `v1.0/accounts/${accountId}/ui/channels`;
        return await this.getObject(url, false);
    }

    async getv2Channel(accountId, channelId) {
        const url = `v2/accounts/${accountId}/channels/${channelId}`;

        let response = await this.getObject(url);

        return response;
    }

    async getChannel(accountId, channelId, channelType) {
        // As part of migration to v2 channels, try the v2 first, otherwise v1.
        let response;
        if(channelType == 'Broadcast'){
            try{
                response = await this.getv2Channel(accountId, channelId);
                if(!response.data.errors){
                    response.data['type'] = 'Broadcast';
                    response.data['name'] = response.data.config.name;
                    response.data['template'] = response.data.config.template;
                    return response;
                } else {
                    console.log('Channel is not v2, reverting to v1.');
                }
            }
            catch(ex){
                console.log('Failed to fetch a v2 channel.');
            }
        }

        const url = `v1.0/accounts/${accountId}/channels/${channelId}`;

        response = await this.getObject(url);

        // Flattens the channels instances and program numbers into root of object
        response.data.backupEnabled = response.data.backupSource && response.data.backupSource.length > 0;
        response.data.instanceId = response.data.instances[0].id;

        function resolveProgramNumber(field, programNumber) {
            if (programNumber !== 0) {
                response.data[field] = programNumber;
            }
            else {
                delete response.data[field];
            }
        }

        response.data.sourceId = response.data.instances[0].sources[0].id;
        resolveProgramNumber('programNumber', response.data.instances[0].sources[0].programNumber);

        if (response.data.instances[0].sources.length > 1) {
            response.data.backupSourceId = response.data.instances[0].sources[1].id;
            resolveProgramNumber('backupProgramNumber', response.data.instances[0].sources[1].programNumber);
        }
        else {
            response.data.backupSourceId = undefined;
            delete response.data['backupProgramNumber'];
        }

        if (response.data.instances.length > 1) {
            response.data.haEnabled = true;
            response.data.haInstanceId = response.data.instances[1].id;
            response.data.haSource = response.data.instances[1].sources[0].sourceId;
            response.data.haSourceId = response.data.instances[1].sources[0].id;
            resolveProgramNumber('haProgramNumber', response.data.instances[1].sources[0].programNumber);

            if (response.data.instances[1].sources.length > 1) {
                response.data.haBackupEnabled = true;
                response.data.haBackupSource = response.data.instances[1].sources[1].sourceId;
                response.data.haBackupSourceId = response.data.instances[1].sources[1].id;
                resolveProgramNumber('haBackupProgramNumber', response.data.instances[1].sources[1].programNumber);
            }
            else {
                response.data.haBackupEnabled = false;
                response.data.haBackupSource = undefined;
                response.data.haBackupSourceId = undefined;
                delete response.data['haBackupProgramNumber'];
            }
        }
        else {
            response.data.haInstanceId = undefined;
            response.data.haEnabled = false;
            response.data.haBackupEnabled = false;
            response.data.haSource = undefined;
            response.data.haSourceId = undefined;
            response.data.haBackupSource = undefined;
            response.data.haBackupSourceId = undefined;
        }

        return response;
    }

    cleanupChannelOptions(channel) {
        // Remove all deprecated or non-API fields.
        if (!channel.use_statmux)
            channel['statmuxPoolId'] = '';

        const fields = [
            'id', 'haEnabled',
            'backupEnabled', 'haBackupEnabled',
            'instanceId', 'haInstanceId',
            'source', 'backupSource', 'haSource', 'haBackupSource',
            'programNumber', 'backupProgramNumber', 'haProgramNumber', 'haBackupProgramNumber',
            'sourceId', 'backupSourceId', 'haSourceId', 'haBackupSourceId',
            'activeSource', 'state', 'status', 'timeStateLastChanged', 'region', 'use_statmux',
            'templateRevision', 'haTemplateRevision', 'feed', 'config'
        ];

        fields.forEach((item) => {
            delete channel[item];
        });
    }

    // channels have instances array relating the array of sources per region where deployed
    extractSource(newChannel, enabled, sources, sourceLabel, programNumberLabel, sourceIdLabel) {
        const source = {};

        if (enabled) {
            source.id = newChannel[sourceIdLabel];
            source.sourceId = newChannel[sourceLabel];
            if (newChannel[programNumberLabel])
                source.programNumber = newChannel[programNumberLabel];

            sources.push(source);
        }

        return source;
    }

    parseChannelIntoInstances(channel) {
        const newChannel = JSON.parse(JSON.stringify(channel));

        const instance1 = { sources: [] };
        this.extractSource(newChannel, true, instance1.sources, 'source', 'programNumber', 'sourceId');
        this.extractSource(newChannel, newChannel.backupEnabled, instance1.sources, 'backupSource', 'backupProgramNumber', 'backupSourceId');
        if ('instanceId' in newChannel) {
            instance1.id = channel.instanceId;
        }
        if ('templateRevision' in newChannel) {
            instance1.templateRevision = channel.templateRevision;

        }

        const instances = [instance1];

        if (newChannel.haEnabled) {
            const instance2 = { sources: [] };
            this.extractSource(newChannel, newChannel.haEnabled, instance2.sources, 'haSource', 'haProgramNumber', 'haSourceId');
            this.extractSource(newChannel, newChannel.haBackupEnabled, instance2.sources, 'haBackupSource', 'haBackupProgramNumber', 'haBackupSourceId');
            if ('haInstanceId' in newChannel) {
                instance2.id = channel.haInstanceId;
            }
            if ('haTemplateRevision' in newChannel) {
                instance2.templateRevision = channel.haTemplateRevision;
            }

            instances.push(instance2);
        }

        newChannel.instances = instances;

        this.cleanupChannelOptions(newChannel);

        return newChannel;
    }

    cleanV2ChannelFields(channel){
        let newChannel = { 'config': {} };
        newChannel.config.name = channel.name;
        newChannel.config.template = channel.template;
        newChannel.config.feed = channel.config.feed;
        newChannel.config.instances = {};

        for(const instance in channel.config.instances){
            newChannel.config.instances[instance] =  {
                ...channel.config.instances[instance],
                'state' : 'stopped'
            };
            delete newChannel.config.instances[instance].label;
            delete newChannel.config.instances[instance].id;
        }

        return newChannel;
    }

    async createChannel(accountId, channel, makeActive) {
        let newChannelObject, url;

        if (channel.type == 'OTT'){
            newChannelObject = this.parseChannelIntoInstances(channel);
            url = `v1.0/accounts/${accountId}/channels`;
        } else {
            newChannelObject = this.cleanV2ChannelFields(channel);
            url = `v2/accounts/${accountId}/channels`;
        }
        const channelJson = JSON.stringify(newChannelObject);

        return this.createObject(url, channelJson, makeActive ? {
            'X-Start-Now': 'true',
        } : {}, false);
    }

    async updateChannel(accountId, channelId, channel) {
        let newChannelObject, url;

        if (channel.type == 'OTT'){
            newChannelObject = this.parseChannelIntoInstances(channel);
            url = `v1.0/accounts/${accountId}/channels/${channelId}`;
            const channelJson = JSON.stringify(newChannelObject);
            return this.updateObject(url, channelJson);
        } else {
            newChannelObject = this.cleanV2ChannelFields(channel);
            url = `v2/accounts/${accountId}/channels/${channelId}`;
            const channelJson = JSON.stringify(newChannelObject);
            return this.patchObject(url, channelJson);
        }
    }

    async startChannel(accountId, channelId, instanceId) {
        const url = `v1.0/accounts/${accountId}/channels/${channelId}/start`;

        const json = instanceId != null ? JSON.stringify({ instanceId: instanceId }) : JSON.stringify({});
        return this.createObject(url, json);
    }

    async stopChannel(accountId, channelId, instanceId) {
        const url = `v1.0/accounts/${accountId}/channels/${channelId}/stop`;

        const json = instanceId != null ? JSON.stringify({ instanceId: instanceId }) : JSON.stringify({});
        return this.createObject(url, json);
    }

    async getChannelOutput(accountId, channelId) {
        const url = `v1.0/accounts/${accountId}/channels/${channelId}/output`;
        return await this.getObject(url);
    }

    async getChannelMediaSecrets(accountId, channelId) {
        const url = `v1.0/accounts/${accountId}/ui/secrets/channels/${channelId}`;
        return await this.getObject(url);
    }

    // Multiplex
    async createSandboxMultiplex(accountId, muxObject) {
        const url = `v1.0/accounts/${accountId}/ui/multiplex_sandboxes`;
        const muxJson = JSON.stringify(muxObject);
        return this.createObject(url, muxJson);
    }

    async deleteSandboxMultiplex(accountId, muxObject, sandbox, applyChanges) {
        const url = `v1.0/accounts/${accountId}/ui/multiplex_sandboxes/${sandbox.sandbox_id}?applyChanges=${applyChanges}`;
        const muxJson = JSON.stringify(muxObject);
        return this.deleteObject(url, true, muxJson);
    }

    async updateSandboxMultiplex(accountId, muxObject, sandbox) {
        const url = `v1.0/accounts/${accountId}/ui/multiplex_sandboxes/${sandbox.sandbox_id}`;
        const muxJson = JSON.stringify(muxObject);
        return this.patchObject(url, muxJson);
    }

    async touchSandboxMultiplex(accountId, sandboxId) {
        const url = `v1.0/accounts/${accountId}/ui/multiplex_sandboxes/${sandboxId}`;
        return this.updateObject(url, null);
    }

    async listMultiplexes(accountId) {
        const url = `v2/accounts/${accountId}/multiplexes`;

        return await this.getObject(url);
    }

    async getMultiplex(accountId, muxId) {
        const url = `v2/accounts/${accountId}/multiplexes/${muxId}`;

        let response = await this.getObject(url);
        return response;
    }

    async updateMultiplex(accountId, muxId, update) {
        const url = `v2/accounts/${accountId}/multiplexes/${muxId}`;

        const json = JSON.stringify(update);

        return this.patchObject(url, json);
    }

    async deleteMultiplex(accountId, muxId) {
        const url = `v2/accounts/${accountId}/multiplexes/${muxId}`;
        return this.deleteObject(url);
    }

    // StatmuxPools
    async listStatmuxPools(accountId) {
        const url = `v1.0/accounts/${accountId}/statmux-pools`;

        return await this.getObject(url);
    }

    async getStatmuxPool(accountId, statmuxPoolId) {
        const url = `v1.0/accounts/${accountId}/statmux-pools/${statmuxPoolId}`;

        let response = await this.getObject(url);
        return response;
    }

    async createStatmuxPool(accountId, statmuxPoolObject) {
        // hack
        delete statmuxPoolObject['id'];
        // /hack
        const url = `v1.0/accounts/${accountId}/statmux-pools`;
        const statmuxPoolJson = JSON.stringify(statmuxPoolObject);
        return this.createObject(url, statmuxPoolJson);
    }

    async updateStatmuxPool(accountId, statmuxPoolId, statmuxPoolObject) {
        // hack
        delete statmuxPoolObject['id'];
        // /hack
        const url = `v1.0/accounts/${accountId}/statmux-pools/${statmuxPoolId}`;
        const statmuxPoolJson = JSON.stringify(statmuxPoolObject);
        return this.patchObject(url, statmuxPoolJson);
    }

    async deleteStatmuxPool(accountId, statmuxPoolId) {
        const url = `v1.0/accounts/${accountId}/statmux-pools/${statmuxPoolId}`;
        return this.deleteObject(url);
    }


    // Clouds
    async listClouds(accountId) {
        const url = `v1.0/accounts/${accountId}/clouds/`;

        return await this.getObject(url);
    }

    async getCloud(accountId, cloudId) {
        const url = `v1.0/accounts/${accountId}/clouds/${cloudId}/`;

        return await this.getObject(url);
    }

    // Deployments
    async listDeployments(accountId) {
        const url = `v1.0/accounts/${accountId}/deployments`;

        return await this.getObject(url);
    }

    async getDeployment(accountId, deploymentId) {
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}`;

        return await this.getObject(url);
    }

    async startDeployment(accountId, deploymentId) {
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}`;

        const json = JSON.stringify({ status: 'Deployed' });
        return this.patchObject(url, json);
    }

    async stopDeployment(accountId, deploymentId) {
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}`;

        const json = JSON.stringify({ status: 'Stopped' });
        return this.patchObject(url, json);
    }

    async createDeployment(accountId, deploymentId, type, cloud, region, zone1, zone2, zone3, zone1WorkerNodePool, zone2WorkerNodePool, zone3WorkerNodePool, version) {
        const url = `v1.0/accounts/${accountId}/deployments`;
        const deploymentJson = JSON.stringify({
            id: deploymentId,
            type: type,
            region: region,
            zone1: zone1,
            zone2: zone2,
            zone3: zone3,
            zone1WorkerNodePool: zone1WorkerNodePool,
            zone2WorkerNodePool: zone2WorkerNodePool,
            zone3WorkerNodePool: zone3WorkerNodePool,
            cloud: cloud,
            version: version });
        return this.createObject(url, deploymentJson, {}, false);
    }

    async deleteDeployment(accountId, deploymentId) {
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}`;
        return this.deleteObject(url);
    }

    async patchDeployment(accountId, changes, deploymentId) {
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}`;
        const sourceJson = JSON.stringify(changes);
        return this.patchObject(url, sourceJson);
    }

    async uploadDeployment(accountId, deploymentFile) {
        const url = `v1.0/accounts/${accountId}/deployments`;

        const formData = new FormData();
        formData.append('deploymentFile', deploymentFile);

        // mustn't set the Content-Type header
        return await this.baseFetch(url, {
            method: 'POST',
            headers: {
                name: deploymentFile.name
            },
            body: formData,
            setContentType: false
        });
    }

    // Clusters
    async listClusters(accountId, deploymentId) {
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}/clusters`;

        return await this.getObject(url);
    }

    // Stub wrappers
    async startCluster(accountId, deploymentId, clusterId) {
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}/clusters/${clusterId}`;

        const json = JSON.stringify({ 'status': 'Deployed' });
        return this.patchObject(url, json);
    }

    async stopCluster(accountId, deploymentId, clusterId) {
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}/clusters/${clusterId}`;

        const json = JSON.stringify({ 'status': 'Stopped' });
        return this.patchObject(url, json);
    }

    async upgradeCluster(accountId, deploymentId, clusterId, version) {
        console.log(`Would have upgraded cluster ${accountId}  ${clusterId}  ${version} `);
        const url = `v1.0/accounts/${accountId}/deployments/${deploymentId}/clusters/${clusterId}`;

        const json = JSON.stringify({ 'version': `${version}` });
        return this.patchObject(url, json);
    }

    // Regions
    async getAccountRegions(accountId) {
        const url = `v1.0/accounts/${accountId}/regions`;
        return await this.getObject(url);
    }

    // Templates
    async listTemplates(accountId, channelType) {
        const channelTypeUrl = channelType ? '?type=' + channelType : '';

        const url = `v1.0/accounts/${accountId}/templates${channelTypeUrl}`;
        return await this.getObject(url);
    }

    // UITemplates
    async listTemplatesUI(accountId, fieldsList) {
        const fieldsListUrl = fieldsList ? '?fields=' + fieldsList.join(',') : '';

        const url = `v1.0/accounts/${accountId}/ui/templates${fieldsListUrl}`;
        return await this.getObject(url);
    }

    async listTemplateRevisions(accountId, templateId) {
        // Uncomment URL when API Endpoint is done
        const templateLogUrl = `v1.0/accounts/${accountId}/templates/${templateId}/log`;

        return await this.getObject(templateLogUrl);
    }

    async getTemplate(accountId, templateId, revision = undefined) {
        const revisionQuery = revision ? `?revision=${revision}` : '';
        const url = `v1.0/accounts/${accountId}/templates/${templateId}${revisionQuery}`;
        return await this.getMultipart(url);
    }

    async updateTemplate(accountId, templateId, updatedTemplate) {
        const url = `v1.0/accounts/${accountId}/templates/${templateId}`;

        const formData = new FormData();
        formData.append('comment', updatedTemplate.comment);

        for (let file in updatedTemplate.templateFiles) {
            formData.append(updatedTemplate.templateFiles[file].name, updatedTemplate.templateFiles[file], updatedTemplate.templateFiles[file].name);
        }

        // mustn't set the Content-Type header
        return await this.baseFetch(url, {
            method: 'PUT',
            body: formData,
            setContentType: false
        });
    }

    async createTemplate(accountId, templateObject) {
        const url = `v1.0/accounts/${accountId}/templates`;

        const formData = new FormData();
        formData.append('comment', templateObject.comment);

        for (let file in templateObject.templateFiles) {
            formData.append(templateObject.templateFiles[file].name, templateObject.templateFiles[file], templateObject.templateFiles[file].name);
        }

        // mustn't set the Content-Type header
        return await this.baseFetch(url, {
            method: 'POST',
            body: formData,
            setContentType: false
        });
    }

    async deleteTemplate(accountId, templateId) {
        const url = `v1.0/accounts/${accountId}/templates/${templateId}`;
        return this.deleteObject(url);
    }

    async createFeed(accountId, feedObject) {
        const url = `v1.0/accounts/${accountId}/feeds`;
        delete feedObject['id'];
        const feedJson = JSON.stringify(feedObject);
        return this.createObject(url, feedJson);
    }

    async editFeed(accountId, feedObject) {
        delete feedObject['status'];
        for (let index in feedObject.config.routes) {
            const route = feedObject.config.routes[index];
            if (route.programNumber == '') {
                delete route.programNumber;
            }
        }

        const url = `v1.0/accounts/${accountId}/feeds/${feedObject.id}`;
        const feedJson = JSON.stringify(feedObject);
        return this.patchObject(url, feedJson);
    }

    async listFeeds(accountId) {
        const url = `v1.0/accounts/${accountId}/feeds?includeSourceNames`;
        let obj = await this.getObject(url);
        return obj;
    }

    async deleteFeed(accountId, feedId) {
        const url = `v1.0/accounts/${accountId}/feeds/${feedId}`;
        return this.deleteObject(url);
    }

    async listFeedsForMediaService(accountId, mediaService) {
        const url = `v1.0/accounts/${accountId}/feeds?mediaService=${mediaService}&includeSourceNames`;
        let obj = await this.getObject(url);
        return obj;
    }

    // Sources - for the different forms (where only source name + id is necessary
    async listSources(accountId, fieldsList) {
        const fieldsListUrl = fieldsList ? '?fields=' + fieldsList.join(',') : '';
        const url = `v1.0/accounts/${accountId}/sources${fieldsListUrl}`;

        let obj = await this.getObject(url);

        for (let index in obj.data) {
            const source = obj.data[index];
            if (source.ipAddresses && source.ipAddresses.length > 0) {
                source.ipType = source.ipAddresses[0].type;
            }
        }

        return obj;
    }

    // UISources - for the list view
    async listSourcesUI(accountId) {
        const url = `v1.0/accounts/${accountId}/ui/sources`;
        let obj = await this.getObject(url);

        for (let index in obj.data) {
            const source = obj.data[index];
            if (source.ipAddresses && source.ipAddresses.length > 0) {
                source.ipType = source.ipAddresses[0].type;
            }
        }

        return obj;
    }

    async createSource(accountId, sourceObject) {
        // cleanup non srt additions, API will reject additional properties
        const keepSrt = ['srtListener', 'srtCaller'];
        // preserve the input object
        const copyOfSource = JSON.parse(JSON.stringify(sourceObject));

        if (!keepSrt.includes(copyOfSource.inputMode)) {
            copyOfSource.properties = {};
        }

        const url = `v1.0/accounts/${accountId}/sources`;
        const sourceJson = JSON.stringify(copyOfSource);
        return this.createObject(url, sourceJson, {}, false);
    }

    async editSource(accountId, sourceObject) {
        if ('usedBy' in sourceObject)
            delete sourceObject['usedBy'];
        const url = `v1.0/accounts/${accountId}/sources/${sourceObject.id}`;
        let payload = {
            name: sourceObject.name,
            inputMode: sourceObject.inputMode,
            region: sourceObject.region,
            properties: {
                srtPassphrase: sourceObject.srtPassphrase,
                srtAESEncryptionType: sourceObject.srtAESEncryptionType
            },
            ipAddresses: [{
                ipAddress: sourceObject.ipAddress,
                port: sourceObject.port,
                ssmSourceIpAddress: sourceObject.ssmSourceIpAddress,
            }]
        };
        if (sourceObject.properties.channelUseMode != 'default') {
            payload.properties.channelUseMode = sourceObject.properties.channelUseMode;
        }
        const sourceJson = JSON.stringify(payload);
        return this.patchObject(url, sourceJson);
    }

    async patchSource(accountId, changes, sourceId) {
        const url = `v1.0/accounts/${accountId}/sources/${sourceId}`;
        const sourceJson = JSON.stringify(changes);
        return this.patchObject(url, sourceJson);
    }

    async deleteSource(accountId, sourceId) {
        const url = `v1.0/accounts/${accountId}/sources/${sourceId}`;
        return this.deleteObject(url);
    }

    async deleteChannel(accountId, channelId) {
        const url = `v1.0/accounts/${accountId}/channels/${channelId}`;
        return this.deleteObject(url);
    }

    // Whitelist Groups
    async listWhitelistGroups(accountId) {
        const url = `v1.0/accounts/${accountId}/whitelistgroups`;
        return await this.getObject(url);
    }

    async createWhitelistGroup(accountId, whitelistObject) {
        const url = `v1.0/accounts/${accountId}/whitelistgroups`;
        const whitelistJson = JSON.stringify(whitelistObject);
        return this.createObject(url, whitelistJson);
    }

    async getWhitelistGroup(accountId, whitelistId) {
        const url = `v1.0/accounts/${accountId}/whitelistgroups/${whitelistId}`;
        return this.getObject(url);
    }

    async deleteWhitelistGroup(accountId, whitelistId) {
        const url = `v1.0/accounts/${accountId}/whitelistgroups/${whitelistId}`;
        return this.deleteObject(url);
    }

    async updateWhitelistGroup(accountId, whitelistGroupId, newData) {
        const url = `v1.0/accounts/${accountId}/whitelistgroups/${whitelistGroupId}`;
        const json = JSON.stringify(newData);
        return this.updateObject(url, json);
    }

    // Permissions
    async getPermissions(accountId) {
        const url = `v1.0/accounts/${accountId}/permissions`;
        return await this.getObject(url, false);
    }

    // Entitlements
    async getEntitlements(accountId) {
        const url = `v1.0/accounts/${accountId}/entitlements`;
        return this.getObject(url);
    }

    // Alerts
    async listAlerts(accountId) {
        const url = `v1.0/accounts/${accountId}/alerts/active`;
        return this.getObject(url);
    }

    // Notifications
    async listNotifications(accountId, queryList) {
        const queryListUrl = queryList ? '?' + queryList.join(',') : '';

        const url = `v1.0/accounts/${accountId}/notifications${queryListUrl}`;
        return this.getObject(url);
    }

    // DVRs
    async listDvrs(accountId) {
        const url = `v1.0/accounts/${accountId}/dvrs`;
        return await this.getObject(url);
    }

    async getDvr(accountId, dvrId) {
        const url = `v1.0/accounts/${accountId}/dvrs/${dvrId}`;
        return await this.getObject(url);
    }

    async createDvr(accountId, dvr) {
        const url = `v1.0/accounts/${accountId}/dvrs`;
        const json = JSON.stringify(dvr);
        return this.createObject(url, json);
    }

    async updateDvr(accountId, dvrId, dvr) {
        const url = `v1.0/accounts/${accountId}/dvrs/${dvrId}`;
        const json = JSON.stringify(dvr);
        return this.updateObject(url, json);
    }

    async deleteDvr(accountId, dvrId) {
        const url = `v1.0/accounts/${accountId}/dvrs/${dvrId}`;
        return this.deleteObject(url);
    }

    async startDvr(accountId, dvrId, instanceId) {
        const url = `v1.0/accounts/${accountId}/dvrs/${dvrId}/start`;
        const json = instanceId != null ? JSON.stringify({ dvrInstanceId: instanceId }) : JSON.stringify({});
        return this.createObject(url, json);
    }

    async stopDvr(accountId, dvrId, instanceId) {
        const url = `v1.0/accounts/${accountId}/dvrs/${dvrId}/stop`;
        const json = instanceId != null ? JSON.stringify({ dvrInstanceId: instanceId }) : JSON.stringify({});
        return this.createObject(url, json);
    }

    async switchInput(accountId, record, instanceId) {
        const switchUrl = `v1.0/accounts/${accountId}/channels/${record}/instances/${instanceId}/switchActiveSource`;
        try {
            const response = await fetch(this.serverUrl + switchUrl, { method: 'PUT' });

            return response;
        } catch (ex) {
            console.log(ex);
        }
    }

    async setChannelSuppressionMode(accountId, channelId, instanceId, newSuppressionMode) {

        const channelUpdateUrl = `v1.0/accounts/${accountId}/channels/${channelId}`;

        const channelUpdateObject = {
            id: channelId,
            instances: [{
                id: instanceId,
                outputSuppressionMode: newSuppressionMode
            }]
        };

        try {
            const updateJson = JSON.stringify(channelUpdateObject);
            const response = this.patchObject(channelUpdateUrl, updateJson);

            return response;
        } catch (ex) {
            console.log(ex);
        }
    }

    markOperation() {
        if (this.saasHubApi) {
            this.saasHubApi.markOperation();
        }
    }

    // Credentials
    async listCredentials(accountId) {
        const url = `v1.0/accounts/${accountId}/credentials`;
        return await this.getObject(url);
    }
}

const SolutionApiContext = React.createContext(null);

export {
    SolutionApi,
    SolutionApiContext,
};

export default SolutionApi;
