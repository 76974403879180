import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useState, useCallback } from 'react';
import ChannelForm from './ChannelForm';
import { useEffect } from 'react';

export default function EditChannelModal(props) {

    const {
        visible = false,
        channelData,
        onConfirm = () => {},
        onCancel = () => {},
        errors = null,
        editEnabled = true
    } = props;

    const [form] = Form.useForm();

    const [channelEdit, updateChannelEdit] = useState({});

    const doConfirm = useCallback(async () => {
        // Pass true to obtain all values, whether mounted or not.
        onConfirm(form.getFieldsValue(true));
    }, [form, onConfirm]);

    const [okToPost, updateOkToPost] = useState(true);
    const updateValid = useCallback((isValid) => {
        updateOkToPost(isValid);
    }, [updateOkToPost]);

    const doCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    useEffect(() => {
        updateChannelEdit({ ...channelData });
    }, [channelData]);

    if (!editEnabled) {
        return (
            <Modal
                title="View Channel"
                width={'1069px'}
                open={visible}
                className='hook-channels-editchannel-menu'
                onCancel={doCancel}
                cancelText='Close'
                cancelButtonProps={{ className: 'hook-channels-channelconfig-editchannelcancel' }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <ChannelForm channel={channelEdit} form={form} errors={errors} editEnabled={false} />
            </Modal>
        );
    } else {
        return (
            <Modal
                title="Edit Channel"
                width={'1069px'}
                open={visible}
                className='hook-channels-editchannel-menu'
                onOk={doConfirm}
                okText='Save changes'
                okButtonProps={{ disabled: !okToPost, className: 'hook-channels-channelconfig-editchannelconfirm' }}
                onCancel={doCancel}
                cancelButtonProps={{ className: 'hook-channels-channelconfig-editchannelcancel' }}
            >
                <ChannelForm channel={channelEdit} form={form} errors={errors} editEnabled={true} updateValid={updateValid}/>
            </Modal>
        );
    }
}

EditChannelModal.propTypes = {
    channelData: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    editEnabled: PropTypes.bool,
    errors:PropTypes.object
};
