import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useState, useCallback } from 'react';
import DvrForm from './DvrForm';

const emptyDvr = {
    name: 'NewDvr',
    template: 'default',
    location: null,
    sourceUrl: '',
    storageUrl: '',
    encryptedSource: false,
    protectionKeyServerUrl: null,
    protectionContentId: null
};

export default function NewDvrModal(props) {

    const {
        visible = false,
        onConfirm = () => {},
        onCancel = () => {},
        errors = null
    } = props;

    const [form] = Form.useForm();

    const [okToPost, updateOkToPost] = useState(false);

    const updateValid = useCallback((isValid) => {
        updateOkToPost(isValid);
    }, [updateOkToPost]);

    const [dvr, updateDvr] = useState({ ...emptyDvr });

    const resetDvr = useCallback(() => {
        updateDvr({ ...emptyDvr });
        updateValid(false);
    }, [updateValid]);

    const doConfirm = useCallback(async () => {
        form.validateFields().then(async values => {
            if (await onConfirm(values)) {
                resetDvr();
            }
        }).catch(err => {console.log('err', err);});
    }, [onConfirm, form, resetDvr]);

    const doCancel = useCallback(() => {
        onCancel();
        resetDvr();
    }, [onCancel, resetDvr]);

    return (
        <Modal
            title="New DVR"
            open={visible}
            className='hook-dvrs-newdvr-menu'
            onOk={doConfirm}
            okText='Add DVR'
            okButtonProps={{ disabled: !okToPost, className: 'hook-dvrs-dvrconfig-adddvr' }}
            onCancel={doCancel}
            cancelButtonProps={{ className: 'hook-dvrs-dvrconfig-canceladddvr' }}
        >
            <DvrForm dvr={dvr} form={form} errors={errors} updateValid={updateValid}/>
        </Modal>
    );
}

NewDvrModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    errors:PropTypes.object
};
