
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission } from '../../services';
import { Button, Row, Table, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faVideo, faVideoSlash, faRandom, faEllipsisH, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getInstanceStatus, getInstanceIcon } from './ChannelStates';
import * as STATE from './ChannelStates';

const CAN_START_CHANNEL = 'channels.start';
const CAN_STOP_CHANNEL = 'channels.stop';
export default function SingleChannelTable({ channel, activeAlerts, enabledControls = null, showActions }) {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;
    const permissionsMap = useContext(PermissionsContext);
    const canStartChannel = testPermission(CAN_START_CHANNEL, permissionsMap);
    const canStopChannel = testPermission(CAN_STOP_CHANNEL, permissionsMap);

    const [loading, updateLoading] = useState(true);

    const [tableData, updateTableData] = useState(null);

    let [source1, setSource] = useState({});

    const refreshChannel = useCallback(async (channel) => {
        if (channel != null) {
            updateTableData(channel.status.instances);

            updateLoading(false);
        }

    }, []);

    const isInstanceSourceSwitching = useCallback((record) => {

        const currentRequestedSource = (record.id in source1) ? source1[record.id] : record.activeSource;
        const currentSource = record.activeSource;

        const switching = currentRequestedSource !== currentSource;

        return switching;
    },[source1]);

    const toggleSource = useCallback(async (record, instanceIndex) => {

        const nextSource = (record.activeSource === 0) ? 1 : 0;

        let response = await solutionApi.switchInput(accountId, channel.id, instanceIndex);

        if (response.ok) {
            setSource((prev) => {
                prev[record.id] = nextSource;
                return { ...prev };
            });
        }

        return response;
    }, [solutionApi, setSource, accountId, channel.id]);

    useEffect(() => {
        refreshChannel(channel);

        let instances = channel.status.instances;
        let updateRequired = false;
        for (let i = 0; i < instances.length; i++){
            let record = instances[i];
            const switching = isInstanceSourceSwitching(record);
            if (!switching && (record.id in source1)) {
                delete source1[record.id];
                updateRequired = true;
            }
        }
        if (updateRequired) {
            setSource((prev) => {
                return { ...prev };
            });
        }

    }, [refreshChannel, channel, isInstanceSourceSwitching, source1]);

    const startChannel = useCallback(async (instanceId) => {
        try {
            await solutionApi.startChannel(accountId, channel.id, instanceId);
        } catch (exception) {
            console.log('problems');
        }
    },[solutionApi, accountId, channel]);

    const stopChannel = useCallback((instanceId) => {
        solutionApi.stopChannel(accountId, channel.id, instanceId);
    }, [solutionApi, accountId, channel]);

    // Get source info per instance for main or backup sources
    const updateSource = useCallback((record, mainOrBackup) => {
        let source = null;
        let active = null;

        for (let instance in channel.instances){
            if (channel.instances[instance].id == record.id) {
                if(mainOrBackup == 'main') {
                    source = channel.instances[instance].sources[0];
                    active = channel.status.instances[instance].activeSource === 0 ? true : false;
                } else if (channel.instances[instance].sources[1])  {
                    source = channel.instances[instance].sources[1];
                    active = channel.status.instances[instance].activeSource === 1 ? true : false;
                }
            }
        }
        return { source, active };
    }, [channel]);

    // Check if a specific source is receiving input by passing the source ID and activeAlerts into the function
    const sourceReceiving = useCallback((sourceId, activeAlerts) => {

        const channelAlerts = activeAlerts[channel.id];

        for (let i = 0; i < channelAlerts.alerts.length; i++){
            if (channelAlerts.alerts[i].code === 'MediakindSourceSrtNotReceiving' && sourceId === channelAlerts.alerts[i].fields.sourceId) {
                return false;
            }
        }
        return true;
    }, [channel]);

    const source = useCallback((record) => {
        let content = <FontAwesomeIcon key='loading' icon={faEllipsisH} pulse />;
        const channelStarted = record.state === 'started';

        // Get main source and check if it is active (per instance)
        let { source, active } = updateSource(record, 'main');

        if (source) {
            let mainSource = source.main;
            content = (channelStarted ?
                <Row align='middle' >
                    <span className={ 'source-active' }>
                        <FontAwesomeIcon size={'lg'} icon={ faExclamationTriangle } />
                    </span>
                    <Tooltip title={ active ? 'Input Active' : 'Input Inactive' }>
                        <span className={ active ? 'input-active' : 'input-inactive' }> {mainSource} </span>
                    </Tooltip>
                </Row> : mainSource);

            if (activeAlerts != null && channelStarted) {
                if (activeAlerts[channel.id]) {
                    content = <Row align='middle' >
                        <span className={ sourceReceiving(source.sourceId, activeAlerts) === true ? 'source-active' : 'source-inactive' }>
                            <FontAwesomeIcon size={'lg'} icon={ faExclamationTriangle } />
                        </span>
                        <Tooltip title={ active ? 'Input Active' : 'Input Inactive' }>
                            <span className={ active ? 'input-active' : 'input-inactive' }> {mainSource} </span>
                        </Tooltip>
                    </Row>;
                }
            }
        }
        return content;
    }, [channel, activeAlerts, sourceReceiving, updateSource]);

    const backupSource = useCallback((record) => {
        let content = '<not used>';
        const channelStarted = record.state === 'started';

        // Get backup source and check if it is active (per instance)
        let { source, active } = updateSource(record, 'backup');

        if (source) {
            let backupSource =  source.backup;
            content = (channelStarted ?
                <Row align='middle' >
                    <span className={ 'source-active' }>
                        <FontAwesomeIcon size={'lg'} icon={ faExclamationTriangle } />
                    </span>
                    <Tooltip title={ active ? 'Input Active' : 'Input Inactive' }>
                        <span className={ active ? 'input-active' : 'input-inactive' }> {backupSource} </span>
                    </Tooltip>
                </Row> : backupSource);

            if (activeAlerts != null && channelStarted) {
                if (activeAlerts[channel.id]) {
                    content = <Row align='middle' >
                        <span className={ sourceReceiving(source.sourceId, activeAlerts) === true ? 'source-active' : 'source-inactive' }>
                            <FontAwesomeIcon size={'lg'} icon={ faExclamationTriangle } />
                        </span>
                        <Tooltip title={ active ? 'Input Active' : 'Input Inactive' }>
                            <span className={ active ? 'input-active' : 'input-inactive' }> {backupSource} </span>
                        </Tooltip>
                    </Row>;
                }
            }
        }
        return content;
    }, [channel, activeAlerts, sourceReceiving, updateSource]);

    const columns = [
        {
            title: 'Deployment',
            dataIndex: 'region',
            key: 'region',
            className: 'hook-channels-advancedchannelcontrol-regionname',
            render: (...[, record, ]) => {
                if (record.region !== null &&  record.region !== ''){
                    return record.region;
                } else {
                    return <Tooltip title={'Channel must have started at least once for a region to be set'}>{'<Not set>'}</Tooltip>;
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'state',
            key: 'state',
            className: 'hook-channels-advancedchannelcontrol-state',
            // eslint-disable-next-line react/display-name
            render: (...[, record, ]) => {
                return getInstanceStatus(record.state);
            }
        },
        {
            title: 'Output Mode',
            key: 'outputMode',
            className: 'hook-channels-advancedchannelcontrol-outputmode',
            // eslint-disable-next-line react/display-name
            render: (...[, , instanceIndex]) => {

                let outputMode = '';
                function capitalizeFirstLetter(string) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                }
                if (channel && channel.instances[instanceIndex]) {
                    outputMode = capitalizeFirstLetter(channel.instances[instanceIndex].outputSuppressionMode);
                }
                return outputMode;
            }
        },
        {
            title: 'Source',
            key: 'source',
            className: 'hook-channels-channeltable-source',
            // eslint-disable-next-line react/display-name
            render: (...[, record, ]) => {
                return source(record);
            }
        },
        {
            title: 'Template',
            key: 'template',
            className: 'hook-channels-channeltable-template',
            // eslint-disable-next-line react/display-name
            render: () => {

                let template = channel.template;

                return template;
            }
        },
        {
            title: 'Template Revision',
            key: 'templateRevision',
            className: 'hook-channels-channeltable-template-revision',
            // eslint-disable-next-line react/display-name
            render: (...[, record, instanceIndex ]) => {

                let templateRevision = '';

                if (record.state == 'stopped') {
                    templateRevision = '<Not Set>';
                } else {
                    if (channel && channel.status.instances[instanceIndex]) {
                        templateRevision = channel.status.instances[instanceIndex].templateRevision;
                    }
                }

                return templateRevision;
            }
        },
        {
            title: 'Backup Source',
            key: 'backup source',
            className: 'hook-channels-channeltable-backupsource',
            // eslint-disable-next-line react/display-name
            render: (...[, record, ]) => {
                return backupSource(record);
            }
        }
    ];

    const actionColumn = {
        title: 'Actions',
        key: 'actions',
        className: 'hook-channels-advancedchannelcontrol-actions',
        // eslint-disable-next-line react/display-name
        render: (...[, record, instanceIndex]) => {
            let startButtonText = '';
            let switchButonText = '';
            let clickHandler = null;
            let nextMode = 'allow';
            let noBackupSource = null;

            const thisSourceTransitioning = isInstanceSourceSwitching(record);

            if (channel.instances[instanceIndex]) {
                noBackupSource = channel.instances[instanceIndex].sources.length < 2;
            }
            async function switchInput() {
                toggleSource(record, instanceIndex);
            }

            const startStopProps = {
                shape: 'circle'
            };
            const switchProps = {
                shape: 'circle',
                icon: thisSourceTransitioning ? <FontAwesomeIcon icon={faSpinner} pulse /> : <FontAwesomeIcon icon={faRandom} />,
                onClick: switchInput
            };

            if (channel && channel.instances[instanceIndex]) {
                const currentMode = channel.instances[instanceIndex].outputSuppressionMode;
                nextMode = currentMode === 'suppress' ? 'allow' : 'suppress';
                clickHandler = ()=>{ solutionApi.setChannelSuppressionMode(accountId, channel.id, channel.instances[instanceIndex].id, nextMode); };
            }
            let suppressOutputText = 'Output suppression';
            const suppressOutputProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={nextMode === 'suppress' ? faVideoSlash : faVideo}/>,
                disabled: !enabledControls,
                onClick: clickHandler
            };

            switch (record.state) {
            case STATE.STOPPED:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.state)}/>;
                startStopProps.onClick = ()=>{ startChannel(record.id); };
                startStopProps.disabled = !enabledControls;
                startButtonText = 'Activate channel instance';
                switchProps.disabled = true;
                switchButonText = 'Must be in Active state to switch';
                suppressOutputText = 'Must be in Active state for output suppression';

                if (!canStartChannel) {
                    startButtonText = 'You lack the required permissions to start a channel';
                    startStopProps.disabled = true;
                }

                break;

            case STATE.START_REQUESTED:
            case STATE.STARTING:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.state)} pulse/>;
                startStopProps.disabled = true;
                startButtonText = 'Already Activating';
                switchProps.disabled = true;
                switchButonText = 'Must be in Active state to switch';
                break;

            case STATE.STARTED:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.state)}/>;
                startStopProps.disabled = !enabledControls;
                startStopProps.onClick = ()=>{ stopChannel(record.id); };
                startButtonText = 'Deactivate channel instance';
                if (!enabledControls) {
                    switchProps.disabled = true;
                    switchButonText = 'Enable advanced controls to switch';
                } else if (thisSourceTransitioning) {
                    switchProps.disabled = true;
                    switchButonText = 'Disabled while switching';
                } else if (noBackupSource) {
                    switchProps.disabled = true;
                    switchButonText = 'No backup source';
                }
                switchButonText = 'Switch source for this instance';
                suppressOutputText = 'Output suppression';
                break;

            case STATE.START_ERROR:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.state)}/>;
                startStopProps.disabled = !enabledControls;
                startStopProps.onClick = ()=>{ stopChannel(record.id); };
                startButtonText = 'Deactivate channel instance';
                switchProps.disabled = true;
                switchButonText = 'Must be in Active state to switch';

                if (!canStopChannel) {
                    startButtonText = 'You lack the required permissions to stop a channel';
                    startStopProps.disabled = true;
                }
                break;

            case STATE.STOP_REQUESTED:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.state)}/>;
                startStopProps.disabled = true;
                startButtonText = 'Already Deactivating';
                switchProps.disabled = true;
                switchButonText = 'Must be in Active state to switch';
                break;

            case STATE.STOPPING:
                startStopProps.icon = <FontAwesomeIcon icon={getInstanceIcon(record.state)} pulse/>;
                startStopProps.disabled = true;
                startButtonText = 'Already Deactivating';
                switchProps.disabled = true;
                switchButonText = 'Must be in Active state to switch';
                break;
            default:
                break;
            }

            return <>
                <Tooltip title={startButtonText}>
                    <Button className='hook-channels-advancedchannelcontrol-startstopbutton' { ...startStopProps } />
                </Tooltip>
                <Tooltip title={suppressOutputText}>
                    <Button className='hook-channels-advancedchannelcontrol-suppressoutputbutton' { ...suppressOutputProps } />
                </Tooltip>
                <Tooltip title={switchButonText}>
                    <Button className='hook-channels-channeltable-switchsource' { ...switchProps } />
                </Tooltip>
            </>;
        }
    };

    if (showActions) {
        columns.push(actionColumn);
    }

    return (<Table className='hook-channels-advancedchannelcontrol-actiontable'
        size='middle'
        loading={{
            spinning: loading,
            indicator: <FontAwesomeIcon icon={faSpinner} pulse />
        }}
        dataSource={tableData}
        columns={columns}
        rowKey='id'
        pagination={{ defaultPageSize: 2, hideOnSinglePage: true }}
    />);


}

SingleChannelTable.propTypes = {
    channel: PropTypes.object,
    activeAlerts: PropTypes.object,
    enabledControls: PropTypes.bool,
    showActions: PropTypes.bool
};
