import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export default function Ticker({ timeout, asyncCallback, immediate }) {
    const [timerContainer, updateTimerContainer] = useState({   // Needs to be an object as used in closure
        quitting: false,
        timerId: null
    });

    const tick = useCallback(async () => {
        const quitting = timerContainer.quitting;

        if (!quitting) {
            await asyncCallback();

            if (!timerContainer.quitting) {
                const newTimerId = setTimeout(tick, timeout);
                timerContainer.timerId = newTimerId;
            }
        }
    // specifically don't want the callback recreated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[asyncCallback, timeout]);

    const cleanupTimer = useCallback((timerContainer) => {
        timerContainer.quitting = true;
        if (timerContainer.timerId != null) {
            clearTimeout(cleanupTimer);
        }
    }, []);

    useEffect(() => {
        async function run() {
            if (immediate) {
                await asyncCallback();
            }

            const newTimerId = setTimeout(tick, timeout);
            const newTimerContainer = { quitting: false, timerId: newTimerId };
            updateTimerContainer(newTimerContainer);
        }

        run();

        return () => {cleanupTimer(timerContainer);};
        // avoid loop when recreating timerContainer
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ immediate, tick, timeout, cleanupTimer]);

    return null;
}

Ticker.propTypes = {
    timeout: PropTypes.number,
    asyncCallback: PropTypes.func,
    immediate: PropTypes.bool
};
