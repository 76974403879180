import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import SaasApi, { SaasApiContext } from './services/SaasApi';
import SolutionApi, { SolutionApiContext } from './services/SolutionApi';
import CiMode, { CiModeContext } from './services/CiMode';

if (typeof process.env.REACT_APP_VERSION !== 'undefined') {
    // eslint-disable-next-line no-undef
    console.log('Frontend version: ' + process.env.REACT_APP_VERSION);
}

fetch(process.env.PUBLIC_URL + '/config/frontend-config.json')
    .then((response) => response.json())
    .then(function (config) {

        const serviceApi = new SaasApi(config);
        const ciMode = new CiMode(config);

        const relativeForwardPath = window.location.pathname;
        const redirSolution = window.location.href.match(/\/\/[^.]+/gi)[0].substr(2);

        const authFailureRedirect = (forwardPath) => {
            // only redir-page if path is more than '/'
            const pathRedirect = forwardPath && (forwardPath.length > 1) ? `&redir-page=${forwardPath.substring(1)}` : '';

            window.location.replace(`${serviceApi.baseUrl}/?redir-solution=${redirSolution}${pathRedirect}`);
        };

        // If the user has no authorisation cookie, immediately redirect
        const authCookie = serviceApi.token;

        if (!authCookie) {
            console.log('User not authenticated, redirecting to hub for authentication');
            authFailureRedirect(relativeForwardPath);
        } else {
            serviceApi.onLogout(authFailureRedirect);
            // Redirect back to login on failure
            serviceApi.initialCookieVerification.catch(authFailureRedirect);

            const solutionApi = new SolutionApi(config, authCookie);

            serviceApi.onTokenChange.addListener(() => {
                solutionApi.bearerToken = `Bearer ${serviceApi.token}`;
            });

            solutionApi.onAuthFailureEvent.addListener(() => {
                authFailureRedirect(relativeForwardPath);
            });

            // Still need to validate cookie. Main app will enter a loading... state Until the cookie
            // has validated
            const application = <SaasApiContext.Provider value={serviceApi}>
                <SolutionApiContext.Provider value={solutionApi}><CiModeContext.Provider value={ciMode}>
                    <App/>
                </CiModeContext.Provider></SolutionApiContext.Provider>
            </SaasApiContext.Provider>;

            ReactDOM.render(application, document.getElementById('root'));
        }

    });

// .catch(window.location.replace(process.env.PUBLIC_URL + '/errors/internal.html'));
