import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Select } from 'antd';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SolutionApiContext, SelectedAccountContext } from '../services';
import { CopyPaster } from '../pages/util';
import VideoPlayer from './VideoPlayer';

export default function InlineMonitor({ scope, channelId }) {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;

    const [options, updateOptions] = useState([]);
    const [selectedProfile, updateSelectedProfile] = useState(null);
    const [copyPaste, updateCopyPaste] = useState(null);
    const [stSecret, updateStSecret] = useState('');

    const onProfileChange = (newValue) => {
        updateSelectedProfile(newValue);
    };

    const loadChannelOutputs = useCallback(async (channelId) => {
        if (channelId != null) {
            let stResponse = await solutionApi.getChannelMediaSecrets(accountId, channelId);
            if (stResponse.ok) {
                if (stResponse.data.stSecret) {
                    const newStSecret = stResponse.data.stSecret;
                    updateStSecret(newStSecret);
                }
            }
            let outputResponse = await solutionApi.getChannelOutput(accountId, channelId);
            if (outputResponse.ok) {
                const channelOutputs = outputResponse.data;
                // Add unique id for each object for ReactJS iteration during return.
                channelOutputs.forEach((item, i) => {
                    item.id = i + 1;
                });
                updateOptions(channelOutputs);
                if (channelOutputs.length > 0) {
                    updateSelectedProfile(0);
                }
            }
        } else {
            updateStSecret('');
            updateSelectedProfile(null);
        }

    }, [solutionApi, accountId]);

    useEffect(() => {
        loadChannelOutputs(channelId);
    }, [loadChannelOutputs, channelId]);

    const selectedProfileUrl = (selectedProfile != null) ? options[selectedProfile].url : '';

    let videoPlayerContent = null;
    if (selectedProfile != null) {
        if (Object.keys(options[selectedProfile].protection).length === 0 || stSecret !== '') {
            videoPlayerContent = (
                <VideoPlayer
                    key={`${channelId}/${selectedProfile}`}
                    channelId={channelId}
                    scope={scope}
                    sourceUrl={selectedProfileUrl}
                    stSecret={stSecret}
                />
            );
        } else {
            videoPlayerContent = <h2 style={{ textAlign:'center', paddingTop:'100px' }}>This output is DRM encrypted</h2>;
        }
    } else {
        console.log('No output selected');
    }

    return <>
        <Row style={{ paddingBottom: '10px' }}>
            <Col style={{ lineHeight:'30px', paddingRight: '10px' }}>Selected output profile:</Col>
            <Col>
                <Select
                    value={selectedProfile}
                    defaultValue={ (selectedProfile != null) ? selectedProfile : 'Loading....'}
                    onChange={onProfileChange}
                >
                    {
                        options.map((item, index) => {
                            return <Select.Option key={item.id} value={index}>{`${item.type}: ${item.url}`}</Select.Option>;
                        })
                    }
                </Select>
            </Col>
            <Col><CopyPaster text={copyPaste} onComplete={() => {updateCopyPaste(null);}}/><Button className='mk-link-button hook-channels-channeltable-outputtablecopy' type='link' style={{ marginLeft: '8px' }} icon={<FontAwesomeIcon icon={faCopy} />} onClick={() => updateCopyPaste(selectedProfileUrl)}/></Col>
        </Row>
        <Row>
            <Col style={{ height:'500px', width:'1000px' }}>
                {videoPlayerContent}
            </Col>
        </Row>
    </>;
}

InlineMonitor.propTypes = {
    channelId: PropTypes.string,
    scope: PropTypes.string,
};

export function ModalMonitor({ channelId, onOk, onOpenOther }) {

    const modal = (
        <Modal
            open={true}
            onOk={onOk}
            onCancel={onOk}
            footer={[
                <Button key='launch' onClick={() => {onOpenOther(channelId);}}>Open in new Window</Button>,
                <Button key='close' onClick={onOk} className='hook-channels-channeltable-monitor-close'>Close</Button>
            ]}
            centered={true}
            width="1000"
        >
            <InlineMonitor key={channelId} scope='inline' channelId={channelId} />
        </Modal>
    );

    // Don't just hide the modal, kill it to make sure the player is stopped and refreshed
    return channelId != null ? modal : null;
}

ModalMonitor.propTypes = {
    channelId: PropTypes.string,
    onOk: PropTypes.func,
    onOpenOther: PropTypes.func
};
