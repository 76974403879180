import React from 'react';

const PermissionsContext = React.createContext(null);

function testPermission(permissionName, context) {
    if (context && context.permissions) {
        return !!context.permissions[permissionName];
    }
}

function testRole(roleName, context) {
    if (context && context.roles) {
        return context.roles.indexOf(roleName) !== -1;
    }
    return false;
}

export default PermissionsContext;
export {
    testPermission,
    testRole
};
