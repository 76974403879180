import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, PageHeader, Row, Space, Table, message } from 'antd';
import { faInfoCircle, faKey } from '@fortawesome/free-solid-svg-icons';
import { SelectedAccountContext, SolutionApiContext } from '../../services';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default function Credentials() {
    const [credentials, setCredentials] = useState();
    const solutionApi = useContext(SolutionApiContext);
    const selectedAccount = useContext(SelectedAccountContext);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        async function getCreds() {
            const response = await solutionApi.listCredentials(selectedAccount.accountID);
            if (response.ok) {
                setCredentials(response.data);
            } else {
                setCredentials([]);
                messageApi.open({ type: 'error', content: 'Something went wrong.' });
            }
        }
        getCreds();
    }, [messageApi, selectedAccount.accountID, solutionApi]);

    const tableColumns = [
        {
            title: 'Time Remaining',
            dataIndex: 'expiryDate',
            render: (text) => {
                return dayjs(text).toNow(true);
            }
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiryDate',
            sorter: (a, b) => a.expiryDate.localeCompare(b.expiryDate),
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'], // Disable default sorting.
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend', 'ascend'], // Disable default sorting.
        },
        {
            title: 'Type',
            dataIndex: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            sortDirections: ['ascend', 'descend', 'ascend'], // Disable default sorting.
        },
        {
            title: 'ID',
            dataIndex: 'id'
        },
    ];

    return (
        <>
            {contextHolder}
            <Row align='middle'>
                <Col>
                    <FontAwesomeIcon icon={faKey} size='4x' />
                </Col>
                <Col>
                    <PageHeader backIcon={null} title='Credentials' />
                </Col>
            </Row>
            <Row className='gutter-bottom feeds-info-row '>
                <Col>
                    <Space>
                        <FontAwesomeIcon className='feeds-info-icon' icon={faInfoCircle} size='2x' color='blue' />
                        <span>Cloud credentials providing access to your resources.</span>
                    </Space>
                </Col>
            </Row>
            <Table
                size='middle'
                dataSource={credentials}
                columns={tableColumns}
                rowKey='id'
                scroll={{ x: true }}
                pagination={{ showSizeChanger: true }}
                loading={!credentials}
            />
        </>
    );
}
