import React, { useCallback, useState, useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Collapse, Modal, Select, Spin } from "antd";
import { Change, diffLines, diffArrays } from "diff";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
/// @ts-ignore
import { SolutionApiContext } from "../../services";

type TemplateDataType = Record<string, string>;

/**
 * Try and format the input string as a JSON string with indentation.
 *
 * @param content String that is assumed to be JSON
 * @returns Formatted JSON string or original string if any errors
 */
function tryJSONFormat(content: string): string {
    try {
        return JSON.stringify(JSON.parse(content), null, 2);
    } catch {
        return content;
    }
}

type DiffContentProps = { value: string };
const DiffRemoved = ({ value }: DiffContentProps) => <s style={{ color: "red" }}>{value}</s>;
const DiffAdded = ({ value }: DiffContentProps) => <span style={{ color: "green" }}>{value}</span>;
const DiffUnchanged = ({ value }: DiffContentProps) => <span>{value}</span>;

// no event propagate handler so the template download button click doesn't also open/close the parent Panel
const onClickNoPropagate = (event: React.MouseEvent) => {
    event.stopPropagation();
};

const FileDownloadButton = ({ fileData, fileName }: { fileData: string; fileName: string }) => {
    const downloadData = useMemo(() => {
        const file = new File([fileData], fileName, { type: "text/plain" });
        const url = URL.createObjectURL(file);
        return {
            file,
            url,
        };
    }, [fileName, fileData]);
    return (
        <Button
            href={downloadData.url}
            download={fileName}
            onClick={onClickNoPropagate}
            icon={<FontAwesomeIcon icon={faFileDownload} color="blue" />}
        />
    );
};

/**
 * Given two maps of templates where:
 *  key is the template file name
 *  value is the template content string
 * calculate which whole files were added or removed.
 * Files present in both are then diffed for content.
 *
 * @param templatesFrom
 * @param templatesTo
 */
function diffTemplateList(templatesFrom: TemplateDataType, templatesTo: TemplateDataType) {
    // Check if a whole file has been removed or added by diffing the filename keys
    const fileChanges = diffArrays(Object.keys(templatesFrom), Object.keys(templatesTo));

    let contentChanges: {
        fileName: string;
        diff: Change[];
        download: React.ReactNode;
    }[] = [];

    for (const change of fileChanges) {
        // change.added and change.removed are mutually exclusive and undefined when
        // the two sides are matching.
        // We can use this to substitute the one side of the diff with "" to mark the
        // whole content added or removed.
        // change.value will be a list of file names.
        contentChanges = contentChanges.concat(
            change.value.map((fileName) => ({
                fileName,
                diff: diffLines(
                    change.added ? "" : templatesFrom[fileName],
                    change.removed ? "" : templatesTo[fileName]
                ),
                download: (
                    <FileDownloadButton
                        fileData={change.removed ? templatesFrom[fileName] : templatesTo[fileName]}
                        fileName={fileName}
                    />
                ),
            }))
        );
    }

    return contentChanges;
}

const TemplateDiff = ({
    templateID,
    fromRevision,
    toRevision,
    fromTemplateData,
    toTemplateData,
}: {
    templateID: string;
    fromRevision: number;
    toRevision: number;
    fromTemplateData: TemplateDataType;
    toTemplateData: TemplateDataType;
}) => {
    const contentChanges = diffTemplateList(fromTemplateData, toTemplateData);

    const key = `${templateID}-${fromRevision}-${toRevision}-`;

    return (
        <Collapse>
            {contentChanges.map(({ fileName, diff, download }) => (
                <Collapse.Panel extra={download} header={fileName} key={fileName}>
                    <pre>
                        {diff.map((part, index) => {
                            if (part.removed) {
                                return <DiffRemoved key={key + index.toString()} value={part.value} />;
                            } else if (part.added) {
                                return <DiffAdded key={key + index.toString()} value={part.value} />;
                            } else {
                                return <DiffUnchanged key={key + index.toString()} value={part.value} />;
                            }
                        })}
                    </pre>
                </Collapse.Panel>
            ))}
        </Collapse>
    );
};

const TemplateDiffViewer = ({
    accountID,
    templateID,
    templateName,
    revisions,
}: {
    accountID: string;
    templateID: string;
    templateName: string;
    revisions: number[];
}) => {
    const hasMultipleRevisions = revisions.length > 1;
    const latestRevision = hasMultipleRevisions ? revisions[revisions.length - 1] : 1;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const solutionApi = useContext<any>(SolutionApiContext);

    const [fromRevision, updateFromRevision] = useState(latestRevision);
    const [toRevision, updateToRevision] = useState(latestRevision);
    const [fromTemplateData, updateFromTemplateData] = useState<TemplateDataType | null>(null);
    const [toTemplateData, updateToTemplateData] = useState<TemplateDataType | null>(null);

    const getTemplateRevisionData = useCallback(
        async (id: string, revision: number) => {
            const resp: Response = await solutionApi.getTemplate(accountID, id, revision.toString());
            const formData = await resp.formData();
            const templateData: TemplateDataType = {};
            for (const [filename, content] of formData.entries()) {
                if (filename.endsWith(".json")) {
                    templateData[filename] = tryJSONFormat(content.toString());
                } else {
                    templateData[filename] = content.toString();
                }
            }
            return templateData;
        },
        [accountID, solutionApi]
    );

    const refreshTemplateContent = useCallback(
        async (id: string, revision: number, callback: (data: TemplateDataType) => void) => {
            const revisionData = await getTemplateRevisionData(id, revision);
            callback(revisionData);
        },
        [getTemplateRevisionData]
    );

    const options = [...revisions]
        .sort((lhs, rhs) => rhs - lhs)
        .map((rev) => ({
            value: rev,
            label: rev.toString(),
        }));
    options[0].label += " (Latest)";

    useEffect(() => {
        refreshTemplateContent(templateID, fromRevision, updateFromTemplateData);
    }, [templateID, fromRevision, updateFromTemplateData, refreshTemplateContent]);

    useEffect(() => {
        refreshTemplateContent(templateID, toRevision, updateToTemplateData);
    }, [templateID, toRevision, updateToTemplateData, refreshTemplateContent]);

    const loading = fromTemplateData === null || toTemplateData === null;
    // Ballpark; should be wide enough for 3 digit revision plus the latest marker
    const selectorWidth = 120;

    return (
        <>
            <span>
                Template Name: <b>{templateName}</b>
            </span>
            <br />
            <span>
                Template ID: <b>{templateID}</b>
            </span>
            <br />
            <span>
                Compare template revisions <b>From revision:</b>{" "}
            </span>
            <Select
                value={fromRevision}
                style={{ width: selectorWidth }}
                options={options}
                onChange={updateFromRevision}
            />
            <span>
                {" "}
                <b>To revision:</b>{" "}
            </span>
            <Select value={toRevision} style={{ width: selectorWidth }} options={options} onChange={updateToRevision} />
            {loading ? (
                <Spin tip="Loading..." size="large" style={{ marginTop: "16px" }} />
            ) : (
                <TemplateDiff
                    templateID={templateID}
                    fromRevision={fromRevision}
                    toRevision={toRevision}
                    fromTemplateData={fromTemplateData}
                    toTemplateData={toTemplateData}
                />
            )}
        </>
    );
};

export default function TemplateViewModal({
    accountID,
    record,
    onOk,
}: {
    accountID: string;
    record: {
        id: string;
        name: string;
        revisions: number[];
    };
    onOk: () => void;
}) {
    return (
        <Modal
            title={"Template Viewer"}
            open={record != null}
            closable={true}
            width={1000}
            onCancel={() => onOk()}
            cancelText="Close"
            okButtonProps={{ style: { display: "none" } }}
        >
            {record ? (
                <TemplateDiffViewer
                    key={record.id}
                    accountID={accountID}
                    templateID={record.id}
                    templateName={record.name}
                    revisions={record.revisions}
                />
            ) : null}
        </Modal>
    );
}

TemplateViewModal.propTypes = {
    accountID: PropTypes.string,
    record: PropTypes.object,
    onOk: PropTypes.func,
};
