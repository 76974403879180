import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Form, Input, Table, Button, Row, Col, message, Popconfirm, Modal, Alert, Space } from 'antd';
import { useEffect, useState, useContext, useRef } from 'react';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { SolutionApiContext, SelectedAccountContext, PermissionsContext, newUniqueId, testPermission, userIP, UserContext } from '../../services';
import { validateTrimmedWhitespace } from '../../services/FormUtils';
import './WhitelistForm.css';

const CAN_EDIT_WHITELIST = 'whitelist.edit';

const EditableContext = React.createContext();

const EditableRow = ({ ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                    title === 'IP Address' ? {
                        pattern: new RegExp(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/),
                        message: 'Wrong IP format. Please use w.x.y.z or v.w.x.y/z.'
                    } : {}
                ]}
            >
                <Input
                    className='hook-whitelists-dashboard-editable-textbox'
                    ref={inputRef}
                    onPressEnter={save}
                    onBlur={save}
                />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};
EditableCell.propTypes = {
    title: PropTypes.string,
    editable: PropTypes.bool,
    children:  PropTypes.array,
    dataIndex: PropTypes.string,
    record: PropTypes.object,
    handleSave: PropTypes.func,
};


const transformEditableColumns = (columns) => {
    return columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: col.handleSave,
            }),
        };
    });

};


function IpsTable({ disabled = false, value = [], onChange }) {

    const userDetails = useContext(UserContext);

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const onDeleteEntry = useCallback((entry) => {
        try {
            let newItems = value.filter((item) => item.id !== entry.id);
            onChange(newItems);
        } catch (ex) {
            console.log(ex);
            message.error(`Failed to delete ip ${entry.name}`);
        }
    }, [value, onChange]);

    const onRowAdd = useCallback(() => {
        let newEntry = { 'id': newUniqueId(), 'name': 'New', 'ipAddress':'' };
        let newArray = [].concat(value);
        newArray.push(newEntry);

        onChange(newArray); // Send changes to parent
    }, [value, onChange]);

    const onAddMe = useCallback(async () => {
        userIP().then(function(address) {
            let newEntry = { 'id': newUniqueId(), 'name': userDetails.userName, 'ipAddress': address };
            let newArray = [].concat(value);
            newArray.push(newEntry);

            onChange(newArray); // Send changes to parent
        });
    }, [userDetails.userName, value, onChange]);

    const handleSave = (newRow) => {
        const newArray = value.map((row) => {
            return row.id === newRow.id ? newRow : row;
        });

        onChange(newArray);
    };

    const columns = transformEditableColumns([
        {
            className: 'hook-whitelists-dashboard-ipname',
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: !disabled,
            handleSave: handleSave,
            width: '40%'
        },
        {
            className: 'hook-whitelists-dashboard-ipaddress',
            title: 'IP Address',
            dataIndex: 'ipAddress',
            key: 'ipAddress',
            editable: !disabled,
            handleSave: handleSave,
            width: '40%'
        },
        {
            title: 'Actions',
            key: 'action',
            // eslint-disable-next-line react/display-name
            render: (...[, record, ]) => {

                return (
                    <Tooltip title='Delete'>
                        <Popconfirm
                            placement="left"
                            okButtonProps={ { className: 'hook-whitelist-dashboard-ip-delete-confirm' } }
                            cancelButtonProps={ { className: 'hook-whitelist-dashboard-ip-delete-cancel' } }
                            title={`Are you sure you want to delete ${record.name}？`}
                            onConfirm={()=>{ onDeleteEntry(record); }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                disabled={disabled ? true : false}
                                className='hook-whitelists-dashboard-ipentrydelete'
                                shape='circle'
                                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                            />
                        </Popconfirm>
                    </Tooltip>
                );
            }
        },
    ]);

    return (
        <>
            <Row>
                <Col>
                    <Button disabled={disabled} offset={2} className='hook-whitelists-dashboard-addnewip' onClick={onRowAdd} style={{
                        marginBottom: 16, marginRight: 8 }}>Add IP Address</Button>
                </Col>
                <Col>
                    <Button disabled={disabled} offset={2} className='hook-whitelists-dashboard-addmyip' onClick={onAddMe} style={{
                        marginBottom: 16, marginRight: 8 }}>Add My IP</Button>
                </Col>
            </Row>
            <Table
                className='hook-whitelists-dashboard-ipstable'
                pagination={false}
                columns={columns}
                rowKey='id'
                dataSource={value}
                components={components}
                rowClassName={() => 'editable-row hook-whitelists-dashboard-iptablerow'}
                scroll={{ x: true }}
            />
        </>
    );
}

IpsTable.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

const emptyGroup = {
    description: 'New Group',
    entries: [],
    id: '',
    name: ''
};

export function WhitelistForm(props) {

    const { selectedGroupId, onNameChange = ()=>{} } = props;

    const disableControls = selectedGroupId === null;

    const solutionApi = useContext(SolutionApiContext);
    const selectedAccountDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);

    const canEditWhitelist = testPermission(CAN_EDIT_WHITELIST, permissionsMap);

    const [isModified, updateIsModified] = useState(false);

    const [form] = Form.useForm();

    const loadWhitelistGroupData = useCallback(async (groupId) => {
        if (selectedGroupId !== null) {
            const groupResponse = await solutionApi.getWhitelistGroup(selectedAccountDetails.accountID, groupId);

            if (groupResponse.ok) {
                updateIsModified(false);
                form.setFieldsValue(groupResponse.data);
            }
        } else {
            updateIsModified(false);
            form.setFieldsValue(emptyGroup);
        }

    }, [solutionApi, selectedAccountDetails.accountID, form, selectedGroupId]);

    useEffect(() =>{
        loadWhitelistGroupData(selectedGroupId);
    }, [loadWhitelistGroupData, selectedGroupId]);

    const onSaveChanges = useCallback(async () => {
        const updatedItem = form.getFieldsValue();

        let response = await solutionApi.updateWhitelistGroup(selectedAccountDetails.accountID, updatedItem.id, updatedItem);
        if (response.ok) {
            updateIsModified(false);
            onNameChange();
        }
    }, [form, solutionApi, selectedAccountDetails.accountID, onNameChange]);

    const onCancelChanges = useCallback(async () => {
        const updatedItem = form.getFieldsValue();

        return loadWhitelistGroupData(updatedItem.id);
    }, [form, loadWhitelistGroupData]);

    const onValuesChanged = useCallback(() => {
        updateIsModified(true);
    }, []);

    const disabledProps = { disabled:(canEditWhitelist ? disableControls : true) } ;

    return (
        <Form
            form={form}
            layout='vertical'
            onValuesChange={onValuesChanged}
            onFinish={onSaveChanges}
            {...disabledProps}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, validator: validateTrimmedWhitespace }]}
            >
                <Input className='hook-whitelists-dashboard-whitelistbookname' />
            </Form.Item>
            <Form.Item label="Description" name="description">
                <Input.TextArea className='hook-whitelists-dashboard-whitelistdescription' />
            </Form.Item>
            <Form.Item label="Entries" name="entries">
                <IpsTable {...disabledProps} />
            </Form.Item>
            <Form.Item className='form-hidden' name="id">
                <Input type='hidden' />
            </Form.Item>
            <Form.Item>
                <Space wrap={true}>
                    <Button
                        className='hook-whitelists-dashboard-savewhitelistbook'
                        type="primary"
                        htmlType="submit"
                        disabled={!isModified}
                    >
                        Save
                    </Button>
                    <Button
                        className='hook-whitelists-dashboard-cancelwhitelistbookchanges'
                        disabled={!isModified}
                        onClick={onCancelChanges}
                    >
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
}

WhitelistForm.propTypes = {
    selectedGroupId: PropTypes.string,
    onNameChange: PropTypes.func
};

export function NewWhitelistForm(props) {

    const updateValid = props.updateValid;

    const { whitelist, errors } = props;
    const [form] = Form.useForm();

    useEffect(() => {
        // on load
        form.resetFields();

    }, [form, whitelist]);

    const errorsClassName = errors ? '' : 'form-hidden';

    const onValuesChanged = useCallback((...[, allValues]) => {
        // update
        Object.assign(whitelist, allValues);

        updateValid(true);
    }, [whitelist, updateValid]);

    return (<Form key='form' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout='horizontal' form={form} onValuesChange={onValuesChanged}>
        <Alert style={{ marginBottom: 8 }} className={errorsClassName} message={errors ? errors.errors : ''} type='error' />
        <Form.Item name='name' label='Friendly Name' rules={[{ validator: validateTrimmedWhitespace }]} >
            <Input className='hook-whitelists-form-name' placeholder='User-visible name' />
        </Form.Item>
    </Form>);

}

NewWhitelistForm.propTypes = {
    whitelist: PropTypes.object,
    errors: PropTypes.object,
    updateValid: PropTypes.func,
};

export function NewWhitelistModal(props) {

    const {
        visible = true,
        onConfirm = () => {},
        onCancel = () => {},
        errors = null,
    } = props;

    const [whitelist, updateWhitelist] = useState({ ...emptyGroup });

    const [isValid, updateIsValid] = useState(false);

    const doConfirm = useCallback(() => {
        onConfirm(whitelist);
    }, [whitelist, onConfirm]);

    const doCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const updateValid = useCallback((isValid) => {
        updateIsValid(isValid);
    }, []);

    useEffect(() => {
        if (visible) {
            // reset template each time modal is made visible
            updateWhitelist({ ...emptyGroup });
        }
    }, [visible]);

    return (
        <Modal
            title={'New Whitelist'}
            open={visible}
            className='hook-whitelist-newwhitelist-menu'
            onOk={doConfirm}
            okText='Create'
            okButtonProps={{ disabled: !isValid, className: 'hook-whitelists-form-create' }}
            onCancel={doCancel}
            cancelButtonProps={{ className: 'hook-whitelists-form-cancel' }}
        >
            <NewWhitelistForm updateValid={updateValid} whitelist={whitelist} errors={errors} />
        </Modal>
    );
}

NewWhitelistModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    errors: PropTypes.object,
};

