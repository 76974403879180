import React, { useCallback, useContext, useState } from 'react';
import Channels from '../channels';
import { Ticker } from '../util';
import Indicators from './Indicators';
import { Row, Col, Space, PageHeader } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import { refreshAlerts, refreshChannels, refreshSources } from '../util/refresh';
import { SolutionApiContext, SelectedAccountContext } from '../../services';

const REFRESH_PERIOD_MS = 5 * 1000;

export default function Dashboard() {
    const solutionApi = useContext(SolutionApiContext);
    const accountId = useContext(SelectedAccountContext).accountID;

    const [channels, setChannels] = useState();
    const [alerts, setAlerts] = useState();
    const [numberOfActiveChannels, setNumberOfActiveChannels] = useState();
    const [numberOfSources, setNumberOfSources] = useState();

    const refresh = useCallback(async () => {
        const [channels, numberOfActiveChannels] = await refreshChannels(solutionApi, accountId);
        channels ? setChannels(channels) : null;
        numberOfActiveChannels >= 0 ? setNumberOfActiveChannels(numberOfActiveChannels) : null;

        const sources = await refreshSources(solutionApi, accountId);
        sources ? setNumberOfSources(sources.length) : null;

        const alerts = await refreshAlerts(solutionApi, accountId);
        alerts ? setAlerts(alerts) : null;
    }, [accountId, solutionApi]);

    return (
        <>
            <Ticker asyncCallback={refresh} timeout={REFRESH_PERIOD_MS} immediate={true} />
            <Row gutter={[16, 16]} className='gutter-bottom'>
                <Col>
                    <Row align='middle'>
                        <Col>
                            <FontAwesomeIcon icon={faVideo} size='4x' />
                        </Col>
                        <Col>
                            <PageHeader backIcon={null} title="Channels" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Space>
                                <FontAwesomeIcon icon={faInfoCircle} size='2x' color='blue' />
                                Channel management and status page.
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col flex='auto' style={{ marginTop: '16px' }}>
                    <Indicators
                        alertGroups={alerts}
                        alertsLoading={!alerts}
                        numberOfActiveChannels={numberOfActiveChannels}
                        numberOfSources={numberOfSources}
                    />
                </Col>
            </Row>

            <Channels
                loading={!channels}
                channels={channels}
                activeAlerts={alerts}
            />
        </>
    );
}
