import React from "react";
import PropTypes from "prop-types";
import { Button, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

interface SearchFilterProps {
    setSelectedKeys: (selectedKeys: string[]) => void;
    selectedKeys: string[];
    confirm: () => void;
    clearFilters: () => void;
}

const SearchFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: SearchFilterProps) => (
    <div style={{ display: "flex", alignItems: "center", padding: "5px" }}>
        <Input
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
        />
        <Button icon={<FontAwesomeIcon icon={faTimes} size={"sm"} />} onClick={clearFilters} />
        <Button icon={<FontAwesomeIcon icon={faSearch} />} onClick={confirm} />
    </div>
);

const onFilterIncludes = (dataIndex: string) => (value: string, record: Record<string, string>) =>
    record[dataIndex].includes(value.trim());
// Use when the filter data is a property of child object of the record e.g. config.name
const onFilterChildIncludes =
    (dataIndex: string[]) => (value: string, record: Record<string, Record<string, string>>) =>
        record[dataIndex[0]][dataIndex[1]].includes(value.trim());

SearchFilter.propTypes = {
    setSelectedKeys: PropTypes.func,
    selectedKeys: PropTypes.object,
    confirm: PropTypes.func,
    clearFilters: PropTypes.func,
};

export { SearchFilter, onFilterIncludes, onFilterChildIncludes };
