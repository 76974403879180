import React from 'react';
import Cookies from 'js-cookie';
import EventSource from './EventSource';

const KEEP_ALIVE_INTERVAL_MS = 20 * 1000; // check to see if we should refresh every 20 seconds
const INACTIVITY_TIMEOUT_MS =  30 * 24 * 60 * 60 * 1000; // Inactivity timeout at 1 month

class SaasApi {
    constructor(config, onInactivityCallback) {

        this.baseUrl = config['general']['saas-hub']['base'];
        this.url = this.baseUrl + config['general']['saas-hub']['api'] + '/';

        this.token = Cookies.get('hub-token') || null;
        this.loginTime = 0;
        this.refreshInterval = 2 * 60 * 1000; // 2 minutes then refresh the token
        this.lastOperationTime = 0;
        this.onInactivityCallback = onInactivityCallback ? onInactivityCallback : null;
        this.initialCookieVerification = this.refresh().then(() =>{return this.decodeToken();});
        this.onLogoutEvent = new EventSource();
        this.onTokenChange = new EventSource();
    }

    onLogout(callback) {
        this.onLogoutEvent.addListener(callback);
    }

    async decodeToken() {
        return fetch(this.url + 'auth/decode', {
            method: 'GET',
            headers: {
                'Authorization': ('Bearer ' + this.token),
            }
        }).then((response) => {
            if (response.ok) {
                this.markOperation();
                this.resetKeepAliveTimer();
            } else {
                throw new Error('decode failure');
            }
            return response.json();
        });
    }

    async refreshTick() {
        if (this.refreshTimer !== null) {
            // prevent any imminent refreshes
            clearTimeout(this.refreshTimer);
        }

        const elapsedSinceRefresh = Date.now() - this.loginTime;
        const elapsedSinceLastOperation = Date.now() - this.lastOperationTime;

        if (elapsedSinceLastOperation > INACTIVITY_TIMEOUT_MS) {
            if (this.onInactivityCallback) {
                this.onInactivityCallback();
            }
            console.log('Logging user out due to inactivity.');
            // Do not refresh anymore
        } else if (elapsedSinceRefresh > this.refreshInterval) {
            console.info('Refreshing use token');
            await this.refresh();
            this.resetKeepAliveTimer();
        } else {
            this.resetKeepAliveTimer();
        }
    }

    markOperation() {
        this.lastOperationTime =  Date.now();
    }

    resetKeepAliveTimer() {
        if (this.refreshTimer !== null) {
            clearTimeout(this.refreshTimer);
        }

        this.refreshTimer = setTimeout(() => this.refreshTick(), KEEP_ALIVE_INTERVAL_MS);
    }

    async currentUser() {

        if (this.token === null) {
            return {};
        }

        const response = await fetch(this.url + 'auth/decode', {
            method: 'GET',
            headers: {
                'Authorization': ('Bearer ' + this.token),
            }
        });
        if (!response.ok) {
            return {};
        }

        this.markOperation();

        const user = await response.json();

        return user;
    }

    async getObject(path) {

        this.markOperation();
        await this.refreshTick();

        const response = await fetch(this.url + path, {
            method: 'GET',
            headers: {
                'Authorization': ('Bearer ' + this.token),
            },
        });

        if (!response.ok) {
            const errors = await response.json();
            console.error(errors);
            throw Error(response.statusText);
        }

        this.markOperation();

        return await response.json();
    }

    async getAccountNames() {
        return await this.getObject('accounts?returnField=accountName');
    }

    async getAccountNamesForERP(erpId) {
        return await this.getObject('accounts?returnField=accountName&queryField=erpId&queryValue=' + erpId);
    }

    async refresh(accountOverride = null) {
        const headers = {
            'Authorization': ('Bearer ' + this.token),
        };
        if (accountOverride !== null) {
            headers['MK-Account-Override'] = accountOverride;
        }
        const response = await fetch(this.url + 'auth/refresh', {
            method: 'GET',
            headers
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }

        const token = await response.text();

        this.token = token;
        Cookies.set('hub-token', token, {
            domain: '.aas.mediakind.com',
            path: '/'
        });

        // If we're doing an account switch then reload the page once we set the hub-token cookie.
        // This gives us a clean slate as I have noticed various issues if the new token contents are
        // just written into the current user context data. Expecially when I move from user role to mkOps.
        if (accountOverride !== null) {
            location.reload();
        }

        this.onTokenChange.raise();

        this.loginTime = Date.now();

        return token;
    }

    logout() {

        if (this.refreshTimer !== null) {
            // prevent any imminent refreshes
            clearTimeout(this.refreshTimer);
        }

        this.token = null;
        this.onTokenChange.raise();

        this.loginTime = 0;
        this.lastOperationTime = 0;
        Cookies.remove('hub-token', {
            domain: '.aas.mediakind.com',
            path: '/'
        });

        this.onLogoutEvent.raise();
    }
}

const SaasApiContext = React.createContext(null);

export {
    SaasApi,
    SaasApiContext
};

export default SaasApi;
