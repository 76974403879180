import React , { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission,
    EntitlementsContext, checkEntitlement } from '../../services';
import { Modal, Table, Button, Row, Col, Tooltip, message, PageHeader, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faExclamationCircle, faSpinner, faPencilAlt,
    faCompressArrowsAlt, faInfoCircle, faCogs } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getMappedMuxStatus, getMappedMuxIcon, getMappedMuxDisabledIconState } from '../channels/ChannelStates';

import { Ticker } from '../util';
import AdvancedMxControlModal from './AdvancedMuxControlModal';

const MUXES_QUOTA_NAME = 'Muxes';

const CAN_CREATE_MUX = 'muxes.add';
const CAN_EDIT_MUX = 'muxes.edit';
const CAN_DELETE_MUX = 'muxes.delete';
const CAN_START_MUX = 'muxes.start';
const CAN_STOP_MUX = 'muxes.stop';
const MUXES_REFRESH_PERIOD_MS = 3 * 1000;
export default function Muxes() {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const entitlementsMap = useContext(EntitlementsContext);
    const permissionsMap = useContext(PermissionsContext);
    const accountId = userDetails.accountID;

    const [muxesList, updateMuxesList] = useState([]);
    const hasMuxesEntitlement = checkEntitlement(MUXES_QUOTA_NAME, entitlementsMap) > 0;

    const canEditMux = testPermission(CAN_EDIT_MUX, permissionsMap);
    const canDeleteMux = testPermission(CAN_DELETE_MUX, permissionsMap);
    const canStartMux = testPermission(CAN_START_MUX, permissionsMap);
    const canStopMux = testPermission(CAN_STOP_MUX, permissionsMap);

    const [advancedControlMux, updateAdvancedControlMux] = useState(null);

    useEffect(() => {
        if (advancedControlMux != null && muxesList) {
            const mux = muxesList.find(element => element.id === advancedControlMux.id);
            if (mux){
                updateAdvancedControlMux(mux);
            }
        }
    },[muxesList, advancedControlMux]);

    const onAdvancedMuxControlOk = useCallback(() => {
        updateAdvancedControlMux(null);
    },[updateAdvancedControlMux]);

    const refreshMuxes = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;
        const shallContinue = () =>  {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const muxesResponse = await solutionApi.listMultiplexes(accountId);
            if (shallContinue()) {
                updateMuxesList(muxesResponse.data);
            }
        } catch (exception) {
            console.log(exception);
            message.error('Failed to retrieve muxes');
        }
    }, [solutionApi, userDetails.accountID]);

    const deleteRecord = useCallback((muxId, muxName) => {
        const accountId = userDetails.accountID;
        Modal.confirm({
            title: `Are you sure you want to delete the Mux '${muxName}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x'/>,
            okButtonProps: { className: 'hook-muxes-dashboard-muxdeleteconfirm' },
            cancelButtonProps: { className: 'hook-muxes-dashboard-muxdeletecancel' },
            onOk() {
                solutionApi.deleteMultiplex(accountId, muxId);
            },
        });
    }, [userDetails.accountID, solutionApi]);

    const startMux = useCallback(async (muxId) => {
        try {
            const mux = muxesList.find(({ id }) => id === muxId);
            const muxUpdate = { config: { instances: {} } };
            Object.keys(mux.config.instances).forEach(instanceId => {
                muxUpdate.config.instances[instanceId] = { state: 'started' };
            });

            await solutionApi.updateMultiplex(accountId, mux.id, muxUpdate);
        } catch (exception) {
            console.log('problems');
        }
    },[solutionApi, accountId, muxesList]);

    const stopMux = useCallback((muxId, muxName) => {
        Modal.confirm({
            title: `Are you sure you want to stop the Multiplex '${muxName}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x'/>,
            okButtonProps: { className: 'hook-muxes-dashboard-muxstopconfirm' },
            cancelButtonProps: { className: 'hook-muxes-dashboard-muxstopcancel' },
            onOk() {
                const mux = muxesList.find(({ id }) => id === muxId);
                const muxUpdate = { config: { instances: {} } };
                Object.keys(mux.config.instances).forEach(instanceId => {
                    muxUpdate.config.instances[instanceId] = { state: 'stopped' };
                });

                solutionApi.updateMultiplex(accountId, mux.id, muxUpdate);
            },
        });
    }, [solutionApi, accountId, muxesList]);

    const getState = useCallback((item) => {
        return getMappedMuxStatus(item);
    }, []);

    const columns = [{
        title: 'Mux Name',
        sorter: (a, b) => a.config.name.localeCompare(b.config.name),
        dataIndex: ['config', 'name'],
        key: 'name',
        className: 'hook-muxes-muxtable-name',
        defaultSortOrder: 'ascend'
    }, {
        title: 'Deployment',
        key: 'region',
        sorter: (a, b) => {
            const aLocation = a.config.instances[Object.keys(a.config.instances)[0]].location;
            const bLocation = b.config.instances[Object.keys(b.config.instances)[0]].location;
            aLocation.localeCompare(bLocation);
        },
        className: 'hook-muxes-muxtable-deployment',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            if (Object.keys(record.config.instances).length > 1) {
                const tooltip = 'Available in ' + record.config.instances[Object.keys(record.config.instances)[0]].location +
                     ' and ' + record.config.instances[Object.keys(record.config.instances)[0]].location + ' locations.';

                return <Tooltip title={tooltip}>
                    <span className='region-ha-text'>HA Multiplex</span>
                </Tooltip>;
            }
            else{
                return record.config.instances[Object.keys(record.config.instances)[0]].location;
            }
        }
    }, {
        title: 'Status',
        sorter: (a, b) => {
            const aState = (getState(a)), bState = (getState(b));
            aState.localeCompare(bState);
        },
        key: 'state',
        className: 'hook-muxes-muxtable-state',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const stateText = getState(record);

            let text = '';
            switch (stateText) {
            case 'stopped':
                text = 'Inactive';
                break;
            case 'start_requested':
                text = 'About to activate';
                break;
            case 'infra_creating':
            case 'starting':
                text = 'Going active';
                break;
            case 'started':
                text = 'Active';
                break;
            case 'start_error':
                text = 'Error activating';
                break;
            case 'stop_requested':
                text = 'About to deactivate';
                break;
            case 'stopping':
                text = 'Deactivating';
                break;
            default:
                text = stateText;
                break;
            }
            return text;
        }
    }, {
        title: 'Actions',
        key: 'actions',
        className: 'hook-muxes-muxtable-actions',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const muxId = record.id;
            const muxName = record.config.name;

            const muxState = getState(record);

            const advancedProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={faCogs}/>,
                onClick: ()=>{ updateAdvancedControlMux(record); }
            };

            let startButtonText = '';
            const startStopIcon = getMappedMuxIcon(record);
            const iconPulse = (startStopIcon === faSpinner) ? true : false;
            const startStopProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={startStopIcon} pulse={iconPulse}/>,
                disabled: getMappedMuxDisabledIconState(startStopIcon, record)
            };

            let editButtonText = 'Edit';
            const editProps = {
                shape:'circle',
                icon: <FontAwesomeIcon icon={faPencilAlt}/>,
            };

            let deleteButtonText = 'Delete';
            const deleteProps = {
                shape:'circle',
                icon: <FontAwesomeIcon icon={faTrashAlt} />
            };

            switch (muxState) {
            case 'Inactive':
                startStopProps.onClick = ()=>{ startMux(muxId); };
                startButtonText = 'Activate';

                deleteProps.onClick = ()=>{ deleteRecord(muxId, muxName); };
                deleteProps.disabled = false;
                advancedProps.disabled = false;
                editProps.disabled = false;

                if (!canStartMux) {
                    startButtonText = 'You lack the required permissions to start a mux';
                    startStopProps.disabled = true;
                }

                break;

            case 'About to activate':
            case 'Going active':
                startButtonText = 'Already Activating';

                deleteButtonText = 'Cannot delete when Active';
                deleteProps.disabled = true;
                editProps.disabled = true;
                advancedProps.disabled = true;

                break;
            case 'Active (upgrading)':
                startButtonText = 'Upgrading';
                editProps.disabled = true;
                deleteProps.disabled = true;
                advancedProps.disabled = true;

                break;
            case 'Error activating':
                startButtonText = 'Use Advanced Channel Control';
                editProps.disabled = true;
                deleteProps.disabled = true;
                advancedProps.disabled = false;

                break;
            case 'Active':
                startStopProps.onClick = ()=>{ stopMux(muxId, muxName); };
                startButtonText = 'Deactivate';

                if (!canStopMux) {
                    startButtonText = 'You lack the required permissions to stop a mux';
                    startStopProps.disabled = true;
                }

                deleteButtonText = 'Cannot delete when Active';
                editProps.disabled = false;
                deleteProps.disabled = true;
                advancedProps.disabled = false;
                break;
            case 'About to deactivate':
            case 'Deactivating':
                startButtonText = 'Deactivating';
                deleteButtonText = 'Cannot delete when Active';
                editProps.disabled = true;
                deleteProps.disabled = true;
                advancedProps.disabled = true;

                break;
            case 'Active (degrading)':
                startButtonText = 'Degrading';
                deleteButtonText = 'Cannot delete when Active';
                editProps.disabled = true;
                deleteProps.disabled = true;
                advancedProps.disabled = true;
                break;
            case 'Active (degraded)':
                startButtonText = 'Use Advanced Channel Control';
                editProps.disabled = false;
                advancedProps.disabled = false;
                deleteProps.disabled = true;

                break;
            default:
                break;
            }

            if (!canEditMux) {
                editButtonText = 'You lack the required permissions to make changes to any multiplexes.';
                editProps.disabled = true;
            }

            if (!canDeleteMux) {
                deleteButtonText = 'You lack the required permissions to delete any multiplexes';
                deleteProps.disabled = true;
            }

            return <>
                <Tooltip title={startButtonText}>
                    <Button className='hook-muxes-muxtable-startstopbutton' { ...startStopProps } />
                </Tooltip>
                <Tooltip title='Advanced instances control'>
                    <Button className='hook-muxes-muxtable-advanedbutton' { ...advancedProps } />
                </Tooltip>
                <Tooltip title={editButtonText}>
                    <Link to={`/multiplexes/${muxId}`}>
                        <Button className='hook-muxes-muxtable-editbutton' { ...editProps } />
                    </Link>
                </Tooltip>
                <Tooltip title={deleteButtonText}>
                    <Button className='hook-muxes-muxtable-deletebutton' { ...deleteProps }/>
                </Tooltip>
            </>;
        }
    },
    ];

    const canCreateMux = testPermission(CAN_CREATE_MUX, permissionsMap);
    const createButtonProps = {};

    let createButtonText = 'Press to create a new Multiplex';
    if (!canCreateMux) {
        createButtonProps['disabled'] = true;
        createButtonText = 'You do not have the required permissions to create a multiplex';
    }
    if (!hasMuxesEntitlement) {
        createButtonProps['disabled'] = true;
        createButtonText = 'Your account does not have the entitlement to use multiplexes';
    }

    // For the time being, the New Multiplex button is work in progress so can't be used unless role and permissions allow
    const headerForm = () => (
        <Row gutter={[8, 8]} justify='space-between'>
            <Col>
                <Tooltip title={createButtonText}>
                    <Link to='/multiplexes/new'>
                        <Button className='hook-muxes-dashboard-newmultiplex' {...createButtonProps}>
                            New Multiplex
                        </Button>
                    </Link>
                </Tooltip>
            </Col>
            <Col>
                <Link to="/muxes/statmuxpools">
                    <Button className='hook-muxes-dashboard-statmuxpools' disabled={!hasMuxesEntitlement} >
                        Statmux pools...
                    </Button>
                </Link>
            </Col>
        </Row>
    );

    useEffect(() => {
        // once only on load
        refreshMuxes(null);
    }, [refreshMuxes]);

    return (
        <>
            <Ticker asyncCallback={refreshMuxes} timeout={MUXES_REFRESH_PERIOD_MS} />
            <Row align='middle'>
                <Col>
                    <FontAwesomeIcon icon={faCompressArrowsAlt} size='4x' />
                </Col>
                <Col>
                    <PageHeader backIcon={null} title="Muxes" />
                </Col>
            </Row>
            <Row className='gutter-bottom'>
                <Col>
                    <Space>
                        <FontAwesomeIcon icon={faInfoCircle} size='2x' color='blue' />
                        Manage muxes for SPTS and MPTS stream processing.
                    </Space>
                </Col>
            </Row>
            <Table
                pagination={false}
                size='middle'
                dataSource={muxesList}
                columns={columns}
                rowKey='id'
                title={headerForm}
                scroll={{ x: true }}
            />
            <AdvancedMxControlModal
                mux={advancedControlMux}
                onOk={onAdvancedMuxControlOk}
            />
        </>
    );
}

Muxes.propTypes = {
    sources: PropTypes.object,
    templates: PropTypes.object,
    muxes: PropTypes.array,
    activeAlerts: PropTypes.object
};
