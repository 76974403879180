import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Tree, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

function isType(value, typeName) {
    return Object.prototype.toString.call(value).indexOf(typeName) > -1;
}

function isIterable(value) {
    return isType(value, 'Object') || isType(value, 'Array');
}

function buildTree(srcObject, compoundKey) {
    let items = [];

    for (let key in srcObject) {
        let itemValue = srcObject[key] ? srcObject[key] : 'null';

        if (itemValue.constructor === Array && itemValue.length === 0) {
            itemValue = 'Empty List';
        }

        let newItem = {
            // Really important to keep keys unique
            key: compoundKey ? `${compoundKey}-${key}` : key,
            title: key
        };

        if (isIterable(itemValue)) {
            // see if its iterable else add it as a property...
            const subItems = buildTree(
                itemValue,
                newItem.key
            );
            newItem.children = subItems;
        } else if (itemValue !== 'null') {
            switch (key) {
            case 'controller_address':
                itemValue = `https://${itemValue}:8443`;
                newItem.selectable = false;
                newItem.title = (
                    <>
                        <>{newItem.title} : </>
                        <a
                            href={itemValue}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {itemValue}
                        </a>
                    </>
                );
                break;
            case 'kube_credentials':
                newItem.selectable = false;
                newItem.title = (
                    <>
                        <>{newItem.title} : </>
                        Download by selecting the &#39;Kube Credentials&#39;
                        icon after closing this popup.
                    </>
                );
                break;
            default:
                newItem.title = `${newItem.title} : ${itemValue}`;
            }
        } else {
            newItem.title = `${newItem.title} : ${itemValue}`;
        }

        newItem.value = itemValue;
        items.push(newItem);
    }

    return items;
}

export default function InfoModal(props) {
    const {
        open = true,
        onOK = () => {},
        data,
        title
    } = props;

    let treeData = buildTree(data ? data : {}, '');

    return (
        <Modal
            title={title}
            open={open}
            onCancel={() => onOK(data)}
            footer={null}
        >
            <p>
                <FontAwesomeIcon icon={faCopy} style={{ marginRight: '6px' }} />
                <i>Select an item to copy its value to your clipboard.</i>
            </p>
            <Tree
                key='tree'
                showLine={true}
                showIcon={true}
                treeData={treeData}
                onSelect={(_, e) => {
                    if (
                        typeof e.node.value === 'string' ||
                        e.node.value instanceof String
                    ) {
                        navigator.clipboard.writeText(e.node.value);
                        message.success('Copied value to clipboard.');
                    } else {
                        message.error('Unable to copy the selected value.');
                    }
                }}
            />
        </Modal>
    );
}

InfoModal.propTypes = {
    open: PropTypes.bool,
    onOK: PropTypes.func,
    data: PropTypes.object,
    title: PropTypes.string
};
