type CallbackType = () => void;

export default class EventSource {
    listeners: CallbackType[];
    constructor() {
        this.listeners = [];
    }

    addListener(callback: CallbackType) {
        this.listeners.push(callback);
    }

    removeListener(callback: CallbackType) {
        const index = this.listeners.findIndex((item) => item === callback);
        if (index !== -1) {
            this.listeners.splice(index, 1);
        }
    }

    raise() {
        for (const listener in this.listeners) {
            this.listeners[listener]();
        }
    }
}
