
const getErrorProps = (fieldName, errors) => {
    const lookup = errors && errors.errors.find(error => error.fieldName === fieldName);
    return lookup ? { validateStatus: 'error', help: lookup.text } : {};
};

async function validateTrimmedWhitespace(rule, value) {
    if (typeof value == 'string' && value !== '') {
        if (!value.match(/^(?!\s).*(?<!\s)$/)) {
            throw new Error('Must not contain leading or trailing whitespace');
        }
    }
}

async function validateThirtyTwoAlphaNumericDashesUnderscore(rule, value) {
    if (typeof value == 'string' && value !== '') {
        if (!value.match(/^[a-zA-Z0-9_-]{1,32}$/)) {
            throw new Error('Must only contain alphanumeric characters, hyphens or underscores and be no more than 32 characters in length');
        }
    }
}

export { getErrorProps, validateTrimmedWhitespace, validateThirtyTwoAlphaNumericDashesUnderscore };
