import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Modal, Alert, Upload } from 'antd';
import { processResponseErrors } from '../../services';
import { validateTrimmedWhitespace } from '../../services/FormUtils';

const emptyTemplate = {
    comment: '',
    templateFiles: [],
};

export function TemplateForm(props) {

    const updateValid = props.updateValid;

    const { template, errors } = props;
    const [form] = Form.useForm();

    const normaliseFile = e => {
        return e.fileList;
    };

    useEffect(() => {
        // on load
        form.resetFields();

    }, [form, template]);


    const errorsClassName = errors ? '' : 'form-hidden';

    let errorObj = {
        summary: '',
        errorsLookup: {}
    };

    if (errors && errors.errors) {
        errorObj = processResponseErrors(errors);
    }

    const getErrorsProps = useCallback((fieldName) => {
        let props = {};

        const lookup = errorObj.errorsLookup[fieldName];
        if (lookup) {
            props.validateStatus = 'error';
            props.help = lookup.text;
        }

        return props;
    }, [errorObj.errorsLookup]);

    const onValuesChanged = useCallback((...[, allValues]) => {
        // update template
        Object.assign(template, allValues);

        // TODO: check whether parameters are valid for creating new template
        updateValid(true);
    }, [template, updateValid]);


    const uploaderProps = {
        onRemove: (fileToRemove) => {

            for (let file in template.templateFiles) {
                if (fileToRemove.name == template.templateFiles[file].name) {
                    template.templateFiles.splice(file, 1);
                    break;
                }
            }
        },
        multiple: true,
        beforeUpload: file => {
            template.templateFiles.push(file);
            return false;
        },

        fileList: template.templateFiles ? [template.templateFiles] : []
    };

    return (
        <Form
            key='form'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout='horizontal'
            form={form}
            onValuesChange={onValuesChanged}
            initialValues={{ comment: '' }}
        >
            <Alert
                style={{ marginBottom: 8 }}
                className={errorsClassName}
                message={errors ? errorObj.summary : ''}
                type='error'
            />

            <Form.Item
                name='comment'
                label='Comment'
                {...getErrorsProps('comment')}
                rules={[{ required: true, validator: validateTrimmedWhitespace }]}
            >
                <Input placeholder='Add comment for revision'/>
            </Form.Item>

            <Form.Item
                name='upload'
                label='Upload Template Files'
                valuePropName='fileList'
                getValueFromEvent={normaliseFile}
                {...getErrorsProps('attachedFile')}
            >
                <Upload.Dragger {...uploaderProps}>
                    <Button>Select Files</Button>
                </Upload.Dragger>
            </Form.Item>
        </Form>
    );
    // TODO: Chooser for quota names!!
}

TemplateForm.propTypes = {
    template: PropTypes.object,
    errors: PropTypes.object,
    updateValid: PropTypes.func,
    accountID: PropTypes.string
};

export function NewOrUpdateTemplateModal(props) {

    const {
        visible = true,
        onConfirm = () => {},
        onCancel = () => {},
        errors = null,
        accountID = null,
        name = null
    } = props;

    const [template, updateTemplate] = useState({ ...emptyTemplate });

    const [isValid, updateIsValid] = useState(false);

    const doConfirm = useCallback(() => {
        onConfirm(template);
    }, [template, onConfirm]);

    const doCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const updateValid = useCallback((isValid) => {
        updateIsValid(isValid);
    }, []);

    useEffect(() => {
        if (visible) {
            // reset template each time modal is made visible
            updateTemplate({ comment: '', templateFiles: [] });
        }
    }, [visible]);

    return (
        <Modal
            title={ name ? name : 'New Template'}
            open={visible}
            onOk={doConfirm}
            okText='Create'
            okButtonProps={{ disabled: !isValid }}
            onCancel={doCancel}
        >
            <TemplateForm updateValid={updateValid} template={template} errors={errors} accountID={accountID} />
        </Modal>
    );
}

NewOrUpdateTemplateModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    errors: PropTypes.object,
    accountID: PropTypes.string,
    name: PropTypes.string
};
