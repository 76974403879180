import React from 'react';
import { useState } from 'react';
import { Tooltip, Button, Modal, Table, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function NotificationsTable({ notificationsList }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalDetail, setModalDetail] = useState('');
    const [modalNotificationId, setModalNotificationId] = useState('');

    const headerForm = () => (
        <Row className='hook-notification-table-header'>
            <Col>Notifications of user actions and system activity</Col>
        </Row>
    );

    const columns = [{
        title: 'Raised Date / Time',
        key: 'actionRaisedTime',
        className: 'hook-notifications-raisedtime',
        dataIndex: 'eventTime',
        sorter: (dateA, dateB) => moment(dateA.eventTime).diff(moment(dateB.eventTime)),
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'descend',
        render: (...[, record, ]) => {
            const dt = new Date(record.eventTime);
            return dt.toString().slice(0, -21);
        }
    },
    {
        title: 'User',
        key: 'actioningUser',
        className: 'hook-notifications-event-time',
        dataIndex: 'actioningUser',
        sorter: (a, b) => a.actioningUser.localeCompare(b.actioningUser),
        sortDirections: ['ascend', 'descend']
    },
    {
        title: 'Message',
        key: 'actionMessage',
        className: 'hook-notifications-description',
        dataIndex: 'message',
        sorter: (a, b) => a.message.localeCompare(b.message),
        sortDirections: ['ascend', 'descend']
    },
    {
        title: 'Actions',
        key: 'action',
        className: 'hook-notifications-action',
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            return (
                <Tooltip title='Details of notification' placement='left'>
                    <Button
                        shape='circle'
                        icon={<FontAwesomeIcon icon={faInfoCircle} />}
                        onClick={() => {
                            setIsModalVisible(true);
                            setModalMessage(record.message);
                            setModalDetail(record.detail);
                            setModalNotificationId(record.id);
                        }}
                    />
                </Tooltip>
            );
        }
    }];

    return (
        <>
            <Table
                className='hook-notifications-table'
                size='middle'
                dataSource={notificationsList}
                columns={columns}
                title={headerForm}
                scroll={{ x: true }}
                loading={!notificationsList}
            />
            <Modal
                centered="true"
                open={isModalVisible}
                title={`Notification details: ${modalMessage}`}
                onOk={() => setIsModalVisible(false)}
                okText="Close"
                onCancel={() => setIsModalVisible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                Notification ID: {modalNotificationId}<br /><br /> {modalDetail}
            </Modal>
        </>
    );
}

NotificationsTable.propTypes = {
    notificationsList: PropTypes.array
};
