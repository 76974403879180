import Cookies from 'js-cookie';
import React, { useEffect, useState, useContext, useCallback } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import { ConfigProvider, Layout, Result, Spin } from 'antd';

import 'antd/dist/antd.variable.min.css';
import './App.css';
import {
    SaasApiContext,
    UserContext,
    SolutionApiContext,
    PermissionsContext,
    EntitlementsContext,
    SelectedAccountContext
} from './services';

import { MKFooter, MKHeader } from './navigation';
import MKMenu from './navigation/MKMenu';
import { SELECTED_ACCOUNT_COOKIE, updateSelectedAccountCookie } from './services/SelectedAccountContext';

import {
    Dashboard,
    Sources,
    Feeds,
    Muxes,
    Dvrs,
    StatmuxPools,
    MuxServices,
    User,
    WhitelistGroups,
    NotFound,
    Usage,
    Deployments,
    Templates,
    Alarms,
    Notifications,
    View,
    Credentials
} from './pages';
import MultiplexDetails from './pages/muxes/MultiplexDetails';

const { Content } = Layout;


export default function App() {
    const selectedAccount = Cookies.get(SELECTED_ACCOUNT_COOKIE) || '';

    const api = useContext(SaasApiContext);
    const solutionApi = useContext(SolutionApiContext);

    const [appState, updateAppState] = useState({
        userDetails: null,
    });

    const [selectedUserDetails, setSelectedUserDetails] = useState(null);

    const setAccountDetails = useCallback(async (details) => {
        setSelectedUserDetails(prevState => {
            updateSelectedAccountCookie(details);
            return { ...prevState, ...details };
        }, [appState]);


    }, [setSelectedUserDetails, appState]);

    const onTokenChange = useCallback(async () => {
        const userJson = await api.decodeToken();
        updateAppState({
            userDetails: userJson,
        });
    }, [api]);



    const [permissions, updatePermissions] = useState({});
    const [entitlements, updateEntitlements] = useState({});

    const [navigateCallback, updateNavigateCallback] = useState(null);

    const setOverrideFunc = useCallback((overrideFunc) => {
        updateNavigateCallback(() => overrideFunc);
    }, [updateNavigateCallback]);

    const refreshTick = useCallback(async () => {
        if (appState.userDetails) {
            const permissionsMap = {};
            const permissionsResponse = await solutionApi.getPermissions(appState.userDetails.accountID);
            if (permissionsResponse.ok) {
                const permissions = permissionsResponse.data;
                permissions.permissions.sort();
                permissions.permissions.map((item)=>{
                    permissionsMap[item] = true;
                    return item;
                });
                permissions.permissions = permissionsMap;
                updatePermissions(permissions);
            }

            const entitlementsResponse = await solutionApi.getEntitlements(appState.userDetails.accountID);
            if (entitlementsResponse.ok) {
                const entitlements = entitlementsResponse.data;
                updateEntitlements(entitlements);
            }
        }
    }, [appState.userDetails, solutionApi]);

    useEffect(() => {
        // once only on load
        api.initialCookieVerification.then((userJson) => {
            updateAppState({
                userDetails: userJson,
            });

            // If a selected-account cookie is set and we're on an mkOps user, use it
            let accountID = userJson.accountID;
            let accountName = userJson.accountName;

            // Validate the cookie contents
            const accountParts = selectedAccount.split(':::');
            if (accountParts.length === 2 && accountParts[0].length > 0 && accountParts[1].length > 0) {
                accountName = accountParts[0];
                accountID = accountParts[1];
            }

            setSelectedUserDetails({
                accountID: accountID,
                accountName: accountName,
            });

            if (!sessionStorage.getItem('appLoaded')) {
                // Code to run only once per session
                const FIRST_SELECTED_ACCOUNT_COOKIE = '__Host_first_selected-account';
                Cookies.set(
                    FIRST_SELECTED_ACCOUNT_COOKIE,
                    `${accountName}:::${accountID}`,
                    {
                        domain: '.aas.mediakind.com',
                        path: '/'
                    }
                );
                // Set the flag in session storage
                sessionStorage.setItem('appLoaded', 'true');
            }
        });
    }, [api.initialCookieVerification, solutionApi, selectedAccount]);

    useEffect(() => {
        api.onTokenChange.addListener(onTokenChange);

        return () => {
            api.onTokenChange.removeListener(onTokenChange);
        };
    }, [api, onTokenChange]);

    useEffect(() => {
        refreshTick();
    }, [appState.userDetails, refreshTick]);

    // redirect if no ability to use HEAAS
    // the below should come from the solution 'config' object we've been talking about
    const required = ['AASHub/mkOps', 'Aquila-Streaming'];

    let hasRoles = permissions && permissions.roles && permissions.roles.length > 0;
    let content = null;
    if (appState.userDetails && hasRoles && selectedUserDetails) {

        const roles = permissions.roles;
        let permitted = false;
        if (hasRoles) {
            for (let index in roles) {
                const role = roles[index];
                for (let testKey in required)
                {
                    if (role.search(required[testKey]) !== -1) {
                        permitted = true;
                        break;
                    }
                }

                if (permitted) {
                    break;
                }
            }
        }
        if (permitted) {
            content = (
                <Switch>
                    <Route exact path='/'>
                        {/* This is a temporary solution to duplicate Channels as home and the a separate Channels link*/}
                        <Dashboard />
                    </Route>
                    <Route exact path='/channels'>
                        <Dashboard />
                    </Route>
                    <Route exact path='/view/:channelId'>
                        <View updateNavigateCallback={setOverrideFunc}/>
                    </Route>
                    <Route exact path='/sources'>
                        <Sources />
                    </Route>
                    <Route exact path='/feeds'>
                        <Feeds />
                    </Route>
                    <Route exact path='/whitelists'>
                        <WhitelistGroups />
                    </Route>
                    <Route exact path='/muxes'>
                        <Muxes />
                    </Route>
                    <Route exact path='/muxes/statmuxpools'>
                        <StatmuxPools />
                    </Route>
                    <Route path='/muxes/:muxId'>
                        <MuxServices updateNavigateCallback={setOverrideFunc}/>
                    </Route>
                    <Route path='/multiplexes/:multiplexId'>
                        <MultiplexDetails updateNavigateCallback={setOverrideFunc}/>
                    </Route>
                    <Route exact path='/alarms'>
                        <Alarms />
                    </Route>
                    <Route exact path='/notifications'>
                        <Notifications />
                    </Route>
                    <Route exact path='/user'>
                        <User />
                    </Route>
                    <Route path='/usage'>
                        <Usage />
                    </Route>
                    <Route path='/deployments'>
                        <Deployments />
                    </Route>
                    <Route path='/templates'>
                        <Templates />
                    </Route>
                    <Route path='/dvrs'>
                        <Dvrs />
                    </Route>
                    <Route path='/credentials'>
                        <Credentials />
                    </Route>
                    <Route component={NotFound}/>
                </Switch>
            );
        } else {
            content = (
                <Result
                    status="warning"
                    title="We're sorry but your account does not have a required role associated with it to allow you to use Aquila AAS."
                />
            );
        }
    } else {
        content = <Spin tip='Verifying login...' size='large' style={{ marginTop: '16px' }} />;
    }

    ConfigProvider.config({
        theme: {
            primaryColor: '#1890ff',
        },
    });

    return (
        <Router>
            <ConfigProvider>
                <Layout style={{ minHeight: '100%' }}>
                    <UserContext.Provider value={appState.userDetails}>
                        <PermissionsContext.Provider value={permissions}>
                            <EntitlementsContext.Provider value={entitlements}>
                                <SelectedAccountContext.Provider value={selectedUserDetails}>
                                    <MKHeader pathOverride={navigateCallback} setAccountDetails={setAccountDetails}/>
                                    <Layout>
                                        <MKMenu />
                                        <Layout>
                                            <Content
                                                style={{
                                                    marginLeft: '20px',
                                                    marginRight: '20px'
                                                }}
                                            >
                                                {content}
                                            </Content>
                                            <MKFooter />
                                        </Layout>
                                    </Layout>
                                </SelectedAccountContext.Provider>
                            </EntitlementsContext.Provider>
                        </PermissionsContext.Provider>
                    </UserContext.Provider>
                </Layout>
            </ConfigProvider>
        </Router>
    );
}
