import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { SelectedAccountContext } from '../../services';

import UiConnector from '../../components/UiConnector';

export default function MuxServices({ muxId, deploymentId }) {

    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;

    const proxyUrlPath = `/proxy/accounts/${accountId}/sandboxes/${deploymentId}`;
    const uiConnectorPath = `/public/edit/services/mux/${muxId}/`;

    return (
        <>
            {muxId ?
                <UiConnector scope='inline' id={muxId} connectorUrl={proxyUrlPath + uiConnectorPath} exitUrl='/muxes' /> : <div key='content'>loading</div>
            }
        </>);
}

MuxServices.propTypes = {
    deploymentId: PropTypes.string,
    muxId: PropTypes.string
};
