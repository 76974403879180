import { v4 as uuidv4 } from 'uuid';
import { publicIpv4 } from 'public-ip';

import SaasApi, { SaasApiContext } from './SaasApi';
import SolutionApi, { SolutionApiContext } from './SolutionApi';
import UserContext from './UserContext';
import SelectedAccountContext from './SelectedAccountContext';
import CiMode, { CiModeContext } from './CiMode';
import PermissionsContext, { testPermission, testRole } from './PermissionsContext';
import EntitlementsContext, { checkEntitlement } from './EntitlementsContext';
import { processResponseErrors } from './ResponseUtils';
import { getErrorProps } from './FormUtils';

function newUniqueId() {
    return uuidv4().replace(/-/g, '');
}

function userIP() {
    return publicIpv4();
}

export {
    SaasApi,
    SaasApiContext,
    SolutionApi,
    SolutionApiContext,
    UserContext,
    SelectedAccountContext,
    PermissionsContext,
    testPermission,
    testRole,
    EntitlementsContext,
    checkEntitlement,
    processResponseErrors,
    getErrorProps,
    newUniqueId,
    userIP,
    CiMode,
    CiModeContext,
};
