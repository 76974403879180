
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row, Switch } from 'antd';

import SingleChannelTable from './SingleChannelTable';

export default function AdvancedControlModel({ channel, onOk, activeAlerts }) {
    const [enabledControls, updateEnabledControls] = useState(false);
    const [channelName, updateChannelName] = useState('');
    const [channelId, updateChannelId] = useState('');

    const refreshChannel = useCallback(async (channel) => {
        if (channel != null) {
            updateChannelName(channel.name);
            updateChannelId(channel.id);
        } else {
            updateChannelName(null);
            updateChannelId(null);
        }

    }, []);

    useEffect(() => {
        refreshChannel(channel);
    }, [refreshChannel, channel]);

    return (
        <Modal
            title='Advanced channel control'
            open={channel != null}
            className='hook-channels-advancedchannelcontrol-modal'
            width={'auto'}
            cancelText='Close'
            style={{ display: 'flex', justifyContent: 'center', maxWidth: '80%' }}
            onCancel={() => {
                onOk();
                updateEnabledControls(false);
            }}
            cancelButtonProps={{ className: 'hook-channels-advancedchannelcontrol-close' }}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <Row>
                <Col offset={2} className='advanced-control-text-col'>
                    Channel: {channelName}
                </Col>
            </Row>
            <Row>
                <Col offset={2} className='advanced-control-text-col-id'>
                    ID: {channelId}
                </Col>
            </Row>
            <Row>
                <Col offset={2}>
                    Enable advanced actions:
                </Col>
                <Col offset={3}>
                    <Switch
                        checked={enabledControls}
                        className='hook-channels-advancedchannelcontrol-toggleEnable'
                        onChange={ () => { updateEnabledControls(!enabledControls); }}
                    />
                </Col>
            </Row>
            <Row>
                <Col offset={2} className='advanced-control-text-col'>
                    <span className='advanced-control-warning-text'>Warning: Using advanced actions will affect channel operation.</span>
                </Col>
            </Row>
            <SingleChannelTable channel={channel} activeAlerts={activeAlerts} enabledControls={enabledControls} showActions={true}/>
        </Modal>
    );
}

AdvancedControlModel.propTypes = {
    channel: PropTypes.object,
    onOk: PropTypes.func,
    activeAlerts: PropTypes.object,
    channelName: PropTypes.string
};
