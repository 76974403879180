import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Col, Row, Select, Space, Tooltip, message } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { SaasApiContext } from '../services/SaasApi';
import { PermissionsContext, testRole, SelectedAccountContext, UserContext } from '../services';
import { updateSelectedAccountCookie } from '../services/SelectedAccountContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';


async function loadSelectableAccounts(api, userDetails, erpId, isRoleMKOps) {
    let accountArray = [];

    if (erpId !== undefined) {
        // erpId is never passed to AccountChooser so this branch is never hit currently
        accountArray = await api.getAccountNamesForERP(erpId);
    } else {
        if (isRoleMKOps) {
            // get all accounts
            accountArray = await api.getAccountNames();
        } else if (userDetails.accessAccounts) {
            accountArray = userDetails.accessAccounts.map((acc) => {
                return {
                    accountID: acc.id,
                    accountName: acc.name,
                };
            });
        }
        return accountArray.sort((a, b) =>
            a.accountName.localeCompare(b.accountName)
        );
    }

    return accountArray;
}


export default function AccountChooser(props) {
    const api = useContext(SaasApiContext);
    const userDetails = useContext(UserContext);
    const permissionsContext = useContext(PermissionsContext);
    const selectedUserDetails = useContext(SelectedAccountContext);

    const [accounts, setAccounts] = useState([]);

    // Control should only work if the auth account has mkOps role
    const isRoleMKOps = testRole('AASHub/mkOps', permissionsContext);

    useEffect(() => {
        async function loadAccounts() {
            try {
                const newAccounts = await loadSelectableAccounts(
                    api,
                    userDetails,
                    props.erpId,
                    isRoleMKOps
                );
                setAccounts(newAccounts);
            } catch (error) {
                if (selectedUserDetails !== null) {
                    setAccounts([selectedUserDetails]);
                } else {
                    setAccounts([]);
                }
            }

        }

        if (api && userDetails) {
            loadAccounts();
        }
    }, [api, userDetails, isRoleMKOps, selectedUserDetails, props.erpId]);

    // check if the selected user account is in the current account list
    // if not then reset it to the current user
    useEffect(() => {
        if (isRoleMKOps) {
            return;
        }

        if (
            userDetails &&
            selectedUserDetails &&
            userDetails.accountID !== selectedUserDetails.accountID
        ) {
            const accountDetails = accounts.find((item) => item.accountID === selectedUserDetails.accountId);

            if (accountDetails === undefined) {
                // selected account is not in the available list
                props.setAccountDetails({
                    accountID: userDetails.accountID,
                    accountName: userDetails.accountName,
                });
            }
        }
    }, [isRoleMKOps, accounts, userDetails, selectedUserDetails, props]);

    const onChange = useCallback((newAccountId) => {
        const accountDetails = accounts.find((item) => item.accountID === newAccountId);

        if (isRoleMKOps) {
            props.setAccountDetails(accountDetails);
        } else {
            updateSelectedAccountCookie(accountDetails);
            api.refresh(newAccountId);
        }
    }, [api, accounts, props, isRoleMKOps]);

    if (accounts.length === 0) {
        return null;
    }

    const options = accounts.map((account) => {
        let labelText = account.accountName;
        // When mkOps mark the actual account we are logged in as.
        if (isRoleMKOps && userDetails.accountID === account.accountID) {
            labelText = account.accountName + ' (default)';
        }
        return {
            label: <><HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} /> {labelText}</>,
            value: account.accountID,
            name: labelText
        };
    });

    if (selectedUserDetails === null) {
        return null;
    }

    const accountNameCheck = selectedUserDetails.accountName !== userDetails.accountName;

    return (
        <Row
            align='middle'
            style={{
                borderRadius: '20px',
                backgroundColor: accountNameCheck ? 'rgba(255, 165, 0, .3)' : 'rgba(222, 241, 245)',
                paddingLeft: '11px' // Matches padding of select component.
            }}
        >
            <Col>
                <Space>
                    <Tooltip title={`Filter view by account. Logged in as ${userDetails.userName}`}>
                        <FontAwesomeIcon icon={faProjectDiagram} />
                    </Tooltip>
                    <Tooltip title="Copy active customer account ID.">
                        <FontAwesomeIcon
                            icon={faCopy}
                            cursor='pointer'
                            onClick={() => {
                                navigator.clipboard.writeText(selectedUserDetails.accountID);
                                message.success('Copied account ID to clipboard.');
                            }}
                        />
                    </Tooltip>
                </Space>
            </Col>
            <Col>
                <Select
                    style={{ width: '180px' }}
                    bordered={false}
                    value={selectedUserDetails.accountName}
                    onChange={onChange}
                    disabled={props.disabled}
                    showSearch={true}
                    filterOption={(inputValue, option) => {
                        return (
                            option.value.toLowerCase().includes(inputValue.toLowerCase()) ||
                            option.name.toLowerCase().includes(inputValue.toLowerCase())
                        );
                    }}
                    options={options}
                />
            </Col>
        </Row>
    );
}

AccountChooser.propTypes = {
    setAccountDetails: PropTypes.func,
    disabled: PropTypes.bool,
    erpId: PropTypes.string
};

