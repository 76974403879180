import React , { useContext, useEffect, useState, useCallback } from 'react';
import { Modal, List, Row, Col, Button, message, Tooltip, PageHeader, Space, Grid } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrashAlt, faShieldAlt } from '@fortawesome/free-solid-svg-icons';

import { SolutionApiContext, SelectedAccountContext, PermissionsContext, newUniqueId, testPermission } from '../../services';
import displayErrors from '../../services/DisplayErrors';
import './WhitelistGroups.css';

import { WhitelistForm, NewWhitelistModal } from './WhitelistForm';

const { useBreakpoint } = Grid;

const CAN_ADD_WHITELIST = 'whitelist.add';
const CAN_DELETE_WHITELIST = 'whitelist.delete';

export default function Whitelist() {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);

    const screens = useBreakpoint();

    const [books, updateBooks] = useState([]);
    const [selectedGroup, updateSelectedGroup] = useState({});

    const canCreateWhitelist = testPermission(CAN_ADD_WHITELIST, permissionsMap);
    const canDeleteWhitelist = testPermission(CAN_DELETE_WHITELIST, permissionsMap);

    const [showNewWhitelist, updateShowNewWhitelist] = useState(false);
    const [whitelistErrors, updateWhitelistErrors] = useState(null);


    const onLoad = useCallback(async (selectedGroupId) => {
        try {
            const accountId = userDetails.accountID;
            const booksResponse = await solutionApi.listWhitelistGroups(accountId);
            const books = booksResponse.data;
            books.sort((a, b) => a.name.localeCompare(b.name));
            updateBooks(books);

            let jsonData = booksResponse.data;
            displayErrors(jsonData.summary, jsonData.errors, 15);

            let match = {};
            if (selectedGroupId != null) {
                books.map((book) => {
                    if (book.id === selectedGroupId) {
                        match = book;
                    }
                    return book;
                });
            } else if (books.length > 0) {
                match = books[0];
            }

            if (match != null) {
                updateSelectedGroup(match);
            }

        } catch (exception) {
            console.log('problems');
            message.error('Failed lo list whitelist groups');
        }
    }, [solutionApi, userDetails.accountID]);

    const deleteBook = useCallback((groupToDelete) => {
        Modal.confirm({
            title: `Are you sure you want to remove Whitelist Group "${groupToDelete.name}" from the Whitelists?`,
            icon: <ExclamationCircleOutlined />,
            okButtonProps: { className: 'hook-whitelist-dashboard-group-delete-confirm' },
            cancelButtonProps: { className: 'hook-whitelist-dashboard-group-delete-cancel' },
            async onOk() {
                const response = await solutionApi.deleteWhitelistGroup(userDetails.accountID, groupToDelete.id);
                if (response.ok) {
                    await onLoad();
                }
            },
        });
    }, [onLoad, solutionApi, userDetails.accountID]);

    useEffect(() => {
        onLoad(null);
    }, [onLoad]);

    const addNewBook = useCallback(async (newWhitelist) => {

        try {
            const accountId = userDetails.accountID;
            newWhitelist.id = newUniqueId();

            const response = await solutionApi.createWhitelistGroup(accountId, newWhitelist);
            if (response.ok) {
                updateWhitelistErrors(null);
                updateShowNewWhitelist(false);
            } else {
                updateWhitelistErrors(response.data);
            }
            onLoad(selectedGroup.id || null);
        } catch (exception) {
            console.log(exception);
        }

    }, [solutionApi, userDetails.accountID, onLoad, selectedGroup.id]);

    const onNewWhitelistCancel = useCallback(() => {
        updateWhitelistErrors(null);
        updateShowNewWhitelist(false);
    }, []);

    const onSelectBook = useCallback((whitelistBook) => {
        updateSelectedGroup(whitelistBook);
    }, []);

    const onNameChange = useCallback(() => {
        // sub component has changed the text
        onLoad(selectedGroup.id || null);
    },[onLoad, selectedGroup.id]);

    const selectedGroupId = selectedGroup.id || null;

    const deleteWhitelistProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faTrashAlt} />
    };

    let deleteWhitelistText = 'Click to delete this whitelist entry. This cannot be undone.';
    if (!canDeleteWhitelist) {
        deleteWhitelistText = 'You lack the required permissions to be able to delete any Whitelist';
        deleteWhitelistProps.disabled = true;
    }

    const addWhitelistProps = {
        style: { margin: '12px 8px' },
        onClick: () => {
            updateShowNewWhitelist(true);
        }
    };

    let addWhitelistText = 'Click to add a new Whitelists';
    if (!canCreateWhitelist) {
        addWhitelistText = 'You lack the required permissions to be able to create a new Whitelist';
        addWhitelistProps.disabled = true;
    }

    const boxStyles = { backgroundColor: 'white' };

    return (
        <>
            <Row align='middle'>
                <Col>
                    <FontAwesomeIcon
                        className='hook-whitelists-dashboard-whitelistbookspage'
                        icon={faShieldAlt}
                        size='4x'
                    />
                </Col>
                <Col>
                    <PageHeader backIcon={null} title="Whitelists" />
                </Col>
            </Row>
            <Row className='gutter-bottom'>
                <Col>
                    <Space>
                        <FontAwesomeIcon icon={faInfoCircle} size='2x' color='blue' />
                        Create groups of IPs to whitelist. You use them for input and/or output whitelisting.
                    </Space>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={8}>
                    <div style={{ ...boxStyles, minHeight: '100%' }}>
                        <Tooltip title={addWhitelistText}>
                            <Button className='hook-whitelists-dashboard-addnewbook' {...addWhitelistProps}>
                                New Whitelist
                            </Button>
                        </Tooltip>
                        <div className='hook-whitelists-dashboard-existingwhitelistbookslist'>
                            <NewWhitelistModal
                                errors={whitelistErrors}
                                visible={showNewWhitelist}
                                onConfirm={addNewBook}
                                onCancel={onNewWhitelistCancel}
                            />
                            <List
                                style={screens.lg ? null : { maxHeight: '40vh', overflowY: 'auto' }}
                                dataSource={books}
                                bordered={true}
                                renderItem={item => (
                                    <List.Item
                                        key={item.id}
                                        className={
                                            item.id === selectedGroup.id ?
                                                'white-list-item selected' : 'white-list-item'
                                        }
                                        onClick={() => onSelectBook(item)}
                                    >
                                        <span className='hook-whitelists-dashboard-existingwhitelistbookname'>
                                            {item.name}
                                        </span>
                                        <Tooltip title={deleteWhitelistText}>
                                            <Button
                                                onClick={() => deleteBook(item)}
                                                className='hook-whitelists-dashboard-existingwhitelistbookdelete'
                                                {...deleteWhitelistProps}
                                            />
                                        </Tooltip>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                </Col>

                <Col xs={24} lg={16}>
                    <div style={{ ...boxStyles, padding: '16px 32px' }}>
                        <WhitelistForm selectedGroupId={selectedGroupId} onNameChange={onNameChange} />
                    </div>
                </Col>
                <Col span={0} />
            </Row>
        </>
    );
}
