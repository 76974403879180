import { Col, Row } from 'antd';
import { faExclamationTriangle, faSignInAlt, faVideo } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IndicatorTile from './IndicatorTile';
import { ALERT_SEVERITIES } from './IndicatorTile';

const Indicators = ({
    alertGroups = {},
    alertsLoading = true,
    numberOfActiveChannels = '-',
    numberOfSources = '-'
}) => {
    const [numberOfAlertingChannels, setNumberOfAlertingChannels] = useState(0);
    const [numberOfOtherAlerts, setNumberOfOtherAlerts] = useState(0);
    const [highestSeverityAlertChannels, setHighestSeverityAlertChannels] = useState('absent');
    const [highestSeverityAlertOther, setHighestSeverityAlertOther] = useState('absent');

    useEffect(() => {
        // Only needs to run when 'alertGroups' changes.
        function getHighestSeverity(alertsArray, startingHighestSeverity) {
            let highestAlertSeverity = startingHighestSeverity;
            alertsArray.forEach((alert) => {
                highestAlertSeverity = (
                    ALERT_SEVERITIES.indexOf(highestAlertSeverity) > ALERT_SEVERITIES.indexOf(alert.severity) ?
                        highestAlertSeverity : alert.severity
                );
            });
            return highestAlertSeverity;
        }

        let newNumberOfAlertingChannels = 0;
        let newHighestSeverityAlertChannels = ALERT_SEVERITIES[0];
        let newNumberOfOtherAlerts = 0;
        let newHighestSeverityAlertOther = ALERT_SEVERITIES[0];
        for (const [id, alertGroup] of Object.entries(alertGroups)) {
            if (id != 'other') {
                // Alert related to a channel (includes source alerts).
                newNumberOfAlertingChannels++;
                newHighestSeverityAlertChannels = getHighestSeverity(alertGroup.alerts, newHighestSeverityAlertChannels);
            } else {
                newHighestSeverityAlertOther = getHighestSeverity(alertGroup.alerts, newHighestSeverityAlertOther);
                alertGroup.alerts.length ? newNumberOfOtherAlerts += alertGroup.alerts.length : newNumberOfOtherAlerts;
            }
        }
        setHighestSeverityAlertChannels(newHighestSeverityAlertChannels);
        setHighestSeverityAlertOther(newHighestSeverityAlertOther);
        setNumberOfAlertingChannels(newNumberOfAlertingChannels);
        setNumberOfOtherAlerts(newNumberOfOtherAlerts);
    }, [alertGroups]);

    return (
        <Row gutter={[16, 16]} justify='space-evenly'>
            <Col>
                <Link to='/sources/' className='hook-aquila-dashboard-sources'>
                    <IndicatorTile
                        icon={faSignInAlt}
                        title='Sources'
                        value={`${numberOfSources}`}
                    />
                </Link>
            </Col>
            <Col>
                <IndicatorTile
                    icon={faVideo}
                    title='Active Channels'
                    value={`${numberOfActiveChannels}`}
                />
            </Col>
            <Col>
                <IndicatorTile
                    icon={faExclamationTriangle}
                    title='Channels in Alarm'
                    value={alertsLoading ? '-' : `${numberOfAlertingChannels}`}
                    severity={highestSeverityAlertChannels}
                />
            </Col>
            <Col>
                <Link to='/alarms'>
                    <IndicatorTile
                        icon={faExclamationTriangle}
                        title='Other Alarms'
                        value={alertsLoading ? '-' : `${numberOfOtherAlerts}`}
                        severity={highestSeverityAlertOther}
                    />
                </Link>
            </Col>
        </Row>
    );
};

Indicators.propTypes = {
    alertGroups: PropTypes.object,
    alertsLoading: PropTypes.bool,
    numberOfActiveChannels: PropTypes.number,
    numberOfSources: PropTypes.number
};

export default Indicators;

