import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Table } from 'antd';

function RenderDateCell(text) {
    return <span style={{ wordBreak: 'break-all' }}>{text}</span>;
}

export default function TemplateLogModal({ revision, onOk }) {
    const close = useCallback(() => {
        onOk();
    }, [onOk]);

    const columns = [
        {
            title: 'Revision',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Date',
            dataIndex: 'datetime',
            key: 'date',
            // eslint-disable-next-line react/display-name
            render: (text) => {
                return <span style={{ whiteSpace: 'nowrap' }}>{text}</span>;
            },
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: RenderDateCell,
        },
    ];

    return (
        <Modal
            title="Revision Log"
            open={revision != null}
            closable={false}
            style={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '80%',
            }}
            width={'auto'}
            footer={[
                <Button key="Close" onClick={close}>
                    Close
                </Button>,
            ]}
        >
            <Row>
                <Table
                    pagination={{
                        defaultPageSize: 5,
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '10', '20'],
                        position: ['bottomCenter'],
                    }}
                    size="middle"
                    dataSource={revision}
                    columns={columns}
                    rowKey="id"
                    scroll={{ x: true }}
                />
            </Row>
        </Modal>
    );
}

TemplateLogModal.propTypes = {
    revision: PropTypes.array,
    onOk: PropTypes.func,
    revisionId: PropTypes.string,
};
