import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IndicatorTile.css';
import { Col, Row, Space } from 'antd';
import PropTypes from 'prop-types';

export const ALERT_SEVERITIES = ['absent', 'warning', 'critical'];

const IndicatorTile = ({ icon, title, value, severity }) => {
    return (
        <Row className={`indicator-tile${severity && severity !== 'absent' ? ` indicator-tile-${severity}` : ''}`}>
            <Col xs={24}><Space size={4}><FontAwesomeIcon icon={icon} />{title}</Space></Col>
            <Col xs={24} className='indicator-tile-value'>{value}</Col>
        </Row>
    );
};

IndicatorTile.propTypes = {
    icon: PropTypes.object,
    title: PropTypes.string,
    value: PropTypes.string,
    severity: PropTypes.oneOf(ALERT_SEVERITIES)
};

export default IndicatorTile;