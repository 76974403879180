import React from 'react';
import Cookies from 'js-cookie';

// use the __Host_ prefix instead of domain for higher isolation and security
const SELECTED_ACCOUNT_COOKIE = '__Host_selected-account';

const SelectedAccountContext = React.createContext(null);

function updateSelectedAccountCookie(newAccount) {
    // remove the previous incarnation of this cookie which didn't have the domain/path set
    Cookies.remove(SELECTED_ACCOUNT_COOKIE);
    Cookies.set(
        SELECTED_ACCOUNT_COOKIE,
        `${newAccount.accountName}:::${newAccount.accountID}`,
        {
            domain: '.aas.mediakind.com',
            path: '/'
        }
    );
}

export default SelectedAccountContext;
export {
    SELECTED_ACCOUNT_COOKIE,
    updateSelectedAccountCookie,
};
