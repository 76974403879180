import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { Ticker } from '../pages/util';

import { Space } from 'antd';

const SECOND_TIMER = 1000;

export default function Time() {
    const [time, setDate] = useState(new Date());
    const [fullDisplay, toggleFormat] = useState(false);

    const tick = useCallback((quitContainer) => {
        if (!quitContainer || !quitContainer.quitting) {
            setDate(new Date());
        }
    }, [setDate]);

    return (
        <>
            <Ticker asyncCallback={tick} timeout={SECOND_TIMER} />
            <div onClick={() => {toggleFormat(!fullDisplay);}} style={{ cursor: 'pointer' }}>
                <Space size={3}>
                    <FontAwesomeIcon icon={faClock} />
                    {fullDisplay ? time.toUTCString() : time.toUTCString().slice(-12)}
                </Space>
            </div>
        </>
    );
}
