import React from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;

export default function MKFooter() {
    return (
        <Footer style={{ padding: '10px 20px', textAlign: 'center' }}>
            &copy; Copyright MediaKind &#124; 2020 - {new Date().getFullYear()} &#124; All Rights Reserved.
        </Footer>
    );
}
