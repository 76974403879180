import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ClusterCard from './ClusterCard';
import { faTrashAlt, faExclamationCircle, faInfoCircle, faPencilAlt, faPlay, faSpinner, faStop } from '@fortawesome/free-solid-svg-icons';
import { Card, Modal, Button, Tooltip, Space, Descriptions, Collapse, Grid } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Ticker } from '../util';
import { DEPLOYMENTS_REFRESH_PERIOD_MS } from './Deployments';
import { SolutionApiContext, SelectedAccountContext } from '../../services';
import displayErrors from '../../services/DisplayErrors';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const { useBreakpoint } = Grid;

export default function DeploymentCard({
    deployment,
    deploymentId,
    deploymentStatus,
    updateInfoModalData,
    setDeploymentModalData
}) {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountID = userDetails.accountID;
    const screens = useBreakpoint();

    const [clustersList, updateClustersList] = useState();

    const startDeployment = useCallback(() => {
        Modal.confirm({
            title: `Are you sure you want to start the Deployment '${deploymentId}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
            async onOk() {
                await solutionApi.startDeployment(accountID, deploymentId);
            },
        });
    }, [accountID, solutionApi, deploymentId]);

    const stopDeployment = useCallback(() => {
        Modal.confirm({
            title: `Are you sure you want to stop the Deployment '${deploymentId}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
            async onOk() {
                await solutionApi.stopDeployment(accountID, deploymentId);
            },
        });
    }, [accountID, solutionApi, deploymentId]);

    const deleteDeployment = useCallback(() => {
        Modal.confirm({
            title: `Are you sure you want to delete the Deployment '${deploymentId}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
            async onOk() {
                await solutionApi.deleteDeployment(accountID, deploymentId);
            },
        });
    }, [accountID, solutionApi, deploymentId]);

    let startButtonText = '';
    const startProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faPlay} />
    };

    let stopButtonText = '';
    const stopProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faStop} />
    };

    let infoButtonText = 'Info';
    const infoProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faInfoCircle} />
    };
    infoProps.onClick = () => {
        const deploymentStatusWithoutPassword = structuredClone(deploymentStatus);
        for (let [, cluster] of Object.entries(deploymentStatusWithoutPassword.cluster_status)) {
            cluster.controller_password = '*';
        }
        updateInfoModalData({
            'title': 'Deployment Info',
            'data': deploymentStatusWithoutPassword
        });
    };
    const editButtonText = 'Edit';
    const editProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faPencilAlt} />,
        disabled: true
    };
    editProps.onClick = () => {
        setDeploymentModalData({ ...{ 'id': deploymentId }, ...deployment });
    };

    let deleteButtonText = 'Delete';
    const deleteProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faTrashAlt} />
    };
    deleteProps.onClick = () => { deleteDeployment(); };
    deleteProps.disabled = ['Deploying', 'Deleting', 'Stopping'].includes(deployment.status);

    switch (deploymentStatus.status) {
    case 'Stopped':
        startProps.icon = <FontAwesomeIcon icon={faPlay}/>;
        startProps.onClick = ()=>{ startDeployment(); };
        startProps.disabled = false;
        startButtonText = 'Activate';

        editProps.disabled = false;

        stopProps.disabled = true;
        stopButtonText = 'Already Deactivated';

        break;

    case 'Deploying':
        startProps.icon = <FontAwesomeIcon icon={faSpinner} pulse={true} />;
        startProps.disabled = true;
        startButtonText = 'Already Activating';


        stopButtonText = 'Cannot stop when Activating';
        stopProps.disabled = true;

        deleteButtonText = 'Cannot delete when Activating';
        deleteProps.disabled = true;

        break;
    case 'Deployed':
        startProps.icon = <FontAwesomeIcon icon={faPlay} />;
        startProps.disabled = true;
        startButtonText = 'Already Activated';

        stopProps.icon = <FontAwesomeIcon icon={faStop} />;
        stopProps.disabled = false;
        stopProps.onClick = ()=>{ stopDeployment(); };
        stopButtonText = 'Deactivate';

        break;
    case 'Deploy Failed':
    case 'Delete Failed':
        startProps.icon = <FontAwesomeIcon icon={faPlay} />;
        startProps.disabled = false;
        startProps.onClick = ()=>{ startDeployment(); };
        startButtonText = 'Activate';

        stopProps.icon = <FontAwesomeIcon icon={faStop} />;
        stopProps.disabled = false;
        stopProps.onClick = ()=>{ stopDeployment(); };
        stopButtonText = 'Deactivate';

        break;
    case 'Stopping':
    case 'Deleting':
        startProps.disabled = true;
        startButtonText = 'Cannot activate when Deactivating';

        stopProps.icon = <FontAwesomeIcon icon={faSpinner} pulse={true} />;
        stopProps.disabled = true;
        stopButtonText = 'Already Deactivating';
        break;
    default:
        break;
    }

    const refreshClusters = useCallback(async (quitContainer) => {
        try {
            const clustersResponse = await solutionApi.listClusters(
                accountID,
                deploymentId
            );
            if (clustersResponse.ok) {
                if (
                    !quitContainer ||
                    (!!quitContainer && ! quitContainer.quitting)
                ) {
                    updateClustersList(clustersResponse.data);
                }
            } else {
                let jsonData = clustersResponse.data;
                displayErrors(jsonData.summary, jsonData.errors, 15);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, accountID, deploymentId]);

    const refreshClustersTick = useCallback(async (quitContainer) => {
        await refreshClusters(quitContainer);
    }, [refreshClusters]);

    useEffect(() => {
        refreshClusters(null);
    }, [refreshClusters]);

    const compareCluster = (clusterA, clusterB) => {
        let result = clusterA.config.name.localeCompare(clusterB.config.name);
        if (result == 0) {
            result = clusterA.config.id.localeCompare(clusterB.config.id);
        }
        return result;
    };

    let clusters = null;
    if (clustersList) {
        const clusterCards = clustersList.sort(compareCluster).map((cluster) => (
            <ClusterCard
                key={cluster.id}
                deploymentId={deploymentId}
                cluster={cluster.config}
                clusterStatus={cluster.status}
                updateInfoModalData={updateInfoModalData}
            />
        ));

        clusters = (
            <Collapse collapsible="header">
                {clusterCards}
            </Collapse>
        );
    }


    return (
        <>
            <Ticker
                key='ticker'
                asyncCallback={refreshClustersTick}
                timeout={DEPLOYMENTS_REFRESH_PERIOD_MS}
            />
            <Card
                title={`ID: ${deploymentId}`}
                size='small'
                className='gutter-bottom'
                style={{ overflowX: 'scroll' }}
                headStyle={{ minWidth: '400px' }}
                bodyStyle={{ minWidth: '400px' }}
                extra={
                    <Space>
                        <Tooltip title={startButtonText}>
                            <Button {...startProps} />
                        </Tooltip>
                        <Tooltip title={stopButtonText}>
                            <Button {...stopProps} />
                        </Tooltip>
                        <Tooltip title={infoButtonText}>
                            <Button {...infoProps} />
                        </Tooltip>
                        <Tooltip title={editButtonText}>
                            <Button {...editProps} />
                        </Tooltip>
                        <Tooltip title={deleteButtonText}>
                            <Button {...deleteProps} />
                        </Tooltip>
                    </Space>
                }
            >
                <Descriptions
                    bordered={true}
                    column={{ xxl: 6, xl: 6, lg: 1, md: 1, sm: 1, xs: 1 }}
                    size='small'
                    className='gutter-bottom'
                    layout={screens.xl ? 'vertical' : 'horizontal'}
                >
                    <Descriptions.Item label="Cloud">{deployment.cloud}</Descriptions.Item>
                    <Descriptions.Item label="Region">{deployment.region}</Descriptions.Item>
                    <Descriptions.Item label="Version">{deployment.version}</Descriptions.Item>
                    <Descriptions.Item label="Created">
                        {
                            deploymentStatus.deploy_start_time ?
                                dayjs(deploymentStatus.deploy_start_time).fromNow() : ''
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Progress">
                        {deploymentStatus.progress === '' ? 'n/a' : deploymentStatus.progress}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                        {`${deploymentStatus.status}${deployment.maintenance_level > 0 ? ' (under maintenance)' : ''}`}
                    </Descriptions.Item>
                </Descriptions>
                {clusters}
            </Card>
        </>
    );
}

DeploymentCard.propTypes = {
    deployment: PropTypes.object,
    deploymentStatus: PropTypes.object,
    deploymentId: PropTypes.string,
    updateInfoModalData: PropTypes.func,
    setDeploymentModalData: PropTypes.func
};
