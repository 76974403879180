import React from 'react';

const EntitlementsContext = React.createContext(null);

function checkEntitlement(quotaName, context) {
    if (context && context.entitlements) {
        // console.log(context.entitlements);
        if (quotaName in context.entitlements) {
            return context.entitlements[quotaName].allowed;
        }
    }
    return 0;
}

export default EntitlementsContext;
export {
    checkEntitlement
};
