import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Select, Space, message } from 'antd';
import { SolutionApiContext, SelectedAccountContext } from '../services';
import PropTypes from 'prop-types';

function filterEmpty(value) {
    return value.length > 0;
}

function parseLocation(location){
    const elements = location.trim().split('/').filter(filterEmpty);

    let fields = {};
    let i = 0;
    for (; i < elements.length - 1; i += 2) {
        fields[elements[i]] = elements[i + 1];
    }
    if (i < elements.length) {
        fields['deployments'] = elements[i];
    }
    return fields;
}

// A custom control that allows the user to create a location entry by selecting a deployment and optional zone
// from the list of deployments available to the current user.
export default function LocationChooser(props) {
    const [fields] = useState(parseLocation(props.location));
    const initialLocation = props.location;
    const setLocation = props.setLocation;
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const [deploymentsList, updateDeploymentsList] = useState([]);
    const [zonesList, updateZonesList] = useState(['automatic']);
    const [selectedDeployment, updateSelectedDeployment] = useState(fields.deployments);
    const [selectedZone, updateSelectedZone] = useState(fields.zones);

    useEffect(() => {
        let deployment = deploymentsList.find(d => d.id === selectedDeployment);
        if (deployment) {
            // Always add automatic as an option
            const lengthOfPrefixToRemove = deployment.config.cloud === 'aws' ? deployment.config.region.length : 0;
            let zones = ['automatic'];
            if (deployment.status.zones.zone1 && deployment.status.zones.zone1.workerNodePool) {
                zones.push(deployment.status.zones.zone1.zone.substring(lengthOfPrefixToRemove));
            }
            if (deployment.status.zones.zone2 && deployment.status.zones.zone2.workerNodePool) {
                zones.push(deployment.status.zones.zone2.zone.substring(lengthOfPrefixToRemove));
            }
            if (deployment.status.zones.zone3 && deployment.status.zones.zone3.workerNodePool) {
                zones.push(deployment.status.zones.zone3.zone.substring(lengthOfPrefixToRemove));
            }
            // Add the current value if required
            if (fields.zones && !zones.includes(fields.zones)) {
                zones.push(fields.zones);
            }

            updateZonesList(zones);
        }
    }, [selectedDeployment, deploymentsList, updateZonesList, fields.zones]);

    useEffect(()=> {
        if (setLocation){
            let location = 'deployments/' + selectedDeployment;
            if (selectedZone) {
                location += '/zones/' + selectedZone;
            }
            if (location !== initialLocation) {
                setLocation(location);
            }
        }
    }, [setLocation,initialLocation, selectedDeployment, selectedZone]);

    const refreshDeployments = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;
        const shallContinue = () => {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const deploymentsResponse = await solutionApi.listDeployments(accountId);
            if (shallContinue()) {
                if (deploymentsResponse.data) {
                    updateDeploymentsList(deploymentsResponse.data);
                    console.log(deploymentsResponse.data);
                }
            }
        } catch (exception) {
            console.log(exception);
            message.error('Failed to retrieve Deployments');
        }
    }, [solutionApi, userDetails.accountID]);

    useEffect(() => {
        // once only on load
        refreshDeployments(null);
    }, [refreshDeployments]);

    return (
        <Space>Deployment:
            <Select className='hook-location-deployment'
                style={{ width: '380px' }}
                value={ selectedDeployment }
                loading={deploymentsList.length == 0}
                onChange={updateSelectedDeployment}>
                { deploymentsList.map(d=> <Select.Option key={d.id} value={d.id}>{d.id}</Select.Option>)}
            </Select>
            Zone:
            <Select className='hook-location-zone'
                style={{ width: '180px' }}
                value={ selectedZone }
                placeholder='automatic'
                loading={deploymentsList.length == 0}
                onChange={updateSelectedZone}>
                { zonesList.map(z => <Select.Option key={z} value={z === 'automatic' ? '' : z}>{z}</Select.Option>)}
            </Select>
        </Space>
    );
}

LocationChooser.propTypes = {
    setLocation: PropTypes.func,
    location: PropTypes.string,
    readOnly: PropTypes.bool
};
