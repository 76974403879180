import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Row, Col, Badge } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import { Ticker } from '../util';
import { SolutionApiContext, SelectedAccountContext } from '../../services';
import displayErrors from '../../services/DisplayErrors';
import NotificationsTable from './NotificationsTable';

const iconMultipler = '7x';
const NOTIFICATION_REFRESH_PERIOD_MS = 5 * 1000;
const oneHour = 1;
const oneDay = 24;

export default function Notifications() {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;

    const [statusIndicators, updateStatusIndicators] = useState({
        lastHourNotificationsCount: 0,
        lastDayNotificationsCount: 0,
        notificationsStartIndex: 0,
        notificationsTotal: 0
    });

    const [notificationsList, updateNotificationsList] = useState(null);

    const notificationSorter = (a, b) => {
        return new Date(b.eventTime) - new Date(a.eventTime);
    };

    const fromXHoursAgo = (date, hours) => {
        const from = 1000 * 60 * 60 * hours;
        const xHoursAgo = Date.now() - from;

        return date > xHoursAgo;
    };

    const refreshNotifications = useCallback(async (quitContainer) => {
        try {
            const notificationsResponse = await solutionApi.listNotifications(accountId);
            if (notificationsResponse.ok) {

                if (!quitContainer || !quitContainer.quitting) {
                    notificationsResponse.data.sort(notificationSorter);
                    let lastHourNotifications = 0;
                    let lastDayNotifications = 0;

                    notificationsResponse.data.forEach((element, index) => {
                        element.key = index; // Set key for the antd Table component.
                        let dt = new Date(element.eventTime);
                        if (fromXHoursAgo(dt, oneHour)) {
                            lastHourNotifications++;
                        }
                        if (fromXHoursAgo(dt, oneDay)) {
                            lastDayNotifications++;
                        }
                    });

                    updateNotificationsList(notificationsResponse.data);

                    const responseStartIndex = notificationsResponse.headers.get('x-item-start-index') || 'absent';
                    const responseTotal = notificationsResponse.headers.get('x-item-total') || 'absent';

                    updateStatusIndicators((currentState) => {
                        return {
                            ...currentState,
                            lastHourNotificationsCount: lastHourNotifications,
                            lastDayNotificationsCount: lastDayNotifications,
                            notificationsStartIndex: responseStartIndex,
                            notificationsTotal: responseTotal
                        };
                    });
                }
            } else {
                console.log(notificationsResponse);
                let jsonData = notificationsResponse.data;
                displayErrors(jsonData.summary, jsonData.errors, 15);
            }
        } catch (exception) {
            console.log('Error in request for Notifications.');
        }

    }, [solutionApi, accountId]);


    const refreshNotificationsTick = useCallback(async (quitContainer) => {
        await refreshNotifications(quitContainer);
    }, [refreshNotifications]);

    // once only OnLoad
    useEffect(() => {
        refreshNotifications(null);
    }, [refreshNotifications]);

    const notificationIndicator = (indicatorValue, duration) => {
        return (
            <>
                <Row justify='center'>
                    <Col>
                        <Badge
                            count={indicatorValue}
                            showZero={true}
                            offset={[0, 8]}
                            color={indicatorValue === 0 ? 'rgba(0, 0, 0, .85)' : 'var(--ant-primary-color)'}
                        >
                            <FontAwesomeIcon icon={faBell} size={iconMultipler} />
                        </Badge>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Notifications in last {duration} hour(s)
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <>
            <Ticker asyncCallback={refreshNotificationsTick} timeout={NOTIFICATION_REFRESH_PERIOD_MS} />
            <Row justify='space-around' gutter={[16, 16]} style={{ marginTop: '16px' }} className='gutter-bottom'>
                <Col>{notificationIndicator(statusIndicators.lastHourNotificationsCount, oneHour)}</Col>
                <Col>{notificationIndicator(statusIndicators.lastDayNotificationsCount, oneDay)}</Col>
            </Row>
            <NotificationsTable notificationsList={notificationsList} />
        </>
    );
}
