import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission, newUniqueId } from '../../services';
import { Modal, Table, Button, Row, Col, Tooltip, Dropdown, Space, PageHeader, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faExclamationCircle, faSpinner, faPencilAlt, faInfo, faEllipsisV, faCogs, faInfoCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';

import AdvancedControlModal from './AdvancedControlModal';
import NewDvrModal from './NewDvrModal';
import EditDvrModal from './EditDvrModal';
import { SearchFilter, onFilterIncludes } from '../../components/listview/ListView';

import getMappedStatus, { getMappedIcon, getMappedDisabledIconState } from './DvrStates';
import * as STATE from './DvrStates';

import { Ticker } from '../util';
const DVRS_REFRESH_PERIOD_MS = 3 * 1000;

const CAN_CREATE_DVR = 'dvrs.add';
const CAN_EDIT_DVR = 'dvrs.edit';
const CAN_DELETE_DVR = 'dvrs.delete';
const CAN_START_DVR = 'dvrs.start';
const CAN_STOP_DVR = 'dvrs.stop';


function MoreActions({ dvr, advancedControlAction }) {

    const items = [
        {
            key: '1',
            label: 'Advanced DVR control',
            icon: (
                <FontAwesomeIcon
                    icon={faCogs}
                    className='advanced-control-menu-item-icon hook-dvrs-moreactions-advancedpopup-action'
                />
            ),
            onClick: () => {
                advancedControlAction(dvr);
            }
        }
    ];

    return (
        <Dropdown menu={ { items } } trigger={['click']}>
            <Tooltip title='More actions'>
                <Button
                    className='hook-dvrs-dvrtable-moreactionsbutton'
                    shape='circle'
                    icon={<FontAwesomeIcon icon={faEllipsisV}/>}
                />
            </Tooltip>
        </Dropdown>
    );
}

MoreActions.propTypes = {
    dvr: PropTypes.object,
    advancedControlAction: PropTypes.func
};


export default function Dvrs() {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);
    const accountId = userDetails.accountID;

    const [dvrs, updateDvrs] = useState([]);

    const [showNewDvr, updateShowNewDvr] = useState(false);
    const [dvrErrors, updateDvrErrors] = useState(null);
    const [dvrDataEditing, updateDvrDataEditing] = useState(null);
    const [dvrEditEnabled, updateDvrEditEnabled] = useState(true);
    const [advancedControlDvr, updateAdvancedControlDvr] = useState(null);

    const canEditDvr = testPermission(CAN_EDIT_DVR, permissionsMap);
    const canDeleteDvr = testPermission(CAN_DELETE_DVR, permissionsMap);
    const canStartDvr = testPermission(CAN_START_DVR, permissionsMap);
    const canStopDvr = testPermission(CAN_STOP_DVR, permissionsMap);

    const refreshDvrs = useCallback(async (quitContainer) => {
        const accountId = userDetails.accountID;
        const shallContinue = () => {
            return (!quitContainer || (!!quitContainer && !quitContainer.quitting));
        };
        try {
            const dvrsResponse = await solutionApi.listDvrs(accountId);
            if (shallContinue()) {
                if (dvrsResponse.data) {
                    updateDvrs(dvrsResponse.data);
                }
            }
        } catch (exception) {
            console.log(exception);
            message.error('Failed to retrieve Dvrs');
        }
    }, [solutionApi, userDetails.accountID]);


    useEffect(() => {
        // once only on load
        refreshDvrs(null);
    }, [refreshDvrs]);

    useEffect(() => {
        if (advancedControlDvr != null) {
            const dvr = dvrs.find(element => element.id === advancedControlDvr.id);
            if (dvr){
                updateAdvancedControlDvr(dvr);
            }
        }
    },[dvrs, advancedControlDvr]);

    const deleteDvr = useCallback((dvrId, dvrName) => {
        Modal.confirm({
            title: `Are you sure you want to delete the DVR '${dvrName}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
            okButtonProps: { className: 'hook-dvrs-dashboard-dvrdeleteconfirm' },
            cancelButtonProps: { className: 'hook-dvrs-dashboard-dvrdeletecancel' },
            async onOk() {
                await solutionApi.deleteDvr(accountId, dvrId);
            },
        });
    }, [accountId, solutionApi]);

    const editDvr = useCallback(async (dvrId, editEnabled = true) => {
        try {
            const dvrDataResponse = await solutionApi.getDvr(accountId, dvrId);
            if (dvrDataResponse.ok) {
                updateDvrDataEditing(dvrDataResponse.data);
                updateDvrEditEnabled(editEnabled);
            } else {
                updateDvrDataEditing(null);
            }
        } catch (exception) {
            updateDvrDataEditing(null);
        }
    }, [solutionApi, accountId]);

    const startDvr = useCallback(async (dvrId, instanceId) => {
        await solutionApi.startDvr(accountId, dvrId, instanceId);
    },[solutionApi, accountId]);

    const stopDvr = useCallback((dvrId, instanceId, dvrName) => {
        Modal.confirm({
            title: `Are you sure you want to stop the DVR '${dvrName}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x'/>,
            okButtonProps: { className: 'hook-dvrs-dashboard-dvrstopconfirm' },
            cancelButtonProps: { className: 'hook-dvrs-dashboard-dvrstopcancel' },
            async onOk() {
                await solutionApi.stopDvr(accountId, dvrId, instanceId);
            },
        });
    }, [solutionApi, accountId]);

    const columns = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        onFilter: onFilterIncludes('name'),
        filterDropdown: SearchFilter,
        className: 'hook-dvrs-dvrtable-name',
        defaultSortOrder: 'ascend'
    }, {
        title: 'Template',
        sorter: (a, b) => a.template.localeCompare(b.template),
        onFilter: onFilterIncludes('template'),
        filterDropdown: SearchFilter,
        dataIndex: 'template',
        key: 'template',
        className: 'hook-dvrs-dvrtable-template',
    }, {
        title: 'Location',
        dataIndex: ['instances', 0, 'location'],
        sorter: (a, b) =>  a?.instances[0].location.localeCompare(b?.instances[0].location),
        key: 'location',
        className: 'hook-dvrs-dvrtable-location',
        render: (...[, record, ]) => {
            if (record.instances.length > 0) {
                return record.instances[0].location;
            }
            else {
                return '';
            }
        }
    },
    {
        title: 'Status',
        sorter: (a, b) => a?.instances[0].status.state.localeCompare(b?.instances[0].status.state),
        onFilter: (value, record) => getMappedStatus(record).includes(value.trim()),
        filterDropdown: SearchFilter,
        dataIndex: 'state',
        key: 'state',
        className: 'hook-dvrs-dvrtable-state',
        render: (...[, record, ]) => {
            return getMappedStatus(record);
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        className: 'hook-dvrs-dvrtable-actions',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const dvrId = record.id;
            const dvrName = record.name;
            // We are in mono-region with only one instance, so taking shortcuts.
            const dvrState = record?.instances[0].status.state;
            const dvrInstanceId = record?.instances[0].id;

            let startButtonText = '';
            let editButtonText = 'Edit';
            let deleteButtonText = 'Delete';

            const startStopIcon = getMappedIcon(record);
            const iconPulse = (startStopIcon === faSpinner) ? true : false;

            const startStopProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={startStopIcon} pulse={iconPulse}/>,
                disabled: getMappedDisabledIconState(startStopIcon)
            };

            const editProps = {
                shape:'circle',
                icon: <FontAwesomeIcon icon={faPencilAlt}/>,
                onClick: ()=>{ editDvr(dvrId); }
            };

            const deleteProps = {
                shape:'circle',
                icon: <FontAwesomeIcon icon={faTrashAlt} />
            };

            switch (dvrState) {
            case STATE.STOPPED:
                startButtonText = 'Activate';

                startStopProps.onClick = ()=>{ startDvr(dvrId, dvrInstanceId); };
                editProps.onClick = ()=>{ editDvr(dvrId, true); };
                deleteProps.onClick = ()=>{ deleteDvr(dvrId, dvrName); };

                deleteProps.disabled = false;

                if (!canStartDvr) {
                    startButtonText = 'You lack the required permissions to start a DVR';
                    startStopProps.disabled = true;
                }

                break;

            case STATE.STARTING:
                startButtonText = 'Already Activating';
                editButtonText = 'View';
                deleteButtonText = 'Cannot delete when Active';

                editProps.icon = <FontAwesomeIcon icon={faInfo}/>;
                editProps.onClick = ()=>{ editDvr(dvrId, false); };
                deleteProps.disabled = true;

                break;
            case STATE.STARTED:
            case STATE.ERROR:
                startButtonText = 'Deactivate';
                editButtonText = 'View';
                deleteButtonText = 'Cannot delete when Active';

                startStopProps.onClick = ()=>{ stopDvr(dvrId, dvrInstanceId, dvrName); };

                if (!canStopDvr) {
                    startButtonText = 'You lack the required permissions to stop a DVR';
                    startStopProps.disabled = true;
                }

                editProps.icon = <FontAwesomeIcon icon={faInfo}/>;
                editProps.onClick = ()=>{ editDvr(dvrId, false); };
                deleteProps.disabled = true;
                break;
            case STATE.STOPPING:
                startButtonText = 'Already Stopping';
                editButtonText = 'View';
                deleteButtonText = 'Cannot delete when Active';

                editProps.onClick = ()=>{ editDvr(dvrId, false); };
                deleteProps.disabled = true;
                break;
            default:
                break;
            }

            if (!canEditDvr) {
                editButtonText = 'You lack the required permissions to make changes to any DVRs.';
                editProps.disabled = true;
            }

            if (!canDeleteDvr) {
                deleteButtonText = 'You lack the required permissions to delete any DVRs';
                deleteProps.disabled = true;
            }

            return (
                <Space size={4}>
                    <Tooltip title={startButtonText}>
                        <Button className='hook-dvrs-dvrtable-startstopbutton' { ...startStopProps } />
                    </Tooltip>
                    <Tooltip title={editButtonText}>
                        <Button className='hook-dvrs-dvrtable-editbutton' { ...editProps } />
                    </Tooltip>
                    <Tooltip title={deleteButtonText}>
                        <Button className='hook-dvrs-dvrtable-deletebutton' { ...deleteProps }/>
                    </Tooltip>
                    <MoreActions
                        dvr={record}
                        advancedControlAction={(dvr) => {
                            updateAdvancedControlDvr(dvr);
                        }}
                    />
                </Space>
            );
        }
    },
    ];

    const onAddNewDvr = useCallback(async (newDvr) => {
        let ok = false;
        try {
            const accountId = userDetails.accountID;
            const dvrId = newUniqueId();
            const payload = {
                'id': dvrId,
                'name': newDvr.name,
                'template': newDvr.template,
                'instances': [{
                    'id': dvrId,
                    'name': `${newDvr.name}_0`,
                    'location': newDvr.location,
                    'sourceURL': newDvr.sourceUrl,
                    'storageId': newDvr.storageUrl,
                    'status': { 'state': STATE.STOPPED },
                }],
            };
            if (newDvr.encryptedSource) {
                payload['instances'][0]['protection'] = {
                    keyServerUrl: newDvr.protectionKeyServerUrl,
                    contentId: newDvr.protectionContentId,
                };
            }
            let response = await solutionApi.createDvr(accountId, payload);
            if (response.ok) {
                updateDvrErrors(null);
                updateShowNewDvr(false);
                ok = true;
            } else {
                console.log(response);
                updateDvrErrors(response.data);
            }

        } catch (exception) {
            console.log(exception);
        }
        return ok;
    }, [solutionApi, userDetails.accountID]);

    const onNewDvrCancel = useCallback(() => {
        updateDvrErrors(null);
        updateShowNewDvr(false);
    }, []);

    const onEditDvrCancel = useCallback(() => {
        updateDvrErrors(null);
        updateDvrDataEditing(null);
    }, []);

    const onSaveDvr = useCallback(async (updatedDvr) => {
        try {
            const accountId = userDetails.accountID;
            const payload = { ...dvrDataEditing, ...{
                name: updatedDvr['name'],
                template: updatedDvr['template'],
                instances: [{ ...dvrDataEditing.instances[0], ... {
                    name: `${updatedDvr['name']}_0`,
                    location: updatedDvr['location'],
                    sourceURL: updatedDvr['sourceUrl'],
                    storageId: updatedDvr['storageUrl']
                } }]
            } };
            if (updatedDvr.encryptedSource) {
                payload['instances'][0]['protection'] = {
                    keyServerUrl: updatedDvr.protectionKeyServerUrl,
                    contentId: updatedDvr.protectionContentId,
                };
            } else {
                payload['instances'][0]['protection'] = null;
            }
            const response = await solutionApi.updateDvr(accountId, payload.id, payload);
            if (response.ok) {
                updateDvrDataEditing(null);
                updateDvrErrors(null);
            } else {
                updateDvrErrors(response.data);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [userDetails.accountID, solutionApi, dvrDataEditing]);

    const canCreateDvr = testPermission(CAN_CREATE_DVR, permissionsMap);
    const createButtonProps = {
        onClick: () => {
            updateShowNewDvr(true);
        }
    };
    let createButtonText = 'Click to create a new DVR';
    if (!canCreateDvr) {
        createButtonProps['disabled'] = true;
        createButtonText = 'You do not have the required permissions to create a DVR';
    }

    const headerForm = () => (
        <Row justify='start'>
            <Col>
                <Tooltip title={createButtonText}><Button className='hook-dvrs-newdvr' {...createButtonProps}>New DVR</Button></Tooltip>
            </Col>
        </Row>
    );

    const showEditDvr = dvrDataEditing != null;

    return <>
        <AdvancedControlModal
            dvr={advancedControlDvr}
            onOk={() => {
                updateAdvancedControlDvr(null);
            }}
        />
        <Row align='middle'>
            <Col>
                <FontAwesomeIcon icon={faPlayCircle} size='4x' />
            </Col>
            <Col>
                <PageHeader backIcon={null} title="DVRs" />
            </Col>
        </Row>
        <Row className='gutter-bottom dvrs-info-row '>
            <Col>
                <Space>
                    <FontAwesomeIcon className='dvrs-info-icon' icon={faInfoCircle} size='2x' color='blue' />
                    <span>Manage DVRs for non-linear content delivery.</span>
                </Space>
            </Col>
        </Row>
        <Ticker asyncCallback={refreshDvrs} timeout={DVRS_REFRESH_PERIOD_MS} />
        <Table
            className='dvrs-table hook-dvrs-dashboard-dvrstable'
            size='middle'
            dataSource={dvrs}
            columns={columns}
            rowKey='id'
            title={headerForm}
            scroll={{ x: true }}
            pagination={{
                defaultPageSize: 50,
                showSizeChanger: true,
            }}
        />
        <EditDvrModal
            errors={dvrErrors}
            visible={showEditDvr}
            dvrData={dvrDataEditing}
            onConfirm={onSaveDvr}
            onCancel={onEditDvrCancel}
            editEnabled={dvrEditEnabled}
        />
        <NewDvrModal
            errors={dvrErrors}
            visible={showNewDvr}
            onConfirm={onAddNewDvr}
            onCancel={onNewDvrCancel}
        />
    </>;
}
