import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useState, useCallback } from 'react';
import DvrForm from './DvrForm';
import { useEffect } from 'react';

export default function EditDvrModal(props) {

    const {
        visible = false,
        dvrData,
        onConfirm = () => {},
        onCancel = () => {},
        errors = null,
        editEnabled = true
    } = props;

    const [form] = Form.useForm();

    const [dvrEdit, updateDvrEdit] = useState({});
    const [okToPut, updateOkPut] = useState(true);

    const updateValid = useCallback((isValid) => {
        updateOkPut(isValid);
    }, [updateOkPut]);

    const doConfirm = useCallback(async () => {
        // Pass true to obtain all values, whether mounted or not.
        onConfirm(form.getFieldsValue(true));
    }, [form, onConfirm]);

    const doCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    useEffect(() => {
        updateDvrEdit({
            id: dvrData?.id,
            name: dvrData?.name,
            template: dvrData?.template,
            location: dvrData?.instances[0].location,
            sourceUrl: dvrData?.instances[0].sourceURL,
            storageUrl: dvrData?.instances[0].storageId,
            encryptedSource: dvrData?.instances[0].protection?.keyServerUrl
                && dvrData?.instances[0].protection?.contentId,
            protectionKeyServerUrl: dvrData?.instances[0].protection?.keyServerUrl,
            protectionContentId: dvrData?.instances[0].protection?.contentId,
        });
    }, [dvrData]);

    if (!editEnabled) {
        return (
            <Modal
                title="View DVR"
                open={visible}
                className='hook-dvrs-editdvr-menu'
                onCancel={doCancel}
                cancelText='Close'
                cancelButtonProps={{ className: 'hook-dvrs-dvrconfig-editdvrcancel' }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <DvrForm dvr={dvrEdit} form={form} errors={errors} editEnabled={false} />
            </Modal>
        );
    } else {
        return (
            <Modal
                title="Edit DVR"
                open={visible}
                className='hook-dvrs-editdvr-menu'
                onOk={doConfirm}
                okText='Save changes'
                okButtonProps={{ disabled: !okToPut, className: 'hook-dvrs-dvrconfig-editdvrconfirm' }}
                onCancel={doCancel}
                cancelButtonProps={{ className: 'hook-dvrs-dvrconfig-editdvrcancel' }}
            >
                <DvrForm dvr={dvrEdit} form={form} errors={errors} editEnabled={true} updateValid={updateValid}/>
            </Modal>
        );
    }
}

EditDvrModal.propTypes = {
    dvrData: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    editEnabled: PropTypes.bool,
    errors:PropTypes.object
};
