import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function CopyPaster({ text, onComplete }) {

    const textRef = useRef(null);

    const style = {
        position: 'fixed',
        top: 0,
        left: -6000,
    };

    useEffect(() => {
        if (text) {
            const selection = window.getSelection();
            let range = document.createRange();
            range.selectNode(textRef.current);
            selection.removeAllRanges();
            selection.addRange(range);

            navigator.clipboard.writeText(text);
            onComplete();
        }
    }, [text, onComplete]);

    return <input ref={textRef} style={style} defaultValue={text ? text : ''} />;
}

CopyPaster.propTypes = {
    text: PropTypes.string,
    onComplete: PropTypes.func
};
