import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { PermissionsContext, testPermission } from '../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faVideo, faCopy, faShieldAlt, faCloud, faCompressArrowsAlt, faPlayCircle, faKey } from '@fortawesome/free-solid-svg-icons';

const { Sider } = Layout;

const CAN_ADMIN_DEPLOYMENTS = 'deployments.admin';
const CAN_ADMIN_TEMPLATES = 'templates.admin';
const CANT_LIST_DVRS = 'dvrs.list';

import './MKMenu.css';

export default function MKMenu() {
    const [collapsed, setCollapsed] = useState(false);

    const location = useLocation();
    const pathItems = location.pathname.split('/');

    const selectMenuItem = useCallback((path) => {
        let key = path[1];
        if (key != null && key === '')
            return 'home';
        return key;
    }, []);

    const selectedMenu = selectMenuItem(pathItems);

    const toggle = useCallback((nextState) => {
        setCollapsed(nextState);
    }, []);

    const permissionsMap = useContext(PermissionsContext);

    const canAdminDeployments = testPermission(CAN_ADMIN_DEPLOYMENTS, permissionsMap);
    const canAdminTemplates = testPermission(CAN_ADMIN_TEMPLATES, permissionsMap);
    const canListDvrs = testPermission(CANT_LIST_DVRS, permissionsMap);

    const items = [
        // {
        //     We'll replace Channels as a home page with a better dashboard when time permits.
        //     label: <Link to="/">Home</Link>,
        //     icon: <FontAwesomeIcon fixedWidth={true} icon={faHome} />,
        //     key: 'home'
        // },
        {
            label: <Link to="/" className='hook-mk-menu-channels'>Channels</Link>,
            icon: <FontAwesomeIcon fixedWidth={true} icon={faVideo} />,
            key: 'home'
        },
        canListDvrs ?
            {
                label: <Link to="/dvrs" className='hook-mk-menu-dvrs'>DVRs</Link>,
                icon: <FontAwesomeIcon fixedWidth={true} icon={faPlayCircle} />,
                key: 'dvrs'
            }
            : null,
        {
            label: <Link to="/muxes" className='hook-mk-menu-muxes'>Muxes</Link>,
            icon: <FontAwesomeIcon fixedWidth={true} icon={faCompressArrowsAlt} />,
            key: 'muxes'
        },
        {
            label: 'Ingress/Egress',
            icon: <FontAwesomeIcon fixedWidth={true} icon={faSignInAlt} />,
            children: [
                {
                    label: <Link to="/sources" className='hook-mk-menu-sources'>Sources</Link>,
                    key: 'sources'
                },
                {
                    label: <Link to="/feeds" className='hook-mk-menu-feeds'>Feeds</Link>,
                    key: 'feeds'
                },
            ]
        },
        {
            label: <Link to="/whitelists" className='hook-mk-menu-security'>Whitelists</Link>,
            icon: <FontAwesomeIcon fixedWidth={true} icon={faShieldAlt} />,
            key: 'whitelists'
        },
        canAdminTemplates ?
            {
                label: <Link to="/templates" className='hook-mk-menu-templates'>Templates</Link>,
                icon: <FontAwesomeIcon fixedWidth={true} icon={faCopy} />,
                key: 'templates'
            }
            : null,
        canAdminDeployments ?
            {
                label: <Link to="/deployments" className='hook-mk-menu-deployments'>Deployments</Link>,
                icon: <FontAwesomeIcon fixedWidth={true} icon={faCloud} />,
                key: 'deployments'
            }
            : null,
        testPermission('credentials.list', permissionsMap) ?
            {
                label: <Link to="/credentials">Credentials</Link>,
                icon: <FontAwesomeIcon fixedWidth={true} icon={faKey} />,
                key: 'credentials'
            }
            : null
    ];

    return (
        <Sider
            className='mk-layout-slider'
            collapsible={true}
            collapsed={collapsed}
            onCollapse={toggle}
            collapsedWidth={64}
            theme='light'
        >
            <Menu
                selectedKeys={[selectedMenu]}
                items={items}
                mode="inline"
            />
        </Sider>
    );
}

MKMenu.propTypes = {
    collapsed: PropTypes.bool
};
