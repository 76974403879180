import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { faExclamationCircle, faInfoCircle, faPlay, faSpinner, faStop, faCodeBranch, faFile } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Tooltip, Input, Space, Descriptions, Grid, Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SolutionApiContext, SelectedAccountContext } from '../../services';
import './ClusterCard.css';

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

export default function ClusterCard({
    deploymentId,
    cluster,
    clusterStatus,
    updateInfoModalData,
    ...antdProps
}) {
    // antdProps need to be passed to the Panel component returned below.

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountID = userDetails.accountID;
    const [showVersionModal, updateShowVersionModal] = useState(false);
    const [versionNumber, updateVersionNumber] = useState(null);
    const screens = useBreakpoint();

    const downloadCreds = (recordConfig, recordStatus) => {
        try {
            const element = document.createElement('a');
            let creds = recordStatus.kube_credentials;
            const file = new Blob([creds], {
                type: 'yaml'
            });
            element.href = URL.createObjectURL(file);
            if (recordConfig.name) {
                element.download = 'kubeCredentials_' + recordConfig.name + '.yaml';
            } else {
                element.download = 'kubeCredentials.yaml';
            }
            document.body.appendChild(element);
            element.click();
        } catch (exception) {
            console.log(exception);
        }
    };

    const startCluster = useCallback(() => {
        try {
            Modal.confirm({
                title: `Are you sure you want to start the cluster '${cluster.id}'?`,
                icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
                async onOk() {
                    await solutionApi.startCluster(accountID, deploymentId, cluster.id);
                },
            });
        } catch (exception) {
            console.log(exception);
        }
    }, [accountID, solutionApi, deploymentId, cluster.id]);

    const stopCluster = useCallback(() => {
        try {
            Modal.confirm({
                title: `Are you sure you want to stop the cluster '${cluster.id}'?`,
                icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
                async onOk() {
                    await solutionApi.stopCluster(accountID, deploymentId, cluster.id);
                },
            });
        } catch (exception) {
            console.log(exception);
        }
    }, [accountID, solutionApi, deploymentId, cluster.id]);

    const upgradeCluster = useCallback(() => {
        const response = solutionApi.upgradeCluster(accountID, deploymentId, cluster.id, versionNumber);
        console.log(response);

        updateShowVersionModal(false);
        updateVersionNumber(null);

    }, [accountID, solutionApi, deploymentId, cluster.id, versionNumber]);

    const hideVersionModel = useCallback(() => {
        updateShowVersionModal(false);
        updateVersionNumber(null);
    },[]);

    const setVersionNumber = useCallback((e) => {
        if (e !== null) {
            updateVersionNumber(e.target.value);
        }
    },[]);

    let startButtonText = '';
    const startProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faPlay} />
    };

    let stopButtonText = '';
    const stopProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faStop} />
    };

    let infoButtonText = 'Cluster Info';
    const infoProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faInfoCircle} />
    };
    infoProps.onClick = () => {
        const clusterStatusWithoutPassword = structuredClone(clusterStatus);
        clusterStatusWithoutPassword.controller_password = '*';
        updateInfoModalData({
            'title': 'Cluster Info',
            'data': clusterStatusWithoutPassword
        });
    };
    let upgradeButtonText = 'Upgrade Cluster';
    const upgradeProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faCodeBranch} />
    };
    upgradeProps.onClick = () => { updateShowVersionModal(true); };

    let credsButtonText = 'Kube Credentials';
    const credsProps = {
        shape: 'circle',
        icon: <FontAwesomeIcon icon={faFile} />
    };
    credsProps.onClick = () => { downloadCreds(cluster, clusterStatus); };
    credsProps.disabled = ['Deploying', 'Deleting', 'Stopping'].includes(clusterStatus.status);

    switch (clusterStatus.status) {
    case 'Stopped':
        startProps.icon = <FontAwesomeIcon icon={faPlay}/>;
        startProps.onClick = ()=>{ startCluster(); };
        startProps.disabled = false;
        startButtonText = 'Activate';

        credsProps.disabled = true;
        upgradeProps.disabled = false;

        stopProps.disabled = true;
        stopButtonText = 'Already Deactivated';

        break;
    case 'Deploying':
        startProps.icon = <FontAwesomeIcon icon={faSpinner} pulse={true} />;
        startProps.disabled = true;
        startButtonText = 'Already Activating';

        stopButtonText = 'Cannot stop when Activating';
        stopProps.disabled = true;

        upgradeButtonText = 'Cannot upgrade when Activating';
        upgradeProps.disabled = true;

        break;
    case 'Deployed':
        startProps.icon = <FontAwesomeIcon icon={faPlay} />;
        startProps.disabled = true;
        startButtonText = 'Already Activated';

        stopProps.icon = <FontAwesomeIcon icon={faStop} />;
        stopProps.disabled = false;
        stopProps.onClick = ()=>{ stopCluster(); };
        stopButtonText = 'Deactivate';

        credsProps.disabled = false;
        upgradeProps.disabled = false;
        break;
    case 'Deploy Failed':
        startProps.icon = <FontAwesomeIcon icon={faPlay} />;
        startProps.disabled = false;
        startProps.onClick = ()=>{ startCluster(); };
        startButtonText = 'Activate';

        stopProps.icon = <FontAwesomeIcon icon={faStop} />;
        stopProps.disabled = false;
        stopProps.onClick = ()=>{ stopCluster(); };
        stopButtonText = 'Deactivate';

        credsProps.disabled = true;
        upgradeProps.disabled = false;
        break;
    case 'Delete Failed':
        startProps.icon = <FontAwesomeIcon icon={faPlay} />;
        startProps.disabled = false;
        startProps.onClick = ()=>{ startCluster(); };
        startButtonText = 'Activate';

        stopProps.icon = <FontAwesomeIcon icon={faStop} />;
        stopProps.disabled = false;
        stopProps.onClick = ()=>{ stopCluster(); };
        stopButtonText = 'Deactivate';

        upgradeButtonText = startButtonText;
        upgradeProps.disabled = true;
        credsProps.disabled = true;
        break;
    case 'Scaling':
        startProps.disabled = true;
        startButtonText = 'Cannot start when Scaling';

        stopButtonText = 'Cannot stop when Scaling';
        stopProps.disabled = true;

        upgradeButtonText = 'Cannot upgrade when Scaling';
        upgradeProps.disabled = true;
        break;
    case 'Upgrading':
        startProps.disabled = true;
        startButtonText = 'Cannot start when Upgrading';

        stopButtonText = 'Cannot stop when Upgrading';
        stopProps.disabled = true;

        upgradeProps.icon = <FontAwesomeIcon icon={faSpinner} pulse={true} />;
        upgradeButtonText = 'Already Upgrading';
        upgradeProps.disabled = true;
        break;
    case 'Stopping':
    case 'Deleting':
        startProps.disabled = true;
        startButtonText = 'Cannot activate when Deactivating';

        stopProps.icon = <FontAwesomeIcon icon={faSpinner} pulse={true} />;
        stopProps.disabled = true;
        stopButtonText = 'Already Deactivating';

        upgradeButtonText = 'Cannot upgrade when deactivating or deleting';
        credsProps.disabled = true;
        upgradeProps.disabled = true;
        break;
    default:
        break;
    }

    return (
        <>
            <Panel
                {...antdProps}
                header={`Cluster ID: ${cluster.id}`}
                className='cluster-card-panel'
                key={cluster.id}
                extra={
                    <Space>
                        <Tooltip title={startButtonText}>
                            <Button {...startProps} />
                        </Tooltip>
                        <Tooltip title={stopButtonText}>
                            <Button {...stopProps} />
                        </Tooltip>
                        <Tooltip title={infoButtonText}>
                            <Button {...infoProps} />
                        </Tooltip>
                        <Tooltip title={upgradeButtonText}>
                            <Button {...upgradeProps} />
                        </Tooltip>
                        <Tooltip title={credsButtonText}>
                            <Button {...credsProps} />
                        </Tooltip>
                    </Space>
                }
            >
                <Descriptions
                    bordered={true}
                    column={{ xxl: 4, xl: 4, lg: 1, md: 1, sm: 1, xs: 1 }}
                    size='small'
                    layout={screens.xl ? 'vertical' : 'horizontal'}
                >
                    <Descriptions.Item label="Type">{cluster.type}</Descriptions.Item>
                    <Descriptions.Item label="Version">{cluster.version}</Descriptions.Item>
                    <Descriptions.Item label="Progress">
                        {
                            clusterStatus.progress === '' ?
                                'n/a' : clusterStatus.progress
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">{clusterStatus.status}</Descriptions.Item>
                </Descriptions>
            </Panel>
            <Modal
                title="Upgrade Cluster"
                open={showVersionModal}
                onOk={upgradeCluster}
                onCancel={hideVersionModel}
            >
                <p>Enter the cluster version you wish to update to.</p>
                <Input
                    placeholder="Cluster version (leave empty for latest)"
                    onChange={setVersionNumber}
                    value={versionNumber}
                />
                <p />
                <p>
                    WARNING: Changing the cluster version will result in
                    outages until the Cluster is brought back up.
                </p>
            </Modal>
        </>
    );
}

ClusterCard.propTypes = {
    deploymentId: PropTypes.string,
    cluster: PropTypes.object,
    clusterStatus: PropTypes.object,
    updateInfoModalData: PropTypes.func
};
