
import { faPlay, faSpinner, faStop } from '@fortawesome/free-solid-svg-icons';

export const STOPPED = 'stopped';
export const STARTING = 'starting';
export const STARTED = 'started';
export const ERROR = 'error';
export const STOPPING = 'stopping';

const DISPLAY_INACTIVE = 'Inactive';
const DISPLAY_GOING_ACTIVE = 'Going active';
const DISPLAY_ACTIVE = 'Active';
const DISPLAY_ERROR_ACTIVATING = 'Error activating';
const DISPLAY_DEACTIVATING = 'Deactivating';

const PLAY_ICON = faPlay;
const STOP_ICON = faStop;
const SPINNER_ICON = faSpinner;

const aggregatedStatusMatrix = {};

// Single instance
aggregatedStatusMatrix[STOPPED] = { text: DISPLAY_INACTIVE, icon: PLAY_ICON };
aggregatedStatusMatrix[STARTING] = { text: DISPLAY_GOING_ACTIVE, icon: SPINNER_ICON };
aggregatedStatusMatrix[STARTED] = { text: DISPLAY_ACTIVE, icon: STOP_ICON };
aggregatedStatusMatrix[ERROR] = { text: DISPLAY_ERROR_ACTIVATING, icon: STOP_ICON };
aggregatedStatusMatrix[STOPPING] = { text: DISPLAY_DEACTIVATING, icon: SPINNER_ICON };


const getMappedStatus = function (dvr) {
    if (dvr.instances.length === 1) {
        return aggregatedStatusMatrix[dvr.instances[0].status.state].text;
    }
};

export const getMappedIcon = function (dvr) {
    if (dvr.instances.length === 1) {
        return aggregatedStatusMatrix[dvr.instances[0].status.state].icon;
    }
};

export const getMappedDisabledIconState = function (icon) {
    switch (icon){
    case SPINNER_ICON:
        return true;
    case STOP_ICON:
    case PLAY_ICON:
        return false;
    default:
        return false;
    }
};

export const getInstanceStatus = function (state) {
    return aggregatedStatusMatrix[state].text;
};

export const getInstanceIcon = function (state) {
    return aggregatedStatusMatrix[state].icon;
};

export default getMappedStatus;
