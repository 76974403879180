import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SolutionApiContext, SelectedAccountContext, PermissionsContext, testPermission } from '../../services';
import { Modal, Table, Button, Row, Col, Tooltip, Popover, Skeleton, Badge, Dropdown, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faExclamationCircle, faSpinner, faPencilAlt, faInfo,
    faEllipsisH, faEllipsisV,faImage, faCopy, faCheck, faExclamationTriangle, faFilm, faCogs, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import AdvancedControlModal from './AdvancedControlModal';
import SingleChannelTable from './SingleChannelTable';
import NewChannelModal from './NewChannelModal';
import EditChannelModal from './EditChannelModal';
import { CopyPaster } from '../util';
import { ModalMonitor } from '../../components/InlineMonitor';
import { SearchFilter, onFilterIncludes } from '../../components/listview/ListView';

import getMappedStatus, { getMappedIcon, getMappedDisabledIconState } from './ChannelStates';

const CAN_CREATE_CHANNEL = 'channels.add';
const CAN_EDIT_CHANNEL = 'channels.edit';
const CAN_DELETE_CHANNEL = 'channels.delete';
const CAN_START_CHANNEL = 'channels.start';
const CAN_STOP_CHANNEL = 'channels.stop';

function OutputEntry({ type, url }) {

    const [copyPaste, updateCopyPaste] = useState(null);

    return <tr key={type}>
        <td><CopyPaster text={copyPaste} onComplete={() => {updateCopyPaste(null);}}/>{`${type}`}:</td>
        <td>{url}</td>
        <td><Button className='mk-link-button hook-channels-channeltable-outputtablecopy' type='link' style={{ marginLeft: '8px' }} icon={<FontAwesomeIcon icon={faCopy} />} onClick={() => updateCopyPaste(url)} /></td>
    </tr>;
}

OutputEntry.propTypes = {
    type: PropTypes.string,
    url: PropTypes.string
};

function OutputsBlock({ channel }) {
    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const accountId = userDetails.accountID;

    const [outputUrls, updateOutputUrls] = useState([]);
    const [showPopover, updateShowPopover] = useState(false);

    const getOutputValue = useCallback(async (accountId, channelId) => {

        let outputResponse = await solutionApi.getChannelOutput(accountId, channelId);

        if (outputResponse.ok) {
            updateOutputUrls(outputResponse.data);
        }

    },[solutionApi]);

    const loadOutputs = useCallback(() => {
        updateShowPopover(true);
        setTimeout(() => {getOutputValue(accountId, channel.id);}, 500);
    }, [getOutputValue, accountId, channel.id]);

    useEffect(() => {
    }, [accountId, channel.id, getOutputValue]);

    let content = [<tr key='row'><td><Skeleton.Input style={{ width: '300px' }} active={true} size='default'/></td></tr>];
    if (outputUrls.length > 0) {
        content = outputUrls.map((urlEntry) => {
            return <OutputEntry key={urlEntry.type} type={urlEntry.type} url={urlEntry.url} />;
        });
    }

    const popContent = <div><table><tbody>{content}</tbody></table></div>;

    let text;
    let disabled = true;

    switch (channel.state) {
    case 'started':
    case 'stop_requested':
    case 'stopping':
        text = 'View Output URLs';
        disabled = false;
        break;
    default:
        text = 'Ensure channel is active to view outputs';
        break;
    }

    return <>
        <Popover placement='left' content={popContent} className='hook-channels-channeltable-outputtable' title={ null } trigger="click" open={showPopover} onOpenChange={updateShowPopover} />
        <Tooltip title={text}>
            <Button disabled={disabled} className='hook-channels-channeltable-output' shape='circle' onClick={loadOutputs}>
                <FontAwesomeIcon icon={faImage} />
            </Button>
        </Tooltip>
    </>;
}

OutputsBlock.propTypes = {
    channel: PropTypes.object,
};

function MoreActions({ channel, monitorAction, advancedControlAction }) {

    const items = [
        {
            key: '1',
            label: 'Monitor channel',
            icon: (
                <FontAwesomeIcon
                    icon={faFilm}
                    className='advanced-control-menu-item-icon hook-channels-moreactions-monitor-action'
                />
            ),
            onClick: () => {
                monitorAction(channel.id);
            }
        },
        {
            key: '2',
            label: 'Advanced channel control',
            icon: (
                <FontAwesomeIcon
                    icon={faCogs}
                    className='advanced-control-menu-item-icon hook-channels-moreactions-advancedpopup-action'
                />
            ),
            onClick: () => {
                advancedControlAction(channel);
            }
        }
    ];

    return (
        <Dropdown menu={ { items } } trigger={['click']}>
            <Tooltip title='More actions'>
                <Button
                    className='hook-channels-channeltable-moreactionsbutton'
                    shape='circle'
                    icon={<FontAwesomeIcon icon={faEllipsisV}/>}
                />
            </Tooltip>
        </Dropdown>
    );
}

MoreActions.propTypes = {
    channel: PropTypes.object,
    monitorAction: PropTypes.func,
    advancedControlAction: PropTypes.func
};


export default function Channels({ channels, handleChange, loading, activeAlerts }) {

    const solutionApi = useContext(SolutionApiContext);
    const userDetails = useContext(SelectedAccountContext);
    const permissionsMap = useContext(PermissionsContext);
    const accountId = userDetails.accountID;

    const [showNewChannel, updateShowNewChannel] = useState(false);
    const [channelErrors, updateChannelErrors] = useState(null);
    const [channelDataEditing, updateChannelDataEditing] = useState(null);
    const [channelMonitoring, updateChannelMonitoring] = useState(null);
    const [channelEditEnabled, updateChannelEditEnabled] = useState(true);
    const [advancedControlChannel, updateAdvancedControlChannel] = useState(null);

    const canEditChannel = testPermission(CAN_EDIT_CHANNEL, permissionsMap);
    const canDeleteChannel = testPermission(CAN_DELETE_CHANNEL, permissionsMap);
    const canStartChannel = testPermission(CAN_START_CHANNEL, permissionsMap);
    const canStopChannel = testPermission(CAN_STOP_CHANNEL, permissionsMap);


    useEffect(() => {
        if (advancedControlChannel != null && channels) {
            const channel = channels.find(element => element.id === advancedControlChannel.id);
            if (channel){
                updateAdvancedControlChannel(channel);
            }
        }
    },[channels, advancedControlChannel]);

    const deleteRecord = useCallback((channelId, channelName) => {
        Modal.confirm({
            title: `Are you sure you want to delete the Channel '${channelName}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x' />,
            okButtonProps: { className: 'hook-channels-dashboard-channeldeleteconfirm' },
            cancelButtonProps: { className: 'hook-channels-dashboard-channeldeletecancel' },
            async onOk() {
                await solutionApi.deleteChannel(accountId, channelId);
            },
        });
    }, [accountId, solutionApi]);

    const editRecord = useCallback(async (channelId, channelType, editEnabled = true) => {
        try {
            const channelDataResponse = await solutionApi.getChannel(accountId, channelId, channelType);
            if (channelDataResponse.ok) {
                updateChannelDataEditing(channelDataResponse.data);
                updateChannelEditEnabled(editEnabled);
            } else {
                updateChannelDataEditing(null);
            }
        } catch (exception) {
            updateChannelDataEditing(null);
        }
    }, [solutionApi, accountId]);

    const startChannel = useCallback(async (channelId, instanceId) => {
        try {
            await solutionApi.startChannel(accountId, channelId, instanceId);
        } catch (exception) {
            console.log('problems');
        }
    },[solutionApi, accountId]);

    const stopChannel = useCallback((channelId, instanceId, channelName) => {
        Modal.confirm({
            title: `Are you sure you want to stop the Channel '${channelName}'?`,
            icon: <FontAwesomeIcon icon={faExclamationCircle} size='4x'/>,
            okButtonProps: { className: 'hook-channels-dashboard-channelstopconfirm' },
            cancelButtonProps: { className: 'hook-channels-dashboard-channelstopcancel' },
            async onOk() {
                await solutionApi.stopChannel(accountId, channelId, instanceId);
            },
        });
    }, [solutionApi, accountId]);

    const sourceHealth = useCallback((record) => {
        let content = <FontAwesomeIcon key='loading' icon={faEllipsisH} pulse />;
        const states = ['Active', 'Active (degraded)', 'Active (upgrading)', 'Active (degrading)'];
        const channelStarted = states.indexOf(getMappedStatus(record)) > -1;
        const source = record.source;

        if (source) {
            // Change the displayed text and colours depending on number of
            // alarms compared to sources.
            let text = 'Inactive';
            let cssClass = 'display-none';

            if (channelStarted) {
                text = 'All active sources OK';
                cssClass = 'source-active';
            }

            if (activeAlerts != null && activeAlerts[record.id]) {
                let channelAlerts = activeAlerts[record.id];

                // get the number of srt not receiving alarms that match each source ID
                let sources = [];
                let notReceivingAlarms = 0;

                for (let a = 0; a < record.instances.length; a++){
                    let instance = record.instances[a];
                    // only add sources from active instances
                    if (record.status.instances[a].state == 'started'){
                        for (let b = 0; b < instance.sources.length; b++) {
                            sources.push(instance.sources[b].sourceId);
                        }
                    }
                }

                for (let c = 0; c < channelAlerts.alerts.length; c++){
                    let alert = channelAlerts.alerts[c];
                    if (alert.code === 'MediakindSourceSrtNotReceiving' && sources.includes(alert.fields.sourceId)) {
                        notReceivingAlarms++;
                    }
                }

                // change the displayed text and colours depending on number of alarms compared to sources
                cssClass = 'source-inactive';
                text = 'Unknown';

                if (notReceivingAlarms == sources.length) {
                    cssClass = 'source-inactive';
                    text = 'All active sources DOWN';
                } else if (notReceivingAlarms > 0) {
                    cssClass = 'some-sources-inactive';
                    text = 'some active sources DOWN';
                } else if (channelAlerts && channelStarted) {
                    cssClass = 'some-sources-inactive';
                    text = 'receiving in ALARM';
                }
            }

            content = (
                <Space>
                    <FontAwesomeIcon className={cssClass} size='lg' icon={faExclamationTriangle} />
                    <span>{text}</span>
                </Space>
            );
        }
        return content;
    }, [activeAlerts]);

    const sourceInfo = useCallback((record) => {
        let content = (
            <Popover
                content={
                    <SingleChannelTable
                        channel={record}
                        activeAlerts={activeAlerts}
                        showActions={false}
                    />
                }
                trigger='hover'
                title='Channel Instances'
            >
                <FontAwesomeIcon size='lg' icon={faInfoCircle} />
            </Popover>
        );
        return content;
    }, [activeAlerts]);

    const openNewWindow = useCallback((channelId) => {
        window.open(`/view/${channelId}`, '_blank');
    }, []);

    const columns = [{
        title: 'Channel Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        onFilter: onFilterIncludes('name'),
        filterDropdown: SearchFilter,
        className: 'hook-channels-channeltable-name',
        defaultSortOrder: 'ascend'
    }, {
        title: 'Alarms',
        key: 'alarms',
        className: 'hook-channels-channeltable-alarms',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const iconMultiplier = '2x';
            let content = <FontAwesomeIcon className='hook-channels-channeltable-alarm-loading' key='loading' icon={faEllipsisH} pulse />;
            const channelStarted = record.state === 'started';

            if (activeAlerts != null) {
                let alarmClassName = channelStarted ? 'table-alarms-none hook-channels-channeltable-alarm-clear' : 'table-alarms-inactive hook-channels-channeltable-alarm-greyed';
                let alertCount = 0;
                let highestSeverity = 'none';
                content = <FontAwesomeIcon size={iconMultiplier} className={alarmClassName} icon={channelStarted ? faCheck : faExclamationTriangle} />;
                const channelAlerts = activeAlerts[record.id];
                if (channelAlerts != null) {
                    alertCount = channelAlerts.alerts.length;
                    channelAlerts.alerts.forEach((alert) => {
                        if (highestSeverity !== 'critical') {
                            highestSeverity = alert.severity;
                        }
                    });

                    alarmClassName = highestSeverity === 'critical' ? 'table-alarms-critical hook-channels-channeltable-alarm-active' : 'table-alarms-warning hook-channels-channeltable-alarm-active';

                    content = <Link to={`/alarms?channelId=${record.id}`}><Badge count={alertCount}>
                        <FontAwesomeIcon size={iconMultiplier} className={alarmClassName} icon={faExclamationTriangle} />
                    </Badge></Link>;
                }
            }

            return content;
        }
    }, {
        title: 'Template',
        sorter: (a, b) => a.template.localeCompare(b.template),
        onFilter: onFilterIncludes('template'),
        filterDropdown: SearchFilter,
        dataIndex: 'template',
        key: 'template',
        className: 'hook-channels-channeltable-template',
    }, {
        title: 'Deployment',
        dataIndex: 'region',
        sorter: (a, b) => a.region.localeCompare(b.region),
        onFilter: onFilterIncludes('region'),
        filterDropdown: SearchFilter,
        key: 'region',
        className: 'hook-channels-channeltable-region',
        render: (...[, record, ]) => {
            if (record.status.instances.length === 0) {
                return <Tooltip title='No sources are available for the desired channel location'>
                    <span className='unable-to-route-feed'>Unable to route to any sources</span>
                </Tooltip>;
            } else if (record.status.instances.length > 1) {
                let tooltip;
                if (record.status.instances[0].region !== '' && record.status.instances[1].region !== '') {
                    tooltip =  'Available in ' + record.status.instances[0].region + ' and ' + record.status.instances[1].region + ' regions.';
                }
                let content = <Tooltip title={tooltip}>
                    <span className='region-ha-text'>HA Channel</span>
                </Tooltip>;

                return content;
            }
            else {
                return record.region;
            }
        }
    },
    {
        title: 'Source Health',
        dataIndex: 'source',
        key: 'source',
        className: 'hook-channels-channeltable-source',
        render: (...[, record, ]) => {
            return sourceHealth(record);
        },
        colSpan: 2
    },
    {
        dataIndex: 'source',
        key: 'sourceInfo',
        render: (...[, record, ]) => {
            return sourceInfo(record);
        },
        colSpan: 0
    },
    {
        title: 'Status',
        sorter: (a, b) => a.state.localeCompare(b.state),
        onFilter: (value, record) => getMappedStatus(record).includes(value.trim()),
        filterDropdown: SearchFilter,
        dataIndex: 'state',
        key: 'state',
        className: 'hook-channels-channeltable-state',
        render: (...[, record, ]) => {
            return getMappedStatus(record);
        }
    }, {
        title: 'Actions',
        key: 'actions',
        className: 'hook-channels-channeltable-actions',
        // eslint-disable-next-line react/display-name
        render: (...[, record, ]) => {
            const channelId = record.id;
            const channelName = record.name;
            const channelType = record.type;

            // Play/Stop Icon
            const channelMappedStatus = getMappedStatus(record);

            let startButtonText = '';
            const startStopIcon = getMappedIcon(record);
            const iconPulse = (startStopIcon === faSpinner) ? true : false;
            const startStopProps = {
                shape: 'circle',
                icon: <FontAwesomeIcon icon={startStopIcon} pulse={iconPulse}/>,
                disabled: getMappedDisabledIconState(startStopIcon, record)
            };

            let editButtonText = 'View';
            const editProps = {
                shape:'circle',
                icon: <FontAwesomeIcon icon={faInfo}/>,
                onClick: ()=>{ editRecord(channelId, channelType, false); }
            };

            let deleteButtonText = channelMappedStatus === 'Inactive' ? 'Delete' : 'Cannot delete when Active';
            const deleteProps = {
                shape:'circle',
                icon: <FontAwesomeIcon icon={faTrashAlt} />,
                disabled: channelMappedStatus !== 'Inactive'
            };

            switch (channelMappedStatus) {
            case 'Inactive':
                startStopProps.onClick = ()=>{ startChannel(channelId, null); };
                startButtonText = 'Activate';

                editButtonText = 'Edit';
                editProps.icon = <FontAwesomeIcon icon={faPencilAlt}/>;
                editProps.onClick = ()=>{ editRecord(channelId, channelType, true); };

                deleteProps.onClick = ()=>{ deleteRecord(channelId, channelName); };

                if (!canStartChannel) {
                    startButtonText = 'You lack the required permissions to start a channel';
                    startStopProps.disabled = true;
                }

                break;

            case 'About to activate':
            case 'Going active':
                startButtonText = 'Activating';

                break;
            case 'Active (upgrading)':
                startButtonText = 'Upgrading';

                break;
            case 'Error activating':
                startButtonText = 'Use Advanced Channel Control';

                break;
            case 'Active':
                startStopProps.onClick = ()=>{ stopChannel(channelId, null, channelName); };
                startButtonText = 'Deactivate';

                if (!canStopChannel) {
                    startButtonText = 'You lack the required permissions to stop a channel';
                    startStopProps.disabled = true;
                }

                break;
            case 'About to deactivate':
            case 'Deactivating':
                startButtonText = 'Deactivating';

                break;
            case 'Active (degrading)':
                startButtonText = 'Degrading';

                break;
            case 'Active (degraded)':
                startButtonText = 'Use Advanced Channel Control';

                break;
            default:
                break;
            }

            if (!canEditChannel) {
                editButtonText = 'You lack the required permissions to make changes to any channels.';
                editProps.disabled = true;
            }

            if (!canDeleteChannel) {
                deleteButtonText = 'You lack the required permissions to delete any channels';
                deleteProps.disabled = true;
            }

            return (
                <Space size={4}>
                    <Tooltip title={startButtonText}>
                        <Button className='hook-channels-channeltable-startstopbutton' { ...startStopProps } />
                    </Tooltip>
                    <Tooltip title={editButtonText}>
                        <Button className='hook-channels-channeltable-editbutton' { ...editProps } />
                    </Tooltip>
                    <Tooltip title={deleteButtonText}>
                        <Button className='hook-channels-channeltable-deletebutton' { ...deleteProps }/>
                    </Tooltip>
                    <MoreActions
                        channel={record}
                        monitorAction={(channelId) => {
                            updateChannelMonitoring(channelId);
                        }}
                        advancedControlAction={(channel) => {
                            updateAdvancedControlChannel(channel);
                        }}
                    />
                </Space>
            );
        }
    },
    ];

    const onAddNewChannel = useCallback(async (newChannel) => {
        let ok = false;
        try {
            const accountId = userDetails.accountID;
            let response = await solutionApi.createChannel(accountId, newChannel);
            if (response.ok) {
                updateChannelErrors(null);
                updateShowNewChannel(false);
                ok = true;
            } else {
                console.log(response);
                updateChannelErrors(response.data);
            }

        } catch (exception) {
            console.log(exception);
        }
        return ok;
    }, [solutionApi, userDetails.accountID]);

    const onNewChannelCancel = useCallback(() => {
        updateChannelErrors(null);
        updateShowNewChannel(false);
    }, []);

    const onEditChannelCancel = useCallback(() => {
        updateChannelErrors(null);
        updateChannelDataEditing(null);
    }, []);

    const onSaveChannel = useCallback(async (updateChannel) => {

        try {
            const accountId = userDetails.accountID;
            const response = await solutionApi.updateChannel(accountId, updateChannel.id, updateChannel);
            if (response.ok) {
                updateChannelDataEditing(null);
                updateChannelErrors(null);
            } else {
                updateChannelErrors(response.data);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [userDetails.accountID, solutionApi]);

    const canCreateChannel = testPermission(CAN_CREATE_CHANNEL, permissionsMap);
    const createButtonProps = {
        onClick: () => {
            updateShowNewChannel(true);
        }
    };
    let createButtonText = 'Click to create a new channel';
    if (!canCreateChannel) {
        createButtonProps['disabled'] = true;
        createButtonText = 'You do not have the required permissions to create a channel';
    }

    const headerForm = () => (
        <Row justify='start'>
            <Col>
                <Tooltip title={createButtonText}><Button className='hook-channels-dashboard-newchannel' {...createButtonProps}>New Channel</Button></Tooltip>
            </Col>
        </Row>
    );

    const showEditChannel = channelDataEditing != null;

    return <>
        <ModalMonitor
            channelId={channelMonitoring}
            onOk={() => {
                updateChannelMonitoring(null);
            }}
            onOpenOther={openNewWindow}
        />
        <AdvancedControlModal
            channel={advancedControlChannel}
            onOk={() => {
                updateAdvancedControlChannel(null);
            }}
            activeAlerts={activeAlerts}
        />
        <Table
            className='channel-table hook-channels-dashboard-channelstable'
            loading={{
                spinning: loading,
                indicator: <FontAwesomeIcon icon={faSpinner} pulse />
            }}
            size='middle'
            dataSource={channels}
            columns={columns}
            rowKey='id'
            title={headerForm}
            onChange={handleChange}
            scroll={{ x: true }}
            pagination={{
                defaultPageSize: 50,
                showSizeChanger: true,
            }}
        />
        <EditChannelModal
            errors={channelErrors}
            visible={showEditChannel}
            channelData={channelDataEditing}
            onConfirm={onSaveChannel}
            onCancel={onEditChannelCancel}
            editEnabled={channelEditEnabled}
        />
        <NewChannelModal
            errors={channelErrors}
            visible={showNewChannel}
            onConfirm={onAddNewChannel}
            onCancel={onNewChannelCancel}
        />
    </>;
}

Channels.propTypes = {
    sources: PropTypes.object,
    templates: PropTypes.object,
    channels: PropTypes.array,
    activeAlerts: PropTypes.object,
    loading: PropTypes.bool,
    pagination: PropTypes.object,
    handleChange: PropTypes.func
};
