import React, { useState, useCallback, useContext, useEffect } from 'react';
import { SolutionApiContext, processResponseErrors } from '../../services';
import PropTypes from 'prop-types';
import { Form, Checkbox, Input, Select, Alert, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './DeploymentForm.css';
import { validateTrimmedWhitespace } from '../../services/FormUtils';

const getRegionsOfCloud = (cloudId, availableClouds) => {
    const cloudDetails = availableClouds.filter(cloud => cloud.id === cloudId);
    if (cloudDetails.length > 0) {
        return cloudDetails[0].regions;
    } else if (cloudId) {
        console.log(`Unknown cloud '${cloudId}' selected`);
    }
    return [];
};

const generateZoneLableAndTooltip = (zoneNumber) => {
    return (
        <Tooltip
            title={
                <pre>
                    Examples: <br />
                    &nbsp;AWS           to specify eu-west-2b      select Cloud: &apos;AWS&apos;           Region: &apos;eu-west-2&apos;    zone: &apos;b&apos; <br />
                    &nbsp;Azure         to specify us-west-2-1     select Cloud: &apos;Azure&apos;         Region: &apos;uswest2&apos;      zone: &apos;1&apos;  <br />
                    &nbsp;Google Cloud  to specify europe-west1-c  select Cloud: &apos;Google Cloud&apos;  Region: &apos;europe-west1&apos; zone: &apos;c&apos;   <br />
                    Leave blank to allow automatic selection of an available zone within the selected Region
                </pre>
            }
            overlayClassName='custom-tooltip'
        >
            zone {zoneNumber}
            <span className='custom-tooltip-icon'>
                <QuestionCircleOutlined />
            </span>
        </Tooltip>
    );
};

const generateWorkerNodePoolInZoneTooltip = () => (
    <Tooltip title={
        <pre>
            Will node groups / nodepools for media processing applications (transcoder/stream processor/packager/etc) be created in this zone? <br />
            &nbsp;Default: [selected]
            &nbsp;The location of media processing applications can be determined by specifying the zone in the channel&apos;s source (ingress and egress) definition.<br />
            &nbsp;If zone is not specified in the source/feed/channel/multiplex location, then the availability zone in which media processing application will be instantiated<br />
            &nbsp;will be any zone where &apos;Deploy Workers nodegroup in zone&apos; is selected.<br />
        </pre>
    }
    overlayClassName='custom-tooltip'
    >
        <span className='custom-tooltip-icon'>
            <QuestionCircleOutlined />
        </span>
    </Tooltip>
);

export function DeploymentForm({
    originalDeploymentData,
    errors,
    editEnabled,
    updateValid,
    accountID,
    onSubmit
}) {
    const solutionApi = useContext(SolutionApiContext);

    const [availableClouds, updateAvailableClouds] = useState([]);
    const [availableRegions, updateAvailableRegions] = useState([]);
    const [manualZoneSelection, setManualZoneSelection] = useState(false);
    const [sameValueError, setSameValueError] = useState(false);

    const handleManualZoneSelectionChange = (e) => {
        setManualZoneSelection(e.target.checked);
    };

    const [form] = Form.useForm();

    const refreshClouds = useCallback(async () => {
        try {
            const cloudsResponse = await solutionApi.listClouds(accountID);
            if (cloudsResponse.ok) {
                updateAvailableClouds(cloudsResponse.data);
            }
        } catch (exception) {
            console.log(exception);
        }
    }, [solutionApi, accountID]);


    const checkSameValue = useCallback(() => {
        const { zone1, zone2, zone3 } = form.getFieldsValue();
        if (manualZoneSelection) {
            // nested as can't do an or with typeof and not typeof
            if (typeof zone1 !== 'undefined') {
                if (zone1 !== '') {
                    if (zone1 === zone2 || zone1 === zone3) {
                        setSameValueError(true);
                    } else {
                        setSameValueError(false);
                    }
                }
            }
            if (typeof zone2 !== 'undefined') {
                if (zone2 !== '') {
                    if (zone2 === zone1 || zone2 === zone3) {
                        setSameValueError(true);
                    } else {
                        setSameValueError(false);
                    }
                }
            }
            if (typeof zone3 !== 'undefined') {
                if (zone3 !== '') {
                    if (zone3 === zone1 || zone3 === zone2) {
                        setSameValueError(true);
                    } else {
                        setSameValueError(false);
                    }
                }
            }
        }
    }, [form, manualZoneSelection]);

    const validateZone1 = (rule, zone1, printToScreen) => {
        if (currentlySelectedCloud === 'azure') {
            if (zone1 && zone1.length !== 0 && zone1.length !== 1  && isNaN(parseInt(zone1))) {
                printToScreen(`For ${currentlySelectedCloud}, a valid entry is a single digit number`);
            } else {
                printToScreen();
            }
        } else {
            if (zone1 && zone1.length !== 0 && (zone1.length !== 1 || !isNaN(parseInt(zone1)))) {
                printToScreen(`For ${currentlySelectedCloud}, a valid entry is a single lowercase letter`);
            } else {
                printToScreen();
            }
        }
    };

    const validateZone2 = (rule, zone2, printToScreen) => {
        if (currentlySelectedCloud === 'azure') {
            if (zone2 && zone2.length !== 0 && zone2.length !== 1  && isNaN(parseInt(zone2))) {
                printToScreen(`For ${currentlySelectedCloud}, a valid entry is a single digit number`);
            } else {
                printToScreen();
            }
        } else {
            if (zone2 && zone2.length !== 0 && (zone2.length !== 1 || !isNaN(parseInt(zone2)))) {
                printToScreen(`For ${currentlySelectedCloud}, a valid entry is a single lowercase letter`);
            } else {
                printToScreen();
            }
        }
    };

    const validateZone3 = (rule, zone3, printToScreen) => {
        if (currentlySelectedCloud === 'azure') {
            if (zone3 && zone3.length !== 0 && zone3.length !== 1  && isNaN(parseInt(zone3))) {
                printToScreen(`For ${currentlySelectedCloud}, a valid entry is a single digit number`);
            } else {
                printToScreen();
            }
        } else {
            if (zone3 && zone3.length !== 0 && (zone3.length !== 1 || !isNaN(parseInt(zone3)))) {
                printToScreen(`For ${currentlySelectedCloud}, a valid entry is a single lowercase letter`);
            } else {
                printToScreen();
            }
        }
    };

    useEffect(() => {
        refreshClouds();
    }, [refreshClouds]);

    useEffect(() => {
        checkSameValue();
    }, [checkSameValue]);

    useEffect(() => {
        form.resetFields();
        setManualZoneSelection(false);
        form.setFieldsValue(originalDeploymentData);
        if (availableClouds.length) {
            const regions = originalDeploymentData.cloud ?
                getRegionsOfCloud(originalDeploymentData.cloud, availableClouds) : [];
            updateAvailableRegions(regions);
        }

        const zone_defaults = {
            zone1: '',
            zone2: '',
            zone3: ''
        };

        // Check initial values for each field and set defaults if not initialized.
        Object.keys(zone_defaults).forEach(fieldName => {
            if (!form.getFieldValue(fieldName)) {
                form.setFieldsValue({ [fieldName]: zone_defaults[fieldName] });
            }
        });

    }, [form, originalDeploymentData, availableClouds]);

    const errorsClassName = errors ? '' : 'form-hidden';

    let errorObj = {
        summary: '',
        errorsLookup: {}
    };

    if (errors && errors.errors) {
        errorObj = processResponseErrors(errors);
    }

    const getErrorsProps = useCallback((fieldName) => {
        let props = {};

        const lookup = errorObj.errorsLookup[fieldName];
        if (lookup) {
            props.validateStatus = 'error';
            props.help = lookup.text;
        }

        return props;
    }, [errorObj.errorsLookup]);

    const [currentlySelectedCloud, setCurrentlySelectedCloud] = useState('');

    const onValuesChanged = useCallback((changedValues, allValues) => {
        let resetRegion = false;

        if ('cloud' in changedValues) {
            // change in cloud - get available regions for new cloud
            allValues.region = undefined;
            updateAvailableRegions(getRegionsOfCloud(allValues.cloud, availableClouds));
            resetRegion = true;
            setCurrentlySelectedCloud(allValues.cloud);
        }

        if ('zone1' in changedValues || 'zone2' in changedValues || 'zone3' in changedValues) {
            checkSameValue();
        }

        if (allValues.version) {
            allValues.version = allValues.version.trim();
        }

        // Check whether parameters are valid for creating new deployment.
        updateValid(
            allValues.id &&
            allValues.id.length > 0 &&
            allValues.type &&
            allValues.type.length > 0 &&
            allValues.cloud &&
            allValues.region
        );

        if (resetRegion) {
            form.setFieldValue('region', allValues.region);
        }
    }, [availableClouds, form, updateValid, checkSameValue]);

    return (
        <Form
            form={form}
            id='deployment-modal-form'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout='horizontal'
            initialValues={{
                'zone1WorkerNodePool': true,
                'zone2WorkerNodePool': false,
                'zone3WorkerNodePool': false
            }}
            onValuesChange={onValuesChanged}
            onFinish={(values) => {
                // Remove the 'manualZoneSelection' from the values object
                const { manualZoneSelection, ...newValues } = values;
                if (! manualZoneSelection || typeof newValues.zone1 === 'undefined') {
                    newValues.zone1 = '';
                }
                if (! manualZoneSelection || typeof newValues.zone2 === 'undefined') {
                    newValues.zone2 = '';
                }
                if (! manualZoneSelection || typeof newValues.zone3 === 'undefined') {
                    newValues.zone3 = '';
                }
                onSubmit(newValues);
            }}
        >
            <Alert
                style={{ marginBottom: 8 }}
                className={errorsClassName}
                message={errors ? errorObj.summary : ''}
                type="error"
            />
            <Form.Item name='id' label='ID' {...getErrorsProps('id')} rules={[{ validator: validateTrimmedWhitespace }]}>
                <Input disabled={editEnabled} placeholder='A/V Pipe deployment ID' />
            </Form.Item>

            <Form.Item name='type' label='Deployment Type' {...getErrorsProps('type')} valuePropName="option">
                <Select
                    disabled={editEnabled}
                    placeholder='Select a type for this A/V Pipe deployment'
                >
                    <Select.Option
                        key='live'
                        value='live'
                    >
                        Live
                    </Select.Option>
                    <Select.Option
                        key='cdvr'
                        value='cdvr'
                    >
                        Cloud DVR
                    </Select.Option>
                    <Select.Option
                        key='od'
                        value='od'
                    >
                        On-Demand
                    </Select.Option>
                    <Select.Option
                        key='prisma'
                        value='prisma'
                    >
                        Prisma
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name='cloud' label='Cloud' {...getErrorsProps('cloud')}>
                <Select disabled={editEnabled} placeholder='Select a cloud for this A/V Pipe deployment'>
                    {
                        availableClouds.map(cloud => (
                            <Select.Option
                                key={cloud.id}
                                value={cloud.id}
                            >
                                {cloud.name}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <Form.Item name='region' label='Region' {...getErrorsProps('region')}>
                <Select
                    disabled={editEnabled}
                    placeholder='Select a region for this A/V Pipe deployment'
                    showSearch={true}
                    optionFilterProp="children"
                >
                    {
                        availableRegions.map(region => (
                            <Select.Option
                                key={region.value}
                                value={region.value}
                            >
                                {region.name}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <Form.Item
                name='manualZoneSelection'
                label={
                    <Tooltip
                        title='By default, the three availability zones across which the deployment will span are
                        automatically selected. If there are specific zones which must be used, you can specify them.'
                        overlayClassName='custom-tooltip'
                    >
                        <span className='label-text'>Manually select zone(s)?</span>
                        <span className='custom-tooltip-icon'>
                            <QuestionCircleOutlined />
                        </span>
                    </Tooltip>
                }
                valuePropName='checked'
            >
                <Checkbox
                    checked={manualZoneSelection}
                    onChange={handleManualZoneSelectionChange}
                />
            </Form.Item>

            {manualZoneSelection ? (
                <>
                    <Form.Item name='zone1' label={generateZoneLableAndTooltip(1)} style={{ marginLeft: '50px', marginBottom: '0px' }} rules={[{ validator: validateZone1 }]}>
                        <Input placeholder='Automatically selected' />
                    </Form.Item>
                    <Form.Item name='zone1WorkerNodePool' valuePropName='checked' style={{ marginLeft: '285px', marginTop: '0px', marginRight: '5px' }}>
                        <Checkbox>
                                Create Workers nodegroup in zone 1? {generateWorkerNodePoolInZoneTooltip()}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name='zone2' label={generateZoneLableAndTooltip(2)} style={{ marginLeft: '50px', marginBottom: '0px' }} rules={[{ validator: validateZone2 }]}>
                        <Input placeholder='Automatically selected' />
                    </Form.Item>
                    <Form.Item name='zone2WorkerNodePool' valuePropName='checked' style={{ marginLeft: '285px', marginTop: '0px', marginRight: '5px' }}>
                        <Checkbox>
                            Create Workers nodegroup in zone 2? {generateWorkerNodePoolInZoneTooltip()}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name='zone3' label={generateZoneLableAndTooltip(3)} style={{ marginLeft: '50px', marginBottom: '0px' }} rules={[{ validator: validateZone3 }]}>
                        <Input placeholder='Automatically selected' />
                    </Form.Item>
                    <Form.Item name='zone3WorkerNodePool' valuePropName='checked' style={{ marginLeft: '285px', marginTop: '0px', marginRight: '5px' }}>
                        <Checkbox>
                            Create Workers nodegroup in zone 3? {generateWorkerNodePoolInZoneTooltip()}
                        </Checkbox>
                    </Form.Item>
                </>
            ) : null}

            {sameValueError ? (
                <Alert
                    style={{ marginBottom: 8 }}
                    message="The values of 'zone 1', 'zone 2' and 'zone 3' must be unique, or not set"
                    type="error"
                />
            ) : null}

            <Form.Item name='version' label='Version' {...getErrorsProps('version')} rules={[{ validator: validateTrimmedWhitespace }]}>
                <Input placeholder='A/V Pipe version (leave empty for latest)' />
            </Form.Item>
        </Form>
    );
}

DeploymentForm.propTypes = {
    originalDeploymentData: PropTypes.object,
    editEnabled: PropTypes.bool,
    errors: PropTypes.object,
    updateValid: PropTypes.func,
    accountID: PropTypes.string,
    onSubmit: PropTypes.func
};
