import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

function VideoPlayerInternal({ channelId, scope, sourceUrl, stSecret }) {
    /*global amc*/

    const playerId = channelId;
    const uniqueId = `${scope}-${playerId}`;

    const forceHttps = window.location.protocol.search('https') !== -1;
    sourceUrl = (sourceUrl && forceHttps) ? sourceUrl.replace('http:', 'https:') : sourceUrl;

    if (stSecret !== '') {
        sourceUrl = (sourceUrl) ? sourceUrl + '?start=LIVE&stSecret=' + stSecret : sourceUrl;
    } else {
        sourceUrl = (sourceUrl) ? sourceUrl + '?start=LIVE' : sourceUrl;
    }

    const createWmc =  useCallback((sourceUrl, componentId) => {
        const videoContainer = document.getElementById(componentId);
        const subContainer = document.getElementById('s' + componentId);

        let wmcMgr = new amc.AmcManager();
        if ((videoContainer != null) && (sourceUrl != null)) {
            const wmcEvents = amc.AmcEvents;
            const wmcConstants = amc.AmcConstants;

            const processEvent = async (eventObj) => {
                switch (eventObj.eventType) {
                case wmcEvents.AMC_EVENT_PLAY_READY:
                    //setup the video and subtitle containers
                    wmcMgr.setContainer(videoContainer, subContainer);
                    // initialize the playback with given parameters to be followed by events AMC_EVENT_DEVICE_REGISTERED (first time) and AMC_EVENT_INIT_COMPLETE ( 2nd time onwards)
                    await wmcMgr.start();
                    await wmcMgr.doLiveNow();
                    await wmcMgr.setOffset(0, 'end');
                    break;
                case wmcEvents.AMC_EVENT_DEVICE_REGISTERED:
                case wmcEvents.AMC_EVENT_INIT_COMPLETE:
                    // start the VOD/LIVE playback
                    await wmcMgr.createPlayer(wmcConstants.IMC_MODE_ADAPTIVE);
                    break;
                case wmcEvents.AMC_EVENT_ERROR:
                    console.error(eventObj.code + ':' + ((eventObj.message) ? eventObj.message : eventObj.message.error));
                    break;
                case wmcEvents.AMC_EVENT_STATE_CHANGED: {
                    const currentState = wmcMgr.getState();

                    if (currentState === 5) { // playing
                        wmcMgr.doLiveNow();
                        wmcMgr.setOffset(0, 'end');
                    }
                    break;
                }

                default:
                }
            };

            wmcMgr.setPlayerKey('91178fce-cecc-43a2-b10a-9739a2e943bd');
            wmcMgr.setMaxBandwidth(500); // Kbps

            // Setup the logger level to DEBUG
            wmcMgr.addEventListener(wmcEvents.AMC_EVENT_ERROR, processEvent);
            wmcMgr.addEventListener(wmcEvents.AMC_EVENT_STATE_CHANGED, processEvent);
            wmcMgr.addEventListener(wmcEvents.AMC_EVENT_VIDEO_POSITION_CHANGED, processEvent);
            wmcMgr.addEventListener(wmcEvents.AMC_EVENT_SEEK_COMPLETE, processEvent);
            wmcMgr.addEventListener(wmcEvents.AMC_EVENT_DEVICE_REGISTERED, processEvent);
            wmcMgr.addEventListener(wmcEvents.AMC_EVENT_INIT_COMPLETE, processEvent);
            wmcMgr.addEventListener(wmcEvents.AMC_EVENT_PLAY_READY, processEvent);

            // Leave for now in case we need it
            //wmcMgr.setLogLevel(wmcConstants.IMC_LOG_DEBUG);
            wmcMgr.setExternalSourceParams({ sourceUrl: sourceUrl, licenseUrl: '' });
            wmcMgr.init();
        }
        return wmcMgr;
    }, []);


    const onSourceChange = useCallback((sourceUrl, componentId) => {
        let newManager = null;
        if ((sourceUrl != null) && (componentId != null)) {
            newManager = createWmc(sourceUrl, componentId);
        }

        return newManager;
    }, [createWmc]);

    useEffect(() => {
        let createdWmc = onSourceChange(sourceUrl, uniqueId);

        if (createdWmc != null) {
            return () => {
                const oldWmc = createdWmc;

                if (oldWmc != null) {
                    console.log('Stop player');
                    oldWmc.stop();
                }
            };
        }
    }, [onSourceChange, sourceUrl, uniqueId]);

    const elemStyle = {
        height: '500px',
        width: '100%'
    };

    return <div id={`${uniqueId}container`} style={{ display:'flex', width: '1000px' }}>
        <video id={uniqueId + 'dummy'} poster='/assets/cband.jpg' crossOrigin='true' style={elemStyle} />
        <video id={uniqueId} poster='/assets/cband.jpg' crossOrigin='true' style={ {
            ...elemStyle,
            marginLeft: '-100%'
        }} />
        <div id={'s' + uniqueId} />
    </div>;
}

VideoPlayerInternal.propTypes = {
    scope: PropTypes.string, // used for providing uniqueness to this component due to 3rd party comp - TODO could UUID it
    channelId: PropTypes.string, // also for uniquesness and context
    sourceUrl: PropTypes.string,
    stSecret: PropTypes.string
};


const VideoPlayer = React.memo(VideoPlayerInternal);

export default VideoPlayer;
