import React from 'react';

class CiMode {
    constructor(config) {
        let ciModeSetting = config?.frontend?.ci_mode;
        this.runningCI = ciModeSetting ? ciModeSetting : false;
        console.log('Running in CI: ', this.runningCI);
    }
}

const CiModeContext = React.createContext(null);

export {
    CiMode,
    CiModeContext
};

export default CiMode;

