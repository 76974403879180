import React, { useEffect, useContext, useState } from 'react';
import { Form, Input } from 'antd';
import { HomeOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { SaasApiContext, PermissionsContext } from '../../services';

export default function User() {
    const api = useContext(SaasApiContext);
    const permissions = useContext(PermissionsContext);
    const [userDetails, updateUserDetails] = useState({
        userDetails: null
    });

    const formItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
    };

    const iconStyle = { color: 'rgba(0,0,0,.25)' };
    const content = [];

    useEffect(() => {
        const onLoad = async () => {
            const userDetails = await api.decodeToken();
            updateUserDetails(userDetails);
        };

        onLoad();
    }, [api]);

    if (userDetails) {
        content.push(
            <Form.Item key="account" label="Customer Account">
                <Input
                    disabled={true}
                    value={userDetails.accountName}
                    prefix={<HomeOutlined style={iconStyle} />}
                />
            </Form.Item>
        );

        content.push(
            <Form.Item key="name" label="User Name">
                <Input
                    disabled={true}
                    value={userDetails.userName}
                    prefix={<UserOutlined style={iconStyle} />}
                />
            </Form.Item>
        );

        content.push(
            <Form.Item key="email" label="User Email">
                <Input
                    disabled={true}
                    value={userDetails.userEmail}
                    prefix={<MailOutlined style={iconStyle} />}
                />
            </Form.Item>
        );

        content.push(
            <Form.Item key="permissions" label="Aquila Permissions">
                {
                    permissions.permissions ? Object.keys(permissions.permissions).map((perm) => {
                        return <div key={perm}>{perm}</div>;
                    }) : []
                }
            </Form.Item>
        );

    } else {
        content.push(<div key='loading'>Loading User details.....</div>);
    }

    return (
        <Form
            {...formItemLayout}
            style={{
                marginTop: '16px'
            }}
            className='user-form'
        >
            {content}
        </Form>
    );
}
